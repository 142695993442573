import sourcesApi from '../../objects/sourcesAPI'

const initialState = {
  insertStatement: {},
  message: undefined,
  isSaving: false
}

export const changedInsertValueAction = {
  type: 'insert/CHANGED_INSERT_VALUE'
}

export const insertingAction = {
  type: 'insert/INSERTING'
}

export const insertedAction = {
  type: 'insert/INSERTED'
}

export const setSubNavigationAction = {
  type: 'insert/SET_SUB_NAVIGATION'
}

export const insert = (tableName, id) => async (dispatch, getState) => {
  const {
    insert: {
      insertStatement
    }
  } = getState()

  await dispatch(insertingAction)

  try {
    await sourcesApi.insertIntoSourceData(id, tableName, insertStatement)
    await dispatch({
      ...insertedAction,
      payload: {
        isSaving: false,
        insertStatement: {},
        message: 'Insert finished successfully'
      }
    })
  } catch (ex) {
    await dispatch({
      ...insertedAction,
      payload: {
        message: ex.data ? ex.data.error : 'Network Error - Try Again',
        isSaving: false
      }
    })
  }
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case insertingAction.type:
      return {
        ...state,
        isSaving: true,
        message: undefined
      }
    case insertedAction.type:
      return {
        ...state,
        ...payload
      }
    case changedInsertValueAction.type: {
      const { insertStatement } = state
      const { name, value, type } = payload
      return {
        ...state,
        insertStatement: {
          ...insertStatement,
          [name]: { value, type }
        },
        message: undefined
      }
    }
    case setSubNavigationAction.type:
      return {
        ...state,
        message: ''
      }
    default:
      return state
  }
}
