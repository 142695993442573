import PropTypes from 'prop-types'
import React from 'react'
import HelperText from './HelperText'
import useLock from '../../hooks/useLock'

import {
  FormControl,
  TextField as BaseTextField
} from '@mui/material'

export default function TextField (props) {
  const errored = Boolean(props.errorText)
  const lock = useLock()

  const inputProps = {
    readOnly: props.readonly,
    endAdornment: props.endAdornment
  }

  const handleTouch = event => {
    props.onChange(props.name, props.value, event.type)
  }

  const handleChange = event => {
    props.onChange(props.name, event.target.value, 'change')
  }

  return (
    <FormControl
      data-testid={props['data-testid']}
      error={errored}
      fullWidth
    >
      <HelperText />

      <BaseTextField
        data-testid='input'
        autoComplete='off'
        fullWidth
        variant='outlined'
        disabled={props.disabled || lock.enabled}
        required={props.helperText === 'Required'}
        autoFocus={props.autoFocus}
        multiline={props.rowCount > 1}
        error={errored}
        minRows={props.rowCount}
        name={props.name}
        label={props.label}
        placeholder={props.placeholder}
        inputRef={props.inputRef}
        InputProps={inputProps}
        value={props.value}
        onBlur={handleTouch}
        onFocus={handleTouch}
        onChange={handleChange}
      />

      <HelperText
        data-testid='label-helper'
      >{props.errorText || props.helperText}</HelperText>
    </FormControl>
  )
}

TextField.propTypes = {
  'data-testid': PropTypes.string,
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  autoFocus: PropTypes.bool,
  rowCount: PropTypes.number,
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  errorText: PropTypes.string,
  inputRef: PropTypes.object,
  endAdornment: PropTypes.node,
  value: PropTypes.string,
  onChange: PropTypes.func
}

/* istanbul ignore next */
TextField.defaultProps = {
  'data-testid': '',
  disabled: false,
  readonly: false,
  autoFocus: false,
  rowCount: 1,
  name: '',
  label: '',
  placeholder: '',
  helperText: '',
  errorText: '',
  inputRef: null,
  endAdornment: null,
  value: '',
  onChange: (_name, _value, _type) => {}
}
