import { Auth, API } from 'aws-amplify'
import countries from '../../store/objects/data/countries'

const DEFAULT_COUNTRY = {
  value: 'US',
  label: 'United States',
  region: 'us-east-1'
}

const handleError = (error) => Promise.reject(error.response)

function getRegionalAPIName (region) {
  return `redi-regional-${region}`
}

async function fetchAuthToken () {
  const session = await Auth.currentSession()
  const token = session.getIdToken().getJwtToken()

  return `Bearer ${token}`
}

async function getHeader () {
  const token = await fetchAuthToken()

  return {
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    }
  }
}

async function getBodyAndHeader (body) {
  const token = await fetchAuthToken()

  return {
    body,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    }
  }
}

function getCountry (code) {
  return countries.find(x => x.value === code) || DEFAULT_COUNTRY
}

function getBucketName (countryCode) {
  const ENV = process.env.REACT_APP_RGAENV
  const country = getCountry(countryCode)

  return `redi-projects-${ENV}-${country.region}.red-rgare.com`
}

async function getWebsocketUrl (countryCode) {
  const ENV = process.env.REACT_APP_RGAENV
  const token = await fetchAuthToken()
  const country = getCountry(countryCode)
  const prefix = `api-websocket-${country.region}`
  const suffix = ENV !== 'cust' ? `-${ENV}` : ''
  const address = `wss://${prefix}.rgare${suffix}-redi.cloud`
  const querystring = `Authorization=${token}`

  return `${address}?${querystring}`
}

async function callApi (verb, route, opts = {}) {
  const body = opts.body || undefined
  const headers = opts.headers || {}
  const querystring = new URLSearchParams(opts.query || {}).toString()
  const uri = [route, querystring].filter(item => item).join('?')
  const subRegion = opts.region || process.env.REACT_APP_REGION
  const region = `redi-regional-${subRegion}`
  const token = await fetchAuthToken()

  return API[verb](region, uri, {
    body,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
      ...headers
    }
  })
}

export const client = {
  get: (uri, opts) => callApi('get', uri, opts),
  post: (uri, body, opts) => callApi('post', uri, { ...opts, body }),
  put: (uri, body, opts) => callApi('put', uri, { ...opts, body }),
  delete: (uri, body, opts) => callApi('del', uri, { ...opts, body })
}

export default {
  apiName: 'redi',
  getBucketName,
  getCountry,
  getRegionalAPIName,
  getHeader,
  getBodyAndHeader,
  handleError,
  getWebsocketUrl,
  client
}
