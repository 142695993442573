import { ResponsiveCalendar } from '@nivo/calendar'
import React from 'react'
import PropTypes from 'prop-types'
const DateChart = ({ data }) => {
  const keys = []
  const dateData = []
  const { min, max } = data

  data.values.map(x => {
    keys.push(x.value)
    dateData.push({ day: x.value, value: Number(x.count) })
    return keys
  })

  return (
    <ResponsiveCalendar
      data={dateData}
      from={min}
      to={max}
      isInteractive={true}
      minValue='auto'
      maxValye='auto'
      emptyColor="#eeeeee"
      margin={{ top: 10, right: 20, bottom: 10, left: 40 }}
      direction="vertical"
      align="top-left"
      yearSpacing={40}
      monthBorderColor="#ffffff"
      monthSpacing={10}
      dayBorderWidth={1}
      dayBorderColor="#ffffff"
      legends={[
        {
          anchor: 'bottom-right',
          direction: 'row',
          translateY: 36,
          itemCount: 4,
          itemWidth: 42,
          itemHeight: 36,
          itemsSpacing: 14,
          itemDirection: 'right-to-left'
        }
      ]}
    />
  )
}
DateChart.propTypes = {
  data: PropTypes.any
}
export default DateChart
