import apiHelper from './apiHelper'
import { API } from 'aws-amplify'

let __region = process.env.REACT_APP_REGION

const setAPIRegion = region => {
  __region = region
}

const profileData = async body => {
  const bodyAndHeader = await apiHelper.getBodyAndHeader({
    inputParams: body
  })

  return API
    .post(
      apiHelper.getRegionalAPIName(__region),
      '/dataprofile',
      bodyAndHeader
    )
    .catch(apiHelper.handleError)
}

const stepGetStatus = async (arn) => {
  const header = await apiHelper.getHeader()

  return API
    .get(
      apiHelper.getRegionalAPIName(__region),
      `/step/${arn}`,
      header
    )
    .catch(apiHelper.handleError)
}

const stepGetStatusByRegion = async (arn, region) => {
  const header = await apiHelper.getHeader()

  return API
    .get(
      apiHelper.getRegionalAPIName(region),
      `/step/${arn}`,
      header
    )
    .catch(apiHelper.handleError)
}

// NEW - functions that use the API client

function waitForCompletion (executionArn, opts = {}) {
  const ms = opts.ms || 5000

  return new Promise((resolve, reject) => {
    const handle = setInterval(async () => {
      const result = await stepGetStatus(executionArn)

      if (result.status !== 'RUNNING') {
        clearInterval(handle)

        switch (result.status) {
          case 'SUCCEEDED':
          case 'ABORTED':
            return resolve(result)

          case 'FAILED':
            return reject(new Error(result.error))

          default:
            return reject(new Error(`Invalid status: ${result.status}`))
        }
      }
    }, ms)
  })
}

export default {
  profileData,
  setAPIRegion,
  stepGetStatus,
  stepGetStatusByRegion,

  waitForCompletion
}
