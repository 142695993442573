import 'ag-grid-enterprise'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import React from 'react'
import Grid from '@mui/material/Grid'
import MuiCircularProgress from '@mui/material/CircularProgress'
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog'
import ActionsRender from '../../home/ActionsRender'
import { styled } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { ReferenceServer } from '../../../store/pages/common/agGRid/agGridServers'

import {
  loadAllReferenceTables,
  deleteReferenceTable
} from '../../../store/pages/reference'

const DEFAULT_COLOR_DEF = {
  flex: 1,
  minWidth: 90,
  resizable: true
}

const BASE_COLUMN_DEFINITIONS = [
  {
    field: 'id',
    headerName: 'Table Id',
    suppressHeaderMenuButton: true,
    sortable: true
  },
  {
    field: 'ownerId',
    headerName: 'User',
    suppressHeaderMenuButton: true,
    sortable: true
  },
  {
    field: 'tableName',
    headerName: 'Table Name',
    suppressHeaderMenuButton: true,
    sortable: true
  },
  {
    field: 'country',
    headerName: 'Client Country',
    suppressHeaderMenuButton: true,
    sortable: true
  },
  {
    field: 'region',
    headerName: 'Region',
    suppressHeaderMenuButton: true,
    sortable: true
  },
  {
    field: 'tags',
    headerName: 'Tags',
    suppressHeaderMenuButton: true,
    sortable: true
  },
  {
    field: 'notes',
    headerName: 'Notes',
    suppressHeaderMenuButton: true,
    sortable: true
  }
]

const Root = styled(Grid)({
  width: '100%',
  minHeight: 400,
  overflowX: 'auto'
})

const CircularProgress = styled(MuiCircularProgress)({
  marginTop: 200,
  marginLeft: '45%'
})

const Search = styled('input')({
  outline: 'none',
  padding: '10px 20px',
  width: '100%',
  border: '2px #dedede solid',
  backgroundColor: '#f8f8f8',
  fontSize: '100%'
})

const TableContainer = styled('div')({
  position: 'fixed',
  overflowY: 'none',
  overflowX: 'none',
  height: '-webkit-fill-available',
  width: '-webkit-fill-available'
})

export default function ReferenceList () {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const gridRef = React.useRef()
  const [deleteId, setDeleteId] = React.useState(null)
  const user = useSelector(store => store.user)
  const reference = useSelector(store => store.reference)

  const handleCancelDelete = () => {
    setDeleteId(null)
  }

  const handleDeleteTable = async () => {
    setDeleteId(null)

    await dispatch(deleteReferenceTable(deleteId))
  }

  const handleSearchChanged = event => {
    gridRef.current.api.setGridOption('quickFilterText', event.target.value)
  }

  const handleGridReady = params => {
    const dataSource = new ReferenceServer()

    params.api.setGridOption('serverSideDatasource', dataSource)
  }

  const handleCellClicked = event => {
    const cellName = event.colDef.field
    const table = event.data

    if (cellName !== 'actions') {
      if (table.ownerId === user.ownerId) {
        navigate(`/reference/${table.id}/upload`)
      }
    }
  }

  const handleRemoveRow = React.useCallback((event, id) => {
    event.stopPropagation()
    setDeleteId(id)
  }, [])

  const columnDefinitions = React.useMemo(() => [
    {
      field: 'actions',
      suppressSizeToFit: false,
      width: 100,
      headerName: 'Actions',
      suppressHeaderMenuButton: true,
      cellRenderer: ActionsRender,
      cellRendererParams: {
        ownerId: user.ownerId,
        onDelete: handleRemoveRow
      }
    },
    ...BASE_COLUMN_DEFINITIONS
  ], [
    user.ownerId,
    handleRemoveRow
  ])

  React.useEffect(() => {
    if (reference.allreferenceTables.length === 0) {
      dispatch(loadAllReferenceTables)
    }
  }, [dispatch, reference.allreferenceTables])

  return !reference.isLoading
    ? (
      <Root data-testid='container-content'>
        <Search
          id='input-search'
          type='search'
          label='Search'
          placeholder='Type to search....'
          onChange={handleSearchChanged}
        />

        <TableContainer>
          <div
            className='ag-theme-alpine'
            style={{ height: 'inherit' }}
          >
            <AgGridReact
              data-testid='grid'
              pagination
              animateRows
              cacheBlockSize={2000}
              paginationPageSize={20}
              rowSelection='single'
              rowModelType='clientSide'
              ref={gridRef}
              defaultColDef={DEFAULT_COLOR_DEF}
              columnDefs={columnDefinitions}
              rowData={reference.allreferenceTables}
              paginationPageSizeSelector={false}
              onGridReady={handleGridReady}
              onCellClicked={handleCellClicked}
            ></AgGridReact>
          </div>
        </TableContainer>

        <ConfirmDialog
          data-testid='dialog-confirm'
          open={deleteId !== null}
          title='Confirm Reference Table Deletion?'
          text='Do you want to delete the reference table?'
          onCancel={handleCancelDelete}
          onConfirm={handleDeleteTable}
        />
      </Root>
      )
    : (
    <Root data-testid='container-loading'>
      <CircularProgress />
    </Root>
      )
}
