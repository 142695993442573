import apiHelper from './apiHelper'
import { API } from 'aws-amplify'

const fetchDataProducts = async (userId, country) => {
  const { region } = apiHelper.getCountry(country)
  const header = await apiHelper.getHeader()
  const dataProducts = process.env.REACT_APP_RGAENV !== 'poc'
    ? API.get(apiHelper.getRegionalAPIName(region), `/edp/data_products/${userId}`, header).catch(apiHelper.handleError)
    : []
  return dataProducts
}

const fetchViews = async (dataproduct, country) => {
  if (!dataproduct) {
    return []
  }
  const currentDP = dataproduct.value.toUpperCase()
  const { region } = apiHelper.getCountry(country)
  const header = await apiHelper.getHeader()
  const edpViews = process.env.REACT_APP_RGAENV !== 'poc'
    ? API.get(apiHelper.getRegionalAPIName(region), `/edp/views/${currentDP}`, header).catch(apiHelper.handleError)
    : []
  return edpViews
}

const fetchColumns = async (dataproduct, viewName, country) => {
  const { region } = apiHelper.getCountry(country)
  const header = await apiHelper.getHeader()
  const edpViewColumns = process.env.REACT_APP_RGAENV !== 'poc'
    ? API.get(apiHelper.getRegionalAPIName(region), `/edp/datatypes?database=UAT_ACC&schema=${dataproduct.value}&name=${viewName.fileName}`, header).catch(apiHelper.handleError)
    : []
  return edpViewColumns
}

export default {
  fetchDataProducts,
  fetchViews,
  fetchColumns
}
