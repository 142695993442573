import moment from 'moment'
import { API } from 'aws-amplify'
import apiHelper, { client } from './apiHelper'
import countries from './data/countries.json'
import edpApi from './edpApi'

let __region = process.env.REACT_APP_REGION

function getAPIRegion () {
  return __region
}

function setAPIRegion (region) {
  __region = region
}

function stringToArray (str) {
  return str ? str.split(',').map(item => item.trim()) : []
}

function stringToDate (str) {
  return str ? moment.utc(str).format('YYYY-MM-DD') : null
}

function formatJob (original) {
  return {
    id: original.JobId,
    projectId: original.ProjectId,
    finished: Boolean(original.Finished),
    totalTime: Number(original.TotalTime),
    fileProcessingTime: Number(original.FileProcessingTime),
    xpCalculatorRunTime: Number(original.XPCalculatorRunTime),
    dataLakeLoadTime: Number(original.DataLakeLoadTime),
    workWorthPerNode: Number(original.WorkWorthPerNode),
    numberOfNodes: Number(original.NumberOfNodes),
    valPolicySize: Number(original.ValPolicySize),
    valTerminateSize: Number(original.ValTerminateSize),
    riskHistorySize: Number(original.RiskHistorySize),
    treatySize: Number(original.TreatySize),
    basisSize: Number(original.BasisSize),
    executionArn: original.ExecutionArn || '',
    packageName: original.DataLakePackage || '',
    errorMessage: original.ErrorMessage || '',
    createdDate: original.CreateDate || null,
    deletedDate: original.DeletedDate || null,
    tableNames: stringToArray(original.TableNames),
    aggregators: stringToArray(original.Aggregators),
    bases: stringToArray(original.Basis),
    parameters: {
      applyIncidenceRateCap: Boolean(original.ApplyIncidenceRateCap),
      addMonthlyAggregation: Boolean(original.AddMonthlyAggregation),
      applySubstandardFlatExtra: Boolean(original.ApplySubstandardFlatExtra),
      addBuhlmannConstants: Boolean(original.AddBuhlmannConstants),
      applyExpectedLapseRates: Boolean(original.ApplyExpectedLapseRates),
      includeWarnings: Boolean(original.IncludeWarnings),
      performMonteCarlo: Boolean(original.PerformMonteCarlo),
      calculateIbnr: Boolean(original.CalculateIbnr),
      incidenceRate: Number(original.IncidenceRate),
      treatyRestrictions: original.TreatyRestrictions || '',
      policyRestrictions: original.PolicyRestrictions || '',
      powerBiReportUrl: original.PowerBIReportUrl || '',
      observationDate: stringToDate(original.ObservationDate),
      periodStartDate: stringToDate(original.PeriodStartDate),
      periodEndDate: stringToDate(original.PeriodEndDate)
    },
    ibnrAggregators: original.ibnrAggregators,
    ibnrSuccess: Boolean(original.ibnrSuccess),
    monthsPrior: original.monthsPrior,
    asOfDate: original.asOfDate,
    caseNumber: original.caseNumber
  }
}

const getRunStudyValues = async (projectId) => {
  const apiName = apiHelper.getRegionalAPIName(getAPIRegion())
  const bodyAndHeader = await apiHelper.getBodyAndHeader({ projectId })

  return API
    .post(apiName, '/source/modelsetup', bodyAndHeader)
    .catch(apiHelper.handleError)
}

// @Deprecated - use fetchJobs() instead
const getJobHistory = async (projectId) => {
  const apiName = apiHelper.getRegionalAPIName(getAPIRegion())
  const header = await apiHelper.getHeader()

  return API
    .get(apiName, `/jobhistory/crud/jh?id=${projectId}`, header)
    .catch(apiHelper.handleError)
}

const deleteJobRun = async (projectId, jobId) => {
  const bodyAndHeader = await apiHelper.getBodyAndHeader({})
  return API.del(apiHelper.getRegionalAPIName(getAPIRegion()), `/jobhistory/crud/jh?id=${projectId}&jobId=${jobId}`, bodyAndHeader).catch(apiHelper.handleError)
}

// NEW - functions that use the API client

async function fetchAggregators () {
  return client.post('/cal/profileagg')
}

async function fetchJobs (projectId) {
  const items = await client.get('/jobhistory/crud/jh', {
    region: __region,
    query: {
      id: projectId
    }
  })

  return items
    .map(formatJob)
    .sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate))
}
const getOuputTeamSpaceEdp = async (countryProject, userid) => {
  if (process.env.REACT_APP_RGAENV === 'poc') {
    return ''
  } else {
  // check if we have a default teamspace setup
    const edpTeamSpace = countries.find(country => country.value === countryProject)?.edpTeamSpace?.toLowerCase() // RED_CAN

    // validate if the user have teamspace access
    const dataProductsByUser = await edpApi.fetchDataProducts(userid.sid, countryProject)

    // if the user doesnt have it then default IES

    const finalDataProductOutput = dataProductsByUser.includes(edpTeamSpace) ? edpTeamSpace : 'RED_IES'

    return finalDataProductOutput.toUpperCase()
  }
}
const checkFabricOrEdp = async (project, user) => {
  if (project.files[0].src === 'dataFabric') {
    return { sourcePkg: project.files[0].dataFabric.pkg }
  } else {
    return { dataProductOutput: await getOuputTeamSpaceEdp(project.country, user) }
  }
}

async function submitJob (user, project, job) {
  const params = job.parameters

  const pkgOrOutput = await checkFabricOrEdp(project, user)

  const peerReviewers = project.sharedWithIds.filter(user => user.role === 'Peer Reviewer').map(user => user.id)
  const analysts = project.sharedWithIds.filter(user => user.role === 'Analyst').map(user => user.id)

  const access = {
    rw: [
      project.ownerId,
      ...peerReviewers
    ],
    ro: analysts
  }

  const body = {
    userId: user.sid,
    projectId: project.id,
    clientProduct: project.product,
    access,
    currentUser: user.ownerId,
    studyType: project.studyType,
    isRedeJob: true,
    formatFiles: project.formatFiles,
    jobProfile: {
      ...pkgOrOutput,
      jobId: job.id,
      product: 'redi',
      country: project.country,
      bases: job.bases,
      aggregators: job.aggregators,
      ibnr: job.ibnr,
      parameters: {
        ...params,
        applyIncidenceRateCap: +params.applyIncidenceRateCap,
        addMonthlyAggregation: +params.addMonthlyAggregation,
        applySubstandardFlatExtra: +params.applySubstandardFlatExtra,
        applyExpectedLapseRates: +params.applyExpectedLapseRates,
        addBuhlmannConstants: +params.addBuhlmannConstants,
        includeWarnings: +params.includeWarnings,
        performMonteCarlo: +params.performMonteCarlo,
        calculateIbnr: +params.calculateIbnr
      }
    }
  }

  return client.post('/enginesubmission', body, {
    region: __region
  })
}

async function abortJob (projectId, jobId) {
  await client.put(`/project/${projectId}/jobs/${jobId}/abort`, null, {
    region: __region
  })
}

async function replaceIbnr (projectId, job, ibnrData) {
  const apiName = apiHelper.getRegionalAPIName(getAPIRegion())
  const bodyAndHeader = await apiHelper.getBodyAndHeader({ projectId, job, ibnrData })

  return API
    .post(apiName, '/source/replaceibnr', bodyAndHeader)
    .catch(apiHelper.handleError)
}

export default {
  formatJob,
  getRunStudyValues,
  getAPIRegion,
  setAPIRegion,
  getJobHistory,
  deleteJobRun,
  fetchAggregators,
  fetchJobs,
  submitJob,
  abortJob,
  replaceIbnr
}
