const REGEX_START_WITH_LETTER = /^[a-f]/

export default function () {
  let result = null

  do {
    result = crypto.randomUUID().replace(/-/g, '')
  } while (!REGEX_START_WITH_LETTER.test(result))

  return result
}
