import React from 'react'
import Fab from '@mui/material/Fab'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import AddIcon from '@mui/icons-material/Add'
import SetMultiSelect from './setList'
import { useDispatch } from 'react-redux'
import { styled } from '@mui/material'
import { addedNewOptionSetAction } from '../../../store/pages/common/update'

const AddButton = styled(Fab)({
  marginLeft: 150
})

export default function SetList () {
  const dispatch = useDispatch()

  const handleClick = () => {
    dispatch(addedNewOptionSetAction)
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={7}>
        <Typography variant='h6'>Set</Typography>
      </Grid>

      <Grid item xs={5}>
        <AddButton
          data-testid='button-add'
          size='small'
          color='primary'
          onClick={handleClick}
        >
          <AddIcon/>
        </AddButton>
      </Grid>

      <Grid item xs={12}>
        <SetMultiSelect />
      </Grid>
    </Grid>
  )
}
