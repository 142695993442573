import PropTypes from 'prop-types'
import React from 'react'
import FormHelperText from '@mui/material/FormHelperText'

export default function HelperText (props) {
  const children = props.children || ' '

  return (
    <FormHelperText
      data-testid={props['data-testid']}
      sx={{
        opacity: children !== ' ' ? 1 : 0
      }}
    >{children}</FormHelperText>
  )
}

HelperText.propTypes = {
  'data-testid': PropTypes.string,
  children: PropTypes.string
}

HelperText.defaultProps = {
  'data-testid': '',
  children: ''
}
