import React from 'react'
import PropTypes from 'prop-types'
import MuiIconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import Grid from '@mui/material/Grid'
import MuiTypography from '@mui/material/Typography'
import { useDispatch } from 'react-redux'
import { styled } from '@mui/material'

import { editingSynonymDialogAction } from '../../store/pages/common/synonymDialog'
import { editedFormulaDialogAction } from '../../store/pages/common/formulaDialog'
import { toggledWorkflowFormulaAction } from '../../store/pages/common/workflow'

const Typography = styled(MuiTypography)(({
  margin: 4,
  whiteSpace: 'nowrap'
}))

const IconButton = styled(MuiIconButton)(({
  padding: 4
}))

const MultiValueStyles = {
  multiValue: (base) => ({
    ...base,
    backgroundColor: '#b7d2e8',
    color: 'black',
    borderRadius: 2,
    border: '1px black solid'
  })
}

const MultiValueLabel = props => {
  return (
    <Typography>
      {props.data.label}
    </Typography>
  )
}

MultiValueLabel.propTypes = {
  data: PropTypes.object.isRequired
}

const MultiValueRemove = (props) => {
  const dispatch = useDispatch()

  const handleEditClick = () => {
    if (props.selectProps.onEdit) {
      props.selectProps.onEdit(props.data, {
        name: props.selectProps.name
      })
    } else {
      if (props.selectProps.order !== undefined) {
        dispatch({
          ...toggledWorkflowFormulaAction,
          payload: {
            order: props.selectProps.order
          }
        })
      }

      dispatch({
        ...editedFormulaDialogAction,
        payload: {
          formula: props.data.value,
          name: props.data.name,
          index: props.selectProps.queryIndex,
          target: props.selectProps.queryTarget,
          key: props.selectProps.queryKey
        }
      })
    }
  }

  if (props.data.type === 'formula') {
    return (
      <Grid container>
        <Grid item xs={6}>
          <IconButton
            aria-label="edit"
            onClick={handleEditClick}
            size="large">
            <EditIcon />
          </IconButton>
        </Grid>

        <Grid item xs={6}>
          <IconButton
            aria-label="delete"
            onClick={() => props.innerProps.onClick()}
            size="large">
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
    );
  }
  if (props.selectProps.allowEdit) {
    return (
      <Grid container>
        <Grid item xs={6}>
          <IconButton
            aria-label="edit"
            onClick={() => dispatch({
              ...editingSynonymDialogAction,
              payload: {
                value: props.data.value,
                label: props.data.name,
                key: props.selectProps.queryKey
              }
            })}
            size="large">
            <EditIcon />
          </IconButton>
        </Grid>
        <Grid item xs={6}>
          <IconButton
            aria-label="delete"
            onClick={() => props.innerProps.onClick()}
            size="large">
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
    );
  }
  return (
    <IconButton
      onClick={() => props.innerProps.onClick()}
      aria-label="delete"
      size="large">
      <DeleteIcon />
    </IconButton>
  );
}

MultiValueRemove.propTypes = {
  data: PropTypes.object.isRequired,
  selectProps: PropTypes.object.isRequired,
  innerProps: PropTypes.object.isRequired
}

export {
  MultiValueLabel,
  MultiValueRemove,
  MultiValueStyles
}
