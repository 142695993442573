import { combineReducers } from 'redux'
import adminTool from './pages/adminTool'
import calculation from './pages/calculation'
import convert from './pages/common/convert'
import dataLake from './pages/dataLake'
import dataQuery from './pages/common/dataQuery'
import dataPrep from './pages/dataPrep'
import formulaDialog from './pages/common/formulaDialog'
import globalState from './pages/global'
import home from './pages/home'
import insert from './pages/common/insert'
import join from './pages/common/join'
import mapping from './pages/mapping'
import navigation from './pages/navigation'
import postCalc from './pages/postCalculation'
import preCalc from './pages/preCalculation'
import project from './pages/project'
import query from './pages/common/query'
import refData from './pages/refData'
import reference from './pages/reference'
import review from './pages/review'
import synonymDialog from './pages/common/synonymDialog'
import update from './pages/common/update'
import user from './user'
import valueMapping from './pages/valueMapping'
import workflow from './pages/common/workflow'
import edp from './pages/edp'

export default (routerReducer) => combineReducers({
  adminTool,
  calculation,
  convert,
  dataLake,
  dataQuery,
  dataPrep,
  formulaDialog,
  global: globalState,
  home,
  insert,
  join,
  mapping,
  navigation,
  postCalc,
  preCalc,
  project,
  query,
  refData,
  reference,
  review,
  synonymDialog,
  update,
  user,
  valueMapping,
  workflow,
  edp,
  router: routerReducer
})
