import React from 'react'
import Typography from '@mui/material/Typography'
import { Grid } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { loadData } from '../../../../store/pages/project/async'
import { setRefreshDataAction } from '../../../../store/pages/dataPrep'

const LoadingDialog = (props) => {
  // eslint-disable-next-line no-unused-vars
  const dispatch = useDispatch()
  const { handleClose, open, id } = props
  const { isLoading, message, refreshData } = props.state.state

  return (
    <Dialog open={open}>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            {
              isLoading &&
                <div>
                  <Typography variant="h6" >
                    <CircularProgress />
                    Adding new column and profiling.
                  </Typography>
                </div>

            }
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {
          (refreshData || !isLoading) && <div>
            <Typography variant="h6" >
              {message}
              <Button
                data-testid="button"
                onClick={() => {
                  if (refreshData) {
                    dispatch(loadData(id, true))
                    dispatch({ ...setRefreshDataAction, payload: { refreshData: false } })
                  }
                  handleClose()
                }}
                color="primary">
                        Close
              </Button>
            </Typography>
          </div>}

      </DialogActions>
    </Dialog>
  )
}

LoadingDialog.propTypes = {
  open: PropTypes.bool,
  id: PropTypes.any,
  handleClose: PropTypes.func,
  state: PropTypes.object
}

export default LoadingDialog
