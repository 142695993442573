import mappingApi from '../../objects/mappingApi'

const ANALYST = 'Analyst'
const PEER_REVIEW = 'Peer Reviewer'
const OWNER = 'Owner'
const ALL = [OWNER, PEER_REVIEW, ANALYST]
const MODIFY_ACCESS = [ANALYST, OWNER]

export const ENTITLEMENTS = {
  UPLOAD: MODIFY_ACCESS,
  DATA_PREP: MODIFY_ACCESS,
  MAPPING: MODIFY_ACCESS,
  VALUE_MAPPING: MODIFY_ACCESS,
  SHARE_PROJECT: MODIFY_ACCESS,
  PRE_CALCULATION: MODIFY_ACCESS,
  CALCULATION: MODIFY_ACCESS,
  POST_CALCULATION: ALL,
  REVIEW: PEER_REVIEW,
  PEERREVIEW: MODIFY_ACCESS
}

export const convertData = async ({ fileInfo, column, dataType }, projectId, mappings) => {
  const fileMapping = mappings.find(file => file.guid === fileInfo.guid)
  if (fileMapping) {
    const currentMapping = fileMapping.columns.find(e => e.source === column)
    let didError = false
    if (currentMapping?.sourceDataType !== dataType) {
      fileMapping.totalErrosType = 1
      fileMapping.totalErrosValue = 1
      didError = true
    }
    if (currentMapping) {
      currentMapping.sourceDataType = dataType
    }
    await mappingApi.setMappings(projectId, fileInfo.guid, fileMapping)
    return didError
  }
  return false
}
