import React from 'react'
import Grid from '@mui/material/Grid'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import MuiTableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import MuiTableRow from '@mui/material/TableRow'
import TablePagination from '@mui/material/TablePagination'
import CircularProgress from '@mui/material/CircularProgress'
import { styled } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { searchLogs } from '../../../../store/pages/dataPrep'

const COLUMNS = [
  {
    id: 'Type',
    label: 'Type',
    minWidth: 50,
    align: 'left'
  },
  {
    id: 'Run Time',
    label: 'Run Time',
    minWidth: 50,
    align: 'left'
  },
  {
    id: 'Run Date',
    label: 'Run Date',
    minWidth: 100,
    align: 'left'
  },
  {
    id: 'message',
    label: 'Message',
    minWidth: 180,
    align: 'left'
  },
  {
    id: 'sql',
    label: 'SQL',
    minWidth: 150,
    align: 'left'
  }
]

const TableRow = styled(MuiTableRow)(props => ({
  background: props.index % 2 ? '#F5F5F5' : '#FFFFFF'
}))

const TableCell = styled(MuiTableCell)({
  display: 'table-cell'
})

const replaceTableName = (sql, file, projectId, refTables) => {
  let result = sql
    .split(file.guid)
    .join(file.fileName)
    .split('ESP_INPUT_')
    .join('')
    .split(`${projectId}_`)
    .join('')

  refTables.forEach(table => {
    result = result.split(table.guid).join(table.name)
  })

  return result
}

export default function LogGrid () {
  const dispatch = useDispatch()
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(50)
  const navigation = useSelector(store => store.navigation)
  const project = useSelector(store => store.project)
  const dataPrep = useSelector(store => store.dataPrep)

  const referenceData = dataPrep.referenceTables.data || []

  const refTableNames = referenceData.map(table => ({
    id: table.id,
    guid: table.guid,
    name: table.tableName
  }))

  const handleSetPage = React.useCallback((_event, newPage) => {
    setPage(newPage)
  }, [setPage])

  const handleRowsPerPageChange = React.useCallback(event => {
    setRowsPerPage(event.target.value)
  }, [setRowsPerPage])

  React.useEffect(() => {
    dispatch(searchLogs(''))
  }, [
    navigation.file,
    dispatch
  ])

  return !dataPrep.logs.isLoading
    ? <Grid container>
      <Grid item xs={12}>
        <TablePagination
          component="div"
          count={dataPrep.logs.ScannedCount}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handleSetPage}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </Grid>

      <Table size="small">
        <TableHead>
          <TableRow>
            {COLUMNS.map(headCell => (
              <TableCell key={headCell.id} width={headCell.minWidth}>
                {headCell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {(rowsPerPage > 0
            ? dataPrep.logs.Items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : dataPrep.logs.Items
          ).map((row, index) => (
            <TableRow key={index} index={index}>
              <TableCell>{row.eventType}</TableCell>
              <TableCell>{(new Date(row.end).getTime() - new Date(row.start).getTime()) / 1000}</TableCell>
              <TableCell>{row.createdDate}</TableCell>
              <TableCell>{row.message.replace(',R', 'R')}</TableCell>
              <TableCell>{replaceTableName(row.sql, navigation.file, project.id, refTableNames)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Grid item xs={12}>
        <TablePagination
          component="div"
          count={dataPrep.logs.ScannedCount}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handleSetPage}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </Grid>
    </Grid>
    : <CircularProgress />
}
