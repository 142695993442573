import React from 'react'

const piiTooltip = (
  <span>
    <p>Make sure you are following all data privacy policies that apply to your data. While there are cases when using PII is acceptable we dont want to have PII in any REDi data if at all possible.</p>

    <p>
      <b>Example of PII:</b>

      <br />

      &nbsp;&nbsp;&nbsp;Address

      <br />

      &nbsp;&nbsp;&nbsp;Name

      <br />

      &nbsp;&nbsp;&nbsp;National ID

      <br />

      &nbsp;&nbsp;&nbsp;Social-Security Number

      <br />

      &nbsp;&nbsp;&nbsp;Zip Code

      <br />

      <b>Example of non-PII:</b>

      <br />

      &nbsp;&nbsp;&nbsp;Client Number

      <br />

      &nbsp;&nbsp;&nbsp;DOB

      <br />

      &nbsp;&nbsp;&nbsp;Policy Number

      <br />
    </p>
  </span>
)

const policyStructureTooltip = (
  <div>
    <p>
      <b>Overlap means policies that show up in the claims data will have records in the inforce data.</b>

      <br />

      Example of overlap:  A policy with a 100K death benefit is in force for several years and then has a mortality claim. The inforce history of the policy remains in the inforce data and a claim record is added to the claims data for the 100K benefit. Thus, there are records in both the claims and inforce data for the same policy.
    </p>

    <p>
      <b>Separate means policies that show up in the claims data will not have records in the inforce data.</b>

      <br />

      Example of separate: A policy with a 100K death benefit is inforce for several years and then has a mortality claim. Claim data is made for this policy and there are no inforce data records for this policy.
    </p>
  </div>
)

const dataFrequencyTooltip = (
  <div>
    <p>
      <span>
        <b>Snapshot</b> - Multiple rows of data for a policy that generally occur monthly, quarterly, or annually
      </span>

      <br />
    </p>

    <table>
      <tbody>
        <tr>
          <td>Inventory Date</td>
          <td>Policy Number</td>
          <td>AAR</td>
        </tr>

        <tr>
          <td>6/30/2018</td>
          <td>1</td>
          <td>150000</td>
        </tr>

        <tr>
          <td>9/30/2018</td>
          <td>1</td>
          <td>140000</td>
        </tr>
      </tbody>
    </table>

    <p></p>

    <p>
      <span>
        <b>Single Entry</b> - One row of data per policy. Sometimes there is no Observation Date(Time Stamp/Record Entry) field for this type of data
      </span>

      <br />
    </p>

    <table>
      <tbody>
        <tr>
          <td>Inventory Date</td>
          <td>Policy Number</td>
          <td>AAR</td>
        </tr>

        <tr>
          <td>6/30/2018</td>
          <td>1</td>
          <td>150000</td>
        </tr>

        <tr>
          <td>6/30/2018</td>
          <td>2</td>
          <td>130000</td>
        </tr>
      </tbody>
    </table>

    <p></p>
  </div>
)

export {
  piiTooltip,
  policyStructureTooltip,
  dataFrequencyTooltip
}
