/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getMappings } from '../../../store/pages/mapping'
import { getComments } from '../../../store/pages/review'
import { getJobHistory } from '../../../store/pages/calculation/async'

import {
  forcedRefreshPostCalcCheckDataAction,
  getData,
  getExpVsRiskAmountData,
  getExpVsRiskCountData,
  getNonTerminatingClaimsData,
  getNonTerminatingOvsQClaimsData,
  getPolicyTrackerData,
  getTerminatingClaimsData,
  getTerminatingOvsQClaimsData
} from '../../../store/pages/postCalculation'

export default () => {
  const dispatch = useDispatch()

  const {
    id,
    commentsId,
    postCalculationCheckRunning,
    loadComments,
    lapseCheckLoading,
    gettingJobHistory,
    mappings,
    latestRun,
    jobHistory,
    lapseCheckData
  } = useSelector(({
    project: {
      id
    },
    mapping: {
      mappings = null
    },
    calculation: {
      gettingJobHistory = false,
      latestRun,
      jobHistory = null
    },
    postCalc: {
      lapseCheckData,
      lapseCheckLoading,
      postCalculationCheckRunning
    },
    review: {
      commentsId,
      loadComments = false
    }
  }) => ({
    id,
    commentsId,
    postCalculationCheckRunning,
    loadComments,
    lapseCheckLoading,
    gettingJobHistory,
    mappings,
    latestRun,
    jobHistory,
    lapseCheckData
  }))

  React.useEffect(() => {
    let unmounted = false

    if (
      !unmounted &&
      !gettingJobHistory && (
        !jobHistory || (
          latestRun && latestRun.ProjectId !== id
        )
      )) {
      dispatch(getJobHistory)
    }

    if (!unmounted && mappings?.length === 0) {
      dispatch(getMappings(id))
    }

    if (!postCalculationCheckRunning && (
      !unmounted && jobHistory && jobHistory.length > 0 && (
        (
          !lapseCheckLoading && (
            !lapseCheckData || (
              latestRun && latestRun.ProjectId !== id
            )
          )
        )
      )
    )) {
      if (
        latestRun.TableNames?.toLowerCase().includes('exp') &&
        latestRun.TableNames?.toLowerCase().includes('val')
      ) {
        dispatch({
          ...forcedRefreshPostCalcCheckDataAction,
          payload: {
            forceRefresh: false
          }
        })

        dispatch(getData)
        dispatch(getExpVsRiskAmountData)
        dispatch(getExpVsRiskCountData)
        dispatch(getPolicyTrackerData)
        dispatch(getTerminatingClaimsData)
        dispatch(getNonTerminatingClaimsData)
        dispatch(getTerminatingOvsQClaimsData)
        dispatch(getNonTerminatingOvsQClaimsData)
      }
    }

    if (
      !unmounted &&
      jobHistory &&
      jobHistory.length > 0 &&
      latestRun &&
      latestRun &&
      latestRun.ProjectId === id &&
      (!loadComments || commentsId !== id)
    ) {
      dispatch(getComments(latestRun.JobId))
    }

    return () => {
      unmounted = true
    }
  }, [
    id,
    commentsId,
    loadComments,
    postCalculationCheckRunning,
    latestRun,
    jobHistory,
    lapseCheckData
  ])
}
