import Convert from './convert/index'
import Merge from './reference/index'
import Aggregate from './aggregate/index'
import NewColumn from './newColumn/index'
import Update from './update/index'
const getView = (visibleView) => {
  let viewToShow
  switch (visibleView) {
    case undefined:
    case 'convert':
      viewToShow = Convert
      break
    case 'aggregate':
      viewToShow = Aggregate
      break
    case 'newColumn':
      viewToShow = NewColumn
      break
    case 'modify':
    case 'removeRows':
      viewToShow = Update
      break
    case 'merge':
      viewToShow = Merge
      break
    default:
      viewToShow = Convert
  }
  return viewToShow
}

export default getView
