import PropTypes from 'prop-types'
import React from 'react'
import Table from '@mui/material/Table'
import MuiTableRow from '@mui/material/TableRow'
import TableHead from '@mui/material/TableHead'
import MuiTableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import MuiTableContainer from '@mui/material/TableContainer'
import Tooltip from '@mui/material/Tooltip'
import { styled } from '@mui/material'
import { getMaxLength } from '../../../components/sql/styleCol'

import {
  tableRowBackGroundColor
} from '../../../components/table/table.utils'

const WIDTH_DRAWER = 240

const TableWrapper = styled('div', {
  shouldForwardProp: prop =>
    prop !== 'open'
})(props => ({
  overflow: 'auto',
  maxWidth: props.open
    ? `calc(100vw - ${WIDTH_DRAWER}px)`
    : 'calc(100vw - 56px)'
}))

const TableContainer = styled(MuiTableContainer)({
  overflowX: 'scroll',
  maxHeight: 'calc(100vh - 500px)'
})

const TableBody = styled(MuiTableBody)({
  display: 'block',
  overflowY: 'hidden'
})

const TableRow = styled(MuiTableRow)({
  display: 'inherit',
  maxHeight: 50,
  background: 'white'
})

export default function ResultsGrid ({
  drawerOpen,
  resultsGrid
}) {
  const { data, columns } = resultsGrid
  const maxCellWidth = getMaxLength(data, null)

  const getCellWidth = label => {
    const result = maxCellWidth.find(i => i.label === label)
      ? maxCellWidth.find(i => i.label === label).length
      : 100

    return result < 10 ? 150 : result * 15
  }

  return (
    <TableWrapper open={drawerOpen}>
      <TableContainer>
        <Table
          data-testid='table'
          size='small'
          stickyHeader
        >
          <TableHead>
            <TableRow>
              {columns.map(header => {
                const headerName = header.match('^([^<]+)')[0]

                const headerType = header.match('<.*?>')
                  ? header.match('<.*?>')[0].toUpperCase()
                  : ''

                return (
                  <Tooltip title={headerType} key={header}>
                    <TableCell
                      key={header}
                      style={{ minWidth: getCellWidth(headerName) }}
                    >{headerName.toUpperCase()}</TableCell>
                  </Tooltip>
                )
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            {data.map((row, rowIndex) => (
              <TableRow
                style={tableRowBackGroundColor(rowIndex)}
                key={rowIndex}
              >
                {columns.map((columns, columnIndex) => (
                  <TableCell
                    key={`${rowIndex}_${columnIndex}`}
                    align='left'
                    style={{
                      minWidth: getCellWidth(columns.match('^([^<]+)')[0])
                    }}
                  >
                    {row[columns.match('^([^<]+)')[0]]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </TableWrapper>
  )
}

ResultsGrid.propTypes = {
  drawerOpen: PropTypes.bool,
  resultsGrid: PropTypes.shape({
    columns: PropTypes.arrayOf(PropTypes.string),
    data: PropTypes.arrayOf(
      PropTypes.arrayOf(
        PropTypes.any
      )
    )
  })
}
