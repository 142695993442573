import preCalApi from '../objects/preCalApi'
import sourcesApi from '../objects/sourcesAPI'
import LABELS from '../objects/data/precalCheckLabel'
import NON_CRITICAL from '../objects/data/LabelsNonCritical'
import stepFunctionStatusLoop from './common/stepFunctionStatusLoop'
import { updatingProjectAction } from './project/actions'

import {
  completedCCFTAction,
  completedREDEPrecalcChecksAction,
  creatingCFAction,
  rediCCFT
} from './valueMapping'

const LABEL_VAL_POLICY = LABELS.find(label =>
  label.checkName === 'CONSISTENCY_val policy'
)

const BASE_SKIP_MAP_CHECKS = [
  {
    type: 'DUPLICATES',
    fileType: 'I',
    skipMapFile: 'val policy',
    checkName: 'DUPLICATES_I'
  },
  {
    type: 'DUPLICATES',
    fileType: 'T',
    skipMapFile: 'val terminated',
    checkName: 'DUPLICATES_T'
  },
  {
    type: 'TERM_ISSUE_DATE',
    fileType: 'T',
    skipMapFile: 'val terminated',
    checkName: 'TERM_ISSUE_DATE_T'
  },
  {
    type: 'AAR',
    fileType: 'T',
    skipMapFile: 'val terminated',
    checkName: 'AAR_T'
  },
  {
    type: 'AAR',
    fileType: 'I',
    skipMapFile: 'val policy',
    checkName: 'AAR_I'
  },
  {
    type: 'REASONABILITY',
    fileType: 'I',
    skipMapFile: 'val policy',
    checkName: 'REASONABILITY_I'
  },
  {
    type: 'DOBLEISSUE',
    fileType: 'I',
    skipMapFile: 'val policy',
    checkName: 'DOBLEISSUE_I'
  },
  {
    type: 'DOBLEDOR',
    fileType: 'I',
    skipMapFile: 'val policy',
    checkName: 'DOBLEDOR_I'
  }
]

export const INITIAL_STATE = {
  load: false,
  loadNonCrit: false,
  fixingFlag: false,
  nonCriticalCompleted: false,
  fixMessage: null,
  currentProject: null,
  nonCriticalCheckStatus: {},
  fixWizard: {
    DUPLICATES_I: null,
    DUPLICATES_T: null,
    AAR_I: null,
    AAR_T: null,
    ISSUE_AGE_I: null,
    ISSUE_AGE_T: null,
    ESSENTIAL_FIELDS_I: null,
    TERM_ISSUE_DATE_T: null,
    LAPSE_SKEWNESS_T: null,
    MONTHLY_SKEWNESS_T: null,
    RISK_RENEWAL_PREM_I: null
  },
  skipMapCheck: [],
  preCalculationCheckData: [],
  nonCriticalCheckData: []
}

export const loadingCheckDataAction = {
  type: 'precalc/LOADING_CHECK_DATA'
}

export const loadedCheckDataAction = {
  type: 'precalc/LOADED_CHECK_DATA'
}

export const updateWizardStepAction = {
  type: 'precalc/UPDATE_WIZARD_STEP'
}

export const includeFixAction = {
  type: 'precalc/INCLUDE_FIX'
}

export const ranNonCriticalChecksAction = {
  type: 'precalc/RAN_NON_CRITICAL_CHECKS'
}

export const loadingNonCriticalChecksAction = {
  type: 'precalc/LOADING_NON_CRITICAL_CHECKS'
}

export const startingPrecalcFixesAction = {
  type: 'precalc/STARTING_PRECALC_FIXES'
}

export const completedPrecalcFixesAction = {
  type: 'precalc/COMPLETD_PRECALC_FIXES'
}

const getSkipMapChecks = (formatFiles, skipMapCheck) => {
  const checks = [...BASE_SKIP_MAP_CHECKS, ...skipMapCheck]
  return checks.reduce((accum, curr) => {
    const findFile = formatFiles.find(file => file.type === curr.skipMapFile)

    if (findFile) {
      accum.push(curr)
    }

    return accum
  }, [])
}

const getFileColumns = (type, formatFiles, studyType) => {
  if (studyType === 'skipMap') {
    const dataType = type === 'I' ? 'val policy' : (type === 'T' ? 'val terminated' : '')
    return formatFiles.find(file => file.type === dataType).columns
  } else {
    return formatFiles.find(file => file.type === type).columns
  }
}

const getCachedNames = columns => {
  return columns.map(column => column.Name.toLowerCase())
}

const getColumnsFromFormatFiles = (formatFiles, studyType) => {
  const inforceColumns = getFileColumns('I', formatFiles, studyType)
  const terminationColumns = getFileColumns('T', formatFiles, studyType)

  return {
    inforce: {
      columns: inforceColumns,
      cachedNames: getCachedNames(inforceColumns)
    },
    termination: {
      columns: terminationColumns,
      cachedNames: getCachedNames(terminationColumns)
    }
  }
}

const getTargetColumns = (label, data) => {
  const targetName = label.target.toLowerCase()

  const getIndex = cachedNames => {
    return cachedNames.findIndex(name => name === targetName)
  }

  const inforceIndex = getIndex(data.inforce.cachedNames)
  const terminationIndex = getIndex(data.termination.cachedNames)

  return {
    iColumn: data.inforce.columns[inforceIndex],
    tColumn: data.termination.columns[terminationIndex]
  }
}

const getStatus = check => {
  if (!check.results) {
    return 2
  }

  return check.results.length > 0 ? 1 : 0
}

const createChecksFromLabels = (data, nonCriticalCheckData) => {
  if (!data) {
    return []
  }

  const allData = [...data, ...nonCriticalCheckData]

  return allData.map(check => {
    if (check.type === 'CONSISTENCY' || check.checkName?.includes('CONSISTENCY')) {
      return {
        ...check,
        nonCritical: true,
        label: `${LABEL_VAL_POLICY.label} | ${check.checkName}`,
        info: LABEL_VAL_POLICY.info,
        errorHandler: LABEL_VAL_POLICY.errorHandler,
        required: LABEL_VAL_POLICY.required,
        goal: LABEL_VAL_POLICY.goal,
        order: LABEL_VAL_POLICY.order,
        fix: null,
        status: getStatus(check),
        results: check.results || [],
        headers: null
      }
    } else {
      const matchedLabel = LABELS.find(
        label =>
          label.checkName === check.checkName ||
          label.checkName === `${check.checkName}_${check.fileType}`
      )

      return matchedLabel
        ? {
            ...check,
            nonCritical: matchedLabel.nonCritical || false,
            label: matchedLabel.label,
            info: matchedLabel.info,
            errorHandler: matchedLabel.errorHandler,
            required: matchedLabel.required,
            goal: matchedLabel.goal,
            fixText: matchedLabel.fixText,
            order: matchedLabel.order,
            fix: check.fix,
            status: getStatus(check),
            results: check.results || [],
            headers: matchedLabel.headers
          }
        : check
    }
  })
}

const createNonCriticalChecks = (formatFiles, studyType) => {
  const itColumnData = getColumnsFromFormatFiles(formatFiles, studyType)

  const consistencyChecks = NON_CRITICAL.LABELS.reduce((accum, curr) => {
    const { iColumn, tColumn } = getTargetColumns(curr, itColumnData)

    if (iColumn && !tColumn) {
      return [...accum, {
        type: 'CONSISTENCY',
        fileType: 'I',
        checkField: iColumn.Name,
        checkName: `CONSISTENCY_${iColumn.Name}`
      }]
    }

    if (!iColumn && tColumn) {
      return [...accum, {
        type: 'CONSISTENCY',
        fileType: 'T',
        checkField: tColumn.Name,
        checkName: `CONSISTENCY_${tColumn.Name}`
      }]
    }

    if (
      iColumn &&
      tColumn &&
      iColumn.Name.toLowerCase() === tColumn.Name.toLowerCase()
    ) {
      return [...accum, {
        type: 'CONSISTENCY',
        fileType: 'I,T',
        checkField: iColumn.Name,
        checkName: `CONSISTENCY_${iColumn.Name}`
      }]
    }

    return accum
  }, [])

  return [
    ...NON_CRITICAL.CHECKS,
    ...consistencyChecks
  ]
}

const createConsistencyChecksSkip = (formatFiles, studyType) => {
  const itColumnData = getColumnsFromFormatFiles(formatFiles, studyType)

  return NON_CRITICAL.LABELS.reduce((accum, curr) => {
    const { iColumn, tColumn } = getTargetColumns(curr, itColumnData)

    if (!iColumn || !tColumn) {
      return accum
    }

    if (iColumn.Name === tColumn.Name) {
      accum.push({
        type: 'CONSISTENCY',
        fileType: 'I',
        checkField: iColumn.Name,
        checkName: `CONSISTENCY_${iColumn.Name}`,
        skipMapFile: 'val policy'
      })
    }

    return accum
  }, [])
}

const getCheckData = (formatFiles, nonCriticalCheckData, updatedCheckData) => {
  if (updatedCheckData && updatedCheckData.length > 0) {
    return updatedCheckData
  } else {
    return formatFiles.length > 5
      ? nonCriticalCheckData
      : []
  }
}

const buildDuplicateValidators = (item, fix) => {
  const TARGET_COLUMNS = ['INVENTORY_DATE', 'UNIQUE_IDENTIFIER']

  const { columns } = fix[item.checkName]

  const querySelectFields = columns.reduce((accum, curr) => {
    const nameIsTarget = TARGET_COLUMNS.indexOf(curr.Name) !== -1

    if (curr.isPrimaryKey || nameIsTarget) {
      accum.push({
        value: curr.Name,
        label: curr.Name,
        order: 1
      })
    } else {
      accum.push({
        value: `${curr.aggregator.value}(${curr.Name}) AS '${curr.Name}'`,
        label: curr.Name,
        order: 2
      })
    }

    return accum
  }, [])

  querySelectFields.sort((a, b) => (a.order > b.order ? 1 : -1))

  return {
    required: item.required,
    projectId: item.projectId,
    checkName: item.checkName,
    tableName: fix[item.checkName].tableName,
    querySelectFields
  }
}

const buildLapseSkewnessValidators = (item, fix) => {
  const { daysToAdd, columns } = fix[item.checkName]

  const querySelectFields = columns.reduce((accum, curr) => {
    if (curr.Name !== 'DOT') {
      accum.push({
        value: curr.Name,
        label: curr.Name
      })
    }

    return accum
  }, [])

  querySelectFields.push({
    value: `case when pol_status = 'TL' then DATEADD(day,${daysToAdd},DOT) else DOT end as DOT `,
    label: `ADDDATE(DOT,${daysToAdd}) as DOT`
  })

  return {
    required: item.required,
    projectId: item.projectId,
    checkName: item.checkName,
    tableName: fix[item.checkName].tableName,
    querySelectFields
  }
}

const buildValidators = (item, fixWizard) => {
  switch (item.checkName) {
    case 'DUPLICATES_I':
      return buildDuplicateValidators(item, fixWizard)

    case 'DUPLICATES_T':
      return buildDuplicateValidators(item, fixWizard)

    case 'LAPSE_SKEWNESS_T':
      return buildLapseSkewnessValidators(item, fixWizard)

    default:
      return null
  }
}

const getFlagAndMessage = (arnStatus, studyType) => {
  if (arnStatus === 'SUCCEEDED') {
    if (studyType === 'redi') {
      return {
        flag: true,
        message: 'Completed Fixes Successfully - Resuming Create Common Format Tables (CCFT)'
      }
    } else {
      return {
        flag: false,
        message: 'Completed Fixes Successfully'
      }
    }
  } else {
    return {
      flag: false,
      message: 'Failed fix'
    }
  }
}

export const getPrecalCheckData = (projectId, updatedCheckData) => async (dispatch, getState) => {
  const {
    preCalc: { nonCriticalCheckData },
    project: { formatFiles }
  } = getState()
  dispatch({
    ...loadingCheckDataAction,
    payload: projectId
  })

  const data = await preCalApi.getPreCalCheck(projectId)
  const checkData = getCheckData(formatFiles, nonCriticalCheckData, updatedCheckData)

  dispatch({
    ...loadedCheckDataAction,
    payload: {
      load: true,
      preCalculationCheckData: createChecksFromLabels(data, checkData)
    }
  })
}

export const startNonCriticalChecks = async (dispatch, getState) => {
  const {
    project: {
      id,
      formatFiles,
      studyType
    },
    preCalc: {
      loadNonCrit,
      preCalculationCheckData,
      nonCriticalCheckData
    }
  } = getState()

  dispatch({
    ...loadingNonCriticalChecksAction,
    payload: {
      loadNonCrit: true
    }
  })

  if (preCalculationCheckData && !nonCriticalCheckData) {
    return
  }

  if (loadNonCrit) {
    const nonCriticalChecks = createNonCriticalChecks(formatFiles, studyType)

    dispatch({
      ...loadedCheckDataAction,
      payload: {
        loadNonCrit: false,
        nonCriticalCheckData: nonCriticalChecks
      }
    })

    await dispatch(getPrecalCheckData(id, nonCriticalChecks))

    const { executionArn } = await sourcesApi.startPreCalCheck(formatFiles, id, 'nonCritical', nonCriticalChecks)

    await stepFunctionStatusLoop(
      executionArn,
      ({ status }) => ['FAILED', 'SUCCEEDED'].includes(status),
      _arn => {
        /*
         * The following code appears to be dead code that mutates
         * `nonCriticalChecks`, but doesn't use the mutated value of
         * `nonCriticalChecks`. I also checked the `main` branch, and
         * `nonCriticalChecks` is also mutated, but never used after the
         * mutation.
         *
         * Here's a link to the current version of `main`:
         * https://github.com/rgare/redi-aws-ui/blob/37e99b3cc8481e414926259aa09770124bccfd9b/src/store/pages/common/preCalcHelper.js#L130C69-L130C75
         *
         * You'll see that a call to `checkNonCriticalStatus()` is called, but
         * the `checks` variable that's used in the `.then()` is defined yet
         * never used. All the `.then()` call does is apply static data to a
         * state mutation, but it never uses the mutated `checks` variable
         * in that resulting logic.
         *
         * I commented the code out the following code for now, but didn't
         * delete it in case it's logic is meant to be applied somewhere.
         *
         * ~Travis True
         */

        // if (arn.status === 'FAILED') {
        //   nonCriticalChecks.forEach(check => {
        //     check.pass = 1
        //   })
        // } else {
        //   const results = JSON.parse(arn.output)

        //   if (
        //     results.length === nonCriticalChecks.length &&
        //     (results[0] === 1 || results[0] === 0)
        //   ) {
        //     for (let i = 0; i < results.length; i++) {
        //       nonCriticalChecks[i].pass = results[i]
        //     }
        //   } else {
        //     nonCriticalChecks.forEach(check => {
        //       check.pass = 1
        //     })
        //   }
        // }

        return dispatch({
          ...ranNonCriticalChecksAction,
          payload: {
            load: false,
            loadNonCrit: false,
            nonCriticalCompleted: true,
            nonCriticalCheckData: []
          }
        })
      }
    )

    return dispatch(getPrecalCheckData(id))
  }
}

export const fixCFT = async (dispatch, getState) => {
  const {
    project: { id: projectId, studyType },
    preCalc: { fixWizard, preCalculationCheckData },
    refData: { rgaColumns }
  } = getState()

  const body = preCalculationCheckData
    .filter(data => data.fix)
    .reduce((accum, curr) => {
      if (curr.required && studyType !== 'skipMapping') {
        const result = buildValidators(curr, fixWizard)

        if (result) {
          accum.push(result)
        }
      } else {
        accum.push({
          required: curr.required,
          projectId: curr.projectId,
          checkName: curr.checkName,
          tableName: '',
          querySelectFields: null
        })
      }

      return accum
    }, [])
    .map(check => {
      if (!check.querySelectFields) {
        return check
      }

      const selectFields = check.querySelectFields.map(querySelectField => {
        const order = rgaColumns
          .find(column => column.name === querySelectField.label)

        if (!order) {
          return {
            ...querySelectField,
            order: querySelectField.label /* The original code would assign the "label" to "order" */
          }
        }

        return {
          ...querySelectField,
          order: check.fileType === 'T' && order.orderT
            ? order.orderT
            : order.order
        }
      })

      return {
        ...check,
        projectId,
        querySelectFields: selectFields.sort((a, b) => a.order - b.order)
      }
    })

  const moveOnAfterPreCalcFixes = async arn => {
    const { flag, message } = getFlagAndMessage(arn.status, studyType)

    if (flag) {
      dispatch(rediCCFT)
    }

    dispatch({
      ...completedPrecalcFixesAction,
      payload: {
        loadNonCrit: true,
        fixingFlag: flag,
        fixMessage: message
      }
    })

    await dispatch(getPrecalCheckData(projectId))
  }

  dispatch({
    ...startingPrecalcFixesAction,
    payload: {
      fixingFlag: true,
      fixMessage: 'Precalc fixes started'
    }
  })

  try {
    const { executionArn } = await preCalApi.runFixPrecalChecks(body)

    return stepFunctionStatusLoop(
      executionArn,
      ({ status }) => ['FAILED', 'SUCCEEDED'].includes(status),
      moveOnAfterPreCalcFixes
    )
  } catch (err) {
    console.error(err)

    return dispatch({
      ...completedPrecalcFixesAction,
      payload: {
        loadNonCrit: false,
        fixingFlag: false,
        fixMessage: 'Failed'
      }
    })
  }
}

export const redePrecalChecksSkipMap = async (dispatch, getState) => {
  const {
    project: {
      id,
      studyType,
      formatFiles
    }
  } = getState()

  const moveOnAfterPreCalcCheck = async arn => {
    if (arn.status === 'SUCCEEDED') {
      dispatch(getPrecalCheckData(id))
    }
  }

  const checksConst = createConsistencyChecksSkip(formatFiles, studyType)
  const skipMapCheck = getSkipMapChecks(formatFiles, checksConst)

  await dispatch(getPrecalCheckData(id, skipMapCheck))

  const { executionArn } = await sourcesApi.startPreCalCheck(formatFiles, id, studyType, [], skipMapCheck)

  return await stepFunctionStatusLoop(
    executionArn,
    ({ status }) => ['FAILED', 'SUCCEEDED', 'ABORTED'].includes(status),
    moveOnAfterPreCalcCheck
  )
}

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case completedREDEPrecalcChecksAction.type:
    case completedCCFTAction.type:
      return {
        ...state,
        ...payload.preCalc
      }
    case loadedCheckDataAction.type:
      return {
        ...state,
        ...payload
      }
    case ranNonCriticalChecksAction.type:
    case startingPrecalcFixesAction.type:
    case completedPrecalcFixesAction.type:
    case updatingProjectAction.type:
      return {
        ...state,
        ...payload
      }

    case loadingCheckDataAction.type:
      return {
        ...state,
        currentProject: payload,
        load: true
      }

    case updateWizardStepAction.type: {
      const { type, object } = payload

      return {
        ...state,
        fixWizard: {
          ...state.fixWizard,
          [type]: object
        }
      }
    }

    case includeFixAction.type: {
      const { preCalculationCheckData } = state
      const { type, fix, nonReq } = payload
      const update = preCalculationCheckData.find(x => x.checkName === type)

      update.fix = fix

      if (nonReq) {
        update.required = false
      }

      return {
        ...state,
        preCalculationCheckData: [...preCalculationCheckData]
      }
    }

    case creatingCFAction.type:
      return {
        ...state,
        loadNonCrit: false,
        nonCriticalCompleted: false
      }

    default:
      return state
  }
}
