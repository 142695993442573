import PropTypes from 'prop-types'
import React from 'react'
import Grid from '@mui/material/Grid'
import Drawer from '@mui/material/Drawer'
import MuiAppBar from '@mui/material/AppBar'
import IconButton from '@mui/material/IconButton'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import DeleteIcon from '@mui/icons-material/Delete'
import LaunchIcon from '@mui/icons-material/Launch'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import SearchBox from '../../../components/form/SearchBox'
import Query from '../query'
import Paper from '@mui/material/Paper'
import Mapping from '../map'
import Update from '../update'
import Reference from '../reference'
import Log from '../log'
import Convert from '../convert'
import NewColumn from '../newColumn'
import RemoveRows from '../removeRows'
import Aggregate from '../aggregate'
import Workflow from '../workflow'
import { styled } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'

import {
  deleteQuery,
  deleteWorkflow,
  openQuery,
  openWorkflow,
  setBarFilterAction,
  setLoadedQuery,
  toggledQueryOpenAction,
  setSubNavigationAction
} from '../../../store/pages/dataPrep'

const WIDTH_DRAWER = 440

const Root = styled('div')(({
  display: 'flex'
}))

const StyledGrid = styled(Grid)(({
  marginTop: 90
}))

const StyledTypography = styled(Typography)(({
  flexGrow: 1,
  marginLeft: 10
}))

const StyledList = styled(List)(({
  scrollBehavior: 'containe'
}))

const StyledPaper = styled(Paper)(({
  width: WIDTH_DRAWER
}))

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'queryDrawerOpen'
})(({ theme, queryDrawerOpen }) => ({
  width: queryDrawerOpen ? `calc(100% - ${WIDTH_DRAWER}px)` : 100,
  marginTop: 72,
  transition: theme.transitions.create(['margin', 'width'], {
    easing: queryDrawerOpen
      ? theme.transitions.easing.easeOut
      : theme.transitions.easing.sharp,
    duration: queryDrawerOpen
      ? theme.transitions.duration.enteringScreen
      : theme.transitions.duration.leavingScreen
  })
}))

const Main = styled('main', {
  shouldForwardProp: (prop) => prop !== 'queryDrawerOpen'
})(({ theme, queryDrawerOpen }) => ({
  marginRight: 0,
  flexGrow: 1,
  padding: theme.spacing(3, 0),
  transition: theme.transitions.create('margin', {
    easing: queryDrawerOpen
      ? theme.transitions.easing.easeOut
      : theme.transitions.easing.sharp,
    duration: queryDrawerOpen
      ? theme.transitions.duration.enteringScreen
      : theme.transitions.duration.leavingScreen
  })
}))

const StyledIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'queryDrawerOpen'
})(({ queryDrawerOpen }) => ({
  display: queryDrawerOpen ? 'none' : 'initial',
  fontSize: 'smaller'
}))

const DrawerHeader = styled('div')(({ theme }) => ({
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
  marginTop: 68,
  display: 'block',
  position: 'absolute',
  top: 20,
  right: 10
}))

const TYPE_MAP = {
  QU: 'query',
  WF: 'workflow',
  MD: 'update',
  MG: 'merge',
  NC: 'newColumn',
  RR: 'removeRows',
  AG: 'aggregate'
}

const TYPE_KEYS = Object.keys(TYPE_MAP)

const ViewContent = props => {
  switch (props.view) {
    case 'query':
      return <Query
        data-testid='content-query'
      />

    case 'update':
      return <Update
        data-testid='content-update'
      />

    case 'mapping':
      return <Mapping
        data-testid='content-mapping'
      />

    case 'log':
      return <Log
        data-testid='content-log'
      />

    case 'merge':
      return <Reference
        data-testid='content-merge'
      />

    case 'convert':
      return <Convert
        data-testid='content-convert'
      />

    case 'newColumn':
      return <NewColumn
        data-testid='content-newColumn'
      />

    case 'removeRows':
      return <RemoveRows
        data-testid='content-removeRows'
      />

    case 'aggregate':
      return <Aggregate
        data-testid='content-aggregate'
      />

    case 'workflow':
      return <Workflow
        data-testid='content-workflow'
      />

    default:
      return <Query
        data-testid='content-query'
      />
  }
}

ViewContent.propTypes = {
  view: PropTypes.string
}

export default function QueryDrawer () {
  const dispatch = useDispatch()
  const [alignment, setAlignment] = React.useState('left')
  const [searchFilter, setSearchFilter] = React.useState('')

  const {
    loadedSavedItems,
    queryDrawerOpen,
    visibleView,
    barFilter,
    queries,
    workflows
  } = useSelector(({
    dataPrep: {
      loadedSavedItems,
      queryDrawerOpen,
      visibleView,
      barFilter,
      queries,
      workflows
    }
  }) => ({
    loadedSavedItems,
    queryDrawerOpen,
    visibleView,
    barFilter,
    queries,
    workflows
  }))

  const sanitizedBarFilter = barFilter || ''
  const typeBarFilter = TYPE_MAP[sanitizedBarFilter]

  const allItems = React.useMemo(() =>
    [...queries, ...workflows].map(item => ({
      ...item,
      lookupName: item.name.toLowerCase()
    })),
  [queries, workflows])

  const filteredItems = React.useMemo(() => allItems.filter(item => {
    const passName = item.lookupName.match(searchFilter)

    const passType = sanitizedBarFilter
      ? item.queryType.match(typeBarFilter)
      : true

    return passName && passType
  }), [
    sanitizedBarFilter,
    typeBarFilter,
    searchFilter,
    allItems
  ])

  const handleToggle = () => {
    dispatch(toggledQueryOpenAction)
  }

  const handleSearch = event => {
    const result = event.target.value.toLowerCase()

    setSearchFilter(result)
  }

  const handleAlignment = (_event, newAlignment) => {
    setAlignment(newAlignment)
  }

  const handleFilterClicked = key => {
    dispatch({
      ...setBarFilterAction,
      payload: key
    })
  }

  const handleOpen = item => {
    switch (item.queryType) {
      case 'query':
        dispatch(setLoadedQuery({ ...item }, true))

        dispatch({
          ...setSubNavigationAction,
          payload: item.queryType
        })
        break

      case 'workflow':
        return dispatch(openWorkflow(item.guid))

      default:
        return dispatch(openQuery(item.guid))
    }
  }

  const handleRemove = item => {
    switch (item.queryType) {
      case 'workflow':
        return dispatch(deleteWorkflow(item.guid))

      default:
        return dispatch(deleteQuery(item.guid))
    }
  }

  return (
    <Root>
      <AppBar
        queryDrawerOpen={queryDrawerOpen}
        position="fixed"
      >
        <StyledIconButton
          queryDrawerOpen={queryDrawerOpen}
          data-testid='button-open'
          color="inherit"
          edge="end"
          onClick={handleToggle}
          size="large">
          <ChevronLeftIcon />

          Stored Procedures
        </StyledIconButton>
      </AppBar>

      <Main queryDrawerOpen={queryDrawerOpen}>
        <ViewContent view={visibleView} />
      </Main>

      <Drawer
        data-testid='drawer'
        style={{
          width: queryDrawerOpen ? 440 : 0,
          flexShrink: 0
        }}
        open={queryDrawerOpen}
        variant="persistent"
        anchor="right"
        PaperProps={{ component: StyledPaper }}
      >
        <DrawerHeader>
          <IconButton
            data-testid='button-close'
            onClick={handleToggle}
            size="large">
            <ChevronRightIcon />
          </IconButton>
        </DrawerHeader>

        {!loadedSavedItems
          ? (
          <StyledGrid
            data-testid='container-loading'
            container
          >
            Loading queries

            <CircularProgress />
          </StyledGrid>
            )
          : (
          <StyledGrid
            data-testid='container-content'
            container
          >
            <Grid item xs={4}>
              <StyledTypography
                gutterBottom
                variant="h6"
              >Saved Queries</StyledTypography>
            </Grid>

            <Grid item xs={12}>
              <SearchBox
                data-testid='search'
                value={searchFilter}
                onChange={handleSearch}
              />
            </Grid>

            <Grid item xs={12}>
              <ToggleButtonGroup
                exclusive
                value={alignment}
                onChange={handleAlignment}
              >
                {TYPE_KEYS.map(key => (
                  <ToggleButton
                    data-testid={`button-filter-${key}`}
                    key={key}
                    style={barFilter === key
                      ? { color: 'white', backgroundColor: '#c62828' }
                      : {}
                    }
                    size="large"
                    value={key}
                    onClick={() => handleFilterClicked(key)}
                  >{key}</ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Grid>

            <Grid item xs={12}>
              <StyledList>
                {filteredItems.map((item, index) => (
                  <ListItem key={item.guid}>
                    <ListItemAvatar>
                      <IconButton
                        data-testid={`button-open-${index}`}
                        edge="end"
                        onClick={() => handleOpen(item)}
                        size="large"><LaunchIcon /></IconButton>
                    </ListItemAvatar>

                    <Tooltip
                      title={item.description || 'No description'}
                    >
                      <ListItemText
                        data-testid={`label-${index}`}
                      >
                        {item.name} ({item.queryType})
                      </ListItemText>
                    </Tooltip>

                    <ListItemSecondaryAction>
                      <IconButton
                        data-testid={`button-delete-${index}`}
                        edge="end"
                        onClick={() => handleRemove(item)}
                        size="large"><DeleteIcon /></IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </StyledList>
            </Grid>
          </StyledGrid>
            )}
      </Drawer>
    </Root>
  )
}
