import React from 'react'
import DialogActions from '@mui/material/DialogActions'
import MuiDialogContent from '@mui/material/DialogContent'
import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import PropTypes from 'prop-types'
import LoadingStepper from './LoadingStepper'
import { useNavigate } from 'react-router-dom'
import { styled } from '@mui/material'

const DialogContent = styled(MuiDialogContent)(({
  width: '100%',
  border: '#5C5C5C',
  borderStyle: 'solid',
  borderWidth: 'thick'
}))

const LoadingDialog = ({
  open,
  isFinished,
  handleClose,
  errors = [],
  code,
  id
}) => {
  const navigate = useNavigate()

  const closeOrRedirect = (code) => {
    switch (code) {
      case 3:
      case 7:
        navigate(`/precalculation/${id}`)
        handleClose()
        break
      case 11:
      case 12:
        if (errors.length === 0) {
          navigate(`/precalculation/${id}`)
        }
        handleClose()
        break

      default:
        handleClose()
        break
    }
  }

  return (
    <Dialog
      open={open}
      aria-labelledby="form-dialog-title"
      maxWidth={'md'}
    >
      <DialogContent>
        <LoadingStepper />
        <DialogActions>
          <div>
            <Button
              data-testid='button-close'
              disabled={!isFinished}
              variant="outlined"
              color="primary"
              autoFocus
              onClick={() => closeOrRedirect(code)}
            >
                        Close
            </Button>
          </div>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

LoadingDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  isFinished: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  errors: PropTypes.any,
  code: PropTypes.any,
  id: PropTypes.any
}

export default LoadingDialog
