import React from 'react'
import MuiPaper from '@mui/material/Paper'
import { Grid, styled } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import IconButton from '@mui/material/IconButton'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'
import SearchableGrid from './components/SearchableGrid'
import DynamicGrid from './components/DynamicGrid'
import useReview from '../app/hooks/useReview'
import BasicGrid from './components/BasicGrid'
import ExposureGrid from './components/ExposureGrid'
import ClaimsGrid from './components/ClaimsGrid'
import ShareProjectDialog from '../upload/shareProject/shareProjectDialog'
import { getAccess } from '../../store/pages/project/async'
import { getPrecalCheckData } from '../../store/pages/preCalculation'

const Paper = styled(MuiPaper)(({ theme }) => ({
  padding: theme.spacing(2),
  width: '100%',
  backgroundColor: '#DEDEDE',
  minHeight: 500
}))

const RunHeader = styled('h1')(({
  marginTop: '10px',
  fontSize: '28px'
}))

const reduceForMappingReviewTable = (files, mappings) => mappings.reduce((accum, curr) => {
  curr.columns.forEach(m => {
    const current = files.find(w => w.guid === curr.guid)
    current && accum.push({
      file: current.path,
      ccf: m.target,
      source: m.source,
      valMapp: m.valMapp
    })
  })
  return accum
}, [])

const reduceForVM = (dataValueMap) =>
  dataValueMap.reduce((accum, curr) => {
    curr.valMapp && curr.valMapp.forEach(mapping => {
      if (mapping.valueMapping !== mapping.originalValue) {
        accum.push({
          file: curr.file,
          ccf: curr.ccf,
          source: mapping.source,
          nv: mapping.valueMapping,
          ov: mapping.originalValue
        })
      }
    })
    return accum
  }, [])

function Review () {
  const dispatch = useDispatch()

  const calculation = useSelector(state => state.calculation)
  const mapping = useSelector(state => state.mapping)
  const postCalc = useSelector(state => state.postCalc)
  const preCalc = useSelector(state => state.preCalc)
  const project = useSelector(state => state.project)
  const review = useSelector(state => state.review)
  const user = useSelector(state => state.user)

  const [openShareProject, setOpenShareProject] = React.useState(false)

  const data = mapping.mappings && reduceForMappingReviewTable(project.files, mapping.mappings)
  const dataVM = reduceForVM(data)

  const access = getAccess(
    project.sharedWithIds,
    project.ownerId,
    project.studyType,
    user.role,
    user.ownerId,
    'REVIEW'
  )

  const accessPeerReview = getAccess(
    project.sharedWithIds,
    project.ownerId,
    project.studyType,
    user.role,
    user.ownerId,
    'PEERREVIEW'
  )

  const jobId = calculation.latestRun && calculation.latestRun.ProjectId === project.id
    ? calculation.latestRun.JobId
    : null

  if (!preCalc.load || (preCalc.currentProject !== project.id)) {
    dispatch(getPrecalCheckData(project.id))
  }

  useReview()

  if (preCalc.preCalculationCheckData && review.duplicates) {
    const duplicatesCount = preCalc.preCalculationCheckData.filter(data => data.checkName
      .includes('DUPLICATES')).map(duplicate => duplicate.results.length)

    const sumDuplicates = duplicatesCount.reduce((count, a) => count + a, 0)

    review.duplicates.sumDuplicates = sumDuplicates
  }

  if (preCalc.preCalculationCheckData.length > 0 && (review.lapseSkewness || review.lapseSkewnessDistribution)) {
    const lapseS = preCalc.preCalculationCheckData
      .find(x => x.checkName.includes('MONTHLY_SKEWNESS'))

    const lapseD = preCalc.preCalculationCheckData
      .find(x => x.checkName.includes('LAPSE_SKEWNESS'))

    review.lapseSkewness.data = lapseS
      ? lapseS.results
      : []

    review.lapseSkewnessDistribution.data = lapseD
      ? lapseD.results
      : []
  }

  if (review.lapseExclusion || postCalc.lapseCheckData) {
    review.lapseExclusion.data = postCalc.lapseCheckData
  }

  if (review.policyTracker || postCalc.policyTrackerData) {
    review.policyTracker.data = postCalc.policyTrackerData
  }

  if (postCalc.expVsRiskAmountCheckData && review.exposureRiskHistory) {
    review.exposureRiskHistory.data = [
      {
        key: 'exposureRiskHistoryAmounts',
        title: 'Exposure vs Risk History Amounts',
        data: postCalc.expVsRiskAmountCheckData
      },
      {
        key: 'exposureRiskHistoryCounts',
        title: 'Exposure vs Risk History Counts',
        data: postCalc.expVsRiskCountCheckData
      }
    ]
  }

  const handleOpenShareProjectClick = () => {
    setOpenShareProject(true)
  }

  return (
    <Paper>
      { accessPeerReview && <ShareProjectDialog
        open={openShareProject}
        onClose={setOpenShareProject}
        data={project}/>
      }
      <Grid container spacing={2}>
        <Grid item xs={12}>
          { jobId && calculation.jobHistory && calculation.jobHistory.length > 0 && data && dataVM && <div>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={10}>
                    <RunHeader>
            REVIEW RUN {jobId}<hr></hr>
                    </RunHeader>
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      data-testid='button-shareProject'
                      disabled={!accessPeerReview}
                      aria-label="share Project"
                      component="span"
                      onClick={handleOpenShareProjectClick}
                      size="large">
                      <SupervisorAccountIcon fontSize= 'large'/>
                      Share Project
                    </IconButton>
                  </Grid>
                </Grid>
                { project.studyType !== 'skipMap' && (<div>
                  <BasicGrid
                    access={access}
                    objectData={review.lapseSkewnessDistribution}
                    keyValue='lapseSkewnessDistribution'
                    title={'Lapse Skewness Distribution of Day of Lapse Since Anniversary'}
                    pagination={true}
                    paginationPageSize={10}
                    paginationAutoPageSize={false}
                    jobId={jobId}
                    projectId={project.id.toString()}/>
                  <BasicGrid
                    access={access}
                    objectData={review.lapseSkewness}
                    keyValue='lapseSkewness'
                    title={'Lapse Skewness -- Monthly Skewness'}
                    pagination={true}
                    paginationPageSize={10}
                    paginationAutoPageSize={false}
                    jobId={jobId}
                    projectId={project.id.toString()}/>
                  <SearchableGrid
                    access={access}
                    objectData={review.columnMappingComment}
                    keyValue='columnMappingComment'
                    data={data}
                    pagination={true}
                    paginationPageSize={10}
                    title={'Column Mapping'}
                    jobId={jobId}
                    projectId={project.id.toString()}/>
                  <SearchableGrid
                    objectData={review.valueMappingComment}
                    access={access}
                    keyValue='valueMappingComment'
                    data={dataVM}
                    pagination={true}
                    paginationPageSize={10}
                    paginationAutoPageSize={false}
                    title={'Value Mapping'}
                    jobId={jobId}
                    projectId={project.id.toString()}/>
                  <DynamicGrid
                    access={access}
                    objectData={review.duplicates}
                    keyValue='duplicates'
                    data={project}
                    title={'Duplicates'}
                    jobId={jobId}
                    projectId={project.id.toString()}/>
                </div>)
                }
                <DynamicGrid
                  access={access}
                  objectData={review.piiComment}
                  keyValue='piiComment'
                  data={project}
                  title={'Personally Identifiable Information PII'}
                  jobId={jobId}
                  projectId={project.id.toString()}/>

                <BasicGrid
                  access={access}
                  objectData={review.lapseExclusion}
                  keyValue='lapseExclusion'
                  title={'Lapse Exclusion'}
                  jobId={jobId}
                  projectId={project.id.toString()}/>
                <ExposureGrid
                  access={access}
                  objectData={review.exposureRiskHistory}
                  keyValue='exposureRiskHistory'
                  title={'Exposure vs Risk History'}
                  pagination={true}
                  paginationPageSize={10}
                  projectId={project.id.toString()}
                />
                <ClaimsGrid
                  objectData={review.terminatingClaims}
                  access={access}
                  title={'Terminating Claims'}
                  keyValue='terminatingClaims'
                  claimsData={postCalc.claimsTerminatingData}
                  claimsOvsData={postCalc.claimsOvsQTerminatingData}
                  jobId={jobId}
                  projectId={project.id.toString()}
                ></ClaimsGrid>
                <ClaimsGrid
                  objectData={review.noTerminatingClaims}
                  access={access}
                  title={'Non-Terminating Claims'}
                  keyValue='noTerminatingClaims'
                  claimsData={postCalc.claimsNonTerminatingData}
                  claimsOvsData={postCalc.claimsOvsQNonTerminatingData}
                  jobId={jobId}
                  projectId={project.id.toString()}
                ></ClaimsGrid>
                <BasicGrid
                  access={access}
                  objectData={review.policyTracker}
                  keyValue='policyTracker'
                  title={'Policy Tracker'}
                  pagination={false}
                  gridHeight='450px'
                  jobId={jobId}
                  projectId={project.id.toString()}/>
              </Grid>
            </Grid>
          </div>
          }
        </Grid>
      </Grid>
    </Paper>
  )
}

export default Review
