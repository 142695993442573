import React from 'react'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { tableRowBackGroundColor } from '../../components/table/table.utils'
import moment from 'moment'
const profileDataLabels = {
  distinctCnt: 'Distinct',
  total: 'Total',
  min: 'Min',
  max: 'Max'
}

const getProfileRows = (values, dataType) => {
  let keys = Object.keys(values)
    .filter(val => Object.keys(profileDataLabels).includes(val))
    .sort()
  if (dataType === 'string') {
    keys = keys.filter(x => x !== 'min').filter(x => x !== 'max')
  }
  return keys.map((key, idx) =>
    React.cloneElement(
      <TableRow style={tableRowBackGroundColor(idx)}>
        <TableCell>
          {profileDataLabels[key]}
        </TableCell>
        <TableCell align="right">
          { dataType === 'date'
            ? values[key]
            : values[key].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
          }
        </TableCell>
      </TableRow>, { key: idx })
  );
}

const sortCount = (a, b) => {
  if (Number(a.count) < Number(b.count)) {
    return 1
  }
  if (Number(a.count) > Number(b.count)) {
    return -1
  }
  return 0
}

const dateReducer = (s, a) => {
  const currentDate = s.find(i => i.value === moment(a.value).year())
  if (currentDate) {
    currentDate.count = currentDate.count + Number(a.count)
  } else {
    s.push({ value: moment(a.value).year(), count: Number(a.count) })
  }
  return s
}

const getValueRows = (data, totalCount, dataType) => {
  const { values = [] } = data
  const dateValue = dataType === 'date' ? values.reduce(dateReducer, []) : values
  return dateValue
    .sort(sortCount)
    .map(({ value, count }, idx) =>
      React.cloneElement(
        <TableRow style={tableRowBackGroundColor(idx)}>
          <TableCell>
            {value}
          </TableCell>
          <TableCell align="right">
            { dataType === 'date'
              ? count
              : count.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
            }
          </TableCell>
          <TableCell align="right">
            { dataType === 'date'
              ? `${parseFloat(count / Number(totalCount) * 100).toFixed(2)} %`
              : `${parseFloat(Number(count) / Number(totalCount) * 100).toFixed(2)} %`
            }
          </TableCell>
        </TableRow>, { key: idx }
      )
    );
}

export { getProfileRows, getValueRows }
