import PropTypes from 'prop-types'
import React from 'react'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import { useSelector } from 'react-redux'

export default function LoadingDialog (props) {
  const dataPrep = useSelector(store => store.dataPrep)

  const handleClose = React.useCallback(() => {
    props.onClose()
  }, [props])

  return (
    <Dialog
      data-testid={props['data-testid']}
      open={props.open}
      onClose={handleClose}
    >
      {dataPrep.aggregators.isLoading
        ? (
          <DialogContent
            data-testid='content'
          >
            <Typography variant='h6'>
              <CircularProgress />

              Applying aggregations and profiling.
            </Typography>
          </DialogContent>
          )
        : (
          <DialogActions
            data-testid='actions'
          >
            <Typography
              data-testid='label-message'
              variant='h6'
            >{dataPrep.aggregators.message}</Typography>

            <Button
              data-testid='button-close'
              color='primary'
              onClick={handleClose}
            >Close</Button>
          </DialogActions>
          )
      }
    </Dialog>
  )
}

LoadingDialog.propTypes = {
  'data-testid': PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func
}

/* istanbul ignore next */
LoadingDialog.defaultProps = {
  'data-testid': '',
  open: false,
  onClose: () => {}
}
