import React from 'react'
import Table from '@mui/material/Table'
import BaseTableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import BaseTableContainer from '@mui/material/TableContainer'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material'
import { useSelector } from 'react-redux'
import { getMaxLength } from '../../../../components/sql/styleCol'
import { tableRowBackGroundColor } from '../../../../components/table/table.utils'

const Root = styled('div')({
  maxWidth: 'calc(87vw)',
  overflow: 'auto'
})

const TableContainer = styled(BaseTableContainer)({
  overflowX: 'scroll',
  maxHeight: 'calc(100vh - 500px)'
})

const TableBody = styled(BaseTableBody)({
  display: 'block',
  overflow: 'auto',
  height: 500
})

export default function ResultsGrid () {
  const join = useSelector(store => store.join)
  const results = join.resultsGrid

  const getCellWidth = (label) => {
    const maxCellWidths = getMaxLength(results, null)

    const width = maxCellWidths.find(entry => entry.label === label)
      ? maxCellWidths.find(entry => entry.label === label).length
      : 13

    return width < 14 ? 140 : width * 10
  }

  return !results.isLoading && results.columns.length > 0 && (
    <Root>
      <Typography variant='h6'>
        Row Count: {results.count}
      </Typography>

      <TableContainer>
        <Table stickyHeader size='small'>
          <TableHead>
            <TableRow>
              {results.columns.map(header => (
                <TableCell
                  key={header}
                  style={{ minWidth: getCellWidth(header) }}
                >{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {results.data.map((row, rowIndex) => (
              <TableRow
                key={rowIndex}
                style={tableRowBackGroundColor(rowIndex)}
              >
                {results.columns.map((column, columnIndex) => (
                  <TableCell
                    key={`${rowIndex}_${columnIndex}`}
                    style={{ minWidth: getCellWidth(column) }}
                    align='left'
                  >{row[column]}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Root>
  )
}
