import React from 'react'
import PropTypes from 'prop-types'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import DialogTitle from '@mui/material/DialogTitle'
import MuiDialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material'

const Dialog = styled(MuiDialog)(({ theme }) => ({
  padding: theme.spacing(2)
}))

export default function LogDialog ({ open, handleClose, data }) {
  return (
    <div >
      <Dialog
        onClose={handleClose}
        open={open} >
        <DialogContent>
          <DialogTitle
            id="simple-dialog-title">
            <b>Check history log</b>   </DialogTitle>
          <Divider />
          <List>
            {data.map((x, i) => (
              <ListItem key={i}>
                <Typography>
                  <b> {x.checkName}</b> : {x.fixLog}
                </Typography>
              </ListItem>
            ))}
          </List>
          <DialogActions>
            <div>
              <Button
                onClick={() => handleClose(false)}
                color="primary"
                autoFocus
                variant='outlined'
              >
                Close
              </Button>
            </div>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  )
}
LogDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  data: PropTypes.any
}
