import React from 'react'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import ReactCreatableSelect from 'react-select/creatable'
import PropTypes from 'prop-types'
import { styled } from '@mui/material'
import MuiTypography from '@mui/material/Typography'
import { SingleValue, SingleValueStyles } from '../../../components/sql/SelectSingleValue'
import { useDispatch } from 'react-redux'
import { updateWizardStepAction } from '../../../store/pages/preCalculation'

const OuterGrid = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2),
  overflow: 'visible',
  minHeight: 400
}))
const StyledTypography = styled(MuiTypography)(({
  padding: '5px 5px 5px 5px'
}))
const InnerGrid = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(1)
}))

const CreatableSelect = styled(ReactCreatableSelect)(({ theme }) => ({
  zIndex: props => 900 + props.index,
  minHeight: 58,
  margin: theme.spacing(1)
}))

const getOptions = (typeField) => {
  switch (typeField.toLowerCase()) {
    case 'varchar':
    case 'character varying':
    case 'date':
      return ['max', 'min', 'count'].map(x => ({ value: x, label: x }))
    case 'int':
    case 'integer':
    case 'bigint':
    case 'double precision':
    case 'double':
    case 'numeric':
    default:
      return ['sum', 'min', 'max', 'avg', 'count'].map(x => ({ value: x, label: x }))
  }
}

const DuplicateAggregators = ({ studyType, checkName, columns, currentFile }) => {
  const dispatch = useDispatch()

  return (
    <OuterGrid container>
      {columns && columns.map((e, i) => !e.isPrimaryKey && e.Name.toUpperCase() !== 'INVENTORY_DATE' &&
        <InnerGrid key={i} item xs={3}>
          <Paper key={e.order}>
          <StyledTypography variant="subtitle2" gutterBottom>
              {e.Name}
            </StyledTypography>
            <CreatableSelect
              name={`aggregate-${i}!`}
              styles={SingleValueStyles}
              placeholder=""
              value={e.aggregator}
              components={{ SingleValue }}
              options={getOptions(e.Type)}
              onChange={(newValue) => {
                currentFile.columns[i].aggregator = newValue
                dispatch({
                  ...updateWizardStepAction,
                  payload: {
                    type: checkName,
                    object: currentFile
                  }
                })
              }}
            />
          </Paper>
        </InnerGrid>
      )}
    </OuterGrid>

  )
}
DuplicateAggregators.propTypes = {
  type: PropTypes.any,
  checkName: PropTypes.any,
  currentFile: PropTypes.any,
  rgaColumns: PropTypes.any,
  columns: PropTypes.any,
  fixWizard: PropTypes.any,
  studyType: PropTypes.any

}

export default DuplicateAggregators
