import React, { useState } from 'react'
import { useTheme } from '@mui/material/styles'
import { styled } from '@mui/material'
import Drawer from '@mui/material/Drawer'
import AppBar from '@mui/material/AppBar'
import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import IconButton from '@mui/material/IconButton'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Typography from '@mui/material/Typography'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import ListItemText from '@mui/material/ListItemText'
import DeleteIcon from '@mui/icons-material/Delete'
import LaunchIcon from '@mui/icons-material/Launch'
import CloseIcon from '@mui/icons-material/Close'
import CircularProgress from '@mui/material/CircularProgress'
import { useDispatch, useSelector } from 'react-redux'

import SearchBox from '../../components/form/SearchBox'
import { toggledMappingsOpenAction, openMappings, deleteMappings } from '../../store/pages/mapping'

const DRAWER_WIDTH = 500

const StyledDiv = styled('div')(({
  display: 'flex'
}))

const ContainerGrid = styled(Grid)(({ theme }) => ({
  margin: theme.spacing(1),
  paddingRight: theme.spacing(1)
}))

const ListContainer = styled(List)({
  overflowY: 'scroll',
  scrollY: true,
  maxHeight: '800px'
})

const SavedMappingsButton = styled(IconButton)({
  display: 'flex',
  fontSize: '10px'
})

const SearchDiv = styled('div')(({ theme }) => ({
  margin: theme.spacing(1),
  maxHeight: 40
}))

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  width: DRAWER_WIDTH,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    marginTop: 65,
    width: DRAWER_WIDTH,
    padding: theme.spacing(0.5)
  }
}))

const StyledLaunchIcon = styled(LaunchIcon)({
  transform: 'rotate(270deg)'
})

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  width: 100,
  marginTop: 75,
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  })
}))

export default function MappingsDrawer () {
  const theme = useTheme()
  const { savedMappings, loadingSavedMapping, isOpen, file, studyType } = useSelector(({
    mapping: { savedMappings, loadingSavedMapping, mappingsDrawerOpen: isOpen },
    navigation: { file },
    project: { studyType }
  }) => ({
    savedMappings, loadingSavedMapping, isOpen, file, studyType
  }))
  const [filterSavedMappings, setFilterSavedMappings] = useState(undefined)
  const dispatch = useDispatch()

  const mappings = savedMappings.filter(x => x.mappings.fileType === file.fileType && x.studyType === studyType)
  const filteredOrSavedMappings = filterSavedMappings === undefined
    ? mappings
    : mappings.filter(x => x.name.toLowerCase().match(filterSavedMappings.toLowerCase()))

  return (
    <StyledDiv >
      <StyledAppBar position="fixed">
        <SavedMappingsButton
          color="inherit"
          aria-label="open drawer"
          edge="end"
          onClick={() => dispatch(toggledMappingsOpenAction)}
          size="large">
          { theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon /> }
          Saved Mappings
        </SavedMappingsButton>
      </StyledAppBar>
      <StyledDrawer
        variant="persistent"
        anchor="right"
        open={isOpen}
      >
        {loadingSavedMapping
          ? <ContainerGrid container>
          Loading Mappings
            <CircularProgress />
          </ContainerGrid>
          : <ContainerGrid container>
            <Grid item xs={11}>
              <Typography variant="h6" gutterBottom>
              Saved Mappings
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <IconButton
                edge="end"
                aria-label="close"
                onClick={() => dispatch(toggledMappingsOpenAction)}
                size="large">
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <SearchDiv>
                <SearchBox onChange={e => setFilterSavedMappings(e.target.value)} />
              </SearchDiv>
            </Grid>
            <Grid item xs={12}>
              <ListContainer>
                {filteredOrSavedMappings.map((value, index) => (
                  <ListItem key={index}>
                    <ListItemAvatar>
                      <IconButton
                        edge="end"
                        aria-label="open"
                        onClick={() => dispatch(openMappings(value.guid))}
                        size="large">
                        <StyledLaunchIcon/>
                      </IconButton>
                    </ListItemAvatar>
                    <ListItemText>
                      {value.name}
                    </ListItemText>
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => dispatch(deleteMappings(value.guid))}
                        size="large">
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </ListContainer>
            </Grid>
          </ContainerGrid>}
      </StyledDrawer>
    </StyledDiv>
  )
}
