import React, { useRef, useCallback } from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import TableHead from '@mui/material/TableHead'
import Grid from '@mui/material/Grid'
import PropTypes from 'prop-types'
import Divider from '@mui/material/Divider'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import Switch from '@mui/material/Switch'
import { getProfileRows } from './ProfileCard.utils'
import { showProfileSummary } from '../../store/pages/valueValidationActions'
import { getValueMappingObject } from './valueMappingObject'
import SwitchRender from './SwitchRender'
import AgTable from './agTable'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-enterprise'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { useDispatch, useSelector } from 'react-redux'
import { ValueMappingGridData } from '../../store/pages/common/agGRid/agGridServers'
import getMappingObject from './MappingObject'
import { styled } from '@mui/material'

const StyledCardContent = styled(CardContent)({
  overflowX: 'scroll'
})

const StyledTable = styled(Table)({
  borderCollapse: 'collapse'
})

const StyledTableCell = styled(TableCell)({
  backgroundColor: 'white',
  padding: '0'
})

const StyledGrid = styled(Grid)({
  height: '600px',
  overflowY: 'auto',
  overflowX: 'hidden'
})

const StyledDivAgGridContainer = styled('div')({
  overflow: 'auto'
})

const StyledDivAgGrid = styled('div')({
  height: '50%',
  width: '100%'
})

const ProfileCard = props => {
  const dispatch = useDispatch()
  const country = useSelector((store) => store.project.county)
  const resultsGrid = useSelector((store) => store.dataQuery.resultsGrid)
  const [filterError, setFilterError] = React.useState(false)
  const {
    guid,
    data = [],
    source,
    sourceColumnOptions,
    targetValue,
    mappings,
    projectId,
    access
  } = props

  const labels = source.split('||') ?? []
  const label = labels[0]
  const relevantDataPre = data.find(datum => datum.guid === guid && datum.fieldName === label)
  const relevantData = relevantDataPre
    ? { ...relevantDataPre }
    : {
        guid,
        projectId,
        values: [],
        fieldName: label
      }
  const { valMapp = [] } = mappings

  const validateInitialMap = (thevalueMap) => {
    if ((!thevalueMap.valMapp || thevalueMap.valMapp.length === 0) && thevalueMap.fieldName !== 'Unmapped') {
      const defaultMapInitial = thevalueMap.values.map(x =>
        ({ originalValue: x.value, valueMapping: x.value, exclude: false }))
      return defaultMapInitial
    } else {
      return [...valMapp]
    }
  }

  if (relevantData) {
    relevantData.valMapp = validateInitialMap(relevantData)
    relevantData.valuesMap = sourceColumnOptions.find(e => e.label === targetValue)
    const displayNames = relevantData.valuesMap ? relevantData.valuesMap.display_names.split(',') : []
    relevantData.valuesMap.validOption = relevantData.valuesMap
      ? relevantData.valuesMap.valid_values.split(',').reduce((s, a, k) => {
        s.push({ value: a, label: displayNames[k] })
        return s
      }, [])
      : 'single Value'
  }
  const isUnmapped = label === 'Unmapped'
  let newM = ''
  if (isUnmapped && relevantData.valMapp.length > 0 && relevantData.valMapp[0].singleValue.value) {
    newM = relevantData.valMapp[0].singleValue.value
  } else {
    newM = ''
  }

  const disableExclude = () => {
    const countExclusion = relevantData.valMapp
      ? relevantData.valMapp.filter(item => item.exclude).length
      : 0

    return ((relevantData.distinctCnt - 1) <= (countExclusion)) || !access
  }

  relevantData.disableExclude = disableExclude()

  const currentSourceColumn = sourceColumnOptions.find(column => column.name === mappings.currentTarget)
  const showValueMapping = showProfileSummary(relevantData)

  const targetDataTypeObject = (currentSourceColumn && currentSourceColumn.validOption && currentSourceColumn.validOption[0].value !== '')
    ? 'OPTIONS'
    : mappings.targetDataType.toUpperCase()

  const { valuesMap: { validOption }, fieldName } = relevantData
  relevantData.values.forEach(x => {
    const current = relevantData.valMapp.find(y => x.value === y.originalValue)
    const isNumber = ['NUMBER', 'INT', 'DECIMAL', 'INTEGER'].includes(targetDataTypeObject.toUpperCase())
    if (current) {
      x.error = current.error
      x.exclude = current.exclude || null
      x.max = relevantData.max
      x.common_format_column = isNumber && current.valueMapping ? Number(current.valueMapping) : current.valueMapping
    }
    x['% of Total'] = `${parseFloat(Number(x.count) / Number(relevantData.total) * 100).toFixed(2)} %`
  })

  const isErrorValue = (value) => !value.exclude && (value.error)

  const getRowStyle = params =>
    isErrorValue(params.data)
      ? { background: '#c62927' }
      : {}

  const copyData = [...relevantData.values]
  const rowData = filterError ? copyData.filter(isErrorValue) : copyData
  const gridRefData = useRef()
  const colDefs = []
  const keys = ['value', 'count', '% of Total']
  keys.forEach(key => colDefs.push({
    field: key,
    suppressHeaderMenuButton: true
  }))
  const excludeObj = {
    field: 'exclude',
    headerName: 'exclude',
    cellRenderer: SwitchRender,
    cellRendererParams: { data, relevantData },
    suppressHeaderMenuButton: true
  }
  colDefs.push(getMappingObject(dispatch, targetDataTypeObject, validOption, relevantData, targetValue))
  colDefs.push(excludeObj)
  const gridRef = useRef()
  const onGridReadyData = (params) => {
    const excludeObj = {
      field: 'exclude',
      headerName: 'exclude',
      editable: true,
      cellRenderer: SwitchRender,
      cellRendererParams: { data, relevantData },
      suppressHeaderMenuButton: true
    }
    const execute = { source: label, sourceDataType: relevantData.sourceDataType, guid, projectId: relevantData.projectId, totalCount: Number(relevantData.distinctCnt) }
    const dataSource = new ValueMappingGridData({ country, resultsGrid }, gridRefData, execute, relevantData, excludeObj)
    params.api.setGridOption('serverSideDatasource', dataSource)
  }
  const onGridReady = useCallback((params) => {
    params.api.autoSizeColumns(['common_format_column', 'value', 'count', 'porcentage'])
  }, [])

  return (
    <Grid container spacing={2}>
      {
        isUnmapped
          ? <Grid item xs={12}>
            <Card>
              <StyledCardContent>
                <Divider />
                <StyledTable stickyHeader size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Enter Unmapped Value</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <StyledTableCell data-testid='unmappedValue'>
                      {getValueMappingObject(targetDataTypeObject, newM, validOption, relevantData, mappings.currentTarget, '', access, dispatch)}
                      </StyledTableCell>
                    </TableRow>
                  </TableBody>
                </StyledTable>
              </StyledCardContent>
            </Card>
          </Grid>
          : <Grid item xs={12}>
            <Card>
              <StyledCardContent>
                <Divider />
                <StyledTable stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>Distinct</TableCell>
                      <TableCell>Total</TableCell>
                      <TableCell>Min</TableCell>
                      <TableCell>Max</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {relevantData && getProfileRows(relevantData, access)}
                  </TableBody>
                </StyledTable>
              </StyledCardContent>
            </Card>
          </Grid>
      }
      {
        !showValueMapping
          ? <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container alignItems="center">
                  <Grid item xs={8}>
                    <Typography color="textSecondary">
                        Value Mapping for: {relevantData ? relevantData.valuesMap.label : 'no map'}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} align='right'>
                    <Typography color="textPrimary"> Filter Error  <Switch
                      size="small"
                      color="primary"
                      checked={filterError}
                      onChange={e => {
                        return setFilterError(!filterError)
                      }}
                    /> </Typography>
                  </Grid>
                  <StyledGrid item xs={12}>
                    <StyledDivAgGridContainer>
                      <StyledDivAgGrid className="ag-theme-alpine">
                        <AgGridReact
                          ref={gridRef}
                          key={label}
                          domLayout={'autoHeight'}
                          rowHeight={75}
                          rowData={rowData}
                          columnDefs={colDefs}
                          onGridReady={onGridReady}
                          singleClickEdit= {true}
                          getRowStyle={getRowStyle}
                        />
                      </StyledDivAgGrid>
                    </StyledDivAgGridContainer>
                  </StyledGrid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          : fieldName !== 'Unmapped'
            ? (
              <Grid item xs={12}>
                <AgTable
                  pagination={true}
                  key={label}
                  gridRef={gridRefData}
                  relevantData={relevantData}
                  onGridReady={onGridReadyData}
                  getRowStyle={getRowStyle}
                />
              </Grid>)
            : <div></div>
      }
    </Grid>
  )
}
ProfileCard.propTypes = {
  source: PropTypes.any,
  guid: PropTypes.string.isRequired,
  data: PropTypes.any,
  sourceColumnOptions: PropTypes.array,
  targetValue: PropTypes.string,
  mappings: PropTypes.object,
  projectId: PropTypes.any,
  excludeData: PropTypes.any,
  access: PropTypes.any
}
export default ProfileCard
