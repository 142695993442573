import { Button } from '@mui/material'
import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { useDispatch, useSelector } from 'react-redux'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'

import InfoDialog from '../../app/InfoDialog'
import { addedFilesAction } from '../../../store/pages/reference'

const useFileDropZone = () => {
  const dispatch = useDispatch()
  const { files } = useSelector(({ reference: { referenceTable: { files } } }) => ({
    files
  }))
  const [openInfo, setOpenInfo] = React.useState(false)
  const handleClose = () => {
    setOpenInfo(false)
  }
  const onDrop = useCallback(files => {
    dispatch({
      ...addedFilesAction,
      payload: files
    })
  }, [dispatch])

  const {
    getRootProps,
    getInputProps,
    isDragActive
  } = useDropzone({ onDrop, useFsAccessApi: false })
  if (files.length > 0) {
    return ''
  }
  return (
    <div>
      <Tooltip title={'View upload best practices'}>
        <IconButton onClick={() => setOpenInfo(true)} large size="large">
          <InfoOutlinedIcon />
        </IconButton>
      </Tooltip>
      <Button
        variant="contained"
        {...getRootProps()}
        className={'uploadArea'}
        style={{
          marginBottom: 8,
          width: '90%',
          borderWidth: 2,
          borderStyle: 'dashed',
          border: 'black',
          boxShadow: 'none',
          padding: 20,
          backgroundColor: 'white',
          color: 'black'
        }}
      >
        <input {...getInputProps()} />
        {
          isDragActive
            ? <div>Drop the file here ...</div>
            : <div>Click to Add a file or Drag and Drop</div>
        }
      </Button>

      <InfoDialog open={openInfo} onClose={handleClose} />
    </div>
  );
}

export default useFileDropZone
