import React from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import DialogActions from '@mui/material/DialogActions'
import MuiDialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import HelperText from '../../../components/controls/HelperText'
import FILE_TYPES from '../../../store/objects/data/fileTypes.json'
import { styled } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'

import {
  setDescriptionSaveQueryDialogAction,
  setNameSaveQueryDialogAction,
  toggleSaveQueryDialogAction,
  saveQueryAggregation,
  saveQueryDialog,
  saveQueryMerge,
  saveQueryNewCol
} from '../../../store/pages/dataPrep'

const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  display: 'grid',
  width: 400,
  gap: theme.spacing(1),
  gridTemplateColumns: '1fr'
}))

export default function SaveQueryDialogLegacy () {
  const dispatch = useDispatch()

  const {
    name,
    description,
    visibleView,
    ourFileType
  } = useSelector(({
    navigation: {
      file: {
        fileType: ourFileType
      }
    },
    dataPrep: {
      visibleView,
      saveQueryDialog: {
        name,
        description
      }
    }
  }) => ({
    name,
    description,
    visibleView,
    ourFileType
  }))

  const type = FILE_TYPES.default.find(type => type.value === ourFileType)

  const [enableSaveButton, setEnableSaveButton] = React.useState(true)

  const handleNameChange = event => {
    dispatch({
      ...setNameSaveQueryDialogAction,
      payload: event.target.value
    })
  }

  const handleDescriptionChange = event => {
    dispatch({
      ...setDescriptionSaveQueryDialogAction,
      payload: event.target.value
    })
  }

  const handleCancel = () => {
    dispatch(toggleSaveQueryDialogAction)
  }

  const handleSave = () => {
    switch (visibleView) {
      case 'newColumn':
        return dispatch(saveQueryNewCol)

      case 'aggregate':
        return dispatch(saveQueryAggregation)

      case 'merge':
        setEnableSaveButton(false)
        return dispatch(saveQueryMerge)

      default:
        return dispatch(saveQueryDialog)
    }
  }

  return (
    <>
      <DialogTitle>Save Query</DialogTitle>

      <DialogContent>
        <Typography
          data-testid='title'
        >Type: {type.label}</Typography>

        <div>
          <HelperText />

          <TextField
            data-testid='textfield-name'
            fullWidth
            label="Name"
            variant='outlined'
            value={name}
            onChange={handleNameChange}
          />

          <HelperText>Required</HelperText>
        </div>

        <div>
          <HelperText/>

          <TextField
            data-testid='textfield-description'
            fullWidth
            label="Description"
            variant='outlined'
            value={description}
            onChange={handleDescriptionChange}
          />

          <HelperText></HelperText>
        </div>
      </DialogContent>

      <DialogActions>
        <Button
          data-testid='button-cancel'
          color="primary"
          onClick={handleCancel}
        >Cancel</Button>

        <Button
          data-testid='button-save'
          color="primary"
          onClick={handleSave}
          disabled={!enableSaveButton}
        >Save</Button>
      </DialogActions>
    </>
  )
}
