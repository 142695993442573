import mappingApi from '../objects/mappingApi'
import projectApi from '../objects/projectApi'
import { createdProjectAction, updatedProjectAction } from './project/actions'
// import { removeLoadedMappings } from '../../store/pages/home'

export const loadedStudiesAction = {
  type: 'home/LOADED_STUDIES'
}

export const deletedProjectAction = {
  type: 'home/DELETED_PROJECT'
}

const initialState = {
  allExperienceStudies: [],
  isLoading: false,
  isRefreshNeeded: true,
  isSearching: false,
  projects: [],
  searchText: ''
}

export const loadStudies = async dispatch => {
  const response = await projectApi.getProjectList('', 'desc', 0, 20)
  // Format the dates
  const projWithDates = response.data.map(project => ({
    ...project,
    projectDate: new Date(project.projectDate).toLocaleDateString()
  }))

  return dispatch({ ...loadedStudiesAction, payload: projWithDates })
}

export const deleteProject = projectId => async dispatch => {
  await projectApi.deleteProject(projectId)
  await mappingApi.removeMappings(projectId)
  return dispatch({ ...deletedProjectAction, payload: projectId })
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case loadedStudiesAction.type:
      return {
        ...state,
        allExperienceStudies: [...payload],
        isRefreshNeeded: false
      }
    case deletedProjectAction.type: {
      const { id } = payload
      const projects = state.projects
      const allExperienceStudies = state.allExperienceStudies
      const index = projects.findIndex(x => x.id === id)
      const indexForAll = allExperienceStudies.findIndex(x => x.id === id)
      return {
        ...state,
        projects: [...projects.slice(index, 1)],
        allExperienceStudies: [
          ...allExperienceStudies.slice(indexForAll, 1)
        ]
      }
    }
    case createdProjectAction.type:
    case updatedProjectAction.type:
      return {
        ...state,
        isRefreshNeeded: true
      }
    default:
      return state
  }
}
