import React, { useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import { Accordion, AccordionSummary, AccordionDetails } from '../../../themes/AccordionTheme'
import { getColumnDefs, getRowData } from './FormatGridData'
import Grid from '@mui/material/Grid'
import { styled } from '@mui/material'
import Comments from './Comments'
import AcknowledgeButton from './AcknowledgeButton'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-enterprise'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'

const AccordionWrapper = styled(Accordion)(({
  marginBottom: '10px',
  border: '1px solid #55645896 !important'
}))

const ClaimsTableWrapper = styled(AccordionDetails)(({
  display: 'block'
}))

const AccordionTitle = styled(Typography)(({
  fontWeight: 500,
  fontSize: '1.25rem'
}))

const ClaimsGridHeader = styled(Typography)(({
  width: '100%',
  textAlign: 'center',
  fontSize: '14px',
  fontWeight: '700',
  color: 'black'
}))

function ClaimsGrid ({
  claimsData,
  claimsOvsData,
  title,
  access,
  objectData,
  keyValue,
  projectId
}) {
  const gridStyle1 = useMemo(() => ({
    height: '200px',
    width: '100%'
  }), [])

  const gridStyle2 = useMemo(() => ({
    height: '320px',
    width: '100%'
  }), [])

  objectData.key = keyValue
  objectData.title = title
  objectData.projectId = projectId

  const gridKeys = useMemo(() =>
    [
      'claimsTotals',
      'claimsFromValTerminated',
      'claimsQualified',
      'claimsOutput',
      'claimsOutputVsQualified'
    ]
  , [])

  const [rowData1, setRowData1] = useState([])
  const [columnDefs1, setColumnDefs1] = useState([])

  const [rowData2, setRowData2] = useState([])
  const [columnDefs2, setColumnDefs2] = useState([])

  const [rowData3, setRowData3] = useState([])
  const [columnDefs3, setColumnDefs3] = useState([])

  const [rowData4, setRowData4] = useState([])
  const [columnDefs4, setColumnDefs4] = useState([])

  const [rowData5, setRowData5] = useState([])
  const [columnDefs5, setColumnDefs5] = useState([])

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 150
    }
  }, [])

  const onGridReady1 = useCallback((params) => {
    if (claimsData) {
      setRowData1(getRowData(gridKeys[0], claimsData))
      setColumnDefs1(getColumnDefs(gridKeys[0]))
      params.api.sizeColumnsToFit()
    }
  }, [claimsData, gridKeys])

  const onGridReady2 = useCallback((params) => {
    if (claimsData && claimsData.length > 0) {
      setRowData2(getRowData(gridKeys[1], claimsData[0]))
      setColumnDefs2(getColumnDefs(gridKeys[1]))
      params.api.sizeColumnsToFit()
    }
  }, [claimsData, gridKeys])

  const onGridReady3 = useCallback((params) => {
    if (claimsData && claimsData.length > 0) {
      setRowData3(getRowData(gridKeys[2], claimsData[1]))
      setColumnDefs3(getColumnDefs(gridKeys[2]))
      params.api.sizeColumnsToFit()
    }
  }, [claimsData, gridKeys])

  const onGridReady4 = useCallback((params) => {
    if (claimsData && claimsData.length > 0) {
      setRowData4(getRowData(gridKeys[3], claimsData[2]))
      setColumnDefs4(getColumnDefs(gridKeys[3]))
      params.api.sizeColumnsToFit()
    }
  }, [claimsData, gridKeys])

  const onGridReady5 = useCallback((params) => {
    if (claimsOvsData && claimsOvsData.length > 0) {
      setRowData5(getRowData(gridKeys[4], claimsOvsData))
      setColumnDefs5(getColumnDefs(gridKeys[4]))
      params.api.sizeColumnsToFit()
    }
  }, [claimsOvsData, gridKeys])

  return (<AccordionWrapper>
    {/* TODO: determine if this can be refactored into a component */}
    <AccordionSummary>
      <Grid container>
        <Grid item xs={10}>
          <AccordionTitle>{title}</AccordionTitle>
        </Grid>
        <Grid item xs={2}>
          <AcknowledgeButton
            objectData={objectData}
            access={access}
          />
        </Grid>
      </Grid>
    </AccordionSummary>
    <ClaimsTableWrapper>
      <Grid container spacing={2}>
        {claimsData && claimsData.length > 0 && (
          <Grid item xs={12}>
            <div style={gridStyle1} className="ag-theme-alpine">
              <AgGridReact
                rowData={rowData1}
                columnDefs={columnDefs1}
                defaultColDef={defaultColDef}
                pagination={false}
                onGridReady={onGridReady1}
              />
            </div>
          </Grid>
        )}
        {claimsData && claimsData.length > 0 && claimsData[0].length > 0 && (
          <Grid item xs={12}>
            <ClaimsGridHeader>Total Claims From Val Terminated</ClaimsGridHeader>
            <div style={gridStyle2} className="ag-theme-alpine">
              <AgGridReact
                rowData={rowData2}
                columnDefs={columnDefs2}
                defaultColDef={defaultColDef}
                pagination={false}
                onGridReady={onGridReady2}

              ></AgGridReact>
            </div>
          </Grid>
        )}
        {claimsData && claimsData.length > 1 && claimsData[1].length > 0 && (
          <Grid item xs={12}>
            <ClaimsGridHeader>Qualified Claims</ClaimsGridHeader>
            <div style={gridStyle2} className="ag-theme-alpine">
              <AgGridReact
                rowData={rowData3}
                columnDefs={columnDefs3}
                defaultColDef={defaultColDef}
                pagination={false}
                onGridReady={onGridReady3}

              ></AgGridReact>
            </div>
          </Grid>
        )}
        {claimsData && claimsData.length > 2 && claimsData[2].length > 0 && (
          <Grid item xs={12}>
            <ClaimsGridHeader>Claims From Output Table</ClaimsGridHeader>
            <div style={gridStyle2} className="ag-theme-alpine">
              <AgGridReact
                rowData={rowData4}
                columnDefs={columnDefs4}
                defaultColDef={defaultColDef}
                pagination={false}
                onGridReady={onGridReady4}

              ></AgGridReact>
            </div>
          </Grid>
        )}
        {claimsOvsData && claimsOvsData.length > 0 && (
          <Grid item xs={12}>
            <ClaimsGridHeader>Output vs Qualified Claims</ClaimsGridHeader>
            <div style={gridStyle2} className="ag-theme-alpine">
              <AgGridReact
                rowData={rowData5}
                columnDefs={columnDefs5}
                defaultColDef={defaultColDef}
                pagination={false}
                onGridReady={onGridReady5}

              ></AgGridReact>
            </div>
          </Grid>
        )}
      </Grid>
      <Comments
        objectData={objectData}
        access={access}
      />
    </ClaimsTableWrapper>
  </AccordionWrapper>)
}

ClaimsGrid.propTypes = {
  access: PropTypes.any,
  title: PropTypes.any,
  keyValue: PropTypes.any,
  claimsData: PropTypes.any,
  claimsOvsData: PropTypes.any,
  objectData: PropTypes.any,
  projectId: PropTypes.any
}

export default ClaimsGrid
