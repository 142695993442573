import PropTypes from 'prop-types'
import React from 'react'
import Alert from '@mui/material/Alert'
import Collapse from '@mui/material/Collapse'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material'

const StyledDiv = styled('div')(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(5),
  right: theme.spacing(5),
  float: true,
  width: '50%',
  borderColor: 'black'
}))

export default function AnnouncementDialog (props) {
  const handleClose = () => {
    props.onClose()
  }

  return (
    <StyledDiv>
      <Collapse in={props.open}>
        <Alert
          data-testid='alert'
          color='info'
          severity='success'
          onClose={handleClose}
        >
          <Typography
            data-testid='label-title'
            variant='h6'
          >{props.title}</Typography>

          <br/>

          <Typography
            data-testid='label-reason'
          >{props.reason}</Typography>

          <br/>
        </Alert>
      </Collapse>
    </StyledDiv>
  )
}

AnnouncementDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  reason: PropTypes.string,
  onClose: PropTypes.func
}

AnnouncementDialog.defaultTypes = {
  open: false,
  title: '',
  reason: '',
  onClose: () => {}
}
