import PropTypes from 'prop-types'
import React from 'react'
import Table from './Table'

export default function SelectionTable (props) {
  const items = props.items.length ? props.items : ['None']

  return <Table
    title={props.title}
    items={items}
  />
}

SelectionTable.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.string)
}
