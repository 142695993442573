import adminApi from '../objects/adminApi'
import moment from 'moment'

const initialState = {
  releaseData: null,
  announcementData: null,
  dialogData: null,
  refresh: false,
  load: false,
  currentRelease: null,
  currentAnnouncement: null
}

export const setDialogDataAction = {
  type: 'adminTool/SET_DIALOG_DATA'
}

export const hidCurrentAnnouncementAction = {
  type: 'adminTool/HID_CURRENT_ANNOUNCEMENT'
}

export const removedAdminDataAction = {
  type: 'adminTool/REMOVED_ADMIN_DATA'
}

export const updatedAdminDataAction = {
  type: 'adminTool/UPDATED_ADMIN_DATA'
}

export const receivedAdminDataAction = {
  type: 'adminTool/RECEIVED_ADMIN_DATA'
}

export const changedDialogDataAction = {
  type: 'adminTool/CHANGED_DIALOG_DATA'
}

export const changedReleaseExpansionAction = {
  type: 'adminTool/CHANGED_RELEASE_EXPANSION'
}

export const getAdminData = async (dispatch) => {
  const today = new Date()
  const now = moment(today)
  const releaseData = await adminApi.getReleaseData()
  const announcementData = await adminApi.getAnnouncementData()
  return dispatch({
    ...receivedAdminDataAction,
    payload: {
      load: true,
      releaseData: releaseData || [],
      announcementData: announcementData || [],
      refresh: false,
      dialogData: null,
      currentRelease: releaseData.filter(i => now.isBetween(i.startDate, i.endDate)),
      currentAnnouncement: announcementData.filter(i => now.isBetween(i.startDate, i.endDate))
    }
  })
}

export const updateAdminData = body => async (dispatch, getState) => {
  const {
    adminTool: { releaseData, announcementData }
  } = getState()

  await adminApi.putAdminData(body)

  body.expand = false

  const updatedData = body.type === 'release' ? releaseData : announcementData
  const id = updatedData.indexOf(updatedData.find(item => item.guid === body.guid))

  if (id || id === 0) {
    updatedData[id] = body
  }

  return dispatch({
    ...updatedAdminDataAction,
    payload: {
      refresh: !body.guid,
      releaseData,
      announcementData
    }
  })
}

export const removeAdminData = body => async (dispatch) => {
  await adminApi.removeAdminData(body)
  return dispatch(removedAdminDataAction)
}

export default (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case setDialogDataAction.type: {
      return {
        ...state,
        dialogData: payload ? { ...payload } : payload
      }
    }
    case hidCurrentAnnouncementAction.type: {
      const {
        currentAnnouncement
      } = state
      const filteredAnnouncements = currentAnnouncement.filter(x => x.guid === payload)
      filteredAnnouncements[0].visible = false
      return {
        ...state,
        currentAnnouncement: [...filteredAnnouncements]
      }
    }
    case removedAdminDataAction.type:
      return {
        ...state,
        refresh: true,
        dialogData: null
      }
    case updatedAdminDataAction.type:
    case receivedAdminDataAction.type:
      return {
        ...state,
        ...payload
      }
    case changedDialogDataAction.type: {
      const { dialogData } = state
      const { name, value } = payload
      return {
        ...state,
        dialogData: {
          ...dialogData,
          [name]: value
        }
      }
    }
    case changedReleaseExpansionAction.type: {
      const { releaseData } = state
      const { guid, expand } = payload
      const newReleaseData = releaseData.map(data => data.guid === guid ? { ...data, expand } : data)
      return {
        ...state,
        releaseData: [
          ...newReleaseData
        ]
      }
    }
    default:
      return state
  }
}
