import React from 'react'
import { Grid, IconButton, styled } from '@mui/material'
import ReactCreatableSelect from 'react-select/creatable'
import ReactSelect from 'react-select'
import DeleteIcon from '@mui/icons-material/Delete'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import {
  SingleValue,
  SingleValueStyles,
  AppendColumnBuilder
} from '../../../../components/sql/SelectSingleValue'

import {
  removedSetConditionAction,
  updateOptionSetList
} from '../../../../store/pages/common/workflow'

const COMPONENTS = { SingleValue }

const Select = styled(ReactSelect)(({ theme }) => ({
  minHeight: 58,
  margin: theme.spacing(1)
}))

const Operation = styled('div')(({ theme }) => ({
  minHeight: 58,
  margin: theme.spacing(1)
}))

const StyledGrid = styled(Grid)(() => ({
  fontSize: 40
}))

const CreatableSelect = styled(ReactCreatableSelect)(({ theme }) => ({
  minHeight: 58,
  margin: theme.spacing(1)
}))

const DeleteButton = styled(IconButton)(() => ({
  marginRight: 75,
  marginTop: 15
}))

function SetList (props) {
  const dispatch = useDispatch()

  const navigation = useSelector((state) => state.navigation)

  const columns = navigation.file.columns || []
  const columnsPlusBuilder = AppendColumnBuilder(columns)
  const { updateSetConditions } = props.state.state

  const columnNameOptions = columns.map(column => ({
    label: column.Name,
    value: column.Name
  }))

  const whereValueOptions = columnsPlusBuilder.map(column => ({
    label: column.Name,
    value: column.Name
  }))

  const buildSelectChangedHandler = index => {
    return (value, event) => {
      dispatch(updateOptionSetList(event.name, index, value, props.order))
    }
  }

  const deleteButtonClicked = event => {
    const index = Number(event.target.getAttribute('data-index'))

    dispatch({
      ...removedSetConditionAction,
      payload: {
        order: props.order,
        index
      }
    })
  }

  return (
    <div>
      {updateSetConditions.map((item, index) => (
        <Grid container key={index}>
          <Grid item xs={3}>
            <Select
              data-testid={`select-columnName-${index}`}
              styles={SingleValueStyles}
              components={COMPONENTS}
              name='columnName'
              queryKey='columnName'
              queryTarget='updateSetConditions'
              queryIndex={index}
              options={columnNameOptions}
              value={item.columnName}
              onChange={buildSelectChangedHandler(index)}
            />
          </Grid>

          <StyledGrid item xs={2}>
            <Operation>
              {item.operation}
            </Operation>
          </StyledGrid>

          <Grid item xs={3}>
            <CreatableSelect
              data-testid={`select-whereValue-${index}`}
              styles={SingleValueStyles}
              components={COMPONENTS}
              name='whereValue'
              queryKey='whereValue'
              queryTarget='updateSetConditions'
              queryIndex={index}
              order={props.order}
              options={whereValueOptions}
              value={item.whereValue}
              onChange={buildSelectChangedHandler(index)}
            />
          </Grid>

          <Grid item xs={1}>
            <DeleteButton
              data-testid={`button-delete-${index}`}
              data-index={index}
              onClick={deleteButtonClicked}
              size="large">
              <DeleteIcon fontSize="small" />
            </DeleteButton>
          </Grid>

          <Grid item xs={3}></Grid>
        </Grid>
      ))}
    </div>
  );
}

SetList.propTypes = {
  order: PropTypes.number,
  state: PropTypes.shape({
    state: PropTypes.shape({
      updateSetConditions: PropTypes.arrayOf(
        PropTypes.shape({
          operation: PropTypes.string,
          columnName: PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.string
          }),
          whereValue: PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.string
          })
        })
      )
    })
  })
}

export default React.memo(SetList)
