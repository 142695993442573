import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import PropTypes from 'prop-types'
import React from 'react'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'

export default function ActionsRender ({
  ownerId,
  data,
  onDelete
}) {
  return (
    ownerId &&
    data &&
    data.ownerId?.toLowerCase() === ownerId.toLowerCase()
  )
    ? (
    <IconButton
      size="large"
      onClick={event => {
        event.preventDefault()
        event.stopPropagation()
        onDelete(event, data.id)
      }}
    >
      <DeleteIcon />
    </IconButton>
      )
    : <div></div>
}

ActionsRender.propTypes = {
  ownerId: PropTypes.string,
  data: PropTypes.any,
  onDelete: PropTypes.func.isRequired
}
