import React from 'react'
import { Grid, Typography, Fab, styled } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import SetMultiSelect from './setList'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { addedNewUpdateSetConditionAction } from '../../../../store/pages/common/workflow'

const AddButton = styled(Fab)(() => ({
  marginLeft: 150
}))

function Set (props) {
  const dispatch = useDispatch()
  return (
    <Grid container>
      <Grid item xs={4}>
        <Typography variant="h6" >
          Set
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <AddButton
          data-testid='button-add-1'
          size="small"
          color="primary"
          aria-label="add"
          onClick={() => dispatch({ ...addedNewUpdateSetConditionAction, payload: { order: props.order } })}
        >
          <AddIcon/>
        </AddButton>
      </Grid>
      <Grid item xs={12}>
        <SetMultiSelect state = {props.state} actions = {props.actions} order = {props.order}/>
      </Grid>
    </Grid>
  )
}

Set.propTypes = {
  state: PropTypes.object,
  actions: PropTypes.object,
  order: PropTypes.number
}

export default React.memo(Set)
