import PropTypes from 'prop-types'
import React from 'react'
import Checkbox from '@mui/material/Checkbox'
import BaseTable from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import { styled } from '@mui/material'

const SELECT_ALL_INPUT_PROPS = {
  'aria-label': 'select all'
}

const StyledHeaderTableCell = styled(TableCell)({
  maxHeight: '20px',
  padding: '2px'
})

const ContentCell = styled(TableCell, {
  shouldForwardProp: prop => prop !== 'size'
})(props => ({
  maxWidth: props.size,
  maxHeight: '20px',
  padding: '5px',
  backgroundColor: 'white'
}))

function HeaderCell (props) {
  const active = props.sortKey === props.fieldName

  const handleLabelClick = () => {
    const order =
      props.sortKey === props.fieldName && props.sortOrder === 'asc'
        ? 'desc'
        : 'asc'

    props.onSort(props.fieldName, order)
  }

  return (
    <StyledHeaderTableCell
      align='left'
      padding='none'
    >
      <TableSortLabel
        data-testid={props['data-testid']}
        active={active}
        direction={active ? props.sortOrder : 'asc'}
        onClick={handleLabelClick}
      >{props.label}</TableSortLabel>
    </StyledHeaderTableCell>
  )
}

HeaderCell.propTypes = {
  'data-testid': PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  sortKey: PropTypes.string.isRequired,
  sortOrder: PropTypes.string.isRequired,
  onSort: PropTypes.func.isRequired
}

function Header (props) {
  const handleSort = (key, order) => {
    props.onSort(key, order)
  }

  return (
    <TableHead>
      <TableRow>
        {!!props.selectedItems && <TableCell
          data-testid={`${props['data-testid']}-header-selected`}
          padding="checkbox"
        >
          {props.enableSelectAll && <Checkbox
            data-testid={`${props['data-testid']}-checkbox-select-all`}
            checked={props.allSelected} // TODO: also add indeterminate mode
            color='primary'
            inputProps={SELECT_ALL_INPUT_PROPS}
          />}
        </TableCell>}

        {props.config.map(config => (
          <HeaderCell
            data-testid={`${props['data-testid']}-header-${config.key}`}
            key={config.key}
            fieldName={config.key}
            label={config.label}
            sortKey={props.sortKey}
            sortOrder={props.sortOrder}
            onSort={handleSort}
          ></HeaderCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

Header.propTypes = {
  'data-testid': PropTypes.string.isRequired,
  enableSelectAll: PropTypes.bool.isRequired,
  allSelected: PropTypes.bool.isRequired,
  sortKey: PropTypes.string.isRequired,
  sortOrder: PropTypes.oneOf(['asc', 'desc']).isRequired,
  config: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string,
      size: PropTypes.number,
      renderer: PropTypes.func
    })
  ).isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.string),
  onSort: PropTypes.func.isRequired
}

function Row (props) {
  const testId = props['data-testid']
  const testIdCellPrefix = `${testId}-cell`
  const testIdContentPrefix = `${testId}-content`
  const style = props.selected ? { background: '#D3D3D3' } : {}

  const handleRowClick = () => {
    props.onRowClick(props.item, props.index, props.selected)
  }

  return (
    <TableRow
      data-testid={`${testId}-row-${props.index}`}
      style={style}
      hover
      tabIndex={-1}
      selected={props.selected}
      aria-checked={props.selected}
      onClick={handleRowClick}
    >
      {props.selectable && <ContentCell
        data-testid={`${testIdCellPrefix}-selected-${props.index}`}
        padding='checkbox'
      >
        <Checkbox
          data-testid={`${testIdContentPrefix}-selected-${props.index}`}
          color='primary'
          checked={props.selected}
        />
      </ContentCell>}

      {props.config.map(config => {
        const value = props.item[config.key]

        const ctx = {
          key: config.key,
          index: props.index,
          item: props.item,
          testIdContentPrefix
        }

        const content = config.render
          ? config.render(value, ctx)
          : value

        return <ContentCell
          data-testid={`${testIdCellPrefix}-${config.key}-${props.index}`}
          key={config.key}
          size={config.size}
        >{content}</ContentCell>
      })}
    </TableRow>
  )
}

Row.propTypes = {
  'data-testid': PropTypes.string,
  selectable: PropTypes.bool.isRequired,
  selected: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  config: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string,
      size: PropTypes.number,
      renderer: PropTypes.func
    })
  ).isRequired,
  item: PropTypes.object.isRequired,
  onRowClick: PropTypes.func.isRequired
}

export default function Table (props) {
  const selectable = Boolean(props.selectedItems)
  const firstKey = props.config[0].key

  const isRowSelected = targetItem => {
    const items = props.selectedItems
    const key = targetItem[props.selectKey]

    return Boolean(items) && items.indexOf(key) !== -1
  }

  const handleSort = (key, order) => {
    props.onSort(key, order)
  }

  const handleRowClick = (item, index, selected) => {
    props.onRowClick(item, index, selected)
  }

  return (
    <TableContainer data-testid={props['data-testid']}>
      <BaseTable size='small'>
        <Header
          data-testid={props['data-testid']}
          enableSelectAll={Boolean(props.enableSelectAll)}
          allSelected={Boolean(props.allSelected)}
          sortKey={props.sortKey}
          sortOrder={props.sortOrder}
          config={props.config}
          selectedItems={props.selectedItems}
          onSort={handleSort}
        />

        <TableBody>
          {props.items.map((item, index) => <Row
            data-testid={props['data-testid']}
            key={item[firstKey]}
            selectable={selectable}
            selected={isRowSelected(item)}
            index={index}
            config={props.config}
            item={item}
            onRowClick={handleRowClick}
          />)}
        </TableBody>
      </BaseTable>
    </TableContainer>
  )
}

Table.propTypes = {
  'data-testid': PropTypes.string,
  enableSelectAll: PropTypes.bool,
  allSelected: PropTypes.bool,
  sortKey: PropTypes.string,
  sortOrder: PropTypes.string,
  selectKey: PropTypes.string,
  config: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string,
      size: PropTypes.number,
      renderer: PropTypes.func
    })
  ).isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.string),
  onSort: PropTypes.func,
  onRowClick: PropTypes.func
}

/* istanbul ignore next */
Table.defaultProps = {
  'data-testid': '',
  enableSelectAll: false,
  allSelected: false,
  sortKey: '',
  sortOrder: 'asc',
  selectKey: '',
  config: [],
  items: [],
  selectedItems: null,
  onSort: (_key, _order) => {},
  onRowClick: (_item, _index, _selected) => {},
  onToggleSelectAll: _flag => {}
}
