import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import { styled } from '@mui/material'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import React from 'react'

const Accordion = styled((props) => (
  <MuiAccordion elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme?.palette?.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&:before': {
    display: 'none'
  }
}))

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    {...props}
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
  />
))(({ theme }) => ({
  backgroundColor:
        theme.palette?.mode === 'dark'
          ? 'rgba(255, 255, 255, .05)'
          : 'rgba(0, 0, 0, .03)',
  // flexDirection: 'row-reverse',
  '& svg': {
    fontSize: '1em'
  },
  '& .MuiAccordionSummary-expandIcon': {
    transform: 'rotate(90deg)',
    fontSize: '1em'
  },
  '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
    transform: 'rotate(270deg)'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: '1px'
  }
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  borderTop: '1px solid rgba(0, 0, 0, .125)'
}))

export { Accordion, AccordionDetails, AccordionSummary }
