const getColumnDefs = (key) => {
  switch (key) {
    case 'lapseSkewnessDistribution': {
      return [
        {
          field: 'days_since_last_annv',
          headerName: 'Days Since Last Anniversary',
          suppressHeaderMenuButton: true
        },
        {
          field: 'lapse_and_conv_count',
          headerName: 'Lapse and Conversion Count',
          suppressHeaderMenuButton: true
        }
      ]
    }
    case 'lapseSkewness': {
      return [
        {
          field: 'policy_year',
          headerName: 'Policy Year',
          suppressHeaderMenuButton: true
        },
        {
          field: 'policy_month',
          headerName: 'Policy Month',
          suppressHeaderMenuButton: true
        },
        {
          field: 'lapse_count',
          headerName: 'Lapse Count',
          suppressHeaderMenuButton: true
        }
      ]
    }
    case 'columnMappingComment': {
      return [
        {
          field: 'file',
          headerName: 'File',
          suppressHeaderMenuButton: true
        },
        {
          field: 'ccf',
          headerName: 'Common Format Column',
          suppressHeaderMenuButton: true
        },
        {
          field: 'source',
          headerName: 'Source Columns',
          suppressHeaderMenuButton: true
        }
      ]
    }
    case 'valueMappingComment': {
      return [
        {
          field: 'file',
          headerName: 'File',
          suppressHeaderMenuButton: true
        },
        {
          field: 'ccf',
          headerName: 'Column',
          suppressHeaderMenuButton: true
        },
        {
          field: 'nv',
          headerName: 'New Value',
          suppressHeaderMenuButton: true
        },
        {
          field: 'ov',
          headerName: 'Old Value',
          suppressHeaderMenuButton: true
        }
      ]
    }
    case 'exposureRiskHistoryAmounts': {
      return [
        {
          field: 'date',
          headerName: 'Date',
          suppressHeaderMenuButton: true
        },
        {
          field: 'exposure',
          headerName: 'Exposure Amount',
          suppressHeaderMenuButton: true
        },
        {
          field: 'risk_history',
          headerName: 'Risk History Amount',
          suppressHeaderMenuButton: true
        }
      ]
    }
    case 'exposureRiskHistoryCounts': {
      return [
        {
          field: 'date',
          headerName: 'Date',
          suppressHeaderMenuButton: true
        },
        {
          field: 'exposure',
          headerName: 'Exposure Count',
          suppressHeaderMenuButton: true
        },
        {
          field: 'risk_history',
          headerName: 'Risk History Count',
          suppressHeaderMenuButton: true
        }
      ]
    }
    case 'claimsTotals': {
      return [
        {
          field: 'row_header',
          headerName: '',
          suppressHeaderMenuButton: true
        },
        {
          field: 'claims_count',
          headerName: 'Count',
          suppressHeaderMenuButton: true
        },
        {
          field: 'claims_amount',
          headerName: 'Amount',
          suppressHeaderMenuButton: true
        }
      ]
    }
    case 'claimsFromValTerminated':
    case 'claimsQualified': {
      return [
        {
          field: 'event_year',
          headerName: 'Calendar Year',
          suppressHeaderMenuButton: true
        },
        {
          field: 'claim_count',
          headerName: 'Count',
          suppressHeaderMenuButton: true
        },
        {
          field: 'claim_amount',
          headerName: 'Amount',
          suppressHeaderMenuButton: true
        }
      ]
    }
    case 'claimsOutput': {
      return [
        {
          field: 'calendar_year',
          headerName: 'Calendar Year',
          suppressHeaderMenuButton: true
        },
        {
          field: 'claim_count',
          headerName: 'Count',
          suppressHeaderMenuButton: true
        },
        {
          field: 'claim_amount',
          headerName: 'Amount',
          suppressHeaderMenuButton: true
        }
      ]
    }
    case 'claimsOutputVsQualified': {
      return [
        {
          field: 'calendar_year',
          headerName: 'Calendar Year',
          suppressHeaderMenuButton: true
        },
        {
          field: 'amount_pct',
          headerName: 'Amount %',
          suppressHeaderMenuButton: true
        },
        {
          field: 'amount_difference',
          headerName: 'Claim Amount',
          suppressHeaderMenuButton: true
        },
        {
          field: 'count_pct',
          headerName: 'Count %',
          suppressHeaderMenuButton: true
        },
        {
          field: 'count_difference',
          headerName: 'Claim Count',
          suppressHeaderMenuButton: true
        }
      ]
    }
    case 'lapseExclusion': {
      return [
        {
          field: 'year',
          headerName: 'Year',
          suppressHeaderMenuButton: true
        },
        {
          field: 'month',
          headerName: 'Month',
          suppressHeaderMenuButton: true
        },
        {
          field: 'cnt',
          headerName: 'Count',
          suppressHeaderMenuButton: true
        }
      ]
    }
    case 'policyTracker': {
      return [
        {
          field: 'policy_status',
          headerName: '',
          suppressHeaderMenuButton: true
        },
        {
          field: 'policy_count',
          headerName: 'Count',
          suppressHeaderMenuButton: true
        },
        {
          field: 'percent',
          headerName: 'Percent',
          suppressHeaderMenuButton: true
        }
      ]
    }
    default: {
      return []
    }
  }
}

const lapseCompleteData = (data) => {
  if (!data) return []
  const allData = []
  let dayIndex = 1
  while (dayIndex < 366) {
    const item = data.find(x => x.days_since_last_annv === dayIndex)
    if (item) {
      allData.push(item)
    } else {
      allData.push({ days_since_last_annv: dayIndex, lapse_and_conv_count: 0 })
    }
    dayIndex++
  }
  return allData.map(x => ({
    days_since_last_annv: x.days_since_last_annv,
    lapse_and_conv_count: x.lapse_and_conv_count
  }))
}

const policyTrackerCompleteData = (data) => {
  if (!data) return []
  const allData = []
  for (let i = 0; i < data.length; i++) {
    const item = data[i]
    allData.push({
      policy_status: item.policy_status,
      policy_count: item.policy_count,
      percent: i > 0 ? ((item.policy_count * 100) / data[0].policy_count).toFixed(2) + '%' : ''
    })
  }
  allData.push({
    policy_status: 'TOTAL',
    policy_count: '',
    percent: '100%'
  })
  return allData
}

const formatNumber = x => {
  x = parseFloat(x).toFixed(0)
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

const sum = (items, prop) => {
  return items.reduce((a, b) => {
    return Number(a) + (Number(b[prop]) || 0)
  }, 0)
}

const formatExposureRiskHistoryData = (data) => {
  if (!data) return null
  return data.map(item => ({
    date: item.date,
    exposure: formatNumber(item.exposure),
    risk_history: formatNumber(item['risk history'])
  }))
}

const formatClaimsTotals = (data) => {
  if (!data) return null
  const totals = []
  totals.push({
    row_header: 'Total Claims From Val Terminated',
    claims_count: data[0] ? formatNumber(sum(data[0], 'claim_count')) : 0,
    claims_amount: data[0] ? formatNumber(sum(data[0], 'claim_amount')) : 0
  })
  totals.push({
    row_header: 'Qualified Claims',
    claims_count: data[1] ? formatNumber(sum(data[1], 'claim_count')) : 0,
    claims_amount: data[1] ? formatNumber(sum(data[1], 'claim_amount')) : 0
  })
  totals.push({
    row_header: 'Claims From Output Table',
    claims_count: data[2] ? formatNumber(sum(data[2], 'claim_count')) : 0,
    claims_amount: data[2] ? formatNumber(sum(data[2], 'claim_amount')) : 0
  })
  return totals
}

const formatClaimsFromValTerminated = (data) => {
  if (!data) return null
  return data.map(item => ({
    event_year: item.event_year,
    claim_count: formatNumber(item.claim_count),
    claim_amount: formatNumber(item.claim_amount)
  }))
}

const formatClaimsQualified = (data) => {
  if (!data) return null
  return data.map(item => ({
    event_year: item.event_year,
    claim_count: formatNumber(item.claim_count),
    claim_amount: formatNumber(item.claim_amount)
  }))
}

const formatClaimsOutput = (data) => {
  if (!data) return null
  return data.map(item => ({
    calendar_year: item.calendar_year,
    claim_count: formatNumber(item.claim_count),
    claim_amount: formatNumber(item.claim_amount)
  }))
}

const formatOutputVsQualifiedClaims = (data) => {
  if (!data) return null
  return data.map(item => ({
    calendar_year: item.calendar_year,
    amount_pct: item['amount%'] + '%',
    amount_difference: formatNumber(item['amount difference']),
    count_pct: item['count%'] + '%',
    count_difference: formatNumber(item['count difference'])
  }))
}

const getRowData = (key, data) => {
  switch (key) {
    case 'lapseSkewnessDistribution': {
      return lapseCompleteData(data)
    }
    case 'lapseSkewness':
    case 'columnMappingComment':
    case 'valueMappingComment':
    case 'lapseExclusion': {
      return data
    }
    case 'exposureRiskHistoryAmounts':
    case 'exposureRiskHistoryCounts': {
      return formatExposureRiskHistoryData(data)
    }
    case 'claimsTotals': {
      return formatClaimsTotals(data)
    }
    case 'claimsFromValTerminated': {
      return formatClaimsFromValTerminated(data)
    }
    case 'claimsQualified': {
      return formatClaimsQualified(data)
    }
    case 'claimsOutput': {
      return formatClaimsOutput(data)
    }
    case 'claimsOutputVsQualified': {
      return formatOutputVsQualifiedClaims(data)
    }
    case 'policyTracker': {
      return policyTrackerCompleteData(data)
    }
    default: {
      return []
    }
  }
}

export { getColumnDefs, getRowData }
