import React from 'react'
import { Grid, styled } from '@mui/material'
import MuiIconButton from '@mui/material/IconButton'
import Checkbox from '@mui/material/Checkbox'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded'
import CircularProgress from '@mui/material/CircularProgress'
import ErrorIcon from '@mui/icons-material/Error'
import Tooltip from '@mui/material/Tooltip'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp'
import { useDispatch } from 'react-redux'

import PropTypes from 'prop-types'
import getView from './getWorkflowView'
import { checkedStepAction, deletedStepAction, expandAction, movedDownAction, movedUpAction } from '../../../store/pages/common/workflow'

const WorkflowLeftIcons = styled(Grid)(() => ({
  padding: '8px'
}))

const IconButton = styled(MuiIconButton)(() => ({
  float: 'right'
}))

const ButtonContainer = styled(Grid)(() => ({
  display: 'flex',
  justifyContent: 'space-evenly',
  alignItems: 'center'
}))

export default function Steps (props) {
  const dispatch = useDispatch()
  const state = props.view
  const isDelete = props.view.type === 'removeRows'
  const { expand, checked } = state
  const { isSaving, isExecuting } = props
  const workflowStatus = () => {
    if (props.view.stepStatus === 'RUNNING') {
      return <CircularProgress size={20}/>
    } else if (props.view.stepStatus === 'FAILED') {
      return props.view.errorMessage && <span><Tooltip title = {props.view.errorMessage}>
        <ErrorIcon color="primary" />
      </Tooltip>
      </span>
    } else if (props.view.stepStatus === 'SUCCEEDED') {
      return <CheckCircleOutlineRoundedIcon style={{ color: 'green' }}></CheckCircleOutlineRoundedIcon>
    } else {
      return <RemoveRoundedIcon/>
    }
  }

  return (
    <Grid>
      <Grid container style={{ border: '1px solid #d3d3d3' }}>
        <WorkflowLeftIcons item xs="auto" >
          <ArrowDownwardIcon onClick = {() => dispatch({ ...movedDownAction, payload: { viewIndex: props.order } })}/>
          <ArrowUpwardIcon onClick = {() => dispatch({ ...movedUpAction, payload: { viewIndex: props.order } })}/>
        </WorkflowLeftIcons>
        <Grid item xs={11}>
          <Grid container >
            <Grid item xs={11} >
              <Grid container >
                <Grid item xs={4} >
                  <Checkbox onClick={() => dispatch({ ...checkedStepAction, payload: { index: props.order } })} style ={{
                    color: '#c62828'
                  }} checked ={checked}/>
                  <span>{`${props.view.type.toUpperCase()}`}</span>
                </Grid>
                <WorkflowLeftIcons item xs={8}>
                  <span>{`${props.view.name}`}</span>
                </WorkflowLeftIcons>
              </Grid>
            </Grid>
            <ButtonContainer item xs={1}>
              {workflowStatus()}
              <IconButton
                disabled={isSaving || isExecuting}
                onClick = {() => dispatch({ ...deletedStepAction, payload: { viewIndex: props.order } })}
                size="large">
                <DeleteIcon style ={{
                  color: '#c62828'
                }} ></DeleteIcon>
              </IconButton>

              <IconButton
                onClick = {() => dispatch({ ...expandAction, payload: { order: props.order } })}
                edge="end"
                size="large">
                {
                  expand
                    ? <KeyboardArrowUp ></KeyboardArrowUp>
                    : <KeyboardArrowDown ></KeyboardArrowDown>}
              </IconButton>
            </ButtonContainer>
          </Grid>
          { expand &&
            <Grid container>
              {React.createElement(getView(props.view.type), { state, order: props.order, isDelete }) }
            </Grid>
          }
        </Grid>
      </Grid>
    </Grid>
  );
}
Steps.propTypes = {
  order: PropTypes.number,
  view: PropTypes.object,
  isSaving: PropTypes.bool,
  isExecuting: PropTypes.bool
}
