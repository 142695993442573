export default {
  CHECKS: [
    {
      type: 'REASONABILITY',
      fileType: 'val policy'
    },
    {
      type: 'DOBLEISSUE',
      fileType: 'val policy'
    },
    {
      type: 'DOBLEDOR',
      fileType: 'val policy'
    }
  ],
  LABELS: [
    {
      source: 'Gender',
      target: 'POLSEX',
      checkName: 'CONSISTENCY_POLSEX'
    },
    {
      source: 'Age Basis',
      target: 'XNL',
      checkName: 'CONSISTENCY_XNL'
    },
    {
      source: 'Issue Date',
      target: 'ISSUE_DATE',
      checkName: 'CONSISTENCY_ISSUE_DATE'
    },
    {
      source: 'Issue Age',
      target: 'ISSUE_AGE',
      checkName: 'CONSISTENCY_ISSUE_AGE'
    },
    {
      source: 'Underwriting Class/Smoker Status',
      target: 'UWCLASS',
      checkName: 'CONSISTENCY_UWCLASS'
    },
    {
      source: 'Substandard Rating',
      target: 'RATING_FACTOR',
      checkName: 'CONSISTENCY_RATING_FACTOR'
    },
    {
      source: 'User Defined Attribute 1',
      target: 'TRAIT_STR_1',
      checkName: 'CONSISTENCY_TRAIT_STR_1'
    },
    {
      source: 'User Defined Attribute 2',
      target: 'TRAIT_STR_2',
      checkName: 'CONSISTENCY_TRAIT_STR_2'
    },
    {
      source: 'User Defined Attribute 3',
      target: 'TRAIT_STR_3',
      checkName: 'CONSISTENCY_TRAIT_STR_3'
    },
    {
      source: 'User Defined Attribute 4',
      target: 'TRAIT_STR_4',
      checkName: 'CONSISTENCY_TRAIT_STR_4'
    },
    {
      source: 'User Defined Attribute 5',
      target: 'TRAIT_STR_5',
      checkName: 'CONSISTENCY_TRAIT_STR_5'
    },
    {
      source: 'User Defined Attribute 6',
      target: 'TRAIT_STR_6',
      checkName: 'CONSISTENCY_TRAIT_STR_6'
    },
    {
      source: 'User Defined Attribute 7',
      target: 'TRAIT_STR_7',
      checkName: 'CONSISTENCY_TRAIT_STR_7'
    },
    {
      source: 'User Defined Attribute 8',
      target: 'TRAIT_STR_8',
      checkName: 'CONSISTENCY_TRAIT_STR_8'
    },
    {
      source: 'User Defined Attribute 9',
      target: 'TRAIT_STR_9',
      checkName: 'CONSISTENCY_TRAIT_STR_9'
    },
    {
      source: 'User Defined Attribute 10',
      target: 'TRAIT_STR_10',
      checkName: 'CONSISTENCY_TRAIT_STR_10'
    },
    {
      source: 'User Defined Attribute 11',
      target: 'TRAIT_STR_11',
      checkName: 'CONSISTENCY_TRAIT_STR_11'
    },
    {
      source: 'User Defined Attribute 12',
      target: 'TRAIT_STR_12',
      checkName: 'CONSISTENCY_TRAIT_STR_12'
    },
    {
      source: 'User Defined Attribute 13',
      target: 'TRAIT_STR_13',
      checkName: 'CONSISTENCY_TRAIT_STR_13'
    },
    {
      source: 'User Defined Attribute 14',
      target: 'TRAIT_STR_14',
      checkName: 'CONSISTENCY_TRAIT_STR_14'
    },
    {
      source: 'User Defined Attribute 15',
      target: 'TRAIT_STR_15',
      checkName: 'CONSISTENCY_TRAIT_STR_15'
    },
    {
      source: 'User Defined Attribute 16',
      target: 'TRAIT_STR_16',
      checkName: 'CONSISTENCY_TRAIT_STR_16'
    },
    {
      source: 'User Defined Attribute 17',
      target: 'TRAIT_STR_17',
      checkName: 'CONSISTENCY_TRAIT_STR_17'
    }
  ]
}
