import React from 'react'
import PropTypes from 'prop-types'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { generateMappingList } from './MappingList.utils'
import { useDispatch, useSelector } from 'react-redux'

const MappingList = props => {
  const {
    handleSourceFocusChange,
    handleTargetChange,
    fileType,
    guid,
    fileData,
    searchFilter,
    profileData,
    filterByError
  } = props
  const dispatch = useDispatch()
  const mappings = useSelector((store) => store.mapping.mappings)
  const fileMappings = { ...(mappings.find(mapping => mapping.guid === guid)) }
  const mappedItems = generateMappingList(
    fileType,
    guid,
    fileData,
    handleSourceFocusChange,
    handleTargetChange,
    fileMappings,
    searchFilter,
    profileData,
    filterByError,
    dispatch
  )

  if (fileMappings.optionalColumns) {
    return mappedItems
  }
  return (
    <>
      { fileMappings
        ? mappedItems
        : (
          <TableRow>
            <TableCell>
            </TableCell>
            <TableCell>
            </TableCell>
          </TableRow>
          )}
    </>
  )
}

MappingList.propTypes = {
  handleSourceFocusChange: PropTypes.func.isRequired,
  handleTargetChange: PropTypes.func.isRequired,
  fileType: PropTypes.string.isRequired,
  guid: PropTypes.string.isRequired,
  fileData: PropTypes.array.isRequired,
  searchFilter: PropTypes.string,
  filterByError: PropTypes.bool,
  profileData: PropTypes.any
}

export default MappingList
