import React, { useMemo } from 'react'
import Grid from '@mui/material/Grid'
import Tooltip from '@mui/material/Tooltip'
import MuiSelect from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import MuiWarningIcon from '@mui/icons-material/Warning'
import { styled } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setFile } from '../store/pages/navigation'

const MESSAGE_DIRTY = 'You have unsaved Data Prep, Mapping/Value Mapping changes to your data. Re-run Load Study Input to save your changes to the common format files. Files to review'

const PAGE_ROUTES = {
  prep: '/prep',
  columnMapping: '/columnmapping',
  valueMapping: '/valuemapping'
}

const WarningIcon = styled(MuiWarningIcon)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginLeft: theme.spacing(2)
}))

const Select = styled(MuiSelect)(({ theme }) => ({
  marginTop: theme.spacing(1),
  background: 'transparent',
  borderBottom: 'solid',
  borderColor: 'black',
  color: 'white'
}))

const getActivePageRoute = location => {
  return Object
    .values(PAGE_ROUTES)
    .find(substring => location.pathname.indexOf(substring) !== -1) || ''
}

const getFilteredFiles = (activePageRoute, selectedFile, files, mappings, dispatch) => {
  switch (activePageRoute) {
    case PAGE_ROUTES.prep:
      return files

    case PAGE_ROUTES.columnMapping:
      return files.filter(file =>
        file.fileType !== 'S' && (file.uploaded || file.isProcessing)
      )

    case PAGE_ROUTES.valueMapping: {
      const filteredMappings = mappings
        .filter(mapping => mapping.include)
        .map(mapping => mapping.guid)

      const filteredFiles = files.filter(file =>
        filteredMappings.indexOf(file.guid) !== -1)

      if (!filteredMappings.includes(selectedFile.guid) && filteredFiles[0]) {
        dispatch(setFile(filteredFiles[0].guid))
      }

      return filteredFiles
    }
  }
}

export default function FileNavigation () {
  const location = useLocation()
  const dispatch = useDispatch()

  const { file, files } = useSelector(store => store.navigation)
  const { mappings } = useSelector(store => store.mapping)

  const activeFile = file.guid && (file.isProcessing || file.uploaded) ? file : null
  const activePageRoute = getActivePageRoute(location)
  const activeFileAndNotDataFabric = activeFile && file.src !== 'dataFabric'
  const activePageIsPrepPage = activePageRoute === PAGE_ROUTES.prep
  const show = activePageIsPrepPage ? activeFileAndNotDataFabric : Boolean(activePageRoute) && Boolean(activeFile)

  const filteredFiles = useMemo(() =>
    show
      ? getFilteredFiles(
        activePageRoute,
        file,
        files,
        mappings,
        dispatch)
      : [],
  [
    show,
    activePageRoute,
    file,
    files,
    mappings,
    dispatch
  ])

  const dirtyFiles = filteredFiles.filter(file => file.isDirty)
  const dirtyFileNames = dirtyFiles.map(file => file.fileName).join(', ')
  const dirtyTooltipMessage = `${MESSAGE_DIRTY}: ${dirtyFileNames}`

  return show && (
    <Grid
      data-testid='root'
      container
    >
      <Grid item xs={3}>
        <Select
          data-testid='select-file'
          name={activeFile.fileName}
          value={activeFile.guid}
          onChange={e => dispatch(setFile(e.target.value))}
        >
          {filteredFiles.map(file => (
            <MenuItem
              data-testid={`item-file-${file.guid}`}
              key={file.guid}
              value={file.guid}
            >
              {[file.fileName, file.erros].filter(item => item).join(' - ')}
            </MenuItem>
          ))}
        </Select>
      </Grid>

      <Grid item xs={1}>
        {dirtyFiles.length > 0
          ? <Tooltip
            data-testid='tooltip-dirty'
            title={dirtyTooltipMessage}
          >
            <WarningIcon
              color='secondary'
            />
          </Tooltip>
          : ''
        }
      </Grid>
    </Grid>
  )
}
