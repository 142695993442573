import React from 'react'
import Typography from '@mui/material/Typography'
import { Grid } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import PropTypes from 'prop-types'

const LoadingDialog = (props) => {
  const { handleClose, open } = props
  const { isLoading, message } = props.state.state

  return (
    <Dialog open={open}>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            {
              isLoading &&
                <div>
                  <Typography variant="h6">
                    <CircularProgress />
                    Applying aggregations and profiling.
                  </Typography>
                </div>
            }
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {
          (!isLoading) && <div>
            <Typography variant="h6">
              {message}
              <Button
                // data-testid="button"
                onClick={() => handleClose() }
                color="primary">
                        Close
              </Button>
            </Typography>
          </div>
        }
      </DialogActions>
    </Dialog>
  )
}

LoadingDialog.propTypes = {
  open: PropTypes.any,
  id: PropTypes.any,
  state: PropTypes.any,
  handleClose: PropTypes.func
}

export default LoadingDialog
