import React from 'react'
import Grid from '@mui/material/Grid'
import PropTypes from 'prop-types'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { styled } from '@mui/material'
import { useSelector } from 'react-redux'

import { EnhancedTable as AggregateTable } from './aggregateTable'
import SelectionAggregation from './selectionAggregation'
import LoadingDialog from './loadingDialog'

const StyledGrid = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2),
  width: '100%',
  height: 'max-content'
}))

const Table = styled(AggregateTable)(({ theme }) => ({
  padding: theme.spacing(2),
  overflow: 'visible',
  minHeight: 400
}))

function Aggregate (props) {
  const [open, setOpen] = React.useState(false)

  const project = useSelector((state) => state.project)

  return (
    <Grid container >
      <LoadingDialog handleClose={setOpen} open={open} id={project.id} state = {props.state}/>
      <StyledGrid item container xs={12} spacing={2}>
        <Grid item xs={5}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Fields </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Table state = {props.state} order = {props.order} />
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={6}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography> Aggregation </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <SelectionAggregation state = {props.state} order = {props.order} />
            </AccordionDetails>
          </Accordion>
        </Grid>

      </StyledGrid>
    </Grid>
  )
}

Aggregate.propTypes = {
  state: PropTypes.any,
  actions: PropTypes.any,
  order: PropTypes.any
}

export default React.memo(Aggregate)
