import React from 'react'
import Grid from '@mui/material/Grid'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import MuiWarningIcon from '@mui/icons-material/Warning'
import Paper from '@mui/material/Paper'
import { styled } from '@mui/material'
import Typography from '@mui/material/Typography'
import MuiCircularProgress from '@mui/material/CircularProgress'

const STATUS_CODES = {
  FIX_KEY: 0,
  ERROR: 1,
  WARNING: 2,
  NONCRITICAL_ERROR: 3
}

const STATUS_COLORS = {
  [STATUS_CODES.FIX_KEY]: '#6f9e71',
  [STATUS_CODES.ERROR]: '#c62728',
  [STATUS_CODES.WARNING]: '#9E9E9E',
  [STATUS_CODES.NONCRITICAL_ERROR]: '#dbba15b5'
}

const StatusIndicator = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'status'
})(props => ({
  backgroundColor: STATUS_COLORS[props.status],
  color: props.theme.palette.common.white,
  padding: props.theme.spacing(3)
}))

const StyledGrid = styled(Grid)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(1),
  backgroundColor: '#DEDEDE',
  zIndex: 50
}))

const WarningIcon = styled(MuiWarningIcon)(({ theme }) => ({
  marginRight: theme.spacing(1)
}))

const CircularProgress = styled(MuiCircularProgress)(({
  color: 'white'
}))

const FixContainer = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'fix'
})(({ theme, fix }) => ({
  backgroundColor: fix ? '#6f9e71' : '#c62728',
  color: theme.palette.common.white,
  padding: theme.spacing(3)
}))

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  width: '100%',
  backgroundColor: '#b7d2e8'
}))

const getLoading = (status, nonCritical) => {
  return status === 1 && nonCritical ? 3 : status
}

const getIcon = (status) => {
  switch (status) {
    case 0: return (<CheckCircleIcon fontSize="small" style={{ color: 'white' }}/>)
    case 1: return (<WarningIcon/>)
    default: return (<CircularProgress/>)
  }
}

const precalcTile = ({ key, currentStep, obj, setCurrentStep, studyType }) => {
  if (obj.nonCritical) {
    return (
      <StyledGrid key={key} item objs={12}>
        <StatusIndicator status={getLoading(obj.status, obj.nonCritical)}>
          <Grid container onClick={() => obj.results.length > 0 ? setCurrentStep(obj) : null} >
            <Grid item objs={11}>
              <Typography>
                {getIcon(obj.status)}

                {obj.label} {obj.results.length > 0
                  ? ('(' + (obj.results[0].SumCount
                      ? obj.results[0].SumCount
                      : (obj.results[0].totalRows || obj.results[0].totalrows)) + ')')
                  : '' }
              </Typography>
            </Grid>
            <Grid item objs={1}>
              {currentStep && obj.label === currentStep.label && <ArrowForwardIcon/>}
            </Grid>
          </Grid>
        </StatusIndicator>
      </StyledGrid>
    )
  }
  return (
    <StyledGrid key={key} item objs={12}>
      { obj.results?.length === 0 &&
                  <StyledGrid item objs={12}>
                    <StyledPaper><Typography>
                      <CheckCircleIcon fontSize="small" style={{ color: 'black' }}/> {obj.label} </Typography>
                    </StyledPaper> </StyledGrid> }
      { obj.results?.length > 0 &&
                  <FixContainer fix={obj.fix}>
                    <Grid container onClick={() => setCurrentStep(obj)} >
                      <Grid item objs={11}>
                        <Typography>
                          { obj.fix ? '' : <WarningIcon/>}
                          {obj.label} {'(' + (obj.results[0].SumCount ? obj.results[0].SumCount : (obj.results[0].totalRows || obj.results[0].totalrows)) + ')' }
                        </Typography>
                      </Grid>
                      <Grid item objs={1}>
                        {currentStep && obj.label === currentStep.label && <ArrowForwardIcon/>}
                      </Grid>
                    </Grid>
                  </FixContainer>
      }
    </StyledGrid>
  )
}
export default precalcTile
