import React from 'react'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import Button from '@mui/material/Button'
import WarningIcon from '@mui/icons-material/Warning'
import { useDispatch, useSelector } from 'react-redux'

import CreatingCommonFormatDialog from '../../components/LoadingDialog'
import rgaColumns from '../../store/objects/data/rga_columns.json'
import ValueMapping from './ValueMapping'
import useGetMapping from '../app/hooks/useGetMappings'
import { createCommonFormat, finishedProcessAction } from '../../store/pages/valueMapping'
import { getValidationForAllFiles } from '../../store/pages/valueValidationActions'
import { getAccess } from '../../store/pages/project/async'
import { styled } from '@mui/material'

const StyledGrid = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2),
  overflow: 'visible',
  minHeight: 400
}))

const StyledTypography = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(2)
}))

const StyledCircularProgress = styled(CircularProgress)({
  marginTop: 200,
  marginLeft: '45%'
})

const StyledDiv = styled('div')({
  marginTop: 10
})

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  width: '100%',
  backgroundColor: '#DEDEDE'
}))

const FABRIC_MESSAGE = 'Value mapping is automatically done for data fabric skip mapping datasets'

const ValueMappingComponent = () => {
  const dispatch = useDispatch()

  const code = useSelector((store) => store.valueMapping.code)
  const errors = useSelector((store) => store.valueMapping.error)
  const isCreating = useSelector((store) => store.valueMapping.isCreating)
  const isFinished = useSelector((store) => store.valueMapping.isFinished)
  const message = useSelector((store) => store.valueMapping.message)
  const mappings = useSelector((store) => store.mapping.mappings)
  const file = useSelector((store) => store.navigation.file)
  const projectFiles = useSelector((store) => store.navigation.files)
  const fileData = useSelector((store) => store.project.fileData)
  const id = useSelector((store) => store.project.id)
  const ownerId = useSelector((store) => store.project.ownderId)
  const studyType = useSelector((store) => store.project.studyType)
  const sharedWithIds = useSelector((store) => store.project.sharedWithIds)
  const profileData = useSelector((store) => store.project.profileData)
  const userId = useSelector((store) => store.user.ownerId)
  const role = useSelector((store) => store.user.role)

  const isSkipMap = studyType === 'skipMap'
  const guidsToInclude = mappings.filter(item => item.include).map(item => item.guid)
  const files = projectFiles.filter(file => guidsToInclude.indexOf(file.guid) !== -1)

  const columnsToCheck = [].concat(...mappings.filter(x => x.guid === file.guid).filter(x => x.include).map(x => x.columns))
  const hasExclusions = [].concat(...columnsToCheck.map(x => x.valMapp).filter(x => x !== undefined)).filter(x => x.exclude).length > 0

  const totalErros = [...files]
    .reduce((accum, curr) => curr.erros
      ? accum + curr.erros
      : accum, 0
    )

  const checkForErrors = totalErros > 0
  const hasNoValues = columnsToCheck.some(a => !a.source)

  const access = getAccess(
    sharedWithIds,
    ownerId,
    studyType,
    role,
    userId,
    'MAPPING'
  )

  React.useEffect(() => {
    getValidationForAllFiles(
      files,
      mappings,
      profileData,
      rgaColumns,
      file.guid
    )
  }, [file.guid, files, mappings, profileData])

  const buttonEvent = () => dispatch(createCommonFormat)

  useGetMapping()

  if ((mappings.length === 0 || fileData === undefined) && !isSkipMap) {
    return (
      <StyledGrid>
        <StyledCircularProgress data-testid='circularProgress'/>
      </StyledGrid>
    )
  }
  if (isSkipMap || file === undefined || !Object.keys(file).length) {
    return (
      <StyledGrid>
        <StyledTypography variant="h5" component="h2" data-testid="typog-skipMap">
          {isSkipMap ? FABRIC_MESSAGE : ' No Files Processed Successfully'}
        </StyledTypography>
      </StyledGrid>
    )
  }

  if (file === undefined || Object.keys(file).length === 0) {
    return (
      <StyledGrid>
        <StyledTypography
          variant="h5"
          component="h2"
        >
          No Files Processed Successfully
        </StyledTypography>
      </StyledGrid>
    )
  }

  return (
    <StyledPaper>
      <CreatingCommonFormatDialog
        message={message}
        isFinished={isFinished}
        handleClose={() => dispatch(finishedProcessAction)}
        open={isCreating}
        errors={errors}
        code={code}
        id={id}
      />
      <Grid container spacing={2}>
        <Grid item xs={7}>
          <Tooltip
            data-testid='commonFormat-tooltip'
            title={checkForErrors
              ? `Please fix all ${totalErros} errors.`
              : ''
            }
            placement="bottom-start">
              <div>
                <Button
                  data-testid='button-commonFormat'
                  disabled={!access || checkForErrors || hasNoValues}
                  variant="outlined"
                  color="primary"
                  onClick={() => buttonEvent()}
                >
                <PlayArrowIcon />
                  Create Common Format
                </Button>
              </div>
            </Tooltip>
          </Grid>
          <Grid item xs={5}>
            {
              hasExclusions
                ? <StyledDiv>
                  <Typography>
                    <WarningIcon/> <b>Warning:</b> The current input source has data marked as excluded
                  </Typography>
                </StyledDiv>

                : ''
            }
          </Grid>
          <Grid item xs={12}>
            <hr />
            <ValueMapping />
          </Grid>
        </Grid>
      </StyledPaper>
  )
}
export default ValueMappingComponent
