import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PreCalculationFiles from '../../containers/precalculation/components/PreCalculationFiles'
import PrecalCheck from './components/precalCheck'
import { getAccess } from '../../store/pages/project/async'
import { setCommonFile } from '../../store/pages/navigation'
import { getPrecalCheckData } from '../../store/pages/preCalculation'

export default function PreCalculation () {
  const dispatch = useDispatch()
  const {
    currentTab,
    formatFile
  } = useSelector(store => store.navigation)

  const {
    load,
    currentProject
  } = useSelector(store => store.preCalc)

  const {
    formatFiles,
    id,
    ownerId,
    sharedWithIds,
    studyType
  } = useSelector(store => store.project)

  const {
    ownerId: userId,
    role
  } = useSelector(store => store.user)

  const access = getAccess(sharedWithIds, ownerId, studyType, role, userId, 'PRE_CALCULATION')

  useEffect(() => {
    if (!load || (currentProject !== id)) {
      dispatch(getPrecalCheckData(id))
    }
    if (!formatFile) {
      dispatch(setCommonFile('precalCheck'))
    }
  })

  const getCurrentTabBody = (tab) => {
    if (formatFiles.length === 0) {
      return <div> Run Load Study Input to see this screen.</div>
    } else {
      const loadingHandler = tab === undefined ? 'precalCheck' : tab
      switch (loadingHandler) {
        case 'precalCheck':
          return <PrecalCheck access={access}/>
        default:
          return <PreCalculationFiles />
      }
    }
  }
  return (getCurrentTabBody(currentTab))
}
