import React from 'react'
import Grid from '@mui/material/Grid'
import Fab from '@mui/material/Fab'
import Typography from '@mui/material/Typography'
import AddIcon from '@mui/icons-material/Add'
import ConditionsList from './conditionsList'
import { useDispatch } from 'react-redux'
import { addedWhereConditionAction } from '../../../store/pages/common/query'
import { styled } from '@mui/material'

const ButtonContainer = styled(Grid)(({ theme }) => ({
  paddingLeft: 120,
  marginTop: theme.spacing(1)
}))

export default function Conditions () {
  const dispatch = useDispatch()

  return (
    <div>
      <Grid container>
        <Grid item xs={7}>
          <Typography variant="h6">Conditions</Typography>
        </Grid>

        <ButtonContainer item xs={5}>
          <Fab
            data-testid='button-add'
            size="small"
            color="primary"
            aria-label="add"
            onClick={() => dispatch(addedWhereConditionAction)}
          ><AddIcon /></Fab>
        </ButtonContainer>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <ConditionsList />
        </Grid>
      </Grid>
    </div>
  )
}
