import React, { createElement } from 'react'
import PropTypes from 'prop-types'

const Points = ({ pointSize = 0, points }) => {
  const mappedPoints = points.reverse().map((point) => ({
    id: point.id,
    symbol: ['Risk History'].includes(`${point.serieId}`)
      ? SymbolTriangle
      : SymbolCircle,
    x: point.x - pointSize / 2,
    y: point.y - pointSize / 2,
    fill: point.color,
    borderColor: point.borderColor
  }))

  return (
    <g>
      {mappedPoints.map(({ symbol, ...point }) =>
        createElement(symbol, { ...point, size: pointSize, key: point.id })
      )}
    </g>
  )
}
const SymbolCircle = ({
  x,
  y,
  size,
  fill,
  borderWidth = 3
}) => (
  <circle
    r={size / 2}
    cx={x + size / 2}
    cy={y + size / 2}
    fill={fill}
    opacity={1}
    strokeWidth={3}
    stroke={'transparent'}
    style={{
      pointerEvents: 'none'
    }}
  />
)

const SymbolTriangle = ({
  x,
  y,
  size,
  fill
}) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <path
        d={`
                M${size / 2} 0
                L${size} ${size}
                L0 ${size}
                L${size / 2} 0
            `}
        fill={fill}
        style={{
          pointerEvents: 'none'
        }}
      />
    </g>
  )
}

SymbolTriangle.propTypes = {
  x: PropTypes.any,
  y: PropTypes.any,
  size: PropTypes.any,
  fill: PropTypes.any
}

SymbolCircle.propTypes = {
  x: PropTypes.any,
  y: PropTypes.any,
  size: PropTypes.any,
  fill: PropTypes.any,
  borderWidth: PropTypes.any
}

Points.propTypes = {
  pointSize: PropTypes.any,
  points: PropTypes.any,
  size: PropTypes.any,
  fill: PropTypes.any,
  borderWidth: PropTypes.any
}

export { SymbolCircle, SymbolTriangle, Points }
