const validateArray = (inputArray) => {
  for (const object of inputArray) {
    if (Object.values(object).some(prop => prop === '' || Object.keys(prop).length === 0)) { return true }
  }
}
const hasAggregate = (step) => step.type === 'aggregate' && (step.state.selectedAggregators.length === 0 || step.state.fieldsAgg.length === 0)
const hasConvert = (step) => step.type === 'convert' && ((step.state.fieldsToConvert.dataType?.value === 'date' && step.state.fieldsToConvert.format === null) || (step.state.fieldsToConvert.field === null || step.state.fieldsToConvert.dataType === null))

const hasNewColumn = (step) => step.type === 'newColumn' && (step.state.name === '' || step.state.formula === '')

const hasModify = (step) => step.type === 'modify' && (((validateArray(step.state.updateSetConditions) || validateArray(step.state.updateWhereConditions))) ||
step.state.updateSetConditions.length === 0)

const hasMerge = (step) => step.type === 'merge' && (((validateArray(step.state.whereList) ||
  validateArray(step.state.joinList) || step.state.joinType === '' || step.state.referenceColumns.length === 0 ||
  step.state.fileColumns.length === 0 || Object.keys(step.state.referenceTable).length === 0)) || step.state.joinList.length === 0)

const hasRemoveRows = (step) => step.type === 'removeRows' && (validateArray(step.state.deleteWhereConditions) || step.state.deleteWhereConditions.length === 0)

const validateStepFields = (steps) => {
  const validateFieldSteps = []
  for (let i = 0; i < steps.length; i++) {
    if (hasAggregate(steps[i]) || hasConvert(steps[i]) || hasNewColumn(steps[i]) || hasModify(steps[i]) || hasMerge(steps[i]) || hasRemoveRows(steps[i])) {
      validateFieldSteps.push(i + 1)
    }
  }
  return validateFieldSteps.length > 0 ? `Please fill the required fields in step ${validateFieldSteps.toString()}` : ''
}
const isOneStepChecked = (steps) => {
  return steps.length > 0 && steps.some((step) => step.checked === true)
}

const validateFields = (desc, name, steps) => desc === '' || name === '' || !steps.length

export { validateFields, validateStepFields, isOneStepChecked }
