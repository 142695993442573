import React from 'react'
import Fab from '@mui/material/Fab'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import AddIcon from '@mui/icons-material/Add'
import ConditionsList from './conditionsList'
import { useDispatch } from 'react-redux'
import { addedWhereConditionAction } from '../../store/pages/common/query'

export default function Conditions () {
  const dispatch = useDispatch()

  const title = 'Filters'

  return (
    <Grid container>
      <Grid item xs={6}>
        <Typography variant="h6">{title}</Typography>
      </Grid>

      <Grid item xs={6}>
        <Fab
          size="small"
          color="primary"
          aria-label="add"
          onClick={() => dispatch(addedWhereConditionAction)}
        ><AddIcon /></Fab>
      </Grid>

      <Grid item xs={12}>
        <ConditionsList />
      </Grid>
    </Grid>
  )
}
