import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import CircularProgress from '@mui/material/CircularProgress'
import ErrorBoundary from '../ErrorBoundary'
import Home from '../home'
import AdminSettings from '../adminSettings'
import Reference from '../reference'
import Upload from '../upload'
import DataPrep from '../prep'
import ColumnMapping from '../mapping'
import ValueMapping from '../valueMapping'
import PreCalculation from '../precalculation'
import Calculation from '../calculation'
import PostCalculation from '../postcalculation'
import Review from '../review'
import Navigation from './Navigation'
import useLoadProjectData from './hooks/useLoadProjectData'
import useLoadReferenceData from './hooks/useLoadReferenceData'
import useLoadReferenceTableData from './hooks/useLoadReferenceTableData'
import { Auth, Hub } from 'aws-amplify'
import { styled } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { signInAction } from '../../store/user'

const StyledDialogTitle = styled(DialogTitle)({
  marginTop: '20%',
  marginLeft: '45%'
})

const StyledDialogContent = styled(DialogContent)({
  marginLeft: '46%'
})

const getRoutes = () => (
  <Routes>
    <Route path="/" element={<Home/>} />
    <Route path="/upload/:projectId?" element={<Upload/>} />
    <Route path="/prep/:projectId" element={<DataPrep/>} />
    <Route path="/Columnmapping/:projectId" element={<ColumnMapping/>} />
    <Route path="/reference/:referenceId?" element={<Reference/>} />
    <Route path="/reference/:referenceId/*" element={<Reference/>} />
    <Route path="/Valuemapping/:projectId" element={<ValueMapping/>} />
    <Route path="/precalculation/:projectId" element={<PreCalculation/>} />
    <Route path="/Calculation/:projectId" element={<Calculation/>} />
    <Route path="/postcalculation/:projectId" element={<PostCalculation/>} />
    <Route path="/review/:projectId" element={<Review/>} />
    <Route path="/settings" element={<AdminSettings/>} />
    <Route path="/AccessDenied" element={<div> ACCESS DENIED </div>} />
  </Routes>
)

const App = () => {
  useLoadProjectData()
  useLoadReferenceData()
  useLoadReferenceTableData()

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const global = useSelector(store => store.global)
  const project = useSelector(store => store.project)

  React.useEffect(() => {
    const getUser = async () => {
      const currentUser = await Auth.currentAuthenticatedUser()

      await dispatch({ ...signInAction, payload: currentUser })
    }

    const unsubscribe = Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          getUser()
          break

        case 'customOAuthState':
          navigate(data)
          break
      }
    })

    return unsubscribe
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    const signin = async () => {
      try {
        const urlParams = new URLSearchParams(window.location.search)
        const code = urlParams.get('code')

        if (!code) {
          await Auth.federatedSignIn({
            provider: 'okta',
            customState: window.location.pathname
          })
        }
      } catch (err) {
        console.error('Error handling the signin', err)
      }
    }

    signin()
  }, [])

  React.useEffect(() => {
    if (project.id === null) {
      navigate('/AccessDenied')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project.id])

  return (
    <ErrorBoundary>
      <Navigation props={getRoutes()} />

      <Dialog
        data-testid='dialog-loading'
        fullScreen
        open={!global.isLoaded}
      >
        <StyledDialogTitle>Loading</StyledDialogTitle>

        <StyledDialogContent>
          <CircularProgress />
        </StyledDialogContent>
      </Dialog>
    </ErrorBoundary>
  )
}

export default React.memo(App)
