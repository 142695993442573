import PropTypes from 'prop-types'
import React from 'react'
import ReactSelect from 'react-select'
import Box from '@mui/material/Box'
import Switch from '@mui/material/Switch'
import Typography from '@mui/material/Typography'
import MuiFormControlLabel from '@mui/material/FormControlLabel'
import { styled } from '@mui/material'
import { OPTION_EMPTY } from '../../../stringConstants'

import {
  SingleValue,
  SingleValueStyles
} from '../../../components/sql/SelectSingleValue'

import {
  MultiValueLabel,
  MultiValueRemove,
  MultiValueStyles
} from '../../../components/sql/SelectMultiValue'

const COMPONENTS_SINGLE = {
  SingleValue
}

const COMPONENTS_MULTI = {
  MultiValueLabel,
  MultiValueRemove
}

const OPTIONS_DIRECTION = [
  {
    value: 'asc',
    label: 'Asc'
  },
  {
    value: 'desc',
    label: 'Desc'
  }
]

const Grid = styled(Box)({
  display: 'grid',
  maxWidth: '800px',
  gridGap: '20px',
  gridTemplateColumns: '1fr 1fr',
  alignItems: 'center'
})

const HeaderGrid = styled(Box)(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(4),
  gridTemplateColumns: 'auto 1fr'
}))

const FormControlLabel = styled(MuiFormControlLabel)({
  margin: 0
})

const Select = styled(ReactSelect)({
  minHeight: 58
})

function namespace (props, key) {
  return [props.name, key].filter(item => item).join('.')
}

function getMultiSelectValue (formValue, event) {
  switch (event.action) {
    case 'select-option':
      return event.value

    case 'remove-value':
      return formValue.filter(item => item.value !== event.removedValue.value)

    case 'clear':
      return []
  }
}

export default function OrderBy (props) {
  const selectValues = {
    orderBy: props.value.orderBy.value ? props.value.orderBy : null
  }

  const handleEnabledChange = event => {
    const enabledName = namespace(props, event.target.name)

    props.onChange(enabledName, event.target.checked)

    if (!event.target.checked) {
      const orderByName = namespace(props, 'orderBy')
      const orderByConditionsName = namespace(props, 'orderByConditions')

      props.onChange(orderByName, OPTION_EMPTY)
      props.onChange(orderByConditionsName, [])
    }
  }

  const handleConditionsChange = (value, event) => {
    const namespace = [props.name, event.name].filter(item => item).join('.')

    const result = getMultiSelectValue(props.value.orderByConditions, {
      action: event.action,
      name: event.name,
      removedValue: event.removedValue,
      value
    })

    props.onChange(namespace, result)
  }

  const handleSelectChange = (value, event) => {
    const namespace = [props.name, event.name].filter(item => item).join('.')

    props.onChange(namespace, value || OPTION_EMPTY)
  }

  return (
    <Grid>
      <HeaderGrid>
        <Typography variant='h6'>Order By</Typography>

        <FormControlLabel
          label='Enable'
          control={<Switch
            data-testid='switch-enable'
            name='enableOrderBy'
            size='small'
            checked={props.value.enableOrderBy}
            onClick={handleEnabledChange}
          />}
        />
      </HeaderGrid>

      <div></div>

      {props.value.enableOrderBy && (
        <>
          <div>
            <Typography variant='h6'>Columns</Typography>
            <Select
              data-testid='select-conditions'
              styles={MultiValueStyles}
              isDisabled={!props.value.enableOrderBy}
              name='orderByConditions'
              isMulti
              components={COMPONENTS_MULTI}
              options={props.columnOptions}
              value={props.value.orderByConditions}
              onChange={handleConditionsChange}
            />
          </div>

          <div>
            <Typography variant='h6'>Direction</Typography>

            <Select
              data-testid='select-order'
              styles={SingleValueStyles}
              isDisabled={!props.value.enableOrderBy}
              name='orderBy'
              isClearable
              components={COMPONENTS_SINGLE}
              options={OPTIONS_DIRECTION}
              value={selectValues.orderBy}
              onChange={handleSelectChange}
            />
          </div>
        </>
      )}
    </Grid>
  )
}

OrderBy.propTypes = {
  name: PropTypes.string,
  value: PropTypes.shape({
    enableOrderBy: PropTypes.bool,
    orderBy: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    }),
    orderByConditions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      })
    )
  }),
  columnOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ),
  onChange: PropTypes.func
}

OrderBy.defaultProps = {
  name: '',
  value: {
    enableOrderBy: false,
    orderBy: OPTION_EMPTY,
    orderByConditions: []
  },
  columnOptions: [],
  onChange: (_name, _value) => {}
}
