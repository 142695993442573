import React from 'react'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import stepTypes from '../../../store/objects/data/stepTypes.json'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { useDispatch } from 'react-redux'

import { saveNewStep, toggledAddNewStepAction } from '../../../store/pages/common/workflow'

export default function AddWorkFlowStep () {
  const dispatch = useDispatch()
  const [stepName, setStepName] = React.useState('')
  const [stepType, setStepType] = React.useState('')

  const disableSave = stepName === '' || stepType === ''

  return (
    <div >
      <DialogTitle id="form-dialog-title" style={{ margin: '1px' }}>Add Step</DialogTitle>
      <DialogContent>
        <Grid container >
          <Grid item xs={12}>
            <TextField
              id="queryName"
              size='small'
              label="Name"
              required
              variant="outlined"
              defaultValue=""
              margin="normal"
              onChange={(e) => setStepName(e.target.value)}
            /></Grid>
          <Grid item xs={11}>
            <FormControl style={{ margin: '1px', minWidth: '195px' }} variant="outlined" size="small">
              <InputLabel id="demo-simple-select-label">Step Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                label="Step Type"
                required
                id="demo-simple-select"
                defaultValue=""
                onChange={(e) => setStepType(e.target.value)}
              >
                {stepTypes.map((type) => <MenuItem key={type.value} value={type.value}> {type.label}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => dispatch(toggledAddNewStepAction)}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={() => dispatch(saveNewStep(stepName, stepType))}
          color="primary"
          disabled={disableSave}
        >
          Save
        </Button>
      </DialogActions>
    </div>
  )
}
