import edpApi from '../objects/edpApi'
import COLUMNS_RGA from '../objects/data/rga_columns.json'
import FILE_TYPES from '../objects/data/skipMapping/fileType.json'
export const validatingEdpMetadataAction = {
  type: 'edp/VALIDATING_EDP_METADATA'
}
export const resetAction = {
  type: 'edp/RESET'
}

export const fetchedViewsAction = {
  type: 'edp/FETCHED_VIEWS'
}

export const fetchedDataProductsAction = {
  type: 'edp/FETCHED_DATA_PRODUCTS'
}
export const fetchedColumnsAction = {
  type: 'edp/FETCHED_COLUMNS'
}

const initialState = {
  dataProducts: null,
  views: null
}

export const fetchViews = (dataProduct, country) => async (dispatch) => {
  const views = await edpApi.fetchViews(dataProduct, country)
  return dispatch({
    ...fetchedViewsAction,
    payload: { views }
  })
}

export const fetchDataProducts = (sid, country) => async (dispatch, getState) => {
  const dataProducts = await edpApi.fetchDataProducts(sid, country)
  return dispatch({
    ...fetchedDataProductsAction,
    payload: {
      dataProducts
    }
  })
}
const isSnowflakeType = (snowflakeTypes, snowflaketype) => snowflakeTypes.some(partialType => snowflaketype.toLowerCase().includes(partialType))

const mapTypeSnowflake = (snowflaketype) => {
  switch (true) {
    case isSnowflakeType(['varchar', 'string'], snowflaketype):
      return 'VARCHAR'
    case isSnowflakeType(['decimal', 'double', 'int'], snowflaketype):
      return 'NUMBER'
    case isSnowflakeType(['timestamp_ntz', 'timestamp', 'date'], snowflaketype):
      return 'DATE'
    case snowflaketype.toLowerCase().includes('number'):{
      const splitType = snowflaketype.split('(')
      return `NUMERIC(${splitType[1]}`
    }
    default:
      return 'VARCHAR'
  }
}

export const validateTypeAndColumnsName = (columns, fileType) => {
  const file = FILE_TYPES.find(item => item.code === fileType).name
  const validationTooltip = COLUMNS_RGA
    .filter(item => item.output_type === 'REDI')
    .filter(item => item.name !== 'UNIQUE_IDENTIFIER')
    .filter(item => item.required_for_filetype.toUpperCase().includes(file.toUpperCase()))
    .reduce((accum, curr) => {
      const targetColumn = columns.find(item =>
        item.Name.toUpperCase() === curr.name.toUpperCase())
      if (targetColumn) {
        const targetDatatype = mapTypeSnowflake(targetColumn.Type)
        const currentDatatype = curr.type.toUpperCase()
        const wrongType = (!currentDatatype.toLowerCase().includes('number') && targetDatatype !== currentDatatype)

        return wrongType
          ? [...accum, `${curr.name} (wrong type)`]
          : accum
      } else {
        return [...accum, `${curr.name} (missing required field)`]
      }
    }, [])
    .join('\n')

  return {
    validationTooltip: validationTooltip === '' ? 'successful' : validationTooltip,
    columns
  }
}

export const validateMetadata = (columns, fileType) => async dispatch => {
  await dispatch({ ...validatingEdpMetadataAction, payload: true })

  const result = columns ? validateTypeAndColumnsName(columns, fileType) : null

  await dispatch({ ...validatingEdpMetadataAction, payload: false })

  return result
}

export const fetchColumns = async (dataproduct, viewName, country) => {
  const columns = await edpApi.fetchColumns(dataproduct, viewName, country)
  const mapEDPcol = columns.map(x => ({ Type: mapTypeSnowflake(x.type), Name: x.name }))
  return mapEDPcol
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case fetchedDataProductsAction.type:
    case fetchedViewsAction.type:
      return {
        ...state,
        ...payload
      }
    case resetAction.type:
      return {
        ...initialState
      }
    default:
      return state
  }
}
