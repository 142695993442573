import React from 'react'
import MuiGrid from '@mui/material/Grid'
import MuiTypography from '@mui/material/Typography'
import { styled } from '@mui/material'
import Select from 'react-select'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import MuiPaper from '@mui/material/Paper'
import { SingleValue, SingleValueStyles } from '../../../../components/sql/SelectSingleValue'
import { selectedFieldsAction } from '../../../../store/pages/common/workflow'

const Paper = styled(MuiPaper)(({ theme }) => ({
  padding: theme.spacing(2),
  width: '100%'
}))

const Grid = styled(MuiGrid)(({ theme }) => ({
  textAlign: 'center',
  color: theme.palette.text.secondary
}))

const Typography = styled(MuiTypography)({
  textAlign: 'left'
})

function Convert (props) {
  const dispatch = useDispatch()

  const navigation = useSelector((state) => state.navigation)

  const fieldsToConvert = props.state.state.fieldsToConvert
  const columns = navigation.file.columns || []
  const dataTypes = ['string', 'date', 'number']
  const dateFormatList = ['YYYY-MM-DD', 'YYYY-DD-MM', 'MM-DD-YYYY', 'DD-MM-YYYY']
  return (
    <Grid container>
      <Paper elevation={0} outlined>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <Typography variant="h6">
                Column
            </Typography>
            <Select
              placeholder=""
              styles={SingleValueStyles}
              components={{ SingleValue }}
              value={fieldsToConvert.field}
              options={columns.map(x => ({ value: x.Name, label: `${x.Name} (${x.Type})` }))}
              onChange={e => dispatch({ ...selectedFieldsAction, payload: { key: 'field', value: e, order: props.order } })}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography variant="h6">
                To
            </Typography>
            <Select
              placeholder=""
              styles={SingleValueStyles}
              components={{ SingleValue }}
              value={fieldsToConvert.dataType}
              options={dataTypes.map(x => ({ value: x, label: x }))}
              onChange={e => dispatch({ ...selectedFieldsAction, payload: { key: 'dataType', value: e, order: props.order } })}

            />
          </Grid>
          {
            fieldsToConvert?.dataType?.value === 'date' &&
            <Grid item xs={4}>
              <Typography variant="h6">
                  Date Format
              </Typography>
              <Select
                placeholder=""
                styles={SingleValueStyles}
                components={{ SingleValue }}
                value={fieldsToConvert.format}
                options={dateFormatList.map(x => ({ value: x, label: x }))}
                onChange={e => dispatch({ ...selectedFieldsAction, payload: { key: 'format', value: e, order: props.order } })}
              />
            </Grid>
          }
          <Grid item xs={2}/>
        </Grid>
        <Grid item xs={12}>
          {props.state.state.message}
        </Grid>
      </Paper>
    </Grid>
  )
}

Convert.propTypes = {
  state: PropTypes.object,
  actions: PropTypes.object,
  order: PropTypes.number
}

export default React.memo(Convert)
