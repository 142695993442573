import sourcesApi from '../../objects/sourcesAPI'
import stepFunctionStatusLoop from './stepFunctionStatusLoop'
import { setProjectFileDirtyFlag, updateSourceFiles } from '../project/async'

const INITIAL_STATE = {
  isSaving: false,
  fieldsToConvert: {
    field: null,
    dataType: null,
    format: null
  }
}

export const convertingFieldAction = {
  type: 'convert/CONVERTING_FIELD'
}

export const convertedFieldAction = {
  type: 'convert/CONVERTED_FIELD'
}

export const clearedMessageAction = {
  type: 'convert/CLEARED_MESSAGE'
}

export const handledFieldSelectAction = {
  type: 'convert/HANDLED_FIELD_SELECT'
}

export const convertField = (id, guid, reference = false) => async (dispatch, getState) => {
  const {
    convert: { fieldsToConvert: fields },
    navigation: { file: { guid: navGuid } }
  } = getState()
  try {
    dispatch({
      ...convertingFieldAction,
      payload: {
        isSaving: true,
        message: 'Converting'
      }
    })

    if (!reference) {
      await dispatch(setProjectFileDirtyFlag(navGuid, true))
    }
    const reqBody = {
      eventType: 'ConvertColumn',
      column: fields.field.value,
      dataType: fields.dataType.value,
      format: fields.dataType.value === 'date' ? fields.format.value : null,
      fileInfo: {
        id,
        guid
      }
    }
    try {
      const { executionArn } = await sourcesApi.startConvert(reqBody)

      const moveOnAfterConversion = async arn => {
        if (!reference) {
          await dispatch(updateSourceFiles(id, guid, reqBody))
        }
        return dispatch({
          ...convertedFieldAction,
          payload: {
            fieldsToConvert: { field: null, dataType: null, format: null },
            message: arn.output ? JSON.parse(arn.output).message || arn.status : arn.status,
            isSaving: false
          }
        })
      }

      return stepFunctionStatusLoop(
        executionArn,
        ({ status }) => ['FAILED', 'SUCCEEDED'].includes(status),
        moveOnAfterConversion
      )
    } catch (ex) {
      return dispatch({
        ...convertedFieldAction,
        payload: {
          fieldsToConvert: { field: null, dataType: null, format: null },
          isSaving: false,
          message: ex.data ? ex.data.error : 'Network Error - Try Again'
        }
      })
    }
  } catch (convertError) {
    return dispatch({
      ...convertedFieldAction,
      payload: {
        fieldsToConvert: { field: null, dataType: null, format: null },
        isSaving: false,
        message: convertError.data ? convertError.data.error : 'Error converting'
      }
    })
  }
}

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case convertingFieldAction.type:
    case convertedFieldAction.type:
    case clearedMessageAction.type:
      return {
        ...state,
        ...payload
      }
    case handledFieldSelectAction.type: {
      const { key, value = '' } = payload
      const { fieldsToConvert } = state
      return {
        ...state,
        fieldsToConvert: {
          ...fieldsToConvert,
          [key]: value
        }
      }
    }
    default:
      return state
  }
}
