import React from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import { Grid, TextField, styled } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'

import { changedDialogDataAction, updateAdminData } from '../../store/pages/adminTool'

const StyledButton = styled(Button)(({ theme }) => ({
  color: 'white',
  backgroundColor: '#c62728',
  marginLeft: theme.spacing(2),
  '&:hover': {
    backgroundColor: 'white',
    color: '#c62728'
  }
}))

const StyledTextField = styled(TextField)({
  backgroundColor: 'white'
})

const StyledDiv = styled('div')({
  backgroundColor: 'white'
})

const StyledTypography = styled(Typography)({
  textAlign: 'left'
})

const StyledDialogContent = styled(DialogContent)({
  border: '1px solid grey',
  backgroundColor: '#DEDEDE'
})

const StyledDialogActions = styled(DialogActions)({
  border: '1px solid grey',
  backgroundColor: '#DEDEDE'
})

const StyledDialogTitle = styled(DialogTitle)({
  border: '1px solid grey',
  backgroundColor: '#DEDEDE'
})

const CONTAINED = 'contained'
const SECONDARY = 'secondary'

const validateDates = (startDate, endDate) => {
  return moment(startDate) > moment(endDate)
}

const validateAnnouncementFields = (dialogData) => {
  return (
    Boolean(dialogData.name) &&
    Boolean(dialogData.description) &&
    Boolean(dialogData.startDate) &&
    Boolean(dialogData.endDate) &&
    Boolean(dialogData.title) &&
    Boolean(dialogData.message)
  )
}

const validateReleaseNoteFields = (dialogData) => {
  return (
    Boolean(dialogData.version) &&
    Boolean(dialogData.name) &&
    Boolean(dialogData.description) &&
    Boolean(dialogData.startDate) &&
    Boolean(dialogData.endDate) &&
    dialogData.notes.every(item => item.name && item.description)
  )
}

const validateRequiredFields = (dialogData, type) => {
  switch (type) {
    case 'announcement':
      return validateAnnouncementFields(dialogData)

    case 'release':
      return validateReleaseNoteFields(dialogData)

    default:
      throw new RangeError(`Invalid type: ${type}`)
  }
}
const getContent = (dispatch, type, dialogData) => {
  const {
    name,
    startDate,
    endDate,
    notes,
    description,
    version
  } = dialogData

  switch (type) {
    case 'release':
      return (<StyledDialogContent>
        <Grid container spacing={3}>
          <Grid item xs={1}>
            <StyledTypography> Version </StyledTypography>
            <StyledTextField
              data-testid={'release-version'}
              fullWidth
              variant="outlined"
              value={version}
              onChange={e =>
                dispatch(
                  {
                    ...changedDialogDataAction,
                    payload: {
                      name: 'version',
                      value: e.target.value
                    }
                  }
                )}
            />
          </Grid>
          <Grid item xs={5}>
            <StyledTypography> Name </StyledTypography>
            <StyledTextField
              data-testid={'release-name'}
              fullWidth
              variant="outlined"
              value={name}
              onChange={e =>
                dispatch(
                  {
                    ...changedDialogDataAction,
                    payload: {
                      name: 'name',
                      value: e.target.value
                    }
                  }
                )}
            />
          </Grid>
          <Grid item xs={6}>
            <StyledTypography> Description </StyledTypography>
            <StyledTextField
              data-testid={'release-description'}
              fullWidth
              variant="outlined"
              multiline={true}
              value={description}
              onChange={e =>
                dispatch(
                  {
                    ...changedDialogDataAction,
                    payload: {
                      name: 'description',
                      value: e.target.value
                    }
                  }
                )}
            />
          </Grid>
          <Grid item xs={6}>
            <StyledTypography> Start Release </StyledTypography>
            <StyledTextField
              data-testid={'start-release'}
              fullWidth
              id="datetime-local"
              label=" "
              type="datetime-local"
              value={startDate}
              InputLabelProps={{ shrink: true }}
              onChange ={(e) => {
                dispatch(
                  {
                    ...changedDialogDataAction,
                    payload: {
                      name: 'startDate',
                      value: e.target.value
                    }
                  }
                )
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <StyledTypography> End Release </StyledTypography>
            <StyledTextField
              data-testid={'end-release'}
              fullWidth
              id="datetime-local"
              label=" "
              type="datetime-local"
              value={endDate}
              InputLabelProps={{ shrink: true }}
              onChange ={(e) => {
                dispatch(
                  {
                    ...changedDialogDataAction,
                    payload: {
                      name: 'endDate',
                      value: e.target.value
                    }
                  }
                )
              }}
            />
          </Grid>
          <Grid item xs={12}>  {
            notes && notes.length === 0 &&
            <StyledButton
              data-testid={'button-add-note'}
              variant={CONTAINED}
              color={SECONDARY}
              onClick ={() => {
                notes.push({ name: null })
                dispatch(
                  {
                    ...changedDialogDataAction,
                    payload: {
                      name: 'notes',
                      value: notes
                    }
                  }
                )
              }}
            > <AddIcon/> </StyledButton>}</Grid>
        </Grid>
        <Grid container spacing={3}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '200px' }}>Feature Name</TableCell>
                <TableCell>Notes</TableCell>
                <TableCell style={{ width: '100px' }}>Remove</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                notes && notes.map((row, i) => (
                  <TableRow key={i}>
                    <TableCell style={{ width: '200px' }}>
                      <StyledTextField
                        data-testid={`notes-name-${i}`}
                        fullWidth
                        variant="outlined"
                        color="secondary"
                        value={row.name}
                        onChange ={(e) => {
                          notes[i].name = e.target.value
                          dispatch(
                            {
                              ...changedDialogDataAction,
                              payload: {
                                name: 'notes',
                                value: notes
                              }
                            }
                          )
                        }}
                      />
                    </TableCell>
                    <TableCell style={{ width: '400px' }}>
                      <StyledTextField
                        data-testid={`notes-description-${i}`}
                        multiline
                        fullWidth
                        variant="outlined"
                        color="secondary"
                        value={row.description}
                        onChange ={(e) => {
                          notes[i].description = e.target.value
                          dispatch(
                            {
                              ...changedDialogDataAction,
                              payload: {
                                name: 'notes',
                                value: notes
                              }
                            }
                          )
                        }} />
                    </TableCell>
                    <TableCell>
                      <StyledButton
                        data-testid={`button-remove-${i}`}
                        variant={CONTAINED}
                        color={SECONDARY}
                        // TODO: WARNING: splice mutates, better to do slice/concatenate
                        onClick ={() => {
                          notes.splice(i, 1)
                          dispatch(
                            {
                              ...changedDialogDataAction,
                              payload: {
                                name: 'notes',
                                value: notes
                              }
                            }
                          )
                        }}
                    > <RemoveIcon/>
                    </StyledButton> {
                      notes.length - 1 === i &&
                      <StyledButton
                        data-testid={`button-add-${i}`}
                        variant={CONTAINED}
                        color={SECONDARY}
                        onClick ={() => {
                          notes.push({ name: null, description: null })
                          dispatch({
                            ...changedDialogDataAction,
                            payload: { name: 'notes', value: notes }
                          })
                        }}
                      > <AddIcon/> </StyledButton> }
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </Grid>
      </StyledDialogContent>)

    case 'announcement':
      return (<StyledDialogContent>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <StyledTypography> Name </StyledTypography>
            <StyledTextField
              data-testid={'announcement-name'}
              fullWidth
              variant="outlined"
              value={name}
              onChange={e =>
                dispatch(
                  {
                    ...changedDialogDataAction,
                    payload: {
                      name: 'name',
                      value: e.target.value
                    }
                  }
                )}
            />
          </Grid>
          <Grid item xs={8}>
            <StyledTypography>
              Description
            </StyledTypography>
            <StyledTextField
              data-testid={'announcement-description'}
              fullWidth
              variant="outlined"
              multiline={true}
              value={description}
              onChange={e => dispatch(
                {
                  ...changedDialogDataAction,
                  payload: {
                    name: 'description',
                    value: e.target.value
                  }
                }
              )}
            />
          </Grid>

          <Grid item xs={4}>
            <StyledTypography> Title </StyledTypography>
            <StyledTextField
              data-testid={'announcement-title'}
              fullWidth
              variant="outlined"
              value={dialogData.title}
              onChange={e =>
                dispatch(
                  {
                    ...changedDialogDataAction,
                    payload: {
                      name: 'title',
                      value: e.target.value
                    }
                  }
                )}
            />
          </Grid>
          <Grid item xs={8}>
            <StyledTypography>  Message </StyledTypography>
            <StyledTextField
              data-testid={'announcement-message'}
              fullWidth
              variant="outlined"
              multiline={true}
              value={dialogData.message}
              onChange={e =>
                dispatch(
                  {
                    ...changedDialogDataAction,
                    payload: {
                      name: 'message',
                      value: e.target.value
                    }
                  }
                )}
            />
          </Grid>
          <Grid item xs={6}>
            <StyledTypography> Start Announcement </StyledTypography>
            <StyledTextField
              data-testid={'start-announcement'}
              fullWidth
              id="datetime-local"
              label=" "
              type="datetime-local"
              value={startDate}
              InputLabelProps={{ shrink: true }}
              onChange ={(e) => {
                dispatch(
                  {
                    ...changedDialogDataAction,
                    payload: {
                      name: 'startDate',
                      value: e.target.value
                    }
                  }
                )
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <StyledTypography> End Announcement </StyledTypography>
            <StyledDiv>
              <StyledTextField
                data-testid={'end-announcement'}
                fullWidth
                id="datetime-local"
                label=" "
                type="datetime-local"
                value={endDate}
                InputLabelProps={{ shrink: true }}
                onChange ={(e) => {
                  dispatch(
                    {
                      ...changedDialogDataAction,
                      payload: {
                        name: 'endDate',
                        value: e.target.value
                      }
                    }
                  )
                }}
              />
            </StyledDiv>
          </Grid>
        </Grid>
      </StyledDialogContent>)
    default:
      return (<div></div>)
  }
}

const AdminDialog = (props) => {
  const dispatch = useDispatch()
  const { dialogData } = useSelector(({ adminTool: { dialogData } }) => ({
    dialogData
  }))

  const { open, setDialogOpen, title, type } = props

  const handleClose = () => {
    setDialogOpen(false)
  }

  const handleSave = () => {
    dispatch(updateAdminData(dialogData))
    setDialogOpen(false)
  }

  return dialogData && (
    <div>
      <Dialog
        data-testid={'dialog-admin'}
        open={open}
        maxWidth={'xl'}
        fullWidth
        onClose={handleClose}
        aria-labelledby="form-dialog-title">
        <StyledDialogTitle
          id="form-dialog-title">{title}
        </StyledDialogTitle>
        {dialogData && getContent(dispatch, type, dialogData)}
        <StyledDialogActions>
          <StyledButton
            data-testid={'button-cancel'}
            onClick={handleClose}
            variant={CONTAINED}
            color={SECONDARY}>
            Cancel
          </StyledButton>
          <Tooltip
            data-testid={'tooltip-date'}
            title={validateDates(dialogData.startDate, dialogData.endDate)
              ? 'Start Date must be before End Date'
              : 'Save'
            }>
            <span>
              <StyledButton
                data-testid={'button-save'}
                onClick={handleSave}
                variant={CONTAINED}
                color={SECONDARY}
                disabled={validateDates(dialogData.startDate, dialogData.endDate) ||
                  !validateRequiredFields(dialogData, type)}
              >
            SAVE
              </StyledButton>
            </span>
          </Tooltip>
        </StyledDialogActions>
      </Dialog>
    </div>
  )
}
AdminDialog.propTypes = {
  open: PropTypes.bool,
  setDialogOpen: PropTypes.func,
  type: PropTypes.string,
  title: PropTypes.string
}

export default AdminDialog
