import { API } from 'aws-amplify'
import apiHelper from './apiHelper'

const getReleaseData = async () => {
  const header = await apiHelper.getHeader()
  return API.get(apiHelper.apiName, '/admin/release?type=release', header).catch(apiHelper.handleError)
}
const getAnnouncementData = async () => {
  const header = await apiHelper.getHeader()
  return API.get(apiHelper.apiName, '/admin/release?type=announcement', header).catch(apiHelper.handleError)
}

const putAdminData = async (body) => {
  const { type, guid } = body
  const method = guid ? 'put' : 'post'
  const bodyAndHeader = await apiHelper.getBodyAndHeader(body)
  const url = `/admin/release?type=${type}`

  return API[method](apiHelper.apiName, url, bodyAndHeader).catch(apiHelper.handleError)
}
const removeAdminData = async (body) => {
  const bodyAndHeader = await apiHelper.getBodyAndHeader()
  const url = `/admin/release?type=${body.type}&guid=${body.guid}`

  return API.del(apiHelper.apiName, url, bodyAndHeader).catch(apiHelper.handleError)
}
export default { putAdminData, getAnnouncementData, getReleaseData, removeAdminData }
