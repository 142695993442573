import React from 'react'
import Select from 'react-select'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import CreatableSelect from 'react-select/creatable'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material'
import { updateOptionSet } from '../../../store/pages/common/formulaDialog'
import { removedOptionSetAction } from '../../../store/pages/common/update'
import { SingleValue, SingleValueStyles } from '../../../components/sql/SelectSingleValue'

const SELECT_COMPONENT = { SingleValue }

const UpdateSelect = styled(Select)(({ theme }) => ({
  minHeight: 58,
  margin: theme.spacing(1)
}));

const UpdateCreatableSelect = styled(CreatableSelect)(({ theme }) => ({
  minHeight: 58,
  margin: theme.spacing(1)
}));

const DeleteButton = styled(IconButton)({
  marginRight: 75,
  marginTop: 15
})

const EqualGrid = styled(Grid)({
  fontSize: 40,
  textAlign: 'center'
})

const EqualDiv = styled('div')(() => ({
  fontSize: 40,
  textAlign: 'center'
}));

export default function SetList () {
  const dispatch = useDispatch()

  const {
    columns,
    setConditions
  } = useSelector(({
    reference: {
      referenceSourceInfo: {
        columns
      }
    },
    update: {
      updateSetConditions: setConditions
    }
  }) => ({
    columns,
    setConditions
  }))

  const columnOptions = columns.map(column => ({
    label: column.Name,
    value: column.Name
  }))

  const handleSelectChange = (value, event) => {
    const [key, rawIndex] = event.name.split('.')
    const index = Number(rawIndex)
    // @ts-ignore
    dispatch(updateOptionSet(key, index, value))
  }

  return (
    <div>
      {setConditions.map((item, index) => (
        <Grid container key={index}>
          <Grid item xs={3}>
            <UpdateSelect
              data-testid={`select-columnName.${index}`}
              name={`columnName.${index}`}
              styles={SingleValueStyles}
              components={SELECT_COMPONENT}
              queryKey='columnName'
              queryIndex={index}
              queryTarget='updateSetConditions'
              options={columnOptions}
              value={item.columnName}
              onChange={handleSelectChange}
            />
          </Grid>

          <EqualGrid item xs={2}>
            <EqualDiv
              data-testid={`label-operation-${index}`}
            >
              {item.operation}
            </EqualDiv>
          </EqualGrid>

          <Grid item xs={3}>
            <UpdateCreatableSelect
              data-testid={`select-whereValue.${index}`}
              name={`whereValue.${index}`}
              styles={SingleValueStyles}
              components={SELECT_COMPONENT}
              queryKey='whereValue'
              queryIndex={index}
              queryTarget='updateSetConditions'
              options={columnOptions}
              value={item.whereValue}
              onChange={handleSelectChange}
            />
          </Grid>

          <Grid item xs={4}>
            <DeleteButton
              data-testid={`button-delete-${index}`}
              onClick={() => dispatch({
                ...removedOptionSetAction,
                payload: index
              })}
              size="large">
              <DeleteIcon fontSize='small' />
            </DeleteButton>
          </Grid>
        </Grid>
      ))}
    </div>
  );
}
