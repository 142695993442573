import React from 'react'
import PropTypes from 'prop-types'
import MuiTable from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import MuiTableCell from '@mui/material/TableCell'
import MuiTableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Grid from '@mui/material/Grid'
import ReactSelect from 'react-select'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import CircularProgress from '@mui/material/CircularProgress'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material'

import { validateMetadata } from '../../../store/pages/dataLake'
import DataLakeTable from './dataLakeTable'
import SearchBox from '../../../components/form/SearchBox'
import { SingleValue, SingleValueStyles } from '../../../components/sql/SelectSingleValue'

const Select = styled(ReactSelect)(({ theme }) => ({
  margin: theme.spacing(0)
}))

const Table = styled(MuiTable)(({ theme }) => ({
  padding: theme.spacing(2)
}))

const TableContainer = styled(MuiTableContainer)(({ theme }) => ({
  marginLeft: theme.spacing(2)
}))

const TableCell = styled(MuiTableCell)(({
  backgroundColor: 'white'
}))

const OPTIONS_FILE_REDI = [
  {
    label: 'val policy',
    value: 'I'
  },
  {
    label: 'val terminated',
    value: 'T'
  },
  {
    label: 'evexp risk history',
    value: 'RH'
  },
  {
    label: 'treaty',
    value: 'TY'
  }
]

export default function DataLakeSourceTable ({ data = [], files, setFiles }) {
  const dispatch = useDispatch()
  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState('Source Name')
  const [selected, setSelected] = React.useState([])
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)
  const [filesMap, setFilesMap] = React.useState([])
  const [loadingMap, setLoadingMap] = React.useState(null)
  const [filterQueries, setFilterQueries] = React.useState(undefined)
  const project = useSelector(store => store.project)

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage)

  const getFileTypeOptions = () => {
    switch (project.studyType) {
      case 'skipMap':
        return OPTIONS_FILE_REDI

      default:
        throw new Error(`Invalid studyType: ${project.studyType}`)
    }
  }

  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === 'asc'

    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const renderValidationCell = (row) => {
    const { validation = null } = row
    if (validation === 'loading' || validation === null) {
      return validation === 'loading'
        ? <CircularProgress data-testid='circle-progress'/>
        : <div data-testid='empty'> {'-'} </div>
    } else {
      return validation === 'successful' || validation === ' '
        ? <Tooltip
            data-testid='tooltip-success'
            title={'Valid'}
          >
      <CheckCircleIcon
        data-testid='check-circle'
        fontSize="large"
        style={{ color: '#6FE52A' }}/>
      </Tooltip>
        : <Tooltip
            data-testid='tooltip-failure'
            title={<span style={{ whiteSpace: 'pre-line' }}>{row.validation}</span>}>
            <ErrorIcon
              data-testid='check-error'
              fontSize="large"
              color='primary'
            />
        </Tooltip>
    }
  }

  const addSelectedFiles = async () => {
    setLoadingMap(true)
    await Promise.all(filesMap.map(info => addFile(info)))
    setLoadingMap(false)
  }

  const allowSpecialCharacters = () => {
    try {
      return data.filter(searchPhrase =>
        searchPhrase.dataSet.toLowerCase()
          .match(filterQueries.toLowerCase()))
    } catch (e) {
      return data.filter(searchPhrase =>
        searchPhrase.dataSet.toLowerCase()
          .match(''.toLowerCase()))
    }
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const updateData = (file, dataset) => {
    const updatedItem = data.find(item => item.dataSet === dataset)
    updatedItem.fileType = file
      ? file.value
      : null

    setFilesMap(data.filter(item => item.fileType))
  }

  const addFile = async (info) => {
    const {
      dataSet,
      pkg,
      role,
      fileType
    } = info

    const targetFileIndex = files.findIndex(file => file.fileType === info.fileType)

    setFiles(prev => {
      const target = {
        ...prev[targetFileIndex],
        validation: 'loading'
      }

      return [
        ...prev.slice(0, targetFileIndex),
        target,
        ...prev.slice(targetFileIndex + 1)
      ]
    })

    const {
      validationTooltip,
      columns
    } = await dispatch(
      validateMetadata(dataSet, pkg?.value, fileType)
    )

    setFiles(prev => {
      const target = {
        ...prev[targetFileIndex],
        dataSet: info.dataSet,
        validation: validationTooltip,
        columns,
        dataFabric: {
          role: role.value,
          pkg: pkg.value,
          dataSet
        }
      }

      return [
        ...prev.slice(0, targetFileIndex),
        target,
        ...prev.slice(targetFileIndex + 1)
      ]
    })

    setFilesMap([])
  }

  const removeFile = index => {
    const updatedFiles = [...files]
    updatedFiles[index].dataSet = null
    updatedFiles[index].validation = null
    setFiles(updatedFiles)
    setFilesMap([])
  }

  const rediFileTypeValidation = (filetype) => {
    const required = files.filter(file => (file.fileType === 'T' || file.fileType === 'I') &&
      file.dataSet) >= 0

    return (filetype === 'T' || filetype === 'I') && required
      ? ' (** Required)'
      : ''
  }

  const rowsFilter = filterQueries === undefined
    ? data
    : allowSpecialCharacters()

  const fileTypeOptions = getFileTypeOptions()

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Typography variant="h6">
           Data sets
        </Typography>
        <Grid item xs={12}>
          <SearchBox
            data-testid='search'
            onChange={event => setFilterQueries(event.target.value)}
          />
        </Grid>

        <br/>
        <TableContainer>
          <Table aria-labelledby="tableTitle" size='small'>
            <DataLakeTable
              data-testid='data-lake-table'
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={data.length}
              filesMap={filesMap}
              addFilesMap={addSelectedFiles}
              loadingMap={loadingMap}
            />
            <TableBody>
              {rowsFilter
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`
                  return (
                    <TableRow key={row.dataSet}>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                      >
                        {row.dataSet}
                      </TableCell>
                      <TableCell>
                        <Select
                          data-testid={`select-dataset-${index}`}
                          placeholder=""
                          styles={SingleValueStyles}
                          isClearable
                          menuPosition={'fixed'}
                          value ={fileTypeOptions.find(option => option.value === row.fileType) || null}
                          options={fileTypeOptions.filter(option => !filesMap.find(file => file.fileType === option.value))}
                          onChange={(e) => {
                            updateData(e || null, row.dataSet)
                          }}
                          components={{ SingleValue }}
                        />
                      </TableCell>
                    </TableRow>
                  )
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          data-testid='tablePagination-1'
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h6" >
           Project Files
        </Typography>
        <br/>
        <br/>
        <br/>
        <TableContainer>
          <Table
            size="small"
            aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Data Set</TableCell>
                <TableCell>File Type</TableCell>
                <TableCell>Validation</TableCell>
                <TableCell>Remove</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {files.map((row, index) => (
                <TableRow key={index}>
                  <TableCell >{row.dataSet}</TableCell>
                  <TableCell >{row.fileType + rediFileTypeValidation(row.fileType)}
                  </TableCell>
                  <TableCell >{renderValidationCell(row)}</TableCell>
                  <TableCell>
                    <IconButton
                      data-testid={`button-remove-${index}`}
                      aria-label="delete"
                      onClick={() => {
                        removeFile(index)
                      }}
                      size="large">
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

DataLakeSourceTable.propTypes = {
  files: PropTypes.any,
  data: PropTypes.any,
  setFiles: PropTypes.func.isRequired
}
