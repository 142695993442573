import React from 'react'
import PropTypes from 'prop-types'
import Switch from '@mui/material/Switch'
import { useDispatch } from 'react-redux'
import { excludeData } from '../../store/pages/mapping'

const SwitchRender = ({ data, relevantData }) => {
  const dispatch = useDispatch()
  const { valMapp } = relevantData
  const currentValue = data.value !== undefined ? data.value : data[relevantData.fieldName]
  const excludedataValue = valMapp.find(x => x.originalValue === currentValue)
  const [check, setCheck] = React.useState(excludedataValue?.exclude || false)
  return <Switch onChange={(e) => {
    dispatch(excludeData(currentValue, relevantData))
    setCheck(!check)
  }}
    checked={check}
    size="small"
    color="primary"
    name="checkedA"
    inputProps={{ 'aria-label': 'secondary checkbox' }}
  />
}
SwitchRender.propTypes = {
  data: PropTypes.any,
  relevantData: PropTypes.any,
  onGridReadyData: PropTypes.any
}

export default SwitchRender
