import React from 'react'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import Typography from '@mui/material/Typography'
import WhereList from './where'
import SetList from './set'
import { update } from '../../../store/pages/common/update'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material'

const ExecuteButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
  float: 'left'
}));

const UpdateRoot = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  width: '100%',
  backgroundColor: '#DEDEDE'
}));

export default function Update () {
  const dispatch = useDispatch()

  const {
    id,
    isSaving,
    message,
    files
  } = useSelector(({
    reference: {
      referenceTable: {
        guid: id,
        files
      }
    },
    update: {
      isSaving,
      message
    }
  }) => ({
    id,
    isSaving,
    message,
    files
  }))

  const tableName = files[0]?.guid

  const handleExecuteButtonClicked = () => {
    // @ts-ignore
    dispatch(update(tableName, id, false))
  }

  return (
    <UpdateRoot>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <SetList />
            </Grid>

            <Grid item xs={12}>
              <WhereList />
            </Grid>

            <Grid item xs={12}>
              {isSaving
                ? <CircularProgress
                  data-testid='progress'
                />
                : <div>
                  <ExecuteButton
                    data-testid='button-execute'
                    startIcon={<PlayArrowIcon />}
                    variant='outlined'
                    color='primary'
                    onClick={handleExecuteButtonClicked}
                  >Execute</ExecuteButton>
                </div>}
            </Grid>

            <Grid item xs={12}>
              <Typography
                data-testid='label-message'
                variant='h6'
              >{message}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </UpdateRoot>
  )
}
