import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import MuiIconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import { useDispatch } from 'react-redux'
import { styled } from '@mui/material'

import { editedFormulaDialogAction } from '../../store/pages/common/formulaDialog'
import { toggledWorkflowFormulaAction } from '../../store/pages/common/workflow'

const Root = styled('div')({
  gridArea: '1 / 1 / 2 / 3'
})

const Container = styled('div', {
  shouldForwardProp: prop => prop !== 'editable'
})(props => ({
  display: 'inline-grid',
  gap: props.theme.spacing(1),
  gridTemplateColumns: props.editable ? 'auto auto' : 'auto',
  padding: props.theme.spacing(1),
  alignItems: 'center',
  whiteSpace: 'nowrap',
  backgroundColor: '#B7D2E8',
  border: '1px solid black',
  borderRadius: 2,
  color: 'black',
  float: 'left'
}))

const IconButton = styled(MuiIconButton)(({ theme }) => ({
  padding: 0,
  leftPadding: theme.spacing(1)
}))

const OPTION_FORMULA_BUILDER = {
  Name: 'USE FORMULA BUILDER',
  Type: 'Formula'
}

const SingleValue = props => {
  const dispatch = useDispatch()

  const editable =
    props.data.type === 'formula' ||
    props.data.type === 'rename'

  const handleEditClick = (event) => {
    event.stopPropagation()

    if (props.selectProps.onEdit) {
      props.selectProps.onEdit(props.data, {
        name: props.selectProps.name
      })
    } else {
      if (props.selectProps.order !== undefined) {
        dispatch({
          ...toggledWorkflowFormulaAction,
          payload: {
            order: props.selectProps.order
          }
        })
      }

      dispatch({
        ...editedFormulaDialogAction,
        payload: {
          formula: props.data.value,
          name: props.data.name,
          index: props.selectProps.queryIndex,
          target: props.selectProps.queryTarget,
          key: props.selectProps.queryKey
        }
      })
    }
  }

  return (
    <Root>
      <Container editable={editable}>
        <Typography>
          {props.data.label}
        </Typography>

        {editable && (
          <IconButton
            data-testid='button-edit'
            disableRipple
            size="large"
            onClick={handleEditClick}
          >
            <EditIcon />
          </IconButton>
        )}
      </Container>
    </Root>
  )
}

const SingleValueStyles = {
  control: (base) => ({
    ...base,
    height: '45px'
  })
}

const AppendColumnBuilder = (columns) => {
  return [
    OPTION_FORMULA_BUILDER,
    ...columns
  ]
}

const AppendAllStar = (selectValues, columns) => {
  return selectValues.some(x => x.value === '*')
    ? columns
    : [{ Name: '*', Type: 'all' }].concat(columns)
}

SingleValue.propTypes = {
  data: PropTypes.object.isRequired,
  selectProps: PropTypes.object.isRequired
}

export { SingleValue, SingleValueStyles, AppendColumnBuilder, AppendAllStar }
