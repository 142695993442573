import PropTypes from 'prop-types'
import React from 'react'
import Table from './Table'
import LaunchIcon from '@mui/icons-material/Launch'
import { styled } from '@mui/material'

const Text = styled('p')({
  margin: '0',
  padding: '0'
})

function getTableNamesValues (job) {
  if (!job.tableNames) {
    return ['-']
  }

  return job.tableNames.length ? job.tableNames : ['None']
}

export default function OutputTable (props) {
  const packageName = props.job.packageName ? props.job.packageName : '-'

  const tableNamesJsx =
    getTableNamesValues(props.job)
      .map((item, index) => <Text
        data-testid={`name-${index}`}
        key={item}
      >{item}</Text>)

  const { powerBiReportUrl } = props.job.parameters

  return <Table
    title='Output Information'
    items={[
      <React.Fragment key='item-package'>
        <strong>Package:</strong>

        <Text data-testid='value-packageName'>{packageName}</Text>
      </React.Fragment>,
      <React.Fragment key='item-tableNames'>
        <strong>Table Names:</strong>
        {tableNamesJsx}
      </React.Fragment>,
      powerBiReportUrl && (
        <React.Fragment key='item-powerBiReport'>
          <a
            data-testid='powerBI-url'
            href={powerBiReportUrl}
            onClick={() => window.open(powerBiReportUrl, 'noreferrer')}
          ><strong>Power BI Report</strong>
          <LaunchIcon
            fontSize='10px'
          /></a>
        </React.Fragment>
      )
    ].filter(item => item)}
  />
}

OutputTable.propTypes = {
  job: PropTypes.shape({
    packageName: PropTypes.string,
    tableNames: PropTypes.arrayOf(PropTypes.string),
    parameters: PropTypes.shape({
      powerBiReportUrl: PropTypes.string
    })
  })
}

OutputTable.defaultProps = {
  job: {
    packageName: '',
    tableNames: [],
    parameters: {
      powerBiReportUrl: ''
    }
  }
}
