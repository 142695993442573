import PropTypes from 'prop-types'
import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import CircularProgress from '@mui/material/CircularProgress'
import { useDispatch, useSelector } from 'react-redux'

import {
  refreshFileData,
  resetFileDataState
} from '../../../../store/pages/dataPrep'

export default function ResetDataDialog (props) {
  const dispatch = useDispatch()
  const [enableConfirm, setEnableConfirm] = React.useState(false)
  const [deleteValue, setDeleteValue] = React.useState('')
  const { refreshData } = useSelector(store => store.dataPrep)

  const handleDeleteTextChange = React.useCallback(event => {
    setDeleteValue(event.target.value)
    setEnableConfirm(event.target.value.toUpperCase() === 'DELETE')
  }, [
    setDeleteValue,
    setEnableConfirm
  ])

  const handleClose = React.useCallback(() => {
    dispatch(resetFileDataState)
    props.onClose()
  }, [props, dispatch])

  return (
    <Dialog open={props.open}>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            {!refreshData.hideButtonBar && (
              <div>
                <Typography variant="h6">
                  You are refeshing your local data stores source data only. This includes updates, converts, merges, and column mappings.
                </Typography>

                <hr />

                <Typography variant="h6">
                  Type the word &#34;DELETE&#34; to continue.
                </Typography>

                <TextField
                  inputProps={{ 'data-testid': 'deleteInput' }}
                  variant="outlined"
                  value={deleteValue}
                  onChange={handleDeleteTextChange}
                />

                <hr />
              </div>
            )}

            {refreshData.isRunning && (
              <div data-testid="spinnerContainer" >
                <Typography variant="h6">
                  <CircularProgress />

                  {refreshData.message}
                </Typography>
              </div>
            )}

            {refreshData.isFinished && (
              <div>
                <Typography variant="h6">
                  Your data was successfully reloaded.

                  <Button
                    data-testid='closeBtn'
                    color="primary"
                    onClick={handleClose}
                  >Close</Button>
                </Typography>
              </div>
            )}
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        {!refreshData.hideButtonBar && (
          <div>
            <Button
              data-testid='cancelBtn'
              color="primary"
              onClick={props.onClose}
            >Cancel</Button>

            <Button
              data-testid='confirmBtn'
              autoFocus
              disabled={!enableConfirm}
              color="primary"
              onClick={() => dispatch(refreshFileData)}
            >Confirm</Button>
          </div>
        )}
      </DialogActions>
    </Dialog>
  )
}

ResetDataDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}
