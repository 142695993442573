import React from 'react'
import MuiSelect from 'react-select'
import MuiCreatableSelect from 'react-select/creatable'
import Grid from '@mui/material/Grid'
import Fab from '@mui/material/Fab'
import Typography from '@mui/material/Typography'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import { styled } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'

import {
  SingleValue,
  SingleValueStyles
} from '../../../components/sql/SelectSingleValue'

import {
  MultiValueLabel,
  MultiValueRemove,
  MultiValueStyles
} from '../../../components/sql/SelectMultiValue'

import {
  addedSortAction,
  removedSortAction,
  setOrderByAction,
  setOrderByConditionsAction
} from '../../../store/pages/common/query'

const COMPONENTS_SINGLE = {
  SingleValue
}

const COMPONENTS_MULTI = {
  MultiValueLabel,
  MultiValueRemove
}

const OPTIONS_DIRECTION = [
  { value: 'asc', label: 'Asc' },
  { value: 'desc', label: 'Desc' }
]

const Container = styled('div')({
  paddingTop: 20
})

const Select = styled(MuiSelect)(({ theme }) => ({
  minHeight: 58,
  marginLeft: theme.spacing(2)
}))

const CreatableSelect = styled(MuiCreatableSelect)(({ theme }) => ({
  minHeight: 58,
  marginLeft: theme.spacing(2)
}))

const DeleteFab = styled(Fab)(({
  marginRight: 75,
  boxShadow: 'none',
  color: 'rgba(0, 0, 0, 0.54)',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)'
  }
}))

export default function OrderBy () {
  const dispatch = useDispatch()
  const navigation = useSelector(store => store.navigation)
  const query = useSelector(store => store.query)

  const columns = navigation.formatFile?.columns ?? []

  const columnOptions = columns.map(column => ({
    label: column.Name,
    value: column.Name
  }))

  const handleAddClick = () => {
    dispatch({
      ...addedSortAction,
      payload: { showOrderByCondition: true }
    })
  }

  const handleConditionChange = value => {
    dispatch({
      ...setOrderByConditionsAction,
      payload: { orderByConditions: value }
    })
  }

  const handleOrderChange = value => {
    dispatch({
      ...setOrderByAction,
      payload: { orderBy: value }
    })
  }

  const handleDeleteClick = () => {
    dispatch(removedSortAction)
  }

  return (
    <Container>
      <Grid container>
        <Grid item xs={6}>
          <Typography variant="h6">Order By</Typography>
        </Grid>

        {!query.showOrderByCondition && (
          <Grid item xs={6}>
            <Fab
              data-testid='button-add'
              color="primary"
              size="small"
              onClick={handleAddClick}
            ><AddIcon /></Fab>
          </Grid>
        )}
      </Grid>

      {query.showOrderByCondition && (
        <Grid container>
          <Grid item xs={4}>
            <CreatableSelect
              data-testid='select-conditions'
              styles={MultiValueStyles}
              isMulti
              queryTarget='orderByConditions'
              queryIndex={0}
              components={COMPONENTS_MULTI}
              value={query.orderByConditions}
              options={columnOptions}
              onChange={handleConditionChange}
            />
          </Grid>

          <Grid item xs={3}>
            <Select
              data-testid='select-order'
              styles={SingleValueStyles}
              isClearable
              isSearchable
              queryTarget='orderBy'
              components={COMPONENTS_SINGLE}
              options={OPTIONS_DIRECTION}
              value={query.orderBy}
              onChange={handleOrderChange}
            />
          </Grid>

          <Grid item xs={1}>
            <DeleteFab
              data-testid='button-remove'
              size="large"
              onClick={handleDeleteClick}
            >
              <DeleteIcon fontSize="small" />
            </DeleteFab>
          </Grid>

          <Grid item xs={4}></Grid>
        </Grid>
      )}
    </Container>
  )
}
