import { ResponsiveLine } from '@nivo/line'
import React from 'react'
import PropTypes from 'prop-types'
import { SymbolCircle, SymbolTriangle, Points } from './CustomSymbolsNivo'

const formatNumberx = x => {
  x = parseFloat(x).toFixed(0)
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

const getSymbol = (data) => {
  const { x, y, size, fill, id, borderWidth } = data
  if (id === 'Risk History') {
    return <SymbolTriangle x={x} y={y} size={size} fill={fill}/>
  } else {
    return <SymbolCircle x={x} y={y} size={size} borderWidth={borderWidth} fill={fill}/>
  }
}

const formatNumber = x => {
  const w = x.replace(/,/g, '')
  return Number(w)
}

const formatData = (data, title) => {
  if (data && data.fieldName) {
    const { fieldName = null, values = null } = data
    const keys = []
    const lineData = []

    values.map(x => {
      keys.push(x.value)
      lineData.push({ x: x.value, y: Number(x.count) })
      return keys
    })
    const dataLineGraph = [{
      id: fieldName,
      color: 'hsl(140, 70%, 50%)',
      data: values.map(x => ({ x: x.value, y: Number(x.count) }))
    }]
    return { dataLineGraph, fieldName }
  } else {
    const fieldName = 'RISK'
    const dataLineGraph = [{
      id: 'Exposure',
      data: data.map(x => ({ x: x.date, y: formatNumber(x.exposure) }))
    },
    {
      id: 'Risk History',
      data: data.map(x => ({ x: x.date, y: formatNumber(x['risk history']) }))
    }]
    return { dataLineGraph, fieldName }
  }
}

const LineChart = ({ data, title }) => {
  const { dataLineGraph } = formatData(data, title)

  return dataLineGraph[0].data && (<ResponsiveLine
    data={dataLineGraph}
    areaBaselineValue={'Date'}
    margin={{ top: 50, right: 110, bottom: 60, left: 100 }}
    yFormat=">-,"
    yScale={{
      format: ' >-,',
      type: 'linear',
      min: 'auto',
      max: 'auto',
      stacked: false,
      reverse: false
    }}
    axisTop={null}
    axisRight={null}
    axisBottom={{
      orient: 'bottom',
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 33,
      legend: title,
      legendOffset: 55,
      legendPosition: 'middle'
    }}
    pointLabel="y"
    pointLabelYOffset={-12}
    pointSymbol= {(data) => getSymbol(data)}
    axisLeft={{
      format: (value) => {
        return formatNumberx(value)
      },
      orient: 'left',
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: '',
      legendOffset: -40,
      legendPosition: 'middle'
    }}
    pointSize={11}
    useMesh={true}
    legends={[
      {
        anchor: 'bottom-right',
        direction: 'column',
        justify: false,
        translateX: 100,
        translateY: 0,
        itemsSpacing: 0,
        itemDirection: 'left-to-right',
        itemWidth: 80,
        itemHeight: 20,
        itemOpacity: 0.75,
        symbolSize: 14,
        symbolShape: (data) => getSymbol(data),
        symbolBorderColor: 'rgba(0, 0, 0, .5)',
        effects: [
          {
            on: 'hover',
            style: {
              itemBackground: 'rgba(0, 0, 0, .03)',
              itemOpacity: 1
            }
          }
        ]
      }
    ]}
    layers={[
      'grid',
      'markers',
      'axes',
      'areas',
      'crosshair',
      'lines',
      Points,
      'slices',
      'mesh',
      'legends'
    ]}
  />
  )
}

LineChart.propTypes = {
  data: PropTypes.any,
  title: PropTypes.any
}

export default LineChart
