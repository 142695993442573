import React from 'react'
import Typography from '@mui/material/Typography'

import { useSelector } from 'react-redux'

export default function Messages () {
  const { joinMessage, queryMessage, updateMessage } = useSelector(({
    join: { message: joinMessage },
    query: { message: queryMessage },
    update: { message: updateMessage }
  }) => ({
    joinMessage, queryMessage, updateMessage
  }))

  return (
    <div>
      <Typography variant="body2" component="p">
        {updateMessage}
        {queryMessage}
        {joinMessage}
      </Typography>
    </div>
  )
}
