export default [
  {
    name: 'ISSUE_AGE(vDateOfBirth, vIssue_Date, vXNL)',
    description: 'Returns the Issue Age Calculation based on specified methods',
    example: "ISSUE_AGE('01-JAN-1960', '01-JUL-2022', 'X')"
  },
  {
    name: 'CONCAT(expression1, expression2)',
    description: 'Concatonate values together with a seperator of choice',
    example: "CONCAT('December 25, ', '2008')"
  },
  {
    name: "TO_CHAR(timestamp_expression | numeric_expression , 'format')",
    description: 'Converts a timestamp or numeric expression to a character-string data format',
    example: "TO_CHAR(timestamp '2009-12-31 23:15:59', 'MONTH-DY-DD-YYYY HH12:MIPM')"
  },
  {
    name: 'TO_DATE(string, format_mask)',
    description: 'format a string into a date',
    example: "TO_DATE('20010631', 'YYYYMMDD')= 2001-07-01"
  },
  {
    name: 'DATEDIFF(datepart, {date|time|timetz|timestamp}, {date|time|timetz|timestamp})',
    description: 'If the second date or time is earlier than the first date or time, the result is negative.',
    example: "DATEDIFF(week,'2009-01-01','2009-12-31')"
  },
  {
    name: 'CASE WHEN…ELSE…END',
    description: 'The CASE expression is a conditional expression, similar to if/then/else statements found in other languages. CASE is used to specify a result when there are multiple conditions.',
    example: "CASE WHEN 5000 <10000 THEN 'group 1' WHEN 15000 >10000 THEN 'group 2' ELSE 'group 3' END"
  },
  {
    name: 'NVL(string1, string2,…, stringn)',
    description: 'An NVL expression returns the value of the first expression in the list that is not null. If all expressions are null, the result is null. When a non-null value is found, the remaining expressions in the list are not evaluated.',
    example: "NVL('2008-06-01','2008-12-31')"
  },
  {
    name: 'COALESCE(string1, string2,…, stringn)',
    description: 'An COALESCE expression returns the value of the first expression in the list that is not null. If all expressions are null, the result is null. When a non-null value is found, the remaining expressions in the list are not evaluated.',
    example: "COALESCE('2008-06-01','2008-12-31')"
  },
  {
    name: 'SUM(aggregate_expression)',
    description: 'Returns the sum of the aggregate expression',
    example: 'SUM(claim_amount)= 100'
  },
  {
    name: 'COUNT(aggregate_expression)',
    description: 'Returns the count of the aggregate expression',
    example: 'COUNT(*)= 1000'
  },
  {
    name: 'MAX(aggregate_expression)',
    description: 'Returns the largest value from the aggregate_expression',
    example: 'MIN(claim_amount)= 1000'
  },
  {
    name: 'MIN(aggregate_expression)',
    description: 'Returns the smallest value from the aggregate_expression',
    example: 'MIN(claim_amount)= 0'
  },
  {
    name: 'DATEADD(day,Number_of_day,date)',
    description: 'Add an interval of DAYS to a date',
    example: "DATEADD(day,30,'2022-01-02')"
  }, {
    name: 'ADD_MONTHS(date, months)',
    description: 'Add an interval of MONTHS to a date',
    example: 'ADD_MONTHS("2020-01-01",2)'
  },
  {
    name: 'SPLIT_PART(string, delimiter, number)',
    description: 'Returns the abesolute value of a number',
    example: "SPLIT_PART('2008-03-05 12:25:29','-',1)"
  },
  {
    name: ' UPPER(string)',
    description: 'Returns a character string that is the same data type as the input string',
    example: 'UPPER(catname) from category order by 1,2'
  },
  {
    name: 'LOWER(string)',
    description: 'Returns a character string that is the same data type as the input string.',
    example: 'LOWER(catname) from category order by 1,2'
  },
  {
    name: 'MD5(string)',
    description: 'Returns a 32-character string that is a text representation of the hexadecimal value of a 128-bit checksum',
    example: "MD5('Amazon Redshift')"
  },
  {
    name: 'SUBSTRING(string, start_position, [length])',
    description: 'Returns the subset of a string based on the specified start position',
    example: "SUBSTRING('caterpillar',6,4)"
  },
  {
    name: 'ROUND(number, [decimal_places])',
    description: 'Rounds a number to the specified decimal places',
    example: 'ROUND(14.123456,2)= 14.12; ROUND(14.123456,4)=14.1235'
  },
  {
    name: 'FLOOR(number)',
    description: 'Returns the greatest integer less than or equal to the input',
    example: 'FLOOR(-31.2)= -32'
  },
  {
    name: 'CEIL(number)',
    description: 'Returns the ceiling of a number',
    example: 'CEIL(4.8)= 5'
  },
  {
    name: 'ABS(number)',
    description: 'Returns the abesolute value of a number',
    example: 'ABS(-4)= 4'
  }
]
