import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { checkForProfilingData } from '../../../store/pages/reference'

const useCheckIfFileIsProfiling = async () => {
  const dispatch = useDispatch()
  const { isLoaded, id } = useSelector(({
    global: { isLoaded },
    reference: { referenceTable: { id } }
  }) => ({
    isLoaded, id
  }))
  React.useEffect(() => {
    const syncCheckForProfilingData = async () => {
      if (isLoaded && id !== null) {
        dispatch(checkForProfilingData)
      }
    }
    syncCheckForProfilingData()
  }, [dispatch, isLoaded, id])
}

export default useCheckIfFileIsProfiling
