import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setIsPrecalculationAction } from '../../../store/pages/common/query'

import {
  loadData,
  loadSavedItems,
  loadUserReferenceTables
} from '../../../store/pages/dataPrep'

function useLoadPrepData () {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {
    isLoaded, redirectToHome
  } = useSelector((store) => store.global)
  const { file } = useSelector((store) => store.navigation)
  const {
    id, studyType, fileData
  } = useSelector((store) => store.project)
  const {
    projectId, loadedSavedItems
  } = useSelector((store) => store.dataPrep)
  const skipMapping = studyType === 'skipMap'

  const shouldLoadUserReferenceTables = React.useMemo(() => !skipMapping && isLoaded && projectId !== id, [id, isLoaded, projectId, skipMapping])
  React.useEffect(() => {
    if (shouldLoadUserReferenceTables) dispatch(loadUserReferenceTables)
  }, [dispatch, shouldLoadUserReferenceTables])
  const shouldLoadData = React.useMemo(() => !!file?.guid || !!(fileData && file) || (projectId && projectId !== id), [file, fileData, id, projectId])
  React.useEffect(() => {
    if (redirectToHome) {
      navigate('/')
    } else if (!skipMapping) {
      if (isLoaded) {
        if (!loadedSavedItems) dispatch(loadSavedItems)
        if (shouldLoadData) dispatch(loadData)
      }
      dispatch({
        ...setIsPrecalculationAction,
        payload: {
          isPrecalculation: false
        }
      })
    }
  }, [dispatch, isLoaded, loadedSavedItems, navigate, redirectToHome, shouldLoadData, skipMapping])
}

export default useLoadPrepData
