import React from 'react'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import MuiButton from '@mui/material/Button'
import GetAccordionObject from './GetAccordionObject'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Dialog from '@mui/material/Dialog'
import CircularProgress from '@mui/material/CircularProgress'
import TrackChangesIcon from '@mui/icons-material/TrackChanges'
import FilterListIcon from '@mui/icons-material/FilterList'
import { styled } from '@mui/material'
import PropTypes from 'prop-types'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'

import useLoadNonCritPreCalc from '../../app/hooks/useLoadNonCritPreCalc'
import precalcTile from './precalcTile'
import LogDialog from './logDialog'
import { useDispatch, useSelector } from 'react-redux'
import { fixCFT } from '../../../store/pages/preCalculation'

const OuterGrid = styled(Grid)(({
  overflowY: 'hidden',
  overflowX: 'hidden',
  minHeight: '75vh'
}))

const OuterPaper = styled(Paper)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(1),
  backgroundColor: '#DEDEDE',
  zIndex: 50
}))

const InnerGrid = styled(Grid)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(1),
  backgroundColor: '#DEDEDE',
  zIndex: 50
}))

const AccordionPaper = styled(Paper)(({ theme }) => ({
  border: '1px solid grey',
  padding: theme.spacing(2),
  width: '100%',
  backgroundColor: 'white'
}))

const Button = styled(MuiButton)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2)
}))

export default function PrecalCheck ({ access }) {
  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState('UNIQUEKEY')
  const dispatch = useDispatch()
  const {
    fixingFlag,
    fixMessage,
    preCalculationCheckData = []
  } = useSelector(store => store.preCalc)
  const studyType = useSelector(store => store.project.studyType)

  const [currentStep, setCurrentStep] = React.useState()
  const [open, setOpen] = React.useState(false)
  const [filter, openFilter] = React.useState(true)
  const [openLog, setOpenLog] = React.useState(false)
  const [valueFilter, setValueFilter] = React.useState([])

  useLoadNonCritPreCalc()
  const includeFix = preCalculationCheckData.filter(x => x.fix).length > 0 ? preCalculationCheckData.filter(x => x.fix).length : 0
  const error = preCalculationCheckData.filter(x => x.results ? x.results.length > 0 : false).length
  const infoLog = preCalculationCheckData.reduce((s, a) => {
    if (a.fix && a.results.length > 0) {
      s.push({ checkName: a.checkName, fixLog: a.logInfo ? a.logInfo.sqlToExecuteLog : 'Acknowledge' })
    }
    return s
  }, [])
  const currentConst = valueFilter.length === 0 ? preCalculationCheckData : valueFilter
  const handleChange = (e) => {
    switch (e.target.value) {
      case 'error':
        return setValueFilter(preCalculationCheckData.filter(x => x.results.length > 0))
      case 'nonCritical':
        return setValueFilter(preCalculationCheckData.filter(x => x.nonCritical))
      default:
        return setValueFilter(preCalculationCheckData)
    }
  }
  const handleFilter = (value) => {
    openFilter(!value)
  }
  return <div>
    <LogDialog open={openLog} handleClose={setOpenLog} data={infoLog}/>
    <Dialog
      open={open}
      aria-labelledby="form-dialog-title"
      maxWidth={'md'}
    >
      <DialogContent>
        <Typography variant="h6" >
          {fixingFlag ? <CircularProgress hidden={!fixingFlag} /> : '' }
          {fixMessage}
        </Typography>
      </DialogContent>
      <DialogActions>
        <div>
          <Button
            disabled={fixingFlag}
            onClick={() => setOpen(false)}
            color="primary"
            autoFocus
          >
                Close
          </Button>
        </div>
      </DialogActions>
    </Dialog>
    <OuterGrid container>
      <Grid item xs={4}>
        <OuterPaper elevation={1}>
          <Grid container>
            <InnerGrid item xs={12}>
              <AccordionPaper>
                <Typography>
                  Failed Check ({error}) - Fix/Acknowledge ({includeFix})
                  <Button disabled={ !access || error === 0 || error !== includeFix || studyType === 'skipMap'} size='small'
                    variant="outlined"
                    color="primary"
                    onClick={() => { dispatch(fixCFT); setOpen(true) }}
                  >

                    <PlayArrowIcon/>
                    Apply Fixes and Continue</Button>
                  {infoLog.length > 0 && <Button size='small'
                    variant="outlined"
                    color="primary"
                    onClick={() => setOpenLog(true)}
                  >
                    <TrackChangesIcon/>
                  </Button> }
                  <Button
                    size='small'
                    variant="outlined"
                    color="primary"
                    onClick={() => handleFilter(filter)}
                  >
                    <FilterListIcon/>
                  </Button>
                </Typography>
              </AccordionPaper> </InnerGrid>
            {filter && <Grid>
              <FormControl fullWidth component="fieldset" style={{ backgroundColor: '#DEDEDE' }}>
                <RadioGroup row aria-label="position" name="position" defaultValue="all" onChange={handleChange}>
                  <FormControlLabel labelPlacement="top" value="all" control={<Radio />} label="All" />
                  <FormControlLabel
                    labelPlacement="top" value="error" control={<Radio />} label="ERROR" />
                  <FormControlLabel labelPlacement="top" value="nonCritical" control={<Radio />} label="Non Critical" />

                </RadioGroup>
              </FormControl></Grid>}
            <Grid style={{ maxHeight: 800, overflow: 'auto', width: '100%' }}>
              { currentConst.sort((a, b) => a.order - b.order).map((x, i) => {
                return precalcTile({ key: `${i}-${x.checkName}`, currentStep, obj: x, setCurrentStep, studyType })
              }) }
            </Grid>
          </Grid>
        </OuterPaper>
      </Grid>
      {currentStep &&
        <Grid item xs={8}>
          {currentStep && <GetAccordionObject
            data={currentStep}
            order={order}
            setOrder={setOrder}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            access={access}
            studyType={studyType}/>}
        </Grid>
      }
    </OuterGrid>
  </div>
}
PrecalCheck.propTypes = {
  access: PropTypes.any
}
