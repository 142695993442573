import { client } from './apiHelper'

async function fetchRoles (userId) {
  const res = await client.get('/source/packages', {
    query: { userId }
  })

  const items = res.roles || []

  const dedupedSet = items.reduce((accum, curr) =>
    ((curr.includes('src') || curr.includes('usmmexp')) && accum.indexOf(curr) === -1)
      ? [...accum, curr]
      : accum, []
  )

  return dedupedSet
}

async function fetchPackages (userId, role) {
  const res = await client.get('/source/packages', {
    query: {
      userId,
      role
    }
  })

  return res.packages || []
}

async function fetchDatasets (userId, role, packageName) {
  const res = await client.get('/source/packages', {
    query: {
      userId,
      role,
      packageName
    }
  })

  return res.datasets || []
}

async function fetchMetadataColumns (packageName, dataset) {
  const res = await client.get('/source/packages', {
    query: {
      metadata: true,
      packageName,
      dataset
    }
  })

  return res.glueMetadata?.StorageDescriptor?.Columns || []
}

export default {
  fetchRoles,
  fetchPackages,
  fetchDatasets,
  fetchMetadataColumns
}
