export const processFinishedAction = {
  type: 'calculation/PROCESS_FINISHED'
}

export const updatedCurrentCalculationTabAction = {
  type: 'calculation/UPDATED_CURRENT_CALC_TAB'
}

export const gettingRediDefaultDatesAction = {
  type: 'calculation/GETTING_REDI_DEFAULT_DATES'
}

export const gotRediDefaultDatesAction = {
  type: 'calculation/GOT_REDI_DEFAULT_DATES'
}

export const changedRunStudySetupAction = {
  type: 'calculation/CHANGED_RUN_STUDY_SETUP'
}

export const updateRunSetupAction = {
  type: 'calculation/UPDATE_RUN_SETUP'
}

export const gettingJobHistoryAction = {
  type: 'calculation/GETTING_JOB_HISTORY'
}

export const receivedJobHistoryAction = {
  type: 'calculation/RECEIVED_JOB_HISTORY'
}

export const setGetDatesAction = {
  type: 'calculation/SET_GET_DATES'
}

export const addedFilesAction = {
  type: 'calculation/ADDED_FILES'
}

export const setRedirectPostCalAction = {
  type: 'calculation/SET_REDIRECT_POST_CALC'
}
