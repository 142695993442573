import PropTypes from 'prop-types'
import React from 'react'
import Grid from '@mui/material/Grid'
import Checkbox from '@mui/material/Checkbox'
import TableBase from '@mui/material/Table'
import TableRow from '@mui/material/TableRow'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import MuiTableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TablePagination from '@mui/material/TablePagination'
import TableSortLabel from '@mui/material/TableSortLabel'
import SearchBox from '../../../components/form/SearchBox'
import { styled } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { getComparator, stableSort } from '../../../commonFunc'
import { selectAggregatorsAction } from '../../../store/pages/dataPrep'

const OPTIONS_ROWS_PER_PAGE = [5, 10, 20]

const CONFIG = [
  {
    key: 'field',
    label: 'Field',
    weight: '100px'
  },
  {
    key: 'type',
    label: 'Type',
    weight: '100px'
  }
]

const COLUMN_STYLE = {
  padding: '2px 2px',
  maxHeight: '20px'
}

const TableCell = styled(MuiTableCell)({
  padding: '5px 5px 5px 5px',
  maxWidth: 100,
  maxHeight: '20px'
})

function Header (props) {
  const someSelected =
    props.numSelected > 0 &&
    props.numSelected < props.rowCount

  const allSelected =
    props.rowCount > 0 &&
    props.numSelected === props.rowCount

  const handleAllClicked = event => {
    props.onSelectAllClick(event)
  }

  const createSortHandler = key => event => {
    props.onSort(event, key)
  }

  return (
    <TableHead>
      <TableRow>
        <TableCell padding='checkbox'>
          <Checkbox
            data-testid='checkbox-select-all'
            color='primary'
            indeterminate={someSelected}
            checked={allSelected}
            onChange={handleAllClicked}
          />
        </TableCell>

        {props.config.map(item => {
          const style = {
            ...COLUMN_STYLE,
            maxWidth: item.weight
          }

          const sortedBy = item.key === props.orderBy

          return (
            <TableCell
              key={item.key}
              style={style}
              align='left'
              padding='none'
              sortDirection={sortedBy ? props.order : false}
            >
              <TableSortLabel
                data-testid={`label-sort-${item.key}`}
                active={sortedBy}
                direction={sortedBy ? props.order : 'asc'}
                onClick={createSortHandler(item.key)}
              >{item.label}</TableSortLabel>
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  )
}

Header.propTypes = {
  numSelected: PropTypes.number.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  config: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      weight: PropTypes.string
    })
  ),
  onSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired
}

export default function Table () {
  const dispatch = useDispatch()
  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState('field')
  const [pageIndex, setPageIndex] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)
  const [searchQuery, setSearchQuery] = React.useState('')
  const navigation = useSelector(store => store.navigation)
  const dataPrep = useSelector(store => store.dataPrep)

  const rows = React.useMemo(() => navigation.file.columns.map(item => ({
    type: item.Type,
    field: item.Name,
    lookup: item.Name.toLowerCase()
  })), [navigation.file.columns])

  const selection = dataPrep.aggregators.selectedAggregators
    .filter(agg => rows.find(col => col.field === agg))

  const startIndex = pageIndex * rowsPerPage
  const endIndex = startIndex + rowsPerPage

  const remainingPaginatedRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - startIndex)

  const rowComparator = getComparator(order, orderBy)

  const query = searchQuery.toLowerCase()

  const filteredRows = searchQuery
    ? rows.filter(col => col.lookup.match(query))
    : rows

  const paginatedRows = stableSort(filteredRows, rowComparator)
    .slice(startIndex, endIndex)

  const isSelected = name => selection.indexOf(name) !== -1

  const handleSearch = React.useCallback(event => {
    setSearchQuery(event.target.value)
  }, [])

  const handleSort = React.useCallback((_event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }, [order, orderBy])

  const handleSelectAllClick = React.useCallback(event => {
    const result = event.target.checked
      ? rows.map(column => column.field)
      : []

    dispatch({
      ...selectAggregatorsAction,
      payload: {
        selectedAggregators: result
      }
    })
  }, [
    rows,
    dispatch
  ])

  const handleRowClick = React.useCallback(row => {
    const selectedIndex = selection.indexOf(row.field)
    const selected = selectedIndex !== -1

    const result = selected
      ? [
          ...selection.slice(0, selectedIndex),
          ...selection.slice(selectedIndex + 1)
        ]
      : [...selection, row.field]

    dispatch({
      ...selectAggregatorsAction,
      payload: {
        selectedAggregators: result
      }
    })
  }, [
    selection,
    dispatch
  ])

  const handleChangePage = React.useCallback((_event, value) => {
    setPageIndex(value)
  }, [setPageIndex])

  const handleChangeRowsPerPage = React.useCallback(event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPageIndex(0)
  }, [setPageIndex])

  return (
    <div>
      <Grid item xs={6}>
        <SearchBox
          data-testid='search'
          onChange={handleSearch}
        />
      </Grid>

      <TableContainer>
        <TableBase size='small'>
          <Header
            numSelected={selection.length}
            order={order}
            orderBy={orderBy}
            rowCount={rows.length}
            config={CONFIG}
            onSort={handleSort}
            onSelectAllClick={handleSelectAllClick}
          />

          <TableBody>
            {paginatedRows.map((row, index) => {
              const selected = isSelected(row.field)

              return (
                <TableRow
                  data-testid={`row-${index}`}
                  style={selected ? { background: '#D3D3D3' } : {}}
                  key={index}
                  hover
                  selected={selected}
                  tabIndex={-1}
                  role='checkbox'
                  onClick={() => handleRowClick(row)}
                >
                  <TableCell padding='checkbox'>
                    <Checkbox
                      data-testid={`checkbox-selected-${index}`}
                      color='primary'
                      checked={selected}
                    />
                  </TableCell>

                  <TableCell
                    data-testid={`cell-field-${index}`}
                    align='left'
                    component='th'
                    scope='row'
                    padding='none'
                  >{row.field}</TableCell>

                  <TableCell
                    data-testid={`cell-type-${index}`}
                    align='left'
                    component='th'
                    scope='row'
                    padding='none'
                  >{row.type}</TableCell>
                </TableRow>
              )
            })}

            {remainingPaginatedRows > 0 && (
              <TableRow style={{ maxHeight: 20 * remainingPaginatedRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </TableBase>
      </TableContainer>

      <TablePagination
        component='div'
        page={pageIndex}
        count={rows.length}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={OPTIONS_ROWS_PER_PAGE}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  )
}
