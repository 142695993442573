import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { updateWizardStepAction } from '../../../store/pages/preCalculation'

const LapseSkewInput = ({ type, checkName }) => {
  const dispatch = useDispatch()
  const { fixWizard, formatFiles } = useSelector(({
    preCalc: { fixWizard },
    project: { formatFiles }
  }) => ({
    fixWizard, formatFiles
  }))
  const currentFile = !fixWizard[checkName] ? formatFiles.find(x => x.type === type) : fixWizard[checkName]
  if (currentFile.daysToAdd === undefined) {
    dispatch({
      ...updateWizardStepAction,
      payload: {
        type: checkName,
        object: { ...currentFile, daysToAdd: currentFile.daysToAdd = 0 }
      }
    })
  }
  return (
    <input
      placeholder={currentFile.daysToAdd ? currentFile.daysToAdd : 0 }
      data-testid='skewness'
      onChange={(event) => {
        if (event.target.value) {
          dispatch({
            ...updateWizardStepAction,
            payload: {
              type: checkName,
              object: { ...currentFile, daysToAdd: event.target.value }
            }
          })
        }
      }}
    />
  )
}
LapseSkewInput.propTypes = {
  type: PropTypes.any,
  checkName: PropTypes.any
}

export default LapseSkewInput
