import PropTypes from 'prop-types'
import React from 'react'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material'

const StyledDialogTitle = styled(DialogTitle)({
  color: '#c62728'
})

const StyledDialogContent = styled(DialogContent)({
  border: '2px solid grey',
  padding: 0,
  overflowX: 'hidden'
})

const ReleaseNoteDialog = props => {
  const notes = props.data.notes || []

  const handleClose = () => {
    props.onClose()
  }

  return (
    <Dialog
      data-testid={props['data-testid']}
      fullWidth
      open={props.open}
      maxWidth='md'
      onClose={handleClose}
    >
      <StyledDialogTitle
        data-testid='label-title'
      >{props.title}</StyledDialogTitle>

      <StyledDialogContent dividers>
        <Typography
          data-testid='label-name'
          variant='h6'
        >Name: {props.data.name}</Typography>

        <Typography
          data-testid='label-description'
          gutterBottom
          variant='h6'
        >Description: {props.data.description}</Typography>

        <br/>

        <br/>

        <Grid container spacing={3}>
          {notes.map((note, index) => (
            <Grid item xs={12} key={index}>
              <Typography
                data-testid={`label-note-name-${index}`}
              >
                Feature: {note.name}
              </Typography>

              <Typography
                data-testid={`label-note-description-${index}`}
                gutterBottom
              >
                Description: {note.description}
              </Typography>

              <br/>
            </Grid>
          ))}
        </Grid>
      </StyledDialogContent>

      <DialogActions>
        <Button
          data-testid='button-ok'
          color='secondary'
          variant='contained'
          onClick={handleClose}
        >OK</Button>
      </DialogActions>
    </Dialog>
  )
}

ReleaseNoteDialog.propTypes = {
  'data-testid': PropTypes.string,
  open: PropTypes.bool,
  title: PropTypes.string,
  data: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    notes: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        description: PropTypes.string
      })
    )
  }),
  onClose: PropTypes.func
}

ReleaseNoteDialog.defaultProps = {
  'data-testid': '',
  open: false,
  title: '',
  data: {
    name: '',
    description: '',
    notes: []
  },
  onClose: () => {}
}

export default ReleaseNoteDialog
