import React from 'react'
import Comments from './Comments'
import AcknowledgeButton from './AcknowledgeButton'
import Typography from '@mui/material/Typography'
import { Accordion, AccordionSummary, AccordionDetails } from '../../../themes/AccordionTheme'
import { getColumnDefs, getRowData } from './FormatGridData'
import PropTypes from 'prop-types'
import GridSearchBox from '../../../components/form/GridSearchBox'
import Grid from '@mui/material/Grid'
import { AgGridReact } from 'ag-grid-react'
import { styled } from '@mui/material'
import 'ag-grid-enterprise'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'

const AccordionTitle = styled(Typography)(({
  fontWeight: 500,
  fontSize: '1.25rem'
}))

const AccordionWrapper = styled(Accordion)(({
  marginBottom: '10px',
  border: '1px solid #55645896 !important'
}))

const ClaimsTableWrapper = styled(AccordionDetails)(({
  display: 'block'
}))

const SearchBar = styled('div')(({
  width: '100%',
  padding: '10px 10px',
  backgroundColor: '#F8F8F8',
  borderTop: '1px #BABFC7 solid',
  borderLeft: '1px #BABFC7 solid',
  borderRight: '1px #BABFC7 solid'
}))

function SearchableGrid ({
  data,
  title,
  objectData,
  keyValue,
  access,
  gridHeight = 'calc(100vh - 400px)',
  pagination = false,
  paginationAutoPageSize = false,
  paginationPageSize = 100,
  projectId
}) {
  const gridStyle = React.useMemo(() => (
    {
      height: gridHeight,
      width: '100%'
    }), [gridHeight])

  objectData.key = keyValue
  objectData.title = title
  objectData.projectId = projectId

  const defaultColDef = React.useMemo(() => {
    return {
      flex: 1,
      minWidth: 150
    }
  }, [])

  const [rowData, setRowData] = React.useState([])
  const [columnDefs, setColumnDefs] = React.useState([])

  const [gridApi, setGridApi] = React.useState(null)

  const onGridReady = React.useCallback((params) => {
    setGridApi(params.api)
    if (data) {
      setRowData(getRowData(keyValue, data))
      setColumnDefs(getColumnDefs(keyValue))
      params.api.sizeColumnsToFit()
    }
  }, [data, keyValue])
  const onSearchTextChange = (e) => {
    gridApi.setQuickFilter(e.target.value)
  }

  return (<AccordionWrapper>
    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
      <Grid container>
        <Grid item xs={10}>
          <AccordionTitle>{title}</AccordionTitle>
        </Grid>
        <Grid item xs={2}>
          <AcknowledgeButton
            objectData={objectData}
            access={access}
          />
        </Grid>
      </Grid>
    </AccordionSummary>
    <ClaimsTableWrapper>
      <div>
        <SearchBar>
          <GridSearchBox
            data-testid='grid-search'
            onChange={onSearchTextChange}
          />
        </SearchBar>
      </div>
      <div>
        {data.length > 0
          ? (
          <div style={gridStyle} className="ag-theme-alpine">
            <AgGridReact
              data-testid='agGrid'
              rowData={rowData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              pagination={pagination}
              paginationAutoPageSize={paginationAutoPageSize}
              paginationPageSize={paginationPageSize}
              onGridReady={onGridReady}
            ></AgGridReact>
          </div>
            )
          : <div data-testid='noData'>  {`No ${title} Data`}</div> }
      </div>
      <Comments
        objectData={objectData}
        access={access}
      />
    </ClaimsTableWrapper>
  </AccordionWrapper>)
}

SearchableGrid.propTypes = {
  data: PropTypes.any,
  headers: PropTypes.any,
  title: PropTypes.any,
  objectData: PropTypes.any,
  keyValue: PropTypes.any,
  access: PropTypes.any,
  projectId: PropTypes.any,
  gridHeight: PropTypes.string,
  paginationPageSize: PropTypes.number,
  pagination: PropTypes.bool,
  paginationAutoPageSize: PropTypes.bool
}

export default SearchableGrid
