import PropTypes from 'prop-types'
import React from 'react'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import MuiDialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import TextField from '../../../components/controls/TextField'
import FILE_TYPE_DEFS from '../../../store/objects/data/fileTypes.json'
import useForm from '../../../hooks/useForm'
import { LockProvider } from '../../../hooks/useLock'
import { styled } from '@mui/material'
import { isRequired } from '@zensen/form-validators'

const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  display: 'grid',
  width: 400,
  gap: theme.spacing(1),
  gridTemplateColumns: '1fr'
}))

const FILE_TYPES = FILE_TYPE_DEFS.default.map(def => def.value)

const createModel = () => {
  return {
    guid: '',
    name: '',
    description: ''
  }
}

const createSelectors = () => {
  return {
    children: {
      name: [isRequired()]
    }
  }
}

export default function SaveQueryDialog ({
  type,
  model,
  onSave,
  onCancel
}) {
  const selectors = React.useMemo(() => createSelectors(), [])
  const form = useForm(model, selectors)

  const typeLabel = React.useMemo(
    () => FILE_TYPE_DEFS.default.find(def => def.value === type).label,
    [type]
  )

  const handleCancel = () => {
    onCancel()
  }

  const handleSave = () => {
    if (form.validate()) {
      const model = form.build()

      onSave(model)
    }
  }

  return (
    <LockProvider>
      <Dialog open>
        <DialogTitle
          data-testid='label-title'
        >{model.guid ? 'Edit' : 'New'} Query</DialogTitle>

        <DialogContent>
          <Typography
            data-testid='label-type'
          >Type: {typeLabel}</Typography>

          <TextField
            data-testid='textfield-name'
            name='name'
            label='Name'
            helperText='Required'
            value={form.state.name}
            errorText={form.errors.name}
            onChange={form.apply}
          />

          <TextField
            data-testid='textfield-description'
            name='description'
            label='Description'
            value={form.state.description}
            errorText={form.errors.description}
            onChange={form.apply}
          />
        </DialogContent>

        <DialogActions>
          <Button
            data-testid='button-cancel'
            color='primary'
            onClick={handleCancel}
          >Cancel</Button>

          <Button
            data-testid='button-save'
            color='primary'
            onClick={handleSave}
          >Save</Button>
        </DialogActions>
      </Dialog>
    </LockProvider>
  )
}

SaveQueryDialog.createModel = createModel
SaveQueryDialog.createSelectors = createSelectors

SaveQueryDialog.propTypes = {
  type: PropTypes.oneOf(FILE_TYPES).isRequired,
  model: PropTypes.shape({
    guid: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string
  }).isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
}
