import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import AutoComplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import FormControl from '@mui/material/FormControl'
import Tooltip from '@mui/material/Tooltip'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormLabel from '@mui/material/FormLabel'
import { Grid, styled } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import InfoIcon from '@mui/icons-material/Info'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import IconButton from '@mui/material/IconButton'
import Select from 'react-select'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import StopIcon from '@mui/icons-material/Stop'

import { useDispatch, useSelector } from 'react-redux'
import { SingleValue, SingleValueStyles } from '../../../components/sql/SelectSingleValue'
import { getNamesLdapQuery, applyAccess } from '../../../store/pages/project/async'
import { setAccessListAction } from '../../../store/pages/project/actions'

const StyledAutoComplete = styled(AutoComplete)`
  width: 300px;
`
const StyledButton = styled(Button)`
  margin-left: ${({ theme }) => theme.spacing(2)};
`

const ROLE_TOOLTIP = <div>
  <p>
    <b>Analyst</b><br/>
   -Full access </p>
  <p>
    <b>Peer Reviewer:</b><br/>
    -Read only access to upload to calc<br/>
    <b> -Write access to post-calc & review</b></p>
</div>

const ROLES = ['Analyst', 'Peer Reviewer'].map(role => ({
  value: role,
  label: role
}))

function ShareProjectDialog ({ open, onClose }) {
  const dispatch = useDispatch()

  const previousSharedWithIds = React.useRef(null)

  const accessList = useSelector((store) => store.project.sharedWithIds)
  const id = useSelector((store) => store.project.id)
  const ownerId = useSelector((store) => store.project.ownerId)

  if (!previousSharedWithIds.current) {
    previousSharedWithIds.current = structuredClone(accessList)
  }

  const [searchOptions, setSearchOptions] = React.useState([])
  const [openSearch, setOpenSearch] = React.useState(false)
  const [editIndex, setEditIndex] = React.useState(-1)
  const [currentUser, setCurrentUser] = React.useState(null)
  const [currentRole, setCurrentRole] = React.useState('Analyst')

  const isLoading = openSearch && searchOptions.length === 0

  const setAccessList = React.useCallback((payload) => {
    dispatch({ ...setAccessListAction, payload })
  }, [dispatch])

  const close = () => {
    setEditIndex(-1)
    setCurrentUser(null)
    setCurrentRole('Analyst')
    onClose()
  }

  const handleRoleRadioClick = event => {
    setCurrentRole(event.target.value)
  }

  const handleSearchChange = async event => {
    const newOps = await dispatch(getNamesLdapQuery(event.target.value))
    setSearchOptions(newOps)
  }

  const handleAddAccessClick = () => {
    setAccessList(accessList.concat({
      role: currentRole,
      id: currentUser.mail,
      name: currentUser.givenName + ' ' + currentUser.sn
    }))
  }

  const handleEditClick = event => {
    const index = Number(event.currentTarget.getAttribute('data-index'))

    if (index !== editIndex) {
      setEditIndex(index)
    } else {
      setEditIndex(-1)
    }
  }

  const handleDeleteClick = event => {
    const index = Number(event.currentTarget.getAttribute('data-index'))

    const modifiedAccessList = [...accessList]
    modifiedAccessList.splice(index, 1)
    setAccessList(modifiedAccessList)
  }

  const handleRowSelectChange = (value, event) => {
    const modifiedAccessList = [...accessList]

    const modifiedItem = {
      ...accessList[event.name],
      role: value.value
    }

    modifiedAccessList.splice(event.name, 1, modifiedItem)

    setAccessList(modifiedAccessList)
  }

  const handleCancel = (event, reason) => {
    if (reason === 'backdropClick') {
      return event
    }

    setAccessList(previousSharedWithIds.current)
    previousSharedWithIds.current = null
    close()
  }

  const handleSave = () => {
    previousSharedWithIds.current = accessList

    dispatch(applyAccess(accessList))
    close()
  }

  const disableAddButton = () => {
    const email = currentUser.mail.toLowerCase()
    const filteredAccessList = accessList.filter(item => item.id.toLowerCase() === email)
    const userisOwner = email === ownerId.toLowerCase()

    return !currentRole || filteredAccessList.length > 0 || userisOwner
  }

  return (
    <Dialog
      data
      fullWidth
      maxWidth={'lg'}
      disableEscapeKeyDown
      onClose={handleCancel}
      open={open}
    >
      <DialogContent>
        <Typography>
          <b>Project {id} - owner {ownerId}</b>
        </Typography>
        <br/>
        <Divider />
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography> Type first name to start user search </Typography>
            <StyledAutoComplete
              data-testid="ldap-search"
              open={openSearch}
              selectOnFocus
              options={searchOptions}
              loading={isLoading}
              onOpen={() => setOpenSearch(true)}
              onClose={() => setOpenSearch(false)}
              onChange={(event, value) => { setCurrentUser(value) }}
              isOptionEqualToValue={(option, value) => option.givenName === value.name}
              getOptionLabel={(option) => option.givenName + ' ' + option.sn}
              renderInput={(params) => (
                <TextField
                  data-testid ='textfield-options'
                  {...params}
                  onChange={handleSearchChange}
                  variant='standard'
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {isLoading
                          ? <CircularProgress
                              color="inherit"
                              size={20}
                            />
                          : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    )
                  }}
                />
              )}
            />
            <br/>
            <FormControl>
              <FormLabel>* Role
                <Tooltip
                  title={ROLE_TOOLTIP}
                >
                  <InfoIcon/>
                </Tooltip></FormLabel>
              <RadioGroup
                value={currentRole}>
                  <FormControlLabel
                    value="Analyst"
                    label="Analyst"
                    control={
                      <Radio
                        data-testid='radio-analyst'
                        onChange={handleRoleRadioClick}
                        color="default"
                      />
                    }
                  />
                  <FormControlLabel
                    value="Peer Reviewer"
                    label="Peer Reviewer"
                    control={
                      <Radio
                        data-testid='radio-peer-reviewer'
                        onChange={handleRoleRadioClick}
                        color="default"
                      />
                    }
                  />
              </RadioGroup>
            </FormControl>
            <br/>
            {currentUser &&
            <Tooltip
              data-testid = 'tooltip-currentUser'
              title={(accessList.find(user => user.id === currentUser.mail) || currentUser.mail === ownerId)
                ? `${currentUser.name} is already added or Owner of the project`
                : `Click to add ${currentUser.name}`}

            >
              <div>
                <Button
                  data-testid='button-addAccess'
                  variant="contained"
                  color="secondary"
                  disabled={disableAddButton()}
                  onClick={handleAddAccessClick}
                >
              ADD Access
                </Button>
              </div>
            </Tooltip>
            }
            <br/>
          </Grid>
          <Grid item xs={8}>
            <Typography> Current Access  </Typography>
            <Table size="small" aria-label="tableAccess">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell>Edit</TableCell>
                  <TableCell>Remove</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {accessList.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">{row.id}</TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>
                      { index === editIndex
                        ? (
                        <Select
                          data-testid={`select-role-${index}`}
                          name={index}
                          placeholder=""
                          styles={SingleValueStyles}
                          components={{ SingleValue }}
                          options={ROLES}
                          onChange={handleRowSelectChange}
                          value={ROLES.find(role => role.value === row.role)}
                        />)
                        : <div
                            data-testid={`row-role-${index}`}
                          >
                            {row.role}
                          </div>
                      }
                    </TableCell>
                    <TableCell>
                      <IconButton
                        data-testid={`button-edit-${index}`}
                        data-index={index}
                        onClick={handleEditClick}
                        size="large">
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <IconButton
                        data-testid={`button-delete-${index}`}
                        data-index={index}
                        onClick={handleDeleteClick}
                        size="large">
                        <DeleteIcon/>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
        <Divider />
        <DialogActions>
          <div>
            <StyledButton
              data-testid='button-save'
              onClick={handleSave}
              variant='outlined'
              color="primary"
            ><PlayArrowIcon />
              SAVE
            </StyledButton>
            <StyledButton
              data-testid='button-cancel'
              color="secondary"
              variant="contained"
              onClick={handleCancel}
            >  <StopIcon />
              CANCEL

            </StyledButton>
          </div>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
ShareProjectDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool
}

export default React.memo(ShareProjectDialog)
