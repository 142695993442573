import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import Table from './Table'

function capitalize (str) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

function getStatus (job) {
  if (job.errorMessage === 'Aborted') {
    return 'Aborted'
  } else {
    return job.finished ? 'Completed' : 'Running'
  }
}

function splitKey (key) {
  return capitalize(key).match(/[A-Z][a-z]+/g);
}

function objToValues (obj) {
  return Object.entries(obj)
    .map(([k, v]) => [splitKey(k).join(' '), v !== null ? v : '-'])
    .map(([k, v]) => `${k}: ${v}`)
}

export default function StatsTable (props) {
  const items = React.useMemo(() => [
    ...objToValues({
      status: getStatus(props.job),
      runDuration: props.job.totalTime,
      fileProcessingTime: props.job.fileProcessingTime,
      calculatedRunTime: props.job.xpCalculatorRunTime,
      dataLakeLoadTime: props.job.dataLakeLoadTime
    }),
    <strong key='data-size'>Data Size</strong>,
    ...objToValues({
      valPolicy: props.job.valPolicySize,
      valTerminated: props.job.valTerminateSize,
      riskHistory: props.job.riskHistorySize,
      treaty: props.job.treatySize
    })
  ], [props.job])

  return <Table
    title='Run Information'
    items={items}
  />
}

StatsTable.propTypes = {
  job: PropTypes.shape({
    finished: PropTypes.bool,
    totalTime: PropTypes.number,
    fileProcessingTime: PropTypes.number,
    xpCalculatorRunTime: PropTypes.number,
    dataLakeLoadTime: PropTypes.number,
    valPolicySize: PropTypes.number,
    valTerminateSize: PropTypes.number,
    riskHistorySize: PropTypes.number,
    treatySize: PropTypes.number,
    deletedDate: PropTypes.instanceOf(moment)
  })
}

StatsTable.defaultProps = {
  job: {
    finished: false,
    totalTime: 0,
    fileProcessingTime: 0,
    xpCalculatorRunTime: 0,
    dataLakeLoadTime: 0,
    valPolicySize: 0,
    valTerminateSize: 0,
    riskHistorySize: 0,
    treatySize: 0,
    deletedDate: null
  }
}
