import React from 'react'
import BaseSelect from 'react-select'
import BaseCreatableSelect from 'react-select/creatable'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import { styled } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { removedOptionSetAction } from '../../../store/pages/common/update'
import { updateOptionSet } from '../../../store/pages/common/formulaDialog'

import {
  SingleValue,
  SingleValueStyles,
  AppendColumnBuilder
} from '../../../components/sql/SelectSingleValue'

const Select = styled(BaseSelect)(({ theme }) => ({
  minHeight: 58,
  margin: theme.spacing(1)
}))

const CreatableSelect = styled(BaseCreatableSelect)(({ theme }) => ({
  minHeight: 58,
  margin: theme.spacing(1)
}))

const Equal = styled(Grid)({
  fontSize: 40
})

const SetRow = styled(Grid)({
  width: '95%'
})

const DeleteButton = styled(IconButton)({
  marginRight: 75,
  marginTop: 15
})

export default function SetList () {
  const dispatch = useDispatch()

  const navigation = useSelector(store => store.navigation)
  const update = useSelector(store => store.update)

  const { columns } = navigation.file

  const columnOptions = React.useMemo(() => columns.map(column => ({
    value: column.Name,
    label: column.Name
  })), [columns])

  const allColumnOptions = React.useMemo(() =>
    AppendColumnBuilder(columns).map(column => ({
      value: column.Name,
      label: column.Name
    })),
  [columns])

  return (
    <div>
      {update.updateSetConditions.map((item, index) => (
        <SetRow container key={index}>
          <Grid item xs={3}>
            <Select
              placeholder=''
              name={`column-${index}!`}
              styles={SingleValueStyles}
              value={item.columnName}
              queryIndex={index}
              queryTarget='updateSetConditions'
              queryKey='columnName'
              components={{ SingleValue }}
              options={columnOptions}
              onChange={value => dispatch(updateOptionSet('columnName', index, value))}
            />
          </Grid>

          <Equal item xs={2}>
            {item.operation}
          </Equal>

          <Grid item xs={3}>
            <CreatableSelect
              placeholder=""
              name={`where-${index}!`}
              styles={SingleValueStyles}
              value={item.whereValue}
              queryIndex={index}
              queryTarget='updateSetConditions'
              queryKey='whereValue'
              components={{ SingleValue }}
              options={allColumnOptions}
              onChange={value => dispatch(updateOptionSet('whereValue', index, value))}
            />
          </Grid>

          <Grid item xs={1}>
            <DeleteButton
              size="large"
              onClick={() => dispatch({ ...removedOptionSetAction, payload: index })}
            >
              <DeleteIcon />
            </DeleteButton>
          </Grid>

          <Grid item xs={3}></Grid>
        </SetRow>
      ))}
    </div>
  )
}
