import React from 'react'
import { DatePicker } from '@mui/x-date-pickers'
import TextField from '@mui/material/TextField'
import Select from 'react-select'
import moment from 'moment'
import { updateColumnMapping } from '../../store/pages/mapping'
import { SingleValue, SingleValueStyles } from '../../components/sql/SelectSingleValue'

const getValueMappingObject = (targetDataType, newM, validOption, relevantData, target, originalValue, access, dispatch) => {
  const max = relevantData.max
  switch (targetDataType) {
    case 'OPTIONS':
      return <div key={`opt-col-${target}-${newM}`}>
        <Select
          key={`opt-select-${target}-${newM}`}
          placeholder=" "
          isDisabled={!access}
          name={`opt-col-${target}-${newM}`}
          value = {validOption.find(e => e.value === newM) ? validOption.find(e => e.value === newM) : null}
          isClearable
          menuPortalTarget={document.querySelector('body')}
          menuPlacement='auto'
          options={validOption}
          styles={SingleValueStyles}
          components={{ SingleValue }}
          onChange={(newValue) => {
            dispatch(updateColumnMapping(relevantData, target, newValue, originalValue))
          }} />
      </div>
    case 'NUMBER':
    case 'INT':
    case 'DECIMAL':
      return <TextField
        disabled={!access}
        variant='standard'
        value = { newM || ''}
        id="standard-number"
        label="Number"
        type="number"
        onChange={(e) => {
          dispatch(updateColumnMapping(relevantData, target, e.target.value, originalValue))
        }}
        InputLabelProps={{
          shrink: true
        }}
      />
    case 'VARCHAR':
      return <TextField
        disabled={!access}
        label="Varchar"
        value={newM || ''}
        onChange={(e) => {
          dispatch(updateColumnMapping(relevantData, target, e.target.value, originalValue))
        }}
        InputLabelProps={{
          shrink: true
        }}
      />
    case 'DATE':
      return (
      <DatePicker
        variant="outlined"
        disabled={!access}
        format="MM/dd/yyyy"
        value={new Date(newM)}
        KeyboardButtonProps={{ 'aria-label': 'change date' }}
        onChange={(newValue) => {
          const format = (max && moment(max).isValid()) ? moment(max).creationData().format + 'THH:mm:ss.SSSSZ' : 'YYYY-MM-DDTHH:mm:ss.SSSSZ'
          const formatDate = moment(newValue).isValid() ? moment(newValue).format(format) : ''
          dispatch(updateColumnMapping(relevantData, target, formatDate, originalValue))
        }}
      />
      )
    default:
      return <div> {targetDataType} </div>
  }
}

export { getValueMappingObject }
