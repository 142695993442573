import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Select from 'react-select'
import CircularProgress from '@mui/material/CircularProgress'
import Button from '@mui/material/Button'
import MuiPaper from '@mui/material/Paper'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import { styled } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { SingleValue, SingleValueStyles } from '../../../components/sql/SelectSingleValue'
import { loadReferenceTable, setRefreshAction } from '../../../store/pages/reference'

import {
  clearedMessageAction,
  handledFieldSelectAction,
  convertField
} from '../../../store/pages/common/convert'

const OPTIONS_DATA_TYPES = [
  'string',
  'date',
  'number'
].map(type => ({
  value: type,
  label: type
}))

const Paper = styled(MuiPaper)`
   width: 100%;
   background-color: #DEDEDE;
   padding: ${({ theme }) => theme.spacing(1)};
`

function Convert () {
  const dispatch = useDispatch()

  const convert = useSelector((state) => state.convert)
  const reference = useSelector((state) => state.reference)

  const columnOptions = reference.referenceSourceInfo.columns.map(column => ({
    label: `${column.Name} (${column.Type})`,
    value: column.Name
  }))

  const handleSaveButtonClicked = () => {
    dispatch({
      ...clearedMessageAction,
      payload: { message: '' }
    })

    dispatch(convertField(
      reference.referenceSourceInfo.projectId,
      reference.referenceSourceInfo.guid,
      true
    ))

    dispatch({
      ...setRefreshAction,
      payload: true
    })
  }

  const handleChange = (value, event) => {
    dispatch({
      ...handledFieldSelectAction,
      payload: {
        key: event.name,
        value
      }
    })
  }

  if (reference.refresh && convert.message === 'Success') {
    dispatch(loadReferenceTable(reference.referenceTable.id, 'convert'))

    dispatch({
      ...setRefreshAction,
      payload: false
    })
  }

  return (
    <Grid container>
      <Paper elevation={1}>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <Typography variant="h6">Column</Typography>

            <Select
              data-testid='select-field'
              name='field'
              placeholder=''
              styles={SingleValueStyles}
              components={{ SingleValue }}
              options={columnOptions}
              value={convert.fieldsToConvert.field}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={3}>
            <Typography variant="h6">To</Typography>

            <Select
              data-testid='select-dataType'
              name='dataType'
              placeholder=''
              styles={SingleValueStyles}
              components={{ SingleValue }}
              options={OPTIONS_DATA_TYPES}
              value={convert.fieldsToConvert.dataType}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={6}/>

          <Grid item xs={12}>
            {convert.isSaving
              ? <CircularProgress />
              : <Button
                data-testid='button-save'
                variant="outlined"
                color="primary"
                startIcon={<PlayArrowIcon />}
                onClick={handleSaveButtonClicked}
              >Execute</Button>
            }
          </Grid>
        </Grid>
      </Paper>

      <Grid
        data-testid='label-message'
        item xs={12}
      >{convert.message}</Grid>
    </Grid>
  )
}

export default React.memo(Convert)
