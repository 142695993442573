import React from 'react'
import PropTypes from 'prop-types'
import CreatableSelect from 'react-select/creatable'
import Select from 'react-select'
import Icon from '@mui/material/Icon'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import SaveIcon from '@mui/icons-material/Save'
import FormulaDialog from '../../../components/sql/FormulaDialog'
import SaveQueryDialogLegacy from '../components/SaveQueryDialogLegacy'
import Navigation from '../components/navigation'
import useClearHook from './useClearHook'
import ResultsGrid from './resultsGrid'
import LoadingDialog from './loadingDialog'
import { styled } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'

import {
  actions as formulaDialogActions
} from '../../../store/pages/common/formulaDialog'

import {
  SingleValue,
  SingleValueStyles
} from '../../../components/sql/SelectSingleValue'

import {
  changedNewColumn,
  executeNewColumn,
  toggleSaveQueryDialogAction
} from '../../../store/pages/dataPrep'

const COMPONENTS = {
  SingleValue
}

const COLUMNS = [
  {
    type: 'formula',
    value: 'USE FORMULA BUILDER',
    label: 'USE FORMULA BUILDER'
  }
]

const Form = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  width: '100%',
  backgroundColor: '#DEDEDE'
}))

const ButtonBar = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2)
}))

export default function NewColumn () {
  useClearHook()

  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(false)
  const { drawerOpen } = useSelector(store => store.global)
  const navigation = useSelector(store => store.navigation)
  const project = useSelector(store => store.project)
  const dataPrep = useSelector(store => store.dataPrep)
  const formulaDialog = useSelector(store => store.formulaDialog)

  const disableRun = !dataPrep.newColumn.name || !dataPrep.newColumn.formula
  const tableNameRedshift = `red_${project.id}_upload.${navigation.file.guid}`

  const saveLabel =
    dataPrep.saveQueryDialog.guid !== undefined &&
    dataPrep.saveQueryDialog.queryType === 'newColumn'
      ? 'UPDATE'
      : 'SAVE'

  const tooltip = disableRun
    ? 'Please fill the required fields.'
    : 'Valid Query'

  const columnNames = React.useMemo(() =>
    navigation.file.columns.map(column => column.Name),
  [navigation.file.columns])

  const handleLoadingDialogClose = () => {
    setOpen(false)
  }

  const handleNameChange = (value, event) => {
    if (value) {
      value.value = value.value.replace(/\s/g, '_')
    }

    dispatch(changedNewColumn(event.name, value))
  }

  const handleFormulaChange = (value, event) => {
    if (value) {
      dispatch(formulaDialogActions.open())
    } else {
      dispatch(changedNewColumn(event.name, null))
    }
  }

  const handleFormulaSave = result => {
    dispatch(changedNewColumn('formula', {
      type: 'formula',
      label: result.formula,
      value: result.formula
    }))

    dispatch(formulaDialogActions.close())
  }

  const handleFormulaCancel = () => {
    dispatch(formulaDialogActions.close())
  }

  const handleExecuteClick = () => {
    dispatch(executeNewColumn(project.id, navigation.file.guid))
    setOpen(true)
  }

  const handleSaveClick = () => {
    dispatch(toggleSaveQueryDialogAction)
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Navigation/>
      </Grid>

      <LoadingDialog
        data-testid='dialog-loading'
        id={project.id}
        open={open}
        onClose={handleLoadingDialogClose}
      />

      <Dialog
        data-testid='dialog-formula-outer'
        fullWidth
        open={formulaDialog.open}
        maxWidth='md'
      >
        <FormulaDialog
          data-testid='dialog-formula'
          forbidAggregators
          tableName={tableNameRedshift}
          resource={project}
          columnNames={columnNames}
          onSave={handleFormulaSave}
          onCancel={handleFormulaCancel}
        />
      </Dialog>

      <Dialog
        data-testid='dialog-saveQuery-outer'
        open={dataPrep.saveQueryDialog.open}
        maxWidth='md'
      >
        <SaveQueryDialogLegacy />
      </Dialog>

      <Form elevation={1}>
        <Grid container alignItems='flex-start' spacing={3}>
          <br />

          <Grid item xs={5}>
            <Typography variant='h6'>
              <Icon color='primary'>*</Icon>

              New Column Name
            </Typography>

            <br />

            <CreatableSelect
              data-testid='select-name'
              styles={SingleValueStyles}
              isClearable
              name='name'
              components={COMPONENTS}
              value={dataPrep.newColumn.name}
              onChange={handleNameChange}
            />
          </Grid>

          <br/>

          <Grid item xs={5}>
            <Typography variant='h6'>
              <Icon color='primary'>*</Icon>

              Field
            </Typography>

            <br/>

            <Select
              data-testid='select-field'
              styles={SingleValueStyles}
              name='formula'
              isClearable
              isSearchable={false}
              components={COMPONENTS}
              options={COLUMNS}
              value={dataPrep.newColumn.formula}
              onChange={handleFormulaChange}
            />
          </Grid>

          <Grid item xs={12}>
            {!dataPrep.newColumn.isLoading && (
              <ButtonBar>
                <Tooltip
                  data-testid='tooltip'
                  title={tooltip}
                >
                  <span>
                    <Button
                      data-testid='button-execute'
                      disabled={disableRun}
                      color='primary'
                      variant='outlined'
                      startIcon={<PlayArrowIcon />}
                      onClick={handleExecuteClick}
                    >Execute</Button>
                  </span>
                </Tooltip>

                <Button
                  data-testid='button-save'
                  variant='contained'
                  color='secondary'
                  startIcon={<SaveIcon />}
                  onClick={handleSaveClick}
                >{saveLabel}</Button>
              </ButtonBar>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography
            data-testid='label-message'
            variant='h6'
          >{dataPrep.newColumn.message}</Typography>
        </Grid>
      </Form>

      <Grid item xs={12}>
        {!dataPrep.newColumn.message && (
          <ResultsGrid
            data-testid='results-grid'
            drawerOpen={drawerOpen}
            resultsGrid={dataPrep.newColumn.resultsGrid}
          />
        )}
      </Grid>
    </Grid>
  )
}

NewColumn.propTypes = {
  'data-testid': PropTypes.string,
  formula: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string
  })
}
