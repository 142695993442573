import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAdminData } from '../../../store/pages/adminTool'
import { loadReferenceData, loadSegmentReceiver, loadBasis } from '../../../store/pages/refData'

export default () => {
  const dispatch = useDispatch()
  const ownerId = useSelector((store) => store.user.ownerId)
  const load = useSelector((store) => store.adminTool.load)

  React.useEffect(() => {
    if (ownerId) {
      if (!load) {
        dispatch(getAdminData)
      }
      dispatch(loadBasis)
      dispatch(loadSegmentReceiver)
      dispatch(loadReferenceData)
    }
  }, [ownerId, load, dispatch])
}
