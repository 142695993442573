import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Grid from '@mui/material/Grid'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import AddBoxIcon from '@mui/icons-material/AddBox'
import { Tooltip, styled } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'

import { tableRowBackGroundColorSelect } from '../../components/table/table.utils'
import SelectedObject from './selectedObject'
import AdminDialog from './adminDialog'
import { removeAdminData, setDialogDataAction } from '../../store/pages/adminTool'

const WrapperDiv = styled('div')({
  maxHeight: 700,
  overflow: 'auto'
})

const StyledTableRow = styled(TableRow)({
  background: 'white',
  display: 'inherit',
  maxHeight: 50
})

const SmallTableCell = styled(TableCell)({
  width: '100px'
})
const MediumTableCell = styled(TableCell)({
  width: '200px'
})
const LargeTableCell = styled(TableCell)({
  width: '400px'
})

const StyledGrid = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(1.5)
}))

const ReleaseNotes = () => {
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [selectedRow, setSelectedRow] = React.useState(null)
  const [title, setTitle] = React.useState(null)
  const dispatch = useDispatch()
  const { dialogData, releaseData, ownerId, role } = useSelector(({
    adminTool: { dialogData, releaseData },
    user: { ownerId, role }
  }) => ({
    dialogData, releaseData, ownerId, role
  }))
  const admin = role === 'admin'

  const newObject = {
    visible: true,
    startDate: null,
    endDate: null,
    userId: ownerId,
    type: 'release',
    name: null,
    description: null,
    version: null,
    notes: [{
      name: null,
      description: null
    }]
  }

  return admin && (
    <Grid container>
      <StyledGrid item xs={5}>
        <WrapperDiv>
          <Table stickyHeader aria-label='sticky table' size='small'>
            <TableHead>
              <StyledTableRow>
                <MediumTableCell>Version</MediumTableCell>
                <MediumTableCell>Name</MediumTableCell>
                <MediumTableCell>Description</MediumTableCell>
                <SmallTableCell></SmallTableCell>
                <SmallTableCell>
                  <Tooltip title={'Add new release'}>
                    <AddBoxIcon onClick={() => {
                      dispatch({ ...setDialogDataAction, payload: newObject })
                      setTitle('Add New Release Note')
                      setDialogOpen(true)
                    }} /></Tooltip></SmallTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {
                releaseData && releaseData
                  .map((row, idx) => {
                    return (
                      <StyledTableRow key={idx}
                        style={tableRowBackGroundColorSelect(idx, selectedRow)} onClick={() => {
                          setSelectedRow(idx)
                          dispatch({ ...setDialogDataAction, payload: row })
                        }}>
                        <MediumTableCell>{row.version}</MediumTableCell>
                        <MediumTableCell>{row.name}</MediumTableCell>
                        <LargeTableCell> {row.description}</LargeTableCell>
                        <SmallTableCell>
                          <EditIcon onClick={() => {
                            setDialogOpen(true)
                            setTitle('Edit Release Note')
                            dispatch({ ...setDialogDataAction, payload: row })
                          }} />
                        </SmallTableCell>
                        <SmallTableCell>
                          <DeleteIcon onClick={ () => dispatch(removeAdminData(row))} /></SmallTableCell>
                      </StyledTableRow>
                    )
                  })

              }
            </TableBody>
          </Table>
        </WrapperDiv>
        {dialogData && <AdminDialog
          type={dialogData.type}
          open={dialogOpen}
          title={title}
          data={dialogData}
          setDialogOpen={setDialogOpen} />}
      </StyledGrid>
      <StyledGrid item xs={7}>
        {dialogData && dialogData.guid && <SelectedObject data={dialogData} />}
      </StyledGrid>
    </Grid>
  )
}

export default ReleaseNotes
