import PropTypes from 'prop-types'
import React from 'react'
import Typography from '@mui/material/Typography'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import MuiAccordion from '@mui/material/Accordion'
import CircularProgress from '@mui/material/CircularProgress'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CancelIcon from '@mui/icons-material/Cancel'
import { styled } from '@mui/material'

const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, .03)',
  '.MuiAccordionSummary-content': {
    display: 'grid',
    alignItems: 'center',
    gap: theme.spacing(2),
    gridTemplateColumns: 'auto 50% auto 1fr'
  }
}))

const AccordionDetails = styled(MuiAccordionDetails)({
  borderTop: '1px solid rgba(0, 0, 0, .125)'
})

const AccordionTitle = styled(Typography)(({ theme }) => ({
  display: 'flex',
  fontWeight: 500,
  fontSize: 16,
  gap: theme.spacing(2)
}))

const StatusContainer = styled('div')({
  width: '24px',
  height: '24px'
})

export default function Accordion (props) {
  const handleChange = React.useCallback(event => {
    event.stopPropagation()
    props.onExpandToggle(props.name)
  }, [props])

  const handleChartTypeToggle = React.useCallback(event => {
    event.stopPropagation()
    props.onChartToggle(props.name, event.target.checked)
  }, [props])

  return (
    <MuiAccordion
      square
      expanded={props.name === props.selectedName}
      elevation={0}
      onChange={handleChange}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
      >
        <StatusContainer>
          {props.isLoading && !props.hasError && (
            <CircularProgress size={20} />
          )}

          {props.hasError && <CancelIcon />}
        </StatusContainer>

        <AccordionTitle>{props.title}</AccordionTitle>

        {props.chartToggle !== null
          ? (
          <FormControlLabel
            label='Tables/Graphs'
            control={<Switch
              name={props.name}
              value={props.chartToggle}
              onClick={event => event.stopPropagation()}
              onChange={handleChartTypeToggle}
            />}
          />
            )
          : <div></div>}

        <div></div>
      </AccordionSummary>

      <AccordionDetails>
        {props.children}
      </AccordionDetails>
    </MuiAccordion>
  )
}

Accordion.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
  chartToggle: PropTypes.bool,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]).isRequired,
  name: PropTypes.string.isRequired,
  selectedName: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onExpandToggle: PropTypes.func.isRequired,
  onChartToggle: PropTypes.func.isRequired
}

Accordion.defaultProps = {
  chartToggle: null
}
