const defaultNumberSql = (sourceColumn, projectId, guid, totalCount, pageNo) => {
  return {
    paging: {
      pageNo,
      pageSize: 100
    },
    id: projectId,
    tableName: `red_${projectId}_upload.${guid}`,
    isDataProfile: true,
    querySelectFields: [
      { value: `${sourceColumn}`, label: '' },
      { value: 'count(*)', label: 'count' },
      { value: `round(count(*)/${totalCount}*100,2)`, label: 'Total_percentage' },
      { value: `sum(${sourceColumn})`, label: 'sum' }
    ],
    isPrecalculation: false,
    queryWhereConditions: [],
    orderByConditions: [
      { value: 'count', label: '' }
    ]
  }
}

const defaultStringSQL = (sourceColumn, projectId, guid, totalCount, pageNo) => {
  return {
    paging: {
      pageNo,
      pageSize: 100
    },
    isDataProfile: true,
    id: projectId,
    tableName: `(SELECT ${sourceColumn},listagg(${sourceColumn})AS bucket_no, count(*) CNT FROM red_${projectId}_upload.${guid} GROUP BY ${sourceColumn} ) RESULTS `,
    isPrecalculation: true,
    querySelectFields: [
      { value: `${sourceColumn}`, label: '' },
      { value: 'sum(cnt)', label: 'count' },
      { value: `round(sum(cnt)/${totalCount}*100,2)`, label: 'Total_percentage' }
    ],
    queryWhereConditions: [],
    orderByConditions: [
      { value: 'count', label: '' }
    ]
  }
}

const buildDefaultDateSQL = (sourceColumn, projectId, guid, totalCount, pageNo) => {
  return {
    paging: {
      pageNo,
      pageSize: 100
    },
    id: projectId,
    isDataProfile: true,
    tableName: `red_${projectId}_upload.${guid}`,
    isPrecalculation: false,
    querySelectFields: [
      { value: `to_char(${sourceColumn}::date,'YYYY-MM')`, label: 'date' },
      { value: 'count(*)', label: 'count' },
      { value: `round(count(*)/${totalCount}*100,2)`, label: 'Total_Percentage' }
    ],
    queryWhereConditions: [],
    orderByConditions: [
      { value: 'date', label: '' }
    ]
  }
}

const typeSQL = {
  date: buildDefaultDateSQL,
  varchar: defaultStringSQL,
  string: defaultStringSQL,
  'character varying': defaultStringSQL,
  int: defaultNumberSql,
  integer: defaultNumberSql,
  'double precision': defaultNumberSql,
  double: defaultNumberSql,
  decimal: defaultNumberSql,
  bigint: defaultNumberSql,
  numeric: defaultNumberSql
}

const buildSQL = (sourceColumn, type, projectId, guid, totalCount, pageNo) =>
  typeSQL[type.toLowerCase()](sourceColumn, projectId, guid, totalCount, pageNo)

export { buildSQL }
