import PropTypes from 'prop-types'
import React from 'react'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import { styled } from '@mui/material'
import { SingleValue } from '../../../components/sql/SelectSingleValue'
import { OPTION_EMPTY, OPERATIONS_OPTIONS } from '../../../stringConstants'

const COMPONENTS_SINGLE = {
  SingleValue
}

const MenuOption = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.string
})

const ConditionValue = PropTypes.shape({
  operation: MenuOption,
  columnName: MenuOption,
  whereValue: MenuOption
})

const FilterValue = PropTypes.arrayOf(ConditionValue)

const Root = styled(Box)(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(4),
  gridTemplateColumns: '1fr'
}))

const GroupsContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(4),
  gridTemplateColumns: '1fr auto',
  alignItems: 'start'
}))

const Group = styled(Box)(({ theme }) => ({
  display: 'grid',
  padding: theme.spacing(4),
  gap: theme.spacing(4),
  gridTemplateColumns: '1fr',
  alignItems: 'center',
  border: '1px solid #737373',
  borderRadius: '3px'
}))

const Row = styled(Box)(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(4),
  gridTemplateColumns: '1fr 128px 1fr auto',
  alignItems: 'center'
}))

const OrSeparator = styled(Typography)(() => ({
  fontSize: '18px',
  fontWeight: 600,
  alignItems: 'center',
  gridColumn: '-1 / 1'
}))

function Condition (props) {
  const value = {
    columnName: props.value.columnName.value ? props.value.columnName : null,
    operation: props.value.operation.value ? props.value.operation : null,
    whereValue: props.value.whereValue.value ? props.value.whereValue : null
  }

  const handleSelectEdit = (value, event) => {
    const namespace = [props.name, event.name].join('.')

    props.onEdit(namespace, value)
  }

  const handleSelectChanged = (value, event) => {
    const namespace = [props.name, event.name].join('.')

    props.onChange(namespace, value || OPTION_EMPTY)
  }

  const handleRemoveClick = () => {
    const index = Number(props.name)

    props.onRemove(index)
  }

  return (
    <Row data-testid={props['data-testid']}>
      <CreatableSelect
        data-testid={`select-columnName-${props.outerIndex}-${props.name}`}
        isClearable
        openMenuOnClick={!value.columnName}
        name='columnName'
        components={COMPONENTS_SINGLE}
        options={props.columnOptions}
        value={value.columnName}
        onEdit={handleSelectEdit}
        onChange={handleSelectChanged}
      />

      <Select
        data-testid={`select-operation-${props.outerIndex}-${props.name}`}
        isClearable
        name='operation'
        options={OPERATIONS_OPTIONS}
        value={value.operation}
        onChange={handleSelectChanged}
      />

      <CreatableSelect
        data-testid={`select-whereValue-${props.outerIndex}-${props.name}`}
        isClearable
        openMenuOnClick={!value.whereValue}
        name='whereValue'
        components={COMPONENTS_SINGLE}
        options={props.columnOptions}
        value={value.whereValue}
        onEdit={handleSelectEdit}
        onChange={handleSelectChanged}
      />

      <IconButton
        data-testid={`button-remove-condition-${props.outerIndex}-${props.name}`}
        disabled={props.disableRemoveButton}
        size='small'
        onClick={handleRemoveClick}
      >
        <DeleteIcon />
      </IconButton>

      {props.showSeparator && <OrSeparator>OR</OrSeparator>}
    </Row>
  )
}

Condition.propTypes = {
  'data-testid': PropTypes.string,
  name: PropTypes.string,
  disableRemoveButton: PropTypes.bool,
  showSeparator: PropTypes.bool,
  outerIndex: PropTypes.number,
  columnOptions: PropTypes.arrayOf(MenuOption),
  value: ConditionValue,
  onRemove: PropTypes.func,
  onChange: PropTypes.func,
  onEdit: PropTypes.func
}

function Filter (props) {
  const handleRemoveFilterClick = () => {
    const index = Number(props.name)

    props.onRemoveFilter(index)
  }

  const handleAddConditionClick = () => {
    props.onAddCondition(props.name)
  }

  const handleRemoveCondition = index => {
    props.onRemoveCondition(props.name, index)
  }

  const handleEdit = (name, value) => {
    const namespace = [props.name, name].join('.')

    props.onEdit(namespace, value)
  }

  const handleChange = (name, value) => {
    const namespace = [props.name, name].join('.')

    props.onChange(namespace, value)
  }

  return (
    <GroupsContainer data-testid={props['data-testid']}>
      <Group>
        {props.value.map((item, index) => (
          <Condition
            data-testid={`condition-${props.name}-${index}`}
            key={index}
            name={`${index}`}
            outerIndex={Number(props.name)}
            disableRemoveButton={props.value.length === 1}
            showSeparator={index < props.value.length - 1}
            columnOptions={props.columnOptions}
            value={item}
            onEdit={handleEdit}
            onChange={handleChange}
            onRemove={handleRemoveCondition}
          />
        ))}

        <Box>
          <Button
            data-testid={`button-add-condition-${props.name}`}
            data-index={props.name}
            color='primary'
            size='medium'
            startIcon={<AddIcon />}
            onClick={handleAddConditionClick}
          >Add Condition</Button>
        </Box>
      </Group>

      <IconButton
        data-testid={`button-remove-filter-${props.name}`}
        size='small'
        onClick={handleRemoveFilterClick}
      ><CloseIcon /></IconButton>
    </GroupsContainer>
  )
}

Filter.propTypes = {
  'data-testid': PropTypes.string,
  name: PropTypes.string,
  columnOptions: PropTypes.arrayOf(MenuOption),
  value: FilterValue,
  onRemoveFilter: PropTypes.func,
  onAddCondition: PropTypes.func,
  onRemoveCondition: PropTypes.func,
  onChange: PropTypes.func,
  onEdit: PropTypes.func
}

export default function Filters (props) {
  const buildNamespace = name => {
    return [props.name, name].filter(item => item).join('.')
  }

  const handleAddFilterClick = () => {
    props.onAddFilter(props.name)
  }

  const handleRemoveFilter = index => {
    props.onRemoveFilter(props.name, index)
  }

  const handleAddCondition = name => {
    const namespace = buildNamespace(name)

    props.onAddCondition(namespace)
  }

  const handleRemoveCondition = (name, index) => {
    const namespace = buildNamespace(name)

    props.onRemoveCondition(namespace, index)
  }

  const handleEdit = (name, value) => {
    const namespace = buildNamespace(name)

    props.onEdit(namespace, value)
  }

  const handleChange = (name, value) => {
    const namespace = buildNamespace(name)

    props.onChange(namespace, value)
  }

  return (
    <Root data-testid={props['data-testid']}>
      <Typography variant='h6'>Filters</Typography>

      {props.value.length > 0
        ? props.value.map((group, index) => (
          <GroupsContainer key={index}>
            <Filter
              data-testid={`filter-${index}`}
              name={`${index}`}
              value={group}
              columnOptions={props.columnOptions}
              onRemoveFilter={handleRemoveFilter}
              onAddCondition={handleAddCondition}
              onRemoveCondition={handleRemoveCondition}
              onEdit={handleEdit}
              onChange={handleChange}
            />
          </GroupsContainer>
        ))
        : <Box
          data-testid='label-empty'
          sx={{ fontStyle: 'italic' }}
        >No filters present</Box>
      }

      <Box>
        <Button
          data-testid='button-add-filter'
          color='primary'
          startIcon={<AddIcon />}
          onClick={handleAddFilterClick}
        >Add Filter</Button>
      </Box>
    </Root>
  )
}

Filters.propTypes = {
  'data-testid': PropTypes.string,
  name: PropTypes.string,
  columnOptions: PropTypes.arrayOf(MenuOption),
  value: PropTypes.arrayOf(FilterValue),
  onAddFilter: PropTypes.func,
  onRemoveFilter: PropTypes.func,
  onAddCondition: PropTypes.func,
  onRemoveCondition: PropTypes.func,
  onChange: PropTypes.func,
  onEdit: PropTypes.func
}

/* istanbul ignore next */
Filters.defaultProps = {
  'data-testid': '',
  name: '',
  columns: [],
  value: [],
  onAddFilter: _name => {},
  onRemoveFilter: _name => {},
  onAddCondition: _name => {},
  onRemoveCondition: _name => {},
  onChange: (_name, _value) => {},
  onEdit: (_name, _value) => {}
}
