import React from 'react'
import MuiGrid from '@mui/material/Grid'
import Table from '@mui/material/Table'
import TableRow from '@mui/material/TableRow'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import LinearProgress from '@mui/material/LinearProgress'
import DeleteIcon from '@mui/icons-material/Delete'
import ErrorIcon from '@mui/icons-material/Error'
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog'
import FileDropZone from '../hooks/useFileDropZone'

import { useDispatch, useSelector } from 'react-redux'
import { deleteSource } from '../../../store/pages/reference'
import { styled } from '@mui/material'

const Grid = styled(MuiGrid)`
    background-color: white;
    width: 100%;
    margin-top: ${({ theme }) => theme.spacing(2)};
    margin-bottom: ${({ theme }) => theme.spacing(2)};
`

export default function FileList () {
  const dispatch = useDispatch()
  const [guid, setGuid] = React.useState('')
  const [openDialog, setOpenDialog] = React.useState(false)

  const { files } = useSelector(({
    reference: {
      referenceTable: {
        files
      }
    }
  }) => ({
    files
  }))

  const handleSourceDelete = async () => {
    setOpenDialog(false)
    await dispatch(deleteSource(guid))
  }

  const handleDelete = (_event, _fileName, srcGuid) => {
    setGuid(srcGuid)
    setOpenDialog(true)
  }

  return (
    <Grid>
      <ConfirmDialog
        open={openDialog}
        title='Delete Source'
        text='Do you want to delete this source?'
        onCancel={() => setOpenDialog(false)}
        onConfirm={handleSourceDelete}
      />

      <Grid item xs={12} >
        <FileDropZone />
      </Grid>

      {files.length > 0 && (
        <Table
          data-testid='table'
          size='small'
        >
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>File Name</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {files.map((file, index) => (
              <TableRow
                data-testid={`row-${index}`}
                key={index}
              >
                <TableCell>
                  <IconButton
                    data-testid={`button-delete-${index}`}
                    onClick={event => handleDelete(event, file.fileName, file.guid)}
                    size="large">
                    <DeleteIcon fontSize='small' />
                  </IconButton>
                </TableCell>

                <TableCell
                  data-testid={`cell-filename-${index}`}
                  align='left'
                >{file.fileName}</TableCell>

                <TableCell data-testid={`cell-status-${index}`}>
                  {file.isProcessing
                    ? file.uploaded
                      ? <>
                        Profiling
                        <LinearProgress
                          data-testid={`progress-profiling-${index}`}
                          color='secondary'
                        />
                      </>
                      : <>
                        Uploading
                        <LinearProgress
                          data-testid={`progress-uploading-${index}`}
                          color='secondary'
                          variant='determinate'
                          value={file.uploadProgress}
                        />
                      </>
                    : <div>
                      {file.profileStatus || ''}

                      {file.messageError && (
                        <Tooltip
                          data-testid={`tooltip-error-${index}`}
                          title={file.messageError}
                        >
                          <ErrorIcon color='primary' />
                        </Tooltip>
                      )}
                    </div>
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </Grid>
  );
}
