import React from 'react'
import Grid from '@mui/material/Grid'
import MuiButton from '@mui/material/Button'
import Navigation from '../components/navigation'
import ResetDataDialog from './components/resetDataDialog'
import LogGrid from './components/logGrid'
import RestoreIcon from '@mui/icons-material/Restore'
import { styled } from '@mui/material'

const Button = styled(MuiButton)(({ theme }) => ({
  marginLeft: theme.spacing(2)
}))

export default function Log () {
  const [open, setOpen] = React.useState(false)

  const handleOpen = React.useCallback(() => {
    setOpen(true)
  }, [setOpen])

  const handleClose = React.useCallback(() => {
    setOpen(false)
  }, [setOpen])

  return (
    <Grid container spacing={3}>
      <ResetDataDialog
        open={open}
        onClose={handleClose}
      />

      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <Navigation/>
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="outlined"
              startIcon={<RestoreIcon />}
              onClick={handleOpen}
            >Reload Data</Button>
          </Grid>
        </Grid>

        <LogGrid />
      </Grid>
    </Grid>
  )
}
