import { Button } from '@mui/material'
import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import Tooltip from '@mui/material/Tooltip'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import IconButton from '@mui/material/IconButton'
import { useDispatch, useSelector } from 'react-redux'
import InfoDialog from '../../app/InfoDialog'
import { addedFilesAction } from '../../../store/pages/project/actions'

const useFileDropZone = () => {
  const dispatch = useDispatch()

  const onDrop = useCallback(files => {
    dispatch({ ...addedFilesAction, payload: files })
  }, [dispatch])

  const {
    isSaving,
    files
  } = useSelector(({
    global: {
      isSaving
    },
    project: {
      files
    }
  }) => ({
    isSaving,
    files
  }))

  const {
    getRootProps,
    getInputProps,
    isDragActive
  } = useDropzone({ onDrop, useFsAccessApi: false })

  const [openInfo, setOpenInfo] = React.useState(false)

  const handleClose = () => {
    setOpenInfo(false)
  }

  const validateFilesMissing = ['I', 'T', 'RH', 'TY']
    .filter(x => !files.find(w => (w.fileType === x)))

  const disabledUpload = validateFilesMissing.length === 0 || isSaving
  return (
    <div>
      <Tooltip title={'View upload best practices'}>
        <IconButton onClick={() => setOpenInfo(true)} size="large">
          <InfoOutlinedIcon />
        </IconButton>
      </Tooltip>
      <Button
        variant="contained"
        {...getRootProps()}
        className={'uploadArea'}
        disabled={disabledUpload}
        style={{
          marginBottom: 8,
          width: '90%',
          borderWidth: 2,
          borderStyle: 'dashed',
          color: '#000000de',
          fontWeight: 'bold',
          border: 'black',
          boxShadow: 'none',
          padding: 20,
          backgroundColor: 'white'
        }}
      >
        <input {...getInputProps()} />
        {
          isDragActive
            ? <div>Drop the files here ...</div>
            : <div>Click to Add files or Drag and Drop

            </div>
        }
      </Button>

      <InfoDialog open={openInfo} onClose={handleClose} />
    </div>
  );
}

export default useFileDropZone
