import React from 'react'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import { styled } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { updateOptionWhere } from '../../../store/pages/common/formulaDialog'
import { removedOptionWhereAction } from '../../../store/pages/common/update'
import { SingleValue, SingleValueStyles } from '../../../components/sql/SelectSingleValue'
import { OPERATIONS_OPTIONS } from '../../../stringConstants'

const UpdateSelect = styled(Select)(({ theme }) => ({
  minHeight: 58,
  margin: theme.spacing(1)
}));

const UpdateCreatableSelect = styled(CreatableSelect)(({ theme }) => ({
  minHeight: 58,
  margin: theme.spacing(1)
}));

const DeleteButton = styled(IconButton)({
  marginRight: 75,
  marginTop: 15
})

export default function WhereList () {
  const dispatch = useDispatch()

  const {
    columns,
    updateWhereConditions
  } = useSelector(({
    reference: {
      referenceSourceInfo: {
        columns
      }
    },
    update: {
      updateWhereConditions
    }
  }) => ({
    columns,
    updateWhereConditions
  }))

  const columnOptions = columns.map(column => ({
    label: column.Name,
    value: column.Name
  }))

  const handleSelectChange = (value, event) => {
    const [key, rawIndex] = event.name.split('.')
    const index = Number(rawIndex)

    // @ts-ignore
    dispatch(updateOptionWhere(key, index, value))
  }

  return (
    <div>
      {updateWhereConditions.map((item, index) => (
        <Grid container key={index}>
          <Grid item xs={3}>
            <UpdateSelect
              data-testid={`select-columnName.${index}`}
              name={`columnName.${index}`}
              styles={SingleValueStyles}
              queryKey='columnName'
              queryIndex={index}
              queryTarget='queryWhereConditions'
              components={{ SingleValue }}
              options={columnOptions}
              value={item.columnName}
              onChange={handleSelectChange}
            />
          </Grid>

          <Grid item xs={2}>
            <UpdateSelect
              data-testid={`select-operation.${index}`}
              name={`operation.${index}`}
              styles={SingleValueStyles}
              value={item.operation}
              components={{ SingleValue }}
              options={OPERATIONS_OPTIONS}
              onChange={handleSelectChange}
            />
          </Grid>

          <Grid item xs={3}>
            <UpdateCreatableSelect
              data-testid={`select-whereValue.${index}`}
              name={`whereValue.${index}`}
              styles={SingleValueStyles}
              value={item.whereValue}
              queryKey='whereValue'
              queryIndex={index}
              queryTarget='queryWhereConditions'
              components={{ SingleValue }}
              options={columnOptions}
              onChange={handleSelectChange}
            />
          </Grid>

          <Grid item xs={4}>
            <DeleteButton
              data-testid={`button-delete-${index}`}
              onClick={() => dispatch({
                ...removedOptionWhereAction,
                payload: { index }
              })}
              size="large">
              <DeleteIcon fontSize='small' />
            </DeleteButton>
          </Grid>
        </Grid>
      ))}
    </div>
  );
}
