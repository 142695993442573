import PropTypes from 'prop-types'
import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Typography from '@mui/material/Typography'

export default function InfoDialog (props) {
  const handleClose = () => {
    props.onClose()
  }

  return (
    <div>
      <Dialog
        data-testid={props['data-testid']}
        fullWidth
        open={props.open}
        maxWidth='md'
        onClose={handleClose}
      >
        <DialogTitle
          data-testid='title'
        >Upload Tips</DialogTitle>

        <DialogContent
          data-testid='content'
          dividers
        >
          <Typography gutterBottom variant="h6">
            Supported File Types
          </Typography>

          <Typography gutterBottom variant="body1">
            REDi supports text and csv files that are delimited by one of the following: pipe (|), semicolon (;), comma (,), or tab
          </Typography>

          <Typography gutterBottom variant="h6">
            File Upload Tips
          </Typography>

          <Typography gutterBottom variant="body1">
            1. Make sure there aren&apos;t any SQL keywords in the column names. Having keywords in the the column names can cause the upload and profile process to fail.
          </Typography>

          <Typography gutterBottom variant="body1">
            2. Please do not navigate away from the upload screen until all files are in the “Profiling” state. Doing so will result in the files failing to upload.
          </Typography>

          <Typography gutterBottom variant="body1">
            3. For large files we recommend that you copy the files to the geographically closest terminal server desktop and then upload to REDi from that terminal server. Terminal servers have tested to have faster upload speeds.
          </Typography>
        </DialogContent>

        <DialogActions
          data-testid='actions'
        >
          <Button
            data-testid='button-ok'
            autoFocus
            color="primary"
            variant="outlined"
            onClick={handleClose}
          >OK</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

InfoDialog.propTypes = {
  'data-testid': PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}
