import PropTypes from 'prop-types'
import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'

export default function MessageDialog (props) {
  const handleClose = React.useCallback(event => {
    event.stopPropagation()
    props.onClose()
  }, [props])

  return (
    <Dialog
      data-testid={props['data-testid']}
      open={props.open}
      onClose={handleClose}
    >
      <DialogTitle
        data-testid='label-title'
      >{props.title}</DialogTitle>

      <DialogContent>
        <DialogContentText
          data-testid='label-text'
        >{props.text}</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          data-testid='button-close'
          variant='contained'
          onClick={handleClose}
        >Close</Button>
      </DialogActions>
    </Dialog>
  )
}

MessageDialog.propTypes = {
  'data-testid': PropTypes.string,
  open: PropTypes.bool,
  title: PropTypes.string,
  text: PropTypes.string,
  onClose: PropTypes.func
}
