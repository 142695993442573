/**
 * @template {string} TPrefix
 * @template {string} TActionType
 * @template {{readonly [TAction in TActionType]: `@@${TPrefix}/${TAction}`}} TActionTypes
 * @param {TPrefix} prefix
 * @param {...TActionType} actionTypes
 */
export function createActionTypes (prefix, ...actionTypes) {
  return /** @type {TActionTypes} */ (Object.freeze(actionTypes.reduce((constants, actionType) => ({
    ...constants,
    [actionType]: `@@${prefix}/${actionType}`
  }), {})))
}
/**
 * @template [TValue=unknown]
 * @typedef {Record<string, TValue>} MutableObject
*/
/**
 * @template [T=unknown]
 * @param {unknown} value
 * @returns {value is MutableObject<T>}
 */
export function isMutableObject (value) {
  return typeof value === 'object' && !Object.isFrozen(value)
}
/**
 * @template {Record<string|number, unknown>} T
 * @param {T} obj
 * @returns {Readonly<{[k in keyof T]: T[k] extends Record<string, unknown> | unknown[] ? Readonly<T[k]> : T[k]}>}
 */
export function deepFreeze (obj) {
  for (const key in obj) if (isMutableObject(obj[key])) deepFreeze(/** @type {Record<string|number, unknown>} */ (obj[key]))
  return Object.freeze(obj)
}
/**
 * @template TValue
 * @callback TypePredicate
 * @param {unknown} [value]
 * @returns {value is TValue}
 */
/**
 * @template {readonly unknown[]} [TArray=readonly unknown[]]
 * @typedef {TArray[number]} ElementOf
 */

/**
 * @template {string[]} TValues
 * @template {ElementOf<TValues>} T
 * @param {TValues} values
 */
export function constantsFactory (...values) {
  const typedSet = new Set(values)
  return deepFreeze({
    values,
    has: /** @type {TypePredicate<T>} */ function (value) {
      return typedSet.has(/** @type {T} */ (value))
    }
  })
}
