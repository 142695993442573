const NUMERIC_TYPES = [
  'int',
  'double',
  'decimal',
  'bigint',
  'numeric',
  'double precision',
  'integer'
]

const isTypeNumeric = type => NUMERIC_TYPES.includes(type.toLowerCase())

const typeValidators = {
  varchar: column => column.targetDataType
    ? !(column.targetDataType.toLowerCase() === 'varchar')
    : false,
  'double precision': column => (column.targetDataType && column.sourceDataType)
    ? !(isTypeNumeric(column.targetDataType) && isTypeNumeric(column.sourceDataType))
    : false,
  decimal: column => (column.targetDataType && column.sourceDataType)
    ? !(isTypeNumeric(column.targetDataType) && isTypeNumeric(column.sourceDataType))
    : false,
  date: column => column.sourceDataType
    ? column.targetDataType !== column.sourceDataType
    : false,
  double: column => (column.targetDataType && column.sourceDataType)
    ? !(isTypeNumeric(column.targetDataType) && isTypeNumeric(column.sourceDataType))
    : false,
  integer: column => (column.targetDataType && column.sourceDataType)
    ? !(isTypeNumeric(column.targetDataType) && isTypeNumeric(column.sourceDataType))
    : false,
  int: column => (column.targetDataType && column.sourceDataType)
    ? !(isTypeNumeric(column.targetDataType) && isTypeNumeric(column.sourceDataType))
    : false,
  bigint: column => (column.targetDataType && column.sourceDataType)
    ? !(isTypeNumeric(column.targetDataType) && isTypeNumeric(column.sourceDataType))
    : false,
  numeric: column => (column.targetDataType && column.sourceDataType)
    ? !(isTypeNumeric(column.targetDataType) && isTypeNumeric(column.sourceDataType))
    : false
}

export { typeValidators, isTypeNumeric }
