import React from 'react'
import Fab from '@mui/material/Fab'
import Grid from '@mui/material/Grid'
import BaseSelect from 'react-select'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import { styled } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { OPERATIONS_OPTIONS } from '../../../../stringConstants'

import {
  SingleValue,
  SingleValueStyles
} from '../../../../components/sql/SelectSingleValue'

import {
  addedJoinItemAction,
  changedJoinItemAction,
  removedJoinItemAction
} from '../../../../store/pages/common/join'

const Root = styled(Grid)(({ theme }) => ({
  margin: theme.spacing(1)
}))

const Select = styled(BaseSelect)(({ theme }) => ({
  minHeight: 58,
  margin: theme.spacing(1)
}))

export default function JoinList () {
  const dispatch = useDispatch()
  const navigation = useSelector(store => store.navigation)
  const dataPrep = useSelector(store => store.dataPrep)
  const join = useSelector(store => store.join)

  const sourceFileColumns = navigation.file.columns || []

  return (
    <Root container>
      <Grid item xs={7}>
        <Typography variant="h6">
          Linking Keys
        </Typography>
      </Grid>

      <Grid item xs={5}>
        <Fab
          size="small"
          color="primary"
          onClick={() => dispatch(addedJoinItemAction)}
        >
          <AddIcon/>
        </Fab>
      </Grid>

      <Grid item xs={1} />

      <Grid item xs={3}>
        <Typography variant="h6">
          Source Columns
        </Typography>
      </Grid>

      <Grid item xs={8}>
        <Typography variant="h6">
          Reference Columns
        </Typography>
      </Grid>

      {join.joinList.map((joinItem, index) => (
        <Grid key={index} container>

          <Grid item xs={3} >
            <Select
              placeholder=""
              value={Object.keys(joinItem.sourceColumn).length === 0 ? '' : joinItem.sourceColumn}
              options={sourceFileColumns.map(x => ({ value: x.Name, label: `${x.Name} (${x.Type})` }))}
              components={{ SingleValue }}
              styles={SingleValueStyles}
              onChange={(newValue) => dispatch({ ...changedJoinItemAction, payload: { name: 'sourceColumn', value: newValue, index } })}
            />
          </Grid>

          <Grid item xs={1}>
            <Select
              placeholder=""
              value={Object.keys(joinItem.sourceOperation).length === 0 ? '' : joinItem.sourceOperation}
              styles={SingleValueStyles}
              options={OPERATIONS_OPTIONS}
              components={{ SingleValue }}
              onChange={(newValue) => dispatch({ ...changedJoinItemAction, payload: { name: 'sourceOperation', value: newValue, index } })}
            />
          </Grid>

          <Grid item xs={3}>
            <Select
              placeholder=""
              value={Object.keys(joinItem.referenceColumn).length === 0 ? '' : joinItem.referenceColumn}
              styles={SingleValueStyles}
              components={{ SingleValue }}
              options={dataPrep.referenceColumns.map(x => ({ value: x.Name, label: `${x.Name} (${x.Type})` }))}
              onChange={(newValue) => dispatch({ ...changedJoinItemAction, payload: { name: 'referenceColumn', value: newValue, index } })}
            />
          </Grid>

          <Grid item xs={4}>
            <IconButton
              size="large"
              onClick={() => dispatch({ ...removedJoinItemAction, payload: index })}
            >
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}
    </Root>
  );
}
