import PropTypes from 'prop-types'
import React from 'react'
import BaseSelect from 'react-select'
import BaseCreatableSelect from 'react-select/creatable'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import { styled } from '@mui/material'
import { OPERATIONS_OPTIONS } from '../../../stringConstants'
import { updateOptionWhere } from '../../../store/pages/common/formulaDialog'
import { removedOptionWhereAction } from '../../../store/pages/common/update'
import { useDispatch, useSelector } from 'react-redux'

import {
  SingleValue,
  SingleValueStyles,
  AppendColumnBuilder
} from '../../../components/sql/SelectSingleValue'

const Select = styled(BaseSelect)(({ theme }) => ({
  minHeight: 58,
  margin: theme.spacing(1)
}))

const CreatableSelect = styled(BaseCreatableSelect)(({ theme }) => ({
  minHeight: 58,
  margin: theme.spacing(1)
}))

const DeleteButton = styled(IconButton)({
  marginRight: 75,
  marginTop: 15
})

const WhereRow = styled(Grid)({
  display: 'flex',
  width: '100%'
})

const WhereRowContainer = styled(Grid)({
  width: '95%',
  border: '1px solid #737373',
  borderRadius: '3px',
  padding: '12px',
  marginTop: '15px',
  marginBottom: '5px'
})

const WhereOrSeparator = styled(Grid)({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '19px',
  fontWeight: '600',
  marginBottom: '12px'
})

const FullWidth = styled(Grid)({
  width: '100%'
})

export default function WhereList (props) {
  const dispatch = useDispatch()
  const [showORState, setShowORState] = React.useState(Array(10).fill(false))
  const navigation = useSelector(store => store.navigation)
  const update = useSelector(store => store.update)

  const whereConditions = props.isDelete
    ? update.deleteWhereConditions
    : update.updateWhereConditions

  const columns = navigation.file.columns || []
  const columnsPlusBuilder = AppendColumnBuilder(columns)

  const handleORSwitchChange = (index, event) => {
    const isChecked = event?.target?.checked
    const newShowORState = [...showORState]
    newShowORState[index] = isChecked
    setShowORState(newShowORState)

    if (!isChecked) {
      dispatch(updateOptionWhere('orColumnName', index, '', props.isDelete))
      dispatch(updateOptionWhere('orOperation', index, '=', props.isDelete))
      dispatch(updateOptionWhere('orWhereValue', index, '', props.isDelete))
    }
  }

  const handleOptionWhereDelete = (index, isDelete) => {
    const newShowORState = [...showORState].splice(index, 1)

    setShowORState(newShowORState)

    dispatch({
      ...removedOptionWhereAction,
      payload: {
        index,
        isDelete
      }
    })
  }

  return (
    <div>
      {whereConditions.map((item, index) => (
        <WhereRowContainer container key={index}>
          <WhereRow>
            <Grid item xs={3}>
              <Select
                placeholder=""
                name={`column-${index}!`}
                styles={SingleValueStyles}
                value={item.columnName}
                queryIndex={index}
                queryTarget='queryWhereConditions'
                queryKey='columnName'
                components={{ SingleValue }}
                options={columnsPlusBuilder.map(x => ({ value: x.Name, label: x.Name }))}
                onChange={(newValue) => dispatch(updateOptionWhere('columnName', index, newValue, props.isDelete))}
              />
            </Grid>

            <Grid item xs={2}>
              <Select
                placeholder=""
                name={`operation-${index}!`}
                styles={SingleValueStyles}
                value={item.operation}
                components={{ SingleValue }}
                options={OPERATIONS_OPTIONS}
                onChange={(newValue) => dispatch(updateOptionWhere('operation', index, newValue, props.isDelete))}
              />
            </Grid>

            <Grid item xs={3}>
              <CreatableSelect
                placeholder=""
                name={`where-${index}!`}
                styles={SingleValueStyles}
                value={item.whereValue}
                queryIndex={index}
                queryTarget='queryWhereConditions'
                queryKey='whereValue'
                components={{ SingleValue }}
                options={columnsPlusBuilder.map(x => ({ value: x.Name, label: x.Name }))}
                onChange={(newValue) => dispatch(updateOptionWhere('whereValue', index, newValue, props.isDelete))}
              />
            </Grid>

            <Grid item xs={1}>
              <DeleteButton
                size="large"
                onClick={handleOptionWhereDelete.bind(this, index, props.isDelete)}
              ><DeleteIcon /></DeleteButton>
            </Grid>

            <Grid item xs={2}>
              <FormGroup>
                <FormControlLabel control={
                  <Switch
                    checked={showORState[index]}
                    data-testid='switch-whereORToggle'
                    onChange={handleORSwitchChange.bind(this, index)}
                    color="secondary"
                  />} label="Add OR Filter"
                />
              </FormGroup>
            </Grid>
          </WhereRow>

          {(showORState[index] || item.orColumnName || item.orWhereValue) && (
            <FullWidth>
              <WhereOrSeparator item xs={8}>OR</WhereOrSeparator>

              <WhereRow>
                <Grid item xs={3}>
                  <Select
                    placeholder=""
                    name={`column-${index}!`}
                    styles={SingleValueStyles}
                    value={item.orColumnName}
                    queryIndex={index}
                    queryTarget={'queryWhereConditions'}
                    queryKey={'orColumnName'}
                    components={{ SingleValue }}
                    options={columnsPlusBuilder.map(x => ({ value: x.Name, label: x.Name }))}
                    onChange={(newValue) => dispatch(updateOptionWhere('orColumnName', index, newValue, props.isDelete))}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Select
                    placeholder=""
                    name={`operation-${index}!`}
                    styles={SingleValueStyles}
                    value={item.orOperation}
                    components={{ SingleValue }}
                    options={OPERATIONS_OPTIONS}
                    onChange={(newValue) => dispatch(updateOptionWhere('orOperation', index, newValue, props.isDelete))}
                  />
                </Grid>

                <Grid item xs={3}>
                  <CreatableSelect
                    placeholder=""
                    name={`where-${index}!`}
                    styles={SingleValueStyles}
                    value={item.orWhereValue}
                    queryIndex={index}
                    queryTarget={'queryWhereConditions'}
                    queryKey={'orWhereValue'}
                    components={{ SingleValue }}
                    options={columnsPlusBuilder.map(x => ({ value: x.Name, label: x.Name }))}
                    onChange={(newValue) => dispatch(updateOptionWhere('orWhereValue', index, newValue, props.isDelete))}
                  />
                </Grid>
              </WhereRow>
            </FullWidth>
          )}
        </WhereRowContainer>
      ))}
    </div>
  );
}

WhereList.propTypes = {
  isDelete: PropTypes.bool
}
