import React from 'react'
import Grid from '@mui/material/Grid'
import ReactCreatableSelect from 'react-select/creatable'
import MuiIconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import { styled } from '@mui/material'
import ReactSelect from 'react-select'
import { useDispatch, useSelector } from 'react-redux'

import { SingleValue, SingleValueStyles } from '../../components/sql/SelectSingleValue'
import { removedWhereConditionAction, updateOptionQueryWhereList } from '../../store/pages/common/query'
import { OPERATIONS_OPTIONS } from '../../stringConstants'

const Select = styled(ReactSelect)(({ theme }) => ({
  zIndex: props => 900 + props.index,
  minHeight: 58,
  margin: theme.spacing(1)
}))

const CreatableSelect = styled(ReactCreatableSelect)(({ theme }) => ({
  zIndex: props => 900 + props.index,
  minHeight: 58,
  margin: theme.spacing(1)
}))

const IconButton = styled(MuiIconButton)(({
  marginRight: 75,
  marginTop: 15
}))

export default function ConditionsList () {
  const dispatch = useDispatch()
  const { formatFile, queryWhereConditions } = useSelector(({
    navigation: { formatFile },
    query: { queryWhereConditions }
  }) => ({
    formatFile, queryWhereConditions
  }))
  const columns = (formatFile ? formatFile.columns : [])

  return (
    <div>
      {
        queryWhereConditions.map((item, index) => (
          <Grid container key={index}>
            <Grid item xs={3}>
              <CreatableSelect
                name={`column-${index}!`}
                styles={SingleValueStyles}
                isClearable
                value={item.columnName}
                queryIndex={index}
                queryTarget={'queryWhereConditions'}
                queryKey={'columnName'}
                openMenuOnClick={item.columnName === ''}
                options={columns.map(x => ({ value: x.Name, label: x.Name }))}
                onChange={(newValue) => dispatch(updateOptionQueryWhereList('columnName', index, newValue))}
                components={{ SingleValue }}
              />
            </Grid>
            <Grid item xs={2}>
              <Select
                name={`operation-${index}!`}
                styles={SingleValueStyles}
                isClearable
                value={item.operation}
                queryIndex={index}
                queryTarget={'queryWhereConditions'}
                options={OPERATIONS_OPTIONS}
                onChange={(newValue) => dispatch(updateOptionQueryWhereList('operation', index, newValue))}
                components={{ SingleValue }}
              />
            </Grid>
            <Grid item xs={3}>
              <CreatableSelect
                name={`where-${index}!`}
                styles={SingleValueStyles}
                isClearable
                value={item.whereValue}
                queryIndex={index}
                queryTarget={'queryWhereConditions'}
                queryKey={'whereValue'}
                openMenuOnClick={item.whereValue === ''}
                options={columns.map(x => ({ value: x.Name, label: x.Name }))}
                onChange={(newValue) => dispatch(updateOptionQueryWhereList('whereValue', index, newValue))}
                components={{ SingleValue }}
              />
            </Grid>
            <Grid item xs={1}>
              <IconButton
                aria-label="delete"
                onClick={() => dispatch({ ...removedWhereConditionAction, payload: index })}
                size="large">
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Grid>
            <Grid item xs={3}></Grid>
          </Grid>
        ))
      }
    </div>
  );
}
