import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import { styled } from '@mui/material'
import { useDispatch } from 'react-redux'
import { updateComments } from '../../../store/pages/review'

const Heading = styled(Typography)(({
  padding: '8px'
}))

const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'flagAcknowledge'
})(({ flagAcknowledge }) => ({
  width: '100%',
  backgroundColor: flagAcknowledge ? '#FFFFFF' : '#f6f4f4',
  border: '1px solid #55645896'
}))

function Comments ({ access, objectData }) {
  const dispatch = useDispatch()

  const savedComment = objectData.comment || ''
  const canComment = (access && !objectData.flagAcknowledge)

  const [updatedComment, setUpdatedComment] = React.useState(null)
  const [isChanged, setIsChanged] = React.useState(false)

  const handleTextFieldChange = event => {
    setUpdatedComment(event.target.value)
    setIsChanged(true)
  }

  return (
    <>
      <Heading variant="subtitle1">
        Comments
        <StyledTextField
          flagAcknowledge={objectData.flagAcknowledge}
          data-testid='textField-comments'
          inputProps={{ readOnly: !canComment }}
          autoFocus
          color="secondary"
          variant="outlined"
          value={isChanged ? updatedComment : savedComment}
          onChange={handleTextFieldChange}
          onBlur={e => {
            if (canComment) {
              objectData.comment = updatedComment
              dispatch(updateComments(objectData))
            }
          }}
        />
      </Heading>
    </>
  )
}

Comments.propTypes = {
  objectData: PropTypes.any,
  access: PropTypes.any
}

export default Comments
