import React from 'react'
import Table from '@mui/material/Table'
import MuiTableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import MuiTableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'
import Grid from '@mui/material/Grid'
import AddBoxIcon from '@mui/icons-material/AddBox'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { useDispatch, useSelector } from 'react-redux'

import { tableRowBackGroundColorSelect } from '../../components/table/table.utils'
import SelectedObject from './selectedObject'
import AdminDialog from './adminDialog'
import { styled } from '@mui/material'
import { removeAdminData, setDialogDataAction, updateAdminData } from '../../store/pages/adminTool'

const SmallTableCell = styled(TableCell)({
  width: '100px'
})
const MediumTableCell = styled(TableCell)({
  width: '200px'
})
const LargeTableCell = styled(TableCell)({
  width: '400px'
})
const StyledDiv = styled('div')({
  maxWidth: 'calc(87vw)',
  overflowX: 'hidden'
})
const StyledTableRow = styled(MuiTableRow)({
  background: 'white',
  display: 'inherit',
  maxHeight: 50
})

const TableBody = styled(MuiTableBody)(({
  display: 'block',
  overflowY: 'hidden'
}))

const TableRow = styled(MuiTableRow)(({
  background: 'white',
  display: 'inherit',
  maxHeight: 50
}))

const Announcements = () => {
  const dispatch = useDispatch()
  const { dialogData, announcementData, ownerId } = useSelector(({
    adminTool: { dialogData, announcementData },
    user: { ownerId }
  }) => ({
    dialogData, announcementData, ownerId
  }))
  const [selectedRow, setSelectedRow] = React.useState(null)
  const [title, setTitle] = React.useState(null)
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const newObject = {
    visible: true,
    startDate: null,
    endDate: null,
    userId: ownerId,
    type: 'announcement',
    name: null,
    description: null,
    message: null,
    title: null
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={5} >
        <StyledDiv>
          <Table aria-label='sticky table' size='small'>
            <TableHead>
              <StyledTableRow>
                <LargeTableCell>
                  Name
                </LargeTableCell>
                <MediumTableCell>
                  Release
                </MediumTableCell>
                <SmallTableCell>
                </SmallTableCell>
                <SmallTableCell>
                  <Tooltip title={'Add New Announcement'}>
                    <AddBoxIcon onClick={() => {
                      dispatch({ ...setDialogDataAction, payload: newObject })
                      setDialogOpen(true)
                      setTitle('Add New Announcement')
                    }}/>
                  </Tooltip>
                </SmallTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {
                announcementData && announcementData
                  .map((row, idx) => {
                    return (
                      <TableRow
                        key={idx}
                        style={tableRowBackGroundColorSelect(idx, selectedRow)} onClick={() => {
                          setSelectedRow(idx)
                          dispatch({ ...setDialogDataAction, payload: row })
                        }}>
                        <LargeTableCell>
                          {row.name}
                        </LargeTableCell>
                        <MediumTableCell>{row.endDate}</MediumTableCell>
                        <SmallTableCell>
                          <EditIcon onClick={() => {
                            setDialogOpen(true)
                            setTitle('Edit Announcement')
                          }} />
                        </SmallTableCell>
                        <SmallTableCell>
                          <DeleteIcon
                            onClick={ () => dispatch(removeAdminData(row))}/>
                        </SmallTableCell>
                      </TableRow>
                    )
                  })
              }
            </TableBody>
          </Table>
        </StyledDiv>
        {dialogData && <AdminDialog
          update={data => dispatch(updateAdminData(data))}
          type={dialogData.type}
          open={dialogOpen}
          title={title}
          setDialogOpen={setDialogOpen}
        />}
      </Grid>
      <Grid item xs={7}>
        {dialogData && dialogData.guid && <SelectedObject
          data={dialogData}
        />}
      </Grid>
    </Grid>
  )
}

export default Announcements
