import apiHelper from './apiHelper'
import { API } from 'aws-amplify'

let __region = process.env.REACT_APP_REGION

const cleanMappings = mappings => {
  const { optionalColumns, ...notOptional } = mappings

  notOptional.columns = notOptional.columns.map(column => {
    const { label, value, ...notThose } = column

    if (label) {
      notThose.source = label
    }

    delete notThose.toolTip

    return notThose
  })

  return notOptional
}

const setAPIRegion = region => {
  __region = region
}

const getMappings = async projectId => {
  const header = await apiHelper.getHeader()

  return API
    .get(
      apiHelper.getRegionalAPIName(__region),
      `/source/mappings/mappings?projectId=${projectId}`,
      header
    )
    .catch(apiHelper.handleError)
}

const setMappings = async (projectId, guid, mappings) => {
  const bodyAndHeader = await apiHelper.getBodyAndHeader(cleanMappings(mappings))

  return API
    .put(
      apiHelper.getRegionalAPIName(__region),
      `/source/mappings/mappings?projectId=${projectId}&guid=${guid}`,
      bodyAndHeader
    )
    .catch(apiHelper.handleError)
}

const removeMappings = async (projectId) => {
  const bodyAndHeader = await apiHelper.getBodyAndHeader(projectId)

  return API
    .del(
      apiHelper.getRegionalAPIName(__region),
      `/source/mappings/mappings?projectId=${projectId}`,
      bodyAndHeader
    )
    .catch(apiHelper.handleError)
}

const removeMappingsByFile = async (projectId, guid) => {
  const bodyAndHeader = await apiHelper.getBodyAndHeader(projectId)

  return API
    .del(
      apiHelper.getRegionalAPIName(__region),
      `/source/mappings/mappings?projectId=${projectId}&guid=${guid}`,
      bodyAndHeader
    )
    .catch(apiHelper.handleError)
}

export default {
  setAPIRegion,
  getMappings,
  setMappings,
  removeMappings,
  removeMappingsByFile
}
