import { openQueryAction, savedQueryMergeAction, setSubNavigationAction } from '../dataPrep'
import { loadedAllReferenceTablesAction } from '../reference'
import sourcesApi from '../../objects/sourcesAPI'
import { setProjectFileDirtyFlag, updateSourceFiles } from '../project/async'
import stepFunctionStatusLoop from './stepFunctionStatusLoop'

const initialState = {
  message: '',
  isLoading: false,
  isSaving: false,
  joinList: [
    {
      sourceColumn: {},
      sourceOperation: {},
      referenceColumn: {},
      whereList: [
        {
          sourceColumn: {},
          sourceOperation: {},
          referenceColumn: {}
        }
      ]
    }
  ],
  whereList: [
    {
      sourceColumn: {},
      sourceOperation: {},
      referenceColumn: {}
    }
  ],
  resultsGrid: {
    isLoading: false,
    columns: [],
    data: [],
    count: 0
  },
  renameDialog: {
    open: false,
    target: undefined,
    index: undefined,
    name: '',
    formula: ''
  }
}

export const changedJoinItemAction = {
  type: 'join/CHANGED_JOIN_ITEM'
}

export const addedJoinItemAction = {
  type: 'join/ADDED_JOIN_ITEM'
}

export const removedJoinItemAction = {
  type: 'join/REMOVED_JOIN_ITEM'
}

export const changedWhereItemAction = {
  type: 'join/CHANGED_WHERE_ITEM'
}

export const addedWhereItemAction = {
  type: 'join/ADDED_WHERE_ITEM'
}

export const removedWhereItemAction = {
  type: 'join/REMOVED_WHERE_ITEM'
}

export const previewingMergeAction = {
  type: 'join/PREVIEWING_MERGE'
}

export const previewedMergeAction = {
  type: 'join/PREVIEWED_MERGE'
}

export const executingMergeAction = {
  type: 'join/EXECUTING_MERGE'
}

export const executedMergeAction = {
  type: 'join/EXECUTED_MERGE'
}

export const executeMergeData = async (dispatch, getState) => {
  const {
    dataPrep: { mergeData: dataToMerge },
    join: { joinList, whereList },
    navigation: { file: { guid } },
    project: { id }
  } = getState()

  dispatch({
    ...executingMergeAction,
    payload: {
      resultsGrid: {
        ...initialState.resultsGrid,
        isLoading: true
      },
      message: ''
    }
  })

  dispatch(setProjectFileDirtyFlag(guid, true))
  try {
    const { executionArn } = await sourcesApi.startMerge({
      ...dataToMerge,
      joinList,
      whereList,
      eventType: 'MergeTables',
      fileInfo: { guid, id }
    })

    const moveOnAfterMerge = async arn => {
      const output = JSON.parse(arn.output)
      if (arn.status === 'SUCCEEDED') {
        if (output.Error) {
          return dispatch({
            ...executedMergeAction,
            payload: {
              join: {
                message: output.Error,
                resultsGrid: {
                  ...initialState.resultsGrid,
                  isLoading: false
                }
              }
            }
          })
        } else {
          await dispatch(updateSourceFiles(id, guid))
          return dispatch({
            ...executedMergeAction,
            payload: {
              join: {
                message: `Merge ${output.results || 'Succeeded'}`,
                resultsGrid: { ...initialState.resultsGrid }
              },
              dataPrep: {
                projectId: null
              }
            }
          })
        }
      } else {
        return dispatch({
          ...executedMergeAction,
          payload: {
            join: {
              message: `Merge ${output.results || 'Succeeded'}`,
              resultsGrid: {
                ...initialState.resultsGrid,
                isLoading: true
              },
              dataPrep: {
                projectId: null
              }
            }
          }
        })
      }
    }
    return stepFunctionStatusLoop(
      executionArn,
      ({ status }) => ['FAILED', 'SUCCEEDED'].includes(status),
      moveOnAfterMerge
    )
  } catch (ex) {
    return dispatch({
      ...executedMergeAction,
      payload: {
        join: {
          message: ex.data ? ex.data.error : 'Network Error - Try Again',
          resultsGrid: {
            ...initialState.resultsGrid
          }
        }
      }
    })
  }
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case changedJoinItemAction.type: {
      const { name, value, index } = payload
      const { joinList } = state
      joinList[index][name] = value
      return {
        ...state,
        joinList: [...joinList],
        message: undefined
      }
    }
    case addedJoinItemAction.type: {
      const { joinList } = state
      joinList.push({
        sourceColumn: {},
        sourceOperation: {},
        referenceColumn: {},
        whereList: [{
          sourceColumn: {},
          sourceOperation: {},
          referenceColumn: {}
        }]
      })
      return {
        ...state,
        joinList: [...joinList],
        message: undefined
      }
    }
    case removedJoinItemAction.type: {
      const { joinList } = state
      joinList.splice(payload, 1)
      return {
        ...state,
        joinList: [...joinList]
      }
    }
    case changedWhereItemAction.type: {
      const { name, value, index } = payload
      const { whereList } = state
      whereList[index][name] = value
      return {
        ...state,
        whereList: [...whereList],
        message: undefined
      }
    }
    case addedWhereItemAction.type: {
      const { whereList } = state
      return {
        ...state,
        whereList: whereList.concat({
          joinType: {},
          sourceColumn: {},
          sourceOperation: {},
          referenceColumn: {}
        })
      }
    }
    case removedWhereItemAction.type: {
      const { whereList } = state
      whereList.splice(payload, 1)
      return {
        ...state,
        whereList: [...whereList]
      }
    }
    case setSubNavigationAction.type:
      return {
        ...state,
        message: ''
      }
    case savedQueryMergeAction.type:
      return {
        ...state,
        ...payload.join
      }
    case previewingMergeAction.type:
    case previewedMergeAction.type:
    case executingMergeAction.type:
      return {
        ...state,
        ...payload
      }
    case openQueryAction.type:
      return {
        ...state,
        ...payload.join
      }
    case loadedAllReferenceTablesAction.type:
      return {
        ...state,
        whereList: [...initialState.whereList],
        joinList: [...initialState.joinList]
      }
    case executedMergeAction.type:
      return {
        ...state,
        ...payload.join
      }
    default:
      return state
  }
}
