const tableRowBackGroundColor = (index) => {
  return index % 2
    ? { background: '#F5F5F5' }
    : { background: 'white' }
}

const tableRowBackGroundColorSelect = (index, select) => {
  const selectedItemCss = index === select
    ? { border: '2px solid black' }
    : {}

  return index % 2
    ? {
        ...selectedItemCss,
        background: '#F5F5F5'
      }
    : {
        ...selectedItemCss,
        background: 'white'
      }
}

export {
  tableRowBackGroundColor,
  tableRowBackGroundColorSelect
}
