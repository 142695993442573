import { API } from 'aws-amplify'
import apiHelper from './apiHelper'

const regionConfig = {
  apiRegion: process.env.REACT_APP_REGION
}

const getProjectInformation = async (jobId, body) => {
  const bodyAndHeader = await apiHelper.getBodyAndHeader(body)
  return API.post(apiHelper.getRegionalAPIName(regionConfig.apiRegion), `/source/information/information?jobId=${jobId}`, bodyAndHeader).catch(apiHelper.handleError)
}

const updateProjectInformation = async (projectId, body) => {
  const bodyAndHeader = await apiHelper.getBodyAndHeader(body)
  return API.put(apiHelper.getRegionalAPIName(regionConfig.apiRegion), `/source/information/information?projectId=${projectId}`, bodyAndHeader).catch(apiHelper.handleError)
}

export default {
  getProjectInformation,
  updateProjectInformation
}
