import sourcesApi from '../../objects/sourcesAPI'
import { getValidationForAllFiles } from '../valueValidationActions'
import { updateSourceFiles } from '../project/async'
import { savedFormulaAction, updateOptionSetAction, updatedOptionWhereAction } from './formulaDialog'
import { openQueryAction, setSubNavigationAction } from '../dataPrep'
import apiHelper from '../../objects/apiHelper'
import stepFunctionStatusLoop from './stepFunctionStatusLoop'

const initialWhereState = {
  columnName: '',
  operation: '=',
  whereValue: '',
  orColumnName: '',
  orOperation: '=',
  orWhereValue: ''
}
const initialState = {
  updateSetConditions: [
    {
      columnName: '',
      operation: '=',
      whereValue: ''
    }
  ],
  updateWhereConditions: [{ ...initialWhereState }],
  deleteWhereConditions: [{ ...initialWhereState }],
  message: undefined,
  isSaving: false,
  isDeleting: false
}

export const updatingAction = {
  type: 'update/UPDATING'
}

export const updatedAction = {
  type: 'update/UPDATED'
}

export const addedNewOptionSetAction = {
  type: 'update/ADDED_NEW_SET_OPTION'
}

export const removedOptionSetAction = {
  type: 'update/REMOVED_SET_OPTION'
}

export const addedNewOptionWhereAction = {
  type: 'update/ADDED_NEW_WHERE_OPTION'
}

export const addedNewOptionDeleteAction = {
  type: 'update/ADDED_NEW_DELETE_OPTION'
}

export const removedOptionWhereAction = {
  type: 'update/REMOVED_WHERE_OPTION'
}

export const savedFormulaDialogAction = {
  type: 'update/SAVED_FORMULA_DIALOG'
}

export const update = (tableName, id, isProject, isDeleting) => async (dispatch, getState) => {
  const {
    mapping: { mappings },
    navigation: { files },
    reference: { referenceTable: { country: referenceCountry } },
    refData: { rgaColumns },
    project: { country: projectCountry, profileData },
    update: { updateSetConditions, updateWhereConditions = [], deleteWhereConditions = [] }
  } = getState()
  /*
   * One of the 2 places for changing regions in the UI.
   *   1. When loading project data, which happens for any screen.
   *   2. When utilizing a reference table, where the metadata for the table contains information on which region is applicable.
   *      - note: this only happens to apply to the 'sourcesApi', since it should be the only interface point to the regional reference data
   */
  const { region } = apiHelper.getCountry(isProject ? projectCountry : referenceCountry)
  sourcesApi.setAPIRegion(region)

  await dispatch({
    ...updatingAction,
    payload: {
      update: {
        message: undefined,
        isSaving: !isDeleting,
        isDeleting
      }
    }
  })
  try {
    const { executionArn, ResultRows: resultRows } = await sourcesApi.updateSourceData(
      id,
      tableName,
      updateSetConditions,
      updateWhereConditions,
      deleteWhereConditions,
      isProject,
      isDeleting
    )

    if (isProject) {
      const moveOnAfterSourceUpdated = async arn => {
        const output = JSON.parse(arn.output)
        if (arn.status === 'SUCCEEDED') {
          await dispatch(updateSourceFiles(id, tableName))

          const mapIdx = mappings.findIndex(file => file.guid === tableName)
          const fileMapping = { ...mappings[mapIdx] }
          getValidationForAllFiles(files,
            [fileMapping],
            profileData,
            rgaColumns,
            tableName)

          return dispatch({
            ...updatedAction,
            payload: {
              update: {
                message: output.message ? output.message.replace('(,', '') : 'SUCCEEDED',
                isSaving: false,
                isDeleting: false
              },
              project: {
                profileData
              },
              refData: {
                rgaColumns
              }
            }
          })
        } else {
          return dispatch({
            ...updatedAction,
            payload: {
              update: {
                message: output.message.replace('(,', ''),
                isSaving: false,
                isDeleting: false
              },
              project: {
                profileData
              },
              refData: {
                rgaColumns
              }
            }
          })
        }
      }

      return stepFunctionStatusLoop(
        executionArn,
        ({ status }) => ['FAILED', 'SUCCEEDED'].includes(status),
        moveOnAfterSourceUpdated
      )
    } else {
      return dispatch({
        ...updatedAction,
        payload: {
          update: {
            message: `${isDeleting ? 'Deleted' : 'Updated'} Rows` + resultRows,
            isSaving: false,
            isDeleting: false
          }
        }
      })
    }
  } catch (ex) {
    return dispatch({
      ...updatedAction,
      payload: {
        update: {
          message: ex.data ? ex.data.error : 'Network Error - Try Again',
          isSaving: false,
          isDeleting: false
        }
      }
    })
  }
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case updatingAction.type:
    case updatedAction.type:
    case openQueryAction.type:
    case updateOptionSetAction.type:
    case updatedOptionWhereAction.type:
      return {
        ...state,
        ...payload.update
      }

    case addedNewOptionSetAction.type: {
      const { updateSetConditions } = state
      updateSetConditions.push({
        columnName: '',
        operation: '=',
        whereValue: ''
      })
      return {
        ...state,
        updateSetConditions
      }
    }

    case addedNewOptionWhereAction.type: {
      const { updateWhereConditions } = state
      updateWhereConditions.push({ ...initialWhereState })
      return {
        ...state,
        updateWhereConditions
      }
    }

    case addedNewOptionDeleteAction.type: {
      const { deleteWhereConditions } = state
      deleteWhereConditions.push({ ...initialWhereState })
      return {
        ...state,
        deleteWhereConditions
      }
    }

    case removedOptionSetAction.type: {
      const { updateSetConditions } = state
      updateSetConditions.splice(payload, 1)
      return {
        ...state,
        updateSetConditions
      }
    }
    case removedOptionWhereAction.type: {
      const { index, isDelete = false } = payload
      const { deleteWhereConditions, updateWhereConditions } = state
      const target = isDelete ? 'deleteWhereConditions' : 'updateWhereConditions'
      const whereConditions = isDelete ? deleteWhereConditions : updateWhereConditions
      whereConditions.splice(index, 1)
      return {
        ...state,
        [target]: whereConditions
      }
    }
    case savedFormulaAction.type:
      return {
        ...state,
        ...payload.update
      }

    case setSubNavigationAction.type:
      return {
        ...state,
        message: ''
      }

    default:
      return state
  }
}
