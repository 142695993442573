const getMaxLength = (data, keysParameter) => {
  const keys = keysParameter || (data.length > 0 ? Object.keys(data[0]) : [])
  const maxCellWitdh = keys.length > 0
    ? data.reduce((s, a, k) => {
      if (k === 0) {
        keys.forEach(i => {
          s.push({
            label: i,
            length: (a[i] && a[i].toString().length > i.length)
              ? a[i].toString().length
              : i.toString().length
          })
        })
      } else {
        keys.forEach(x => {
          const currentKey = s.find(w => w.label === x)
          if (currentKey && a[x]) {
            currentKey.length = currentKey.length < a[x].toString().length
              ? a[x].toString().length
              : currentKey.length
          }
        })
      }
      return s
    }, [])
    : []
  return maxCellWitdh
}

export { getMaxLength }
