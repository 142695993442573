import PropTypes from 'prop-types'
import React from 'react'

const LockContext = React.createContext()

export default function useLock () {
  return React.useContext(LockContext)
}

export function LockProvider (props) {
  const [enabled, setEnabled] = React.useState(false)

  const enable = React.useCallback(
    () => setEnabled(true),
    [setEnabled]
  )

  const disable = React.useCallback(
    () => setEnabled(false),
    [setEnabled]
  )

  const value = React.useMemo(
    () => ({ enabled, enable, disable }),
    [enabled, enable, disable]
  )

  return (
    <LockContext.Provider value={value}>
      {props.children}
    </LockContext.Provider>
  )
}

LockProvider.propTypes = {
  children: PropTypes.node
}

LockProvider.defaultProps = {
  children: null
}
