import React from 'react'
import PropTypes from 'prop-types'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import InfoIcon from '@mui/icons-material/Info'
import CircularProgress from '@mui/material/CircularProgress'
import { useSelector } from 'react-redux'

const JSX_FILE_TOOLTIP_MAP = {
  skipMap: (
    <span>
      <b>TY: Treaty </b><br/>
      <b>RH: Risk History</b><br/>
      <b>I: Val Policy</b><br/>
      <b>T: Val Terminated</b><br/>
    </span>
  )
}

export default function DataLakeTable (props) {
  const {
    order,
    orderBy,
    onRequestSort,
    filesMap,
    addFilesMap,
    loadingMap
  } = props

  const {
    studyType
  } = useSelector(({
    project: {
      studyType
    }
  }) => ({
    studyType
  }))

  const headCells = [
    {
      id: '1',
      numeric: false,
      disablePadding: false,
      label: 'Data Set'
    },
    {
      id: '2',
      numeric: false,
      disablePadding: false,
      label: 'File Type ',
      icon: (
        <Tooltip
          title={JSX_FILE_TOOLTIP_MAP[studyType]}
        >
          <InfoIcon/>
        </Tooltip>
      )
    }
  ]

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label} {headCell.icon}

            </TableSortLabel>
            {headCell.id === '2' && loadingMap
              ? <CircularProgress />
              : headCell.id === '2' &&
                <Button
                  color="primary"
                  disabled={filesMap.length <= 0}
                  variant="contained"
                  onClick={addFilesMap}> {'Add ' + filesMap.length}
                </Button>
            }

          </TableCell>

        ))}</TableRow>
    </TableHead>
  )
}

DataLakeTable.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  validating: PropTypes.any,
  filesMap: PropTypes.any,
  addFilesMap: PropTypes.any,
  loadingMap: PropTypes.any
}
