export const startedProjectDataLoadAction = {
  type: 'project/STARTED_PROJECT_DATA_LOAD'
}

export const unauthorizedProjectDataLoadAction = {
  type: 'project/UNAUTHORIZED_PROJECT_DATA_LOAD'
}

export const loadedProjectDataAction = {
  type: 'project/LOADED_PROJECT_DATA'
}

export const updatedSourceFilesAction = {
  type: 'project/UPDATED_SOURCE_FILES'
}

export const setAccessListAction = {
  type: 'project/SET_ACCESS_LIST'
}

export const resetProjectDataAction = {
  type: 'project/RESET_PROJECT_DATA'
}

export const addedDataSourceAction = {
  type: 'project/ADDED_DATA_SOURCE'
}
export const addedEdpSourceAction = {
  type: 'project/ADDED_EDP_SOURCE'
}

export const setEdpUploadingStatusAction = {
  type: 'project/SET_EDP_UPLOADING'
}

export const setEdpFailedStatusAction = {
  type: 'project/SET_EDP_FAILED'
}

export const setEdpUploadedStatusAction = {
  type: 'project/SET_EDP_UPLOADED'
}

export const changedAction = {
  type: 'project/HANDLED_CHANGE'
}
export const changeSegmentReceiverAction = {
  type: 'project/HANDLED_SEGMENT_RECEIVER_CHANGE'
}

export const changedFileTypeAction = {
  type: 'project/CHANGED_FILE_TYPE'
}

export const addedFilesAction = {
  type: 'project/ADDED_FILES'
}

export const progressedUploadAction = {
  type: 'project/PROGRESSED_UPLOAD'
}

export const erroredUploadAction = {
  type: 'project/ERRORED_UPLOAD'
}

export const completedUploadAction = {
  type: 'project/COMPLETED_UPLOAD'
}

export const updatedProfilingAction = {
  type: 'project/UPDATED_PROFILING'
}

export const creatingProjectAction = {
  type: 'project/CREATING_PROJECT'
}

export const createdProjectAction = {
  type: 'project/CREATED_PROJECT'
}

export const updatingProjectAction = {
  type: 'project/UPDATING_PROJECT'
}

export const updatedProjectAction = {
  type: 'project/UPDATED_PROJECT'
}

export const saveFinishedAction = {
  type: 'project/SAVE_FINISHED'
}

export const deletedSourceFabricAction = {
  type: 'project/DELETED_SOURCE_FABRIC'
}

export const deleteSourceAction = {
  type: 'project/DELETE_SOURCE'
}

export const setProjectFileDirtyFlagAction = {
  type: 'project/SET_PROJECT_FILE_DIRTY'
}
