import PropTypes from 'prop-types'
import React from 'react'
import MuiFab from '@mui/material/Fab'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import AddIcon from '@mui/icons-material/Add'
import WhereMultiSelect from './whereList'
import { styled } from '@mui/material'
import { useDispatch } from 'react-redux'

import {
  addedNewOptionDeleteAction,
  addedNewOptionWhereAction
} from '../../../store/pages/common/update'

const Fab = styled(MuiFab)(() => ({
  marginLeft: 150
}))

export default function WhereList (props) {
  const dispatch = useDispatch()

  const updateActionKey = props.isDelete
    ? addedNewOptionDeleteAction
    : addedNewOptionWhereAction

  return (
    <Grid container>
      <Grid item xs={4}>
        <Typography variant="h6" align='left'>
          Filters
        </Typography>
      </Grid>

      <Grid item xs={8}>
        <Fab
          data-testid='button-add'
          size="small"
          color="primary"
          onClick={() => dispatch(updateActionKey)}
        >
          <AddIcon />
        </Fab>
      </Grid>

      <Grid item xs={12}>
        <WhereMultiSelect
          isDelete={props.isDelete}
        />
      </Grid>
    </Grid>
  )
}

WhereList.propTypes = {
  isDelete: PropTypes.bool
}
