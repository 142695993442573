import React from 'react'
import { Grid, TextField, styled } from '@mui/material'
import Typography from '@mui/material/Typography'
import moment from 'moment'
import Dialog from '@mui/material/Dialog'
import { useDispatch, useSelector } from 'react-redux'

import AddStep from './AddStepDialog'
import { setWorkFlowFieldAction } from '../../../store/pages/common/workflow'

const DescTextField = styled(TextField)(() => ({
  backgroundColor: 'white',
  width: '93%',
  '& > div > input': { height: '4rem' }
}))

const NameTextField = styled(TextField)(() => ({
  backgroundColor: 'white',
  maxWidth: '75%',
  '& > div > input': { height: '1vh' }
}))

const StyledDialog = styled(Dialog)(() => ({
  padding: '20px 24px'
}))

const StyledGrid = styled(Grid)(() => ({
  marginTop: '40px'
}))

const OuterGrid = styled(Grid)(() => ({
  margin: '25px 0px 0 0'
}))

const EachItemGrid = styled(Grid)(() => ({
  padding: '0rem'
}))

const StyledTypography = styled(Typography)(() => ({
  fontSize: '1.25rem',
  fontWeight: 'bold'
}))

function Info () {
  const dispatch = useDispatch()

  const workflow = useSelector((state) => state.workflow)

  const handleTextFieldChange = React.useCallback(function (event) {
    dispatch({
      ...setWorkFlowFieldAction,
      payload: {
        value: event.target.value,
        key: event.target.name
      }
    })
  }, [dispatch])

  return (<Grid container >
    <StyledDialog
      data-testid='dialog-info'
      open={workflow.openAddNewDialog}
      fullWidth={false}
    >
      <AddStep />
    </StyledDialog>
    <OuterGrid container direction="row">
      <Grid item xs={5}>
        <Grid container direction="column">
          <EachItemGrid container direction="row">
            <Grid item xs={4}>
              <StyledTypography>
                Workflow Name
                <span>:</span>
              </StyledTypography>
            </Grid>
            <Grid item xs={8}>
              <NameTextField
                data-testid='textField-name'
                variant="outlined"
                name='name'
                fullWidth
                disabled={workflow.isExecuting}
                value={workflow.name}
                onChange={handleTextFieldChange}
              />
            </Grid>
          </EachItemGrid>
          <StyledGrid></StyledGrid>
          <EachItemGrid container direction="row">
            <Grid item xs={4}>
              <StyledTypography>
                Create date
                <span>:</span>
              </StyledTypography>
            </Grid>
            <Grid item xs={8}>
              <StyledTypography
                data-testid='typography'
              >
                {workflow.createdDate
                  ? moment(workflow.createdDate).format('MM/DD/YYYY')
                  : moment().format('MM/DD/YYYY')}
              </StyledTypography>
            </Grid>
          </EachItemGrid>
          <EachItemGrid container direction="row">
            <Grid item xs={4}>
              <StyledTypography>
                Last update date
                <span>:</span>
              </StyledTypography>
            </Grid>
            <Grid item xs={8}>
              <StyledTypography
                data-testid='typography-updatedDate'
              >
                {workflow.updatedDate
                  ? moment(workflow.updatedDate).format('MM/DD/YYYY')
                  : ''}
              </StyledTypography>
            </Grid>
          </EachItemGrid>
        </Grid>
      </Grid>
      <Grid item xs={7}>
        <Grid container direction="column">
          <EachItemGrid container direction="row">
            <Grid item xs={4}>
              <StyledTypography>
                Description
                <span>:</span>
              </StyledTypography>
            </Grid>
            <Grid item xs={8}>
              <DescTextField
                data-testid='textField-desc'
                multiline
                minRows="5"
                variant="outlined"
                name='desc'
                disabled={workflow.isExecuting}
                value={workflow.desc}
                onChange={handleTextFieldChange}
              />
            </Grid>
          </EachItemGrid>
        </Grid>
      </Grid>
    </OuterGrid>
  </Grid>)
}

export default React.memo(Info)
