import { ResponsivePie } from '@nivo/pie'
import React from 'react'
import PropTypes from 'prop-types'
const pieData = (data) => {
  const realData = data.filter((x, i) => i !== 0).map((x, i) => ({ label: x.policy_status, id: x.policy_status, value: x.policy_count }))
  return realData
}
const PieChart = ({ data }) => {
  const pieDataFormat = pieData(data)
  return (<ResponsivePie
    data={pieDataFormat}
    margin={{ top: 10, right: 700, bottom: 40, left: 10 }}
    innerRadius={0.5}
    padAngle={0.7}
    cornerRadius={3}
    activeOuterRadiusOffset={8}
    colors={{ scheme: 'nivo' }}
    borderColor={{
      from: 'color',
      modifiers: [
        [
          'darker',
          3
        ]
      ]
    }}
    arcLinkLabelsSkipAngle={10}
    arcLinkLabelsTextColor="#333333"
    arcLinkLabelsThickness={2}
    arcLinkLabelsColor={{ from: 'color' }}
    arcLabelsSkipAngle={10}
    arcLabelsTextColor="#333333"
    legends={[
      {
        anchor: 'right',
        direction: 'column',
        justify: false,
        translateX: 1,
        translateY: 0,
        itemsSpacing: 2,
        itemWidth: 60,
        itemHeight: 14,
        itemTextColor: '#999',
        itemDirection: 'left-to-right',
        itemOpacity: 1,
        symbolSize: 14,
        symbolShape: 'triangle'
      }
    ]}
  />
  )
}
PieChart.propTypes = {
  data: PropTypes.any,
  title: PropTypes.any
}

export default PieChart
