import React from 'react'
import Grid from '@mui/material/Grid'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import AnnouncementIcon from '@mui/icons-material/Announcement'
import LaunchIcon from '@mui/icons-material/Launch'
import { useDispatch, useSelector } from 'react-redux'

import { styled } from '@mui/material'
import ReleaseNotes from './releaseNotes'
import Announcements from './announcements'
import {
  getAdminData,
  setDialogDataAction,
  updateAdminData
} from '../../store/pages/adminTool'

const StyledTabs = styled(Tabs)(({ theme }) => ({
  marginTop: theme.spacing(2),
  flexDirection: 'row'
}))

const StyledTab = styled(Tab)({
  color: '#000000de',
  padding: '9px 12px 6px 12px',
  marginRight: '16px',
  '&:hover': {
    color: '#c62728'
  },
  '&.Mui-selected': {
    color: 'black'
  }
})

const getTabContents = (dispatch, tab, releaseData, announcementData) => {
  switch (tab) {
    case 'Announcements':
      return (
        <Announcements
          data-testid='view-announcement'
          data={announcementData}
          update={updateData => dispatch(updateAdminData(updateData))}
        />
      )

    case 'Release Notes':
      return (
        <ReleaseNotes
          data-testid='view-releaseNote'
          data={releaseData}
          update={updateData => dispatch(updateAdminData(updateData))}
        />
      )
  }
}

const AdminSettings = () => {
  const dispatch = useDispatch()
  const {
    releaseData,
    announcementData,
    refresh,
    load,
    role,
    sid
  } = useSelector(({
    adminTool: {
      releaseData,
      announcementData,
      refresh,
      load
    },
    user: {
      role,
      sid
    }
  }) => ({
    releaseData,
    announcementData,
    refresh,
    load,
    role,
    sid
  }))

  const [currentTab, setCurrentTab] = React.useState('Release Notes')
  const admin = role === 'admin'

  if ((!load || refresh) && admin) {
    dispatch(getAdminData)
  }
  return (
    admin
      ? <Grid
          container
          data-testid={'grid-tab'}>
            <Grid item xs={12}>
              <StyledTabs
                data-testid={'tabs-switching'}
                sx={{ backgroundColor: 'white', color: 'red' }}
                textColor='primary'
                value={currentTab}
                centered align='middle'
                >
                <StyledTab
                  data-testid={'tab-release'}
                  value={'Release Notes'}
                  icon={<LaunchIcon />}
                  iconPosition="top"
                  label={'Release Notes'}
                  onClick={() => {
                    setCurrentTab('Release Notes')
                    dispatch({ ...setDialogDataAction, payload: null })
                  }}/>
                <StyledTab
                  data-testid={'tab-announcements'}
                  value={'Announcements'}
                  icon={<AnnouncementIcon />}
                  label={'Announcements'}
                  onClick={() => {
                    setCurrentTab('Announcements')
                    dispatch({ ...setDialogDataAction, payload: null })
                  }} />
              </StyledTabs>
            </Grid>
            <Grid item xs={12}>
              <hr/>
            </Grid>
              {
                getTabContents(
                  dispatch,
                  currentTab,
                  releaseData,
                  announcementData
                )
              }
          </Grid>
      : <Grid container>
              <Grid item xs={12}>
                <div
                  data-testid={'role-message'}
                  style={{ marginTop: 20 }}
                >
                  Hello {sid},&nbsp;
                  your current role is <em>{role || 'unknown'}</em>.
                  You are not allowed to access the admin page.
                </div>
              </Grid>
            </Grid>
  )
}

AdminSettings.propTypes = {}

export default AdminSettings
