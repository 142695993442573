import React from 'react'
import useCheckIfFileIsProfiling from './hooks/useCheckifFileIsProfiling'
import useFileProfileComplete from './hooks/useFileProfilingComplete'
import List from './list'
import Upload from './upload'
import Query from './query'
import Update from './update'
import Insert from './insert'
import Convert from './convert'
import { useLocation } from 'react-router-dom'

export default function Reference () {
  const location = useLocation()

  const view = location.pathname.split('/')[3] || 'list'

  useCheckIfFileIsProfiling()
  useFileProfileComplete()

  switch (view) {
    case 'list':
      return <List />

    case 'upload':
      return <Upload />

    case 'query':
      return <Query />

    case 'update':
      return <Update />

    case 'insert':
      return <Insert />

    case 'convert':
      return <Convert />

    default:
      return <List />
  }
}
