module.exports = (stepResult) => {
  let errMsg
  let profilingStatus
  if (stepResult.status === 'SUCCEEDED' && stepResult.output) {
    const out = JSON.parse(stepResult.output)
    if (out.errorInfo) {
      profilingStatus = 'FAILED'
      const cause = JSON.parse(out.errorInfo.Cause)
      errMsg = cause.ErrorMessage
    } else if (out.profileSql && out.columns) {
      profilingStatus = 'SUCCEEDED'
    }
  } else {
    profilingStatus = 'FAILED'
  }
  return { status: profilingStatus, error: errMsg }
}
