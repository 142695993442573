import React, { useState } from 'react'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import Tooltip from '@mui/material/Tooltip'
import InfoIcon from '@mui/icons-material/Info'
import Grid from '@mui/material/Grid'
import Checkbox from '@mui/material/Checkbox'
import { useDispatch } from 'react-redux'
import { piiTooltip } from '../../upload/inputQuestionsToolTips'
import { Accordion, AccordionSummary, AccordionDetails } from '../../../themes/AccordionTheme'
import Comments from './Comments'
import AcknowledgeButton from './AcknowledgeButton'
import { updateComments } from '../../../store/pages/review'
import { styled } from '@mui/material'

const AccordionTitle = styled(Typography)(({
  fontWeight: 500,
  fontSize: '1.25rem'
}))

const Heading = styled(Typography)(({
  padding: '8px'
}))

const ClaimsTableWrapper = styled(AccordionDetails)(({
  display: 'block'
}))

const TerminationTableWrapper = styled('div')(({
  display: 'flex'
}))

const AccordionWrapper = styled(Accordion)(({
  marginBottom: '10px',
  border: '1px solid #55645896 !important'
}))

const getObject = (keyValue, data, access, dispatch) => {
  const handleCheckBoxClickNo = () => {
    data.piiReview = 'NO'
    dispatch(updateComments(data))
  }

  const handleCheckBoxClickYes = () => {
    data.piiReview = 'YES'
    dispatch(updateComments(data))
  }

  switch (keyValue) {
    case 'piiComment':
    {
      return (<div>
        <Heading variant="h6">
            Please review all the source columns for PII.
        </Heading>
        <Heading variant="subtitle1">
          <Tooltip title={piiTooltip} >
            <InfoIcon/>
          </Tooltip>
          Does the Project contain PII?
        </Heading>
        NO
        <Checkbox
          data-testid='check-no'
          disabled={!access || data.flagAcknowledge}
          variant="outlined"
          color="primary"
          onClick={handleCheckBoxClickNo}
          checked={data.piiReview === 'NO'}
        />
        YES
        <Checkbox
          data-testid='check-yes'
          disabled={!access || data.flagAcknowledge}
          variant="outlined"
          color="primary"
          onClick={handleCheckBoxClickYes}
          checked={data.piiReview === 'YES'}
        />
      </div>)
    }
    case 'duplicates':
    {
      return (<div data-testid='duplicates'>
        {`Based on the users latest mappings ${data.sumDuplicates} duplicates were found.`}
      </div>)
    }
    default:
      break
  }
}

function DynamicGrid ({ title, objectData, keyValue, access, projectId }) {
  const dispatch = useDispatch()

  const [expandedPanel, setExpandedPanel] = useState(false)

  const handleAccordionChange = panel => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : false)
  }

  objectData.key = keyValue
  objectData.title = title
  objectData.projectId = projectId

  return (<AccordionWrapper
            data-testid='accordion-PII'
            expanded={expandedPanel === 'panel1'}
            onChange={handleAccordionChange('panel1')}
          >
    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
      <Grid container>
        <Grid item xs={10}>
          <AccordionTitle>{title}</AccordionTitle>
        </Grid>
        <Grid item xs={2}>
          <AcknowledgeButton
            objectData={objectData}
            access={access}
          />
        </Grid>
      </Grid>
    </AccordionSummary>
    <ClaimsTableWrapper>
      <TerminationTableWrapper>
        {getObject(keyValue, objectData, access, dispatch)}
      </TerminationTableWrapper>
      <Comments
        objectData={objectData}
        access={access}
      />
    </ClaimsTableWrapper>
  </AccordionWrapper>)
}

DynamicGrid.propTypes = {
  data: PropTypes.any,
  headers: PropTypes.any,
  title: PropTypes.any,
  objectData: PropTypes.any,
  keyValue: PropTypes.any,
  access: PropTypes.any,
  projectId: PropTypes.any
}

export default DynamicGrid
