import React from 'react'
import MuiTabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { useLocation } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material'
import { setCommonFile } from '../store/pages/navigation'

const Tabs = styled(MuiTabs)(({ theme }) => ({
  marginTop: theme.spacing(1)
}))

const REDI_TYPE = {
  I: 'Inforce',
  T: 'Termination',
  IT: 'inforce+termination',
  'val policy': 'Val Policy',
  'val terminated': 'Val Terminated',
  'evexp risk history': 'evexp risk history',
  treaty: 'treaty',
  RH: 'evexp risk history'
}

const SKIP_MAP_REDI = {
  'val policy': 'Val Policy',
  'val terminated': 'Val Terminated',
  treaty: 'treaty',
  'evexp risk history': 'evexp risk history'
}

const getMapType = (studyType) => {
  switch (studyType) {
    case 'redi':
      return REDI_TYPE
    case 'skipMap':
      return SKIP_MAP_REDI
  }
}

export default function PreCalculationNavigation () {
  const location = useLocation()

  const {
    studyType,
    formatFiles
  } = useSelector(store => store.project)

  const currentTab = useSelector(store => store.navigation.currentTab)

  const dispatch = useDispatch()

  const fileMap = getMapType(studyType)

  if (formatFiles.length === 0) {
    return ''
  }

  const renderNavTabs = ['precalculation'].some(substring => location.pathname.indexOf(substring) !== -1)
  if (!renderNavTabs) {
    return ''
  }

  return (
    <Grid container >
      <Grid item xs={12}>
        <Tabs
          data-testid='tabs-preCal'
          value={currentTab || 'precalCheck'}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs"
          onChange={e => {
            dispatch(setCommonFile(e.currentTarget.attributes.fileType.nodeValue))
          }}
        >
          <Tab
            key={'precalCheck'}
            value={'precalCheck'}
            filetype={'precalCheck'}
            label={'Precalculation Checks'}
          />
          {
            formatFiles.map((file, index) =>
              <Tab
                data-testid={`tab-file-type-${index}`}
                key={index}
                filetype={file.type}
                value={file.type}
                label={fileMap[file.type]}
                />
            )
          }
        </Tabs>
      </Grid>
    </Grid>
  )
}
