import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import DuplicateAggregators from './duplicateAggregators'
import LapseSkewInput from './LapseSkewInput'
import { updateWizardStepAction } from '../../../store/pages/preCalculation'

const sortColumns = (columns, rgaColumns, fileType) => {
  const unSortedColumns = columns.map(querySelectField => {
    const order = rgaColumns.find(x => x.name === querySelectField.Name)
    if (!order) {
      return { ...querySelectField, order: querySelectField.Name }
    }
    const orderMain = fileType === 'T' && order.orderT ? order.orderT : order.order
    return { ...querySelectField, order: orderMain }
  })

  unSortedColumns.forEach(x => {
    const columnWithPrimaryKey = rgaColumns.find(w => w.name === x.Name)
    if (columnWithPrimaryKey) {
      x.isPrimaryKey = x.editKey ? x.isPrimaryKey : (columnWithPrimaryKey.isPrimaryKey || ['INVENTORY_DATE', 'UNIQUE_IDENTIFIER'].indexOf(x.Name) >= 0)
    }
    x.aggregator = !x.aggregator ? { value: 'max', label: 'max' } : x.aggregator
  })
  return unSortedColumns.sort((a, b) => a.order - b.order)
}

const FixInfo = ({ data, rgaColumns, fixWizard, formatFiles, studyType }) => {
  const dispatch = useDispatch()
  switch (data.checkName) {
    case 'DUPLICATES_I': {
      const currentFile = fixWizard && !fixWizard[data.checkName] ? formatFiles.find(x => x.type === 'I') : fixWizard[data.checkName]
      const columns = sortColumns(currentFile.columns, rgaColumns, 'I')
      if (!fixWizard[data.checkName]) {
        dispatch({
          ...updateWizardStepAction,
          payload: {
            type: data.checkName,
            object: { ...currentFile, columns }
          }
        })
      }
      return <DuplicateAggregators
        studyType={studyType}
        data={data}
        currentFile={currentFile}
        rgaColumns={rgaColumns}
        fixWizard={fixWizard}
        columns={columns}
        checkName='DUPLICATES_I'
        type='I' />
    }
    case 'DUPLICATES_T': {
      const currentFile = fixWizard && !fixWizard[data.checkName] ? formatFiles.find(x => x.type === 'T') : fixWizard[data.checkName]
      const columns = sortColumns(currentFile.columns, rgaColumns, 'T')
      if (!fixWizard[data.checkName]) {
        dispatch({
          ...updateWizardStepAction,
          payload: {
            type: data.checkName,
            object: { ...currentFile, columns }
          }
        })
      }
      return <DuplicateAggregators
        studyType={studyType}
        data={data}
        currentFile={currentFile}
        rgaColumns={rgaColumns}
        fixWizard={fixWizard}
        columns={columns}
        checkName='DUPLICATES_T' type='T' />
    }
    case 'LAPSE_SKEWNESS_T':
      return <LapseSkewInput data={data} checkName='LAPSE_SKEWNESS_T' type='T' />
    default:
      return (<div></div>)
  }
}

FixInfo.propTypes = {
  data: PropTypes.any,
  rgaColumns: PropTypes.any,
  fixWizard: PropTypes.any,
  formatFiles: PropTypes.any,
  studyType: PropTypes.any
}

export default FixInfo
