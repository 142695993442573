import { RED_SUPPORT_EMAIL } from '../../../stringConstants'

export default {
  messageMap: {
    redi: [
      { code: 1, message: 'Submitting Load Study Input Process' },
      { code: 2, message: 'Combining Data by File Type' },
      { code: 3, message: 'Combining Data  Completed. Starting Pre-Calculation Checks' },
      { code: 4, message: 'Failed' },
      { code: 5, message: 'Failed - Returned Errors.' },
      { code: 6, message: 'Running Pre-Calculation Checks' },
      { code: 7, message: 'Found validation Issues. Please Review And Fix Them.' },
      { code: 8, message: 'Pre-Calculation Checks | Succeeded. Starting Process To Create Common Format Tables' },
      { code: 9, message: 'Creating Common Format Tables' },
      { code: 10, message: 'Creating Common Format Tables | Running' },
      { code: 11, message: 'Failed' },
      { code: 12, message: 'Common Format Tables Completed' },
      { code: 13, message: `Step-Function Failed, Please Contact ${RED_SUPPORT_EMAIL}` }
    ],
    skipMap: [
      { code: 1, message: 'Submitting Load Study Input Process' },
      { code: 2, message: 'Combining Data by File Type' },
      { code: 3, message: 'Combining Data  Completed. Starting Pre-Calculation Checks' },
      { code: 4, message: 'Failed' },
      { code: 5, message: 'Failed - Returned Errors.' },
      { code: 6, message: 'Running Pre-Calculation Checks' },
      { code: 7, message: 'Found validation Issues. Please Review And Fix Them.' },
      { code: 8, message: 'Pre-Calculation Checks | Succeeded. Starting Process To Create Common Format Tables' },
      { code: 9, message: 'Creating Common Format Tables' },
      { code: 10, message: 'Creating Common Format Tables | Running' },
      { code: 11, message: 'Failed' },
      { code: 12, message: 'Common Format Tables Completed' },
      { code: 13, message: `Step-Function Failed, Please Contact ${RED_SUPPORT_EMAIL}` }
    ]
  },
  steps: {
    redi: [
      { code: 1, message: 'Submitting Load Study Input Process', step: 0 },
      { code: 2, message: 'Combining Data by File Type', step: 1 },
      { code: 3, message: 'Combining Data Completed. Starting Pre-Calculation Checks', step: 2 },
      { code: 6, message: 'Running Pre-Calculation Checks', step: 3 },
      { code: 9, message: 'Creating Common Format Tables', step: 4 },
      { code: 12, message: 'Common Format Tables Completed', step: 5 }
    ],
    skipMap: [
      { code: 1, message: 'Submitting Load Study Input Process', step: 0 },
      { code: 2, message: 'Combining Data by File Type', step: 1 },
      { code: 3, message: 'Combining Data Completed. Starting Pre-Calculation Checks', step: 2 },
      { code: 6, message: 'Running Pre-Calculation Checks', step: 3 },
      { code: 9, message: 'Creating Common Format Tables', step: 4 },
      { code: 12, message: 'Common Format Tables Completed', step: 5 }
    ]
  }
}
