import React from 'react'
import Icon from '@mui/material/Icon'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import { styled } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { changedInsertValueAction, insert } from '../../../store/pages/common/insert'

const InsertRoot = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  width: '100%',
  backgroundColor: '#DEDEDE'
}))

const InsertTextField = styled(TextField)({
  width: 400,
  backgroundColor: 'white'
})

const InsertButtonBar = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(2)
}))

const ExecuteButton = styled(Button)(({ theme }) => ({
  color: 'primary',
  variant: 'outlined'
}));

export default function Insert () {
  const dispatch = useDispatch()

  const {
    id,
    isSaving,
    message,
    insertStatement,
    files,
    columns
  } = useSelector(({
    insert: {
      isSaving,
      message,
      insertStatement
    },
    reference: {
      referenceTable: {
        guid: id,
        files
      },
      referenceSourceInfo: {
        columns
      }
    }
  }) => ({
    id,
    isSaving,
    message,
    insertStatement,
    files,
    columns
  }))

  const handleExecute = (tableName, id) => {
    // @ts-ignore
    dispatch(insert(tableName, id))
  }

  const tableName = files[0]?.guid

  return (
    <InsertRoot>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={9}>
            <Typography variant="h6">
              Insert
            </Typography>
          </Grid>

          <Grid item xs={3}></Grid>

          {columns.map((column, index) =>
            <Grid container key={index}>
              <Grid item xs={8}>
                <Typography variant="h6">
                  <Icon color="primary">*</Icon>

                  {`${column.Name.toUpperCase()} (${column.Type})`}
                </Typography>

                <InsertTextField
                  data-testid={`textfield-${index}`}
                  variant="outlined"
                  value={insertStatement[column.Name] !== undefined
                    ? insertStatement[column.Name].value
                    : ''
                  }
                  onChange={event => dispatch({
                    ...changedInsertValueAction,
                    payload: {
                      name: column.Name,
                      type: column.Type,
                      value: event.target.value
                    }
                  })}
                />
              </Grid>

              <Grid item xs={4}/>
            </Grid>
          )}

          <InsertButtonBar item xs={12} >
            {
              isSaving
                ? <CircularProgress />
                : <ExecuteButton
                  variant="outlined"
                  color="primary"
                  startIcon={<PlayArrowIcon />}
                  onClick={() => handleExecute(tableName, id)}
                >EXECUTE</ExecuteButton>
            }
          </InsertButtonBar>

          <Grid item xs={12}>
            <Typography variant="h6">{message}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </InsertRoot>
  )
}
