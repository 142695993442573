import React from 'react'
import ReactCreatableSelect from 'react-select/creatable'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'
import { styled } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'

import {
  SingleValue,
  SingleValueStyles
} from '../../../components/sql/SelectSingleValue'

import {
  removedFieldAggregationAction,
  selectedFieldAggregationAction
} from '../../../store/pages/dataPrep'

const COMPONENT_SINGLE = {
  SingleValue
}

const OPTIONS_STRING = [
  'max',
  'min',
  'count'
].map(item => ({
  value: item,
  label: item
}))

const OPTIONS_NUMERIC = [
  'sum',
  'min',
  'max',
  'avg',
  'count'
].map(item => ({
  value: item,
  label: item
}))

const Root = styled(Box)(({ theme }) => ({
  display: 'block',
  overflow: 'scroll',
  padding: theme.spacing(2),
  height: 400,
  backgroundColor: '#D3D3D3',
  borderRadius: '4px'
}))

const Grid = styled(Box)(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(2),
  gridTemplateColumns: '1fr 1fr 1fr',
  gridAutoRows: 'auto'
}))

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1)
}))

const ItemRow = styled(Box)(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(1),
  gridTemplateColumns: '1fr auto',
  alignItems: 'center'
}))

const CreatableSelect = styled(ReactCreatableSelect)({
  minHeight: 58
})

const getOptions = (fields, field) => {
  const currentField = fields.find(a => a.Name === field)
  const type = currentField ? currentField.Type : ''

  switch (type) {
    case 'varchar':
    case 'character varying':
    case 'date':
      return OPTIONS_STRING

    case 'int':
    case 'integer':
    case 'bigint':
    case 'double precision':
    case 'double':
    case 'numeric':
      return OPTIONS_NUMERIC

    default:
      throw new RangeError(`Invalid type: ${type}`)
  }
}

export default function Aggregate () {
  const dispatch = useDispatch()
  const navigation = useSelector(store => store.navigation)
  const dataPrep = useSelector(store => store.dataPrep)

  const checkAggregators = dataPrep.aggregators.selectedAggregators
    .filter(agg => navigation.file.columns.find(col => col.Name === agg))

  const fieldsAggCheck = dataPrep.aggregators.fieldsAgg
    .filter(agg =>
      checkAggregators.some(word =>
        agg.value === word
      )
    )

  const aggregations = checkAggregators.map(item => {
    const current = fieldsAggCheck.find(check => check.value === item)

    return {
      field: item,
      value: current?.agg
    }
  })

  return (
    <Root>
      <Grid>
        {aggregations.map((item, index) => (
          <Item key={item.field} elevation={3}>
            <ItemRow>
              <Typography
                data-testid={`label-field-${index}`}
                variant="subtitle2"
                gutterBottom
              >{item.field}</Typography>

              <IconButton
                data-testid={`button-remove-${index}`}
                size="small"
                onClick={() => dispatch({
                  ...removedFieldAggregationAction,
                  payload: {
                    remove: item.field
                  }
                })}
              >
                <ClearRoundedIcon fontSize="inherit" />
              </IconButton>
            </ItemRow>

            <CreatableSelect
              data-testid={`select-value-${index}`}
              styles={SingleValueStyles}
              components={COMPONENT_SINGLE}
              options={getOptions(navigation.file.columns, item.field)}
              value={item.value}
              onChange={value => dispatch({
                ...selectedFieldAggregationAction,
                payload: {
                  value: item.field,
                  agg: value
                }
              })}
            />
          </Item>
        ))}
      </Grid>
    </Root>
  )
}
