import React from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { loadData } from '../../../store/pages/project/async'

const PATHS_TO_EXCLUDE = ['callback', 'reference']

export default () => {
  const location = useLocation()
  const dispatch = useDispatch()

  const
    ownerId =
   useSelector(({
     user
   }) => (
     user.ownerId
   ))

  React.useEffect(() => {
    const paths = location.pathname.split('/')
    // eslint-disable-next-line
    const [_ignored, path, projectId] = paths

    const id = !PATHS_TO_EXCLUDE.includes(path) ? projectId : undefined

    if (ownerId) {
      dispatch(loadData(id))
    }
  }, [
    ownerId,
    location.pathname,
    dispatch
  ])
}
