import React from 'react'
import { useDispatch } from 'react-redux'
import { clearNewColumnAction } from '../../../store/pages/dataPrep'

export default function useClearHook () {
  const dispatch = useDispatch()

  React.useEffect(() => {
    return () => {
      dispatch(clearNewColumnAction)
    }
  }, [dispatch])
}
