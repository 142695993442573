import uuid from '../../uuid'
import apiHelper from './apiHelper'
import { Auth } from 'aws-amplify'
import { Upload } from '@aws-sdk/lib-storage'
import { S3Client } from '@aws-sdk/client-s3'

import {
  CognitoIdentityClient,
  GetIdCommand,
  GetOpenIdTokenCommand
} from '@aws-sdk/client-cognito-identity'

import {
  STSClient,
  AssumeRoleWithWebIdentityCommand
} from '@aws-sdk/client-sts'

const CLIENT_CONFIG = {
  region: 'us-east-1'
}

const identityClient = new CognitoIdentityClient(CLIENT_CONFIG)
const stsClient = new STSClient(CLIENT_CONFIG)

let _s3Client = null

async function authorize (ownerId, region) {
  const sessionId = uuid()
  const session = await Auth.currentSession()
  const token = session.getIdToken().getJwtToken()

  const { IdentityId } = await identityClient.send(new GetIdCommand({
    IdentityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    Logins: {
      [process.env.REACT_APP_USERPOOLCOGNITOID]: token
    }
  }))

  const openIdTokenRes = await identityClient.send(new GetOpenIdTokenCommand({
    IdentityId,
    Logins: {
      [process.env.REACT_APP_USERPOOLCOGNITOID]: token
    }
  }))

  const openIdToken = openIdTokenRes.Token

  const { Credentials } = await stsClient.send(
    new AssumeRoleWithWebIdentityCommand({
      RoleArn: process.env.REACT_APP_USERUPLOADROLEARN,
      RoleSessionName: sessionId,
      WebIdentityToken: openIdToken
    })
  )

  /*
   The `Credentials` object returns its property names in PascalCase, but
   `S3Client` wants its property names in camelCase. I submitted a migration
   issue on GitHub for this: https://github.com/aws/aws-sdk-js-v3/issues/6296.
  */

  _s3Client = new S3Client({
    region,
    credentials: {
      accessKeyId: Credentials.AccessKeyId,
      secretAccessKey: Credentials.SecretAccessKey,
      sessionToken: Credentials.SessionToken,
      expiration: Credentials.Expiration
    }
  })
}

const upload = (file, id, progressAction, completeAction, errorAction, profile, country) => async (dispatch, getState) => {
  file.guid = uuid()

  const instance = new Upload({
    client: _s3Client,
    params: {
      Bucket: apiHelper.getBucketName(country),
      Key: `${id}/${file.guid}/${file.name}`,
      Body: file,
      profile
    }
  })

  try {
    await instance
      .on('httpUploadProgress', progress => {
        dispatch({
          ...progressAction,
          payload: {
            guid: file.guid,
            progress: Math.round(progress.loaded / progress.total * 100)
          }
        })
      })
      .done()

    await dispatch(completeAction(file.guid))
  } catch (err) {
    console.error(err)

    if (errorAction.type) {
      dispatch({
        ...errorAction,
        payload: {
          guid: file.guid,
          error: 'Error Uploading s3'
        }
      })
    } else {
      await dispatch(errorAction(file.guid))
    }
  }
}

export default {
  authorize,
  upload
}
