import PropTypes from 'prop-types'
import React from 'react'
import Box from '@mui/material/Box'
import ParametersTable from './ParametersTable'
import SelectionTable from './SelectionTable'
import StatsTable from './StatsTable'
import OutputTable from './OutputTable'
import { styled } from '@mui/material'

const Grid = styled(Box)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  padding: theme.spacing(2),
  gap: theme.spacing(2),
  flexDirection: 'row',
  backgroundColor: '#ECEFF1',
  fontSize: '14px'
}))

export default function JobRun (props) {
  return (
    <Grid>
      <ParametersTable
        data-testid='table-parameters'
        parameters={props.job.parameters}
      />

      <SelectionTable
        data-testid='table-aggregators'
        title='Aggregators'
        items={props.job.aggregators}
      />

      <SelectionTable
        data-testid='table-bases'
        title='Expected Bases'
        items={props.job.bases}
      />

      <StatsTable
        data-testid='table-stats'
        job={props.job}
      />

      <OutputTable
        data-testid='table-output'
        job={props.job}
      />
    </Grid>
  )
}

JobRun.propTypes = {
  job: PropTypes.shape({
    id: PropTypes.string,
    finished: PropTypes.bool,
    totalTime: PropTypes.number,
    fileProcessingTime: PropTypes.number,
    xpCalculatorRunTime: PropTypes.number,
    dataLakeLoadTime: PropTypes.number,
    valPolicySize: PropTypes.number,
    valTerminateSize: PropTypes.number,
    riskHistorySize: PropTypes.number,
    treatySize: PropTypes.number,
    errorMessage: PropTypes.string,
    createdDate: PropTypes.string,
    deletedDate: PropTypes.string,
    bases: PropTypes.arrayOf(PropTypes.string),
    aggregators: PropTypes.arrayOf(PropTypes.string),
    tableNames: PropTypes.arrayOf(PropTypes.string),
    parameters: PropTypes.shape({
      applyIncidenceRateCap: PropTypes.bool,
      addMonthlyAggregation: PropTypes.bool,
      applySubstandardFlatExtra: PropTypes.bool,
      applyExpectedLapseRates: PropTypes.bool,
      treatyRestrictions: PropTypes.string,
      policyRestrictions: PropTypes.string,
      observationDate: PropTypes.string,
      periodStartDate: PropTypes.string,
      periodEndDate: PropTypes.string
    })
  })
}

/* istanbul ignore next */
JobRun.defaultProps = {
  job: {
    id: '',
    finished: false,
    totalTime: 0,
    fileProcessingTime: 0,
    xpCalculatorRunTime: 0,
    dataLakeLoadTime: 0,
    valPolicySize: 0,
    valTerminateSize: 0,
    riskHistorySize: 0,
    treatySize: 0,
    errorMessage: '',
    createdDate: '',
    deletedDate: null,
    bases: [],
    aggregators: [],
    tableNames: [],
    parameters: {
      applyIncidenceRateCap: false,
      addMonthlyAggregation: false,
      applySubstandardFlatExtra: false,
      applyExpectedLapseRates: false,
      treatyRestrictions: '',
      policyRestrictions: '',
      observationDate: '',
      periodStartDate: '',
      periodEndDate: ''
    }
  }
}
