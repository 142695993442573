import React from 'react'
import MuiTypography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import ReactCreatableSelect from 'react-select/creatable'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { SingleValue, SingleValueStyles } from '../../../../components/sql/SelectSingleValue'
import { selectAggregationAction, removedSelectedAggregatorAction } from '../../../../store/pages/common/workflow'

const StyledGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  backgroundColor: '#D3D3D3',
  flexWrap: 'wrap',
  '& > *': {
    margin: theme.spacing(3)
  },
  height: 400,
  overflow: 'scroll'
}))

const CreatableSelect = styled(ReactCreatableSelect)(({ theme }) => ({
  minHeight: 58,
  margin: theme.spacing(1)
}))

const Typography = styled(MuiTypography)(({
  padding: '5px 5px 5px 5px'
}))

const getOptions = (fields, field) => {
  const currentField = fields.find(a => a.Name === field)
  const type = currentField ? currentField.Type : ''
  switch (type) {
    case 'varchar':
    case 'character varying':
    case 'date':
      return ['max', 'min', 'count'].map(x => ({ value: x, label: x }))
    case 'int':
    case 'integer':
    case 'bigint':
    case 'double precision':
    case 'double':
    case 'numeric':
    default:
      return ['sum', 'min', 'max', 'avg', 'count'].map(x => ({ value: x, label: x }))
  }
}

function Aggregate (props) {
  const dispatch = useDispatch()

  const navigation = useSelector((state) => state.navigation)

  const { selectedAggregators, fieldsAgg } = props.state.state
  const selectedAggregatorsCheck = selectedAggregators.filter(a => navigation.file.columns.find(x => x.Name === a))
  const fieldsAggCheck = fieldsAgg.filter((a) => selectedAggregatorsCheck.some(word => a.value === word))
  const aggregations = selectedAggregatorsCheck.reduce((s, a) => {
    const current = fieldsAggCheck.find(x => x.value === a)
    if (current) {
      s.push({ field: a, value: current.agg })
    } else {
      s.push({ field: a })
    }
    return s
  }, [])
  return (
    <StyledGrid container>
      {aggregations && aggregations.map((e, i) =>
        <Grid key={i} item xs={3}>
          <Paper elevation={3} key={i}>
            <Typography variant="subtitle2" gutterBottom>
              {e.field}
              <IconButton
              data-testid='button-delete-1'
              aria-label="delete"
              size="small"
              onClick={() => dispatch({ ...removedSelectedAggregatorAction, payload: { remove: e.field, order: props.order } })}>
                <ClearRoundedIcon fontSize="inherit" />
              </IconButton>
            </Typography>

            <CreatableSelect
              data-testid='creatable-select'
              name={`aggregate-${i}!`}
              styles={SingleValueStyles}
              placeholder=""
              value={e.value}
              components={{ SingleValue }}
              options={getOptions(navigation.file.columns, e.field)}
              onChange={(newValue) => {
                dispatch({ ...selectAggregationAction, payload: { field: e.field, agg: newValue, order: props.order } })
              }}
            />
          </Paper>
        </Grid>
      )}
    </StyledGrid>
  )
}

Aggregate.propTypes = {
  state: PropTypes.any,
  actions: PropTypes.any,
  order: PropTypes.any
}

export default React.memo(Aggregate)
