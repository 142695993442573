import apiHelper from '../../objects/apiHelper'

const ws = { dataQuery: null }

const DEFAULT_QUERY_SELECTED_FIELDS = [
  {
    value: '*',
    label: '* (all)'
  }
]

const DEFAULT_ORDER_BY_CONDITIONS = [
  {
    value: '*',
    label: '* (all)'
  }
]

const DEFAULT_ORDER_BY = {
  value: 'asc',
  label: 'Asc'
}

const INITIAL_STATE = {
  isLoading: false,
  isDownloading: false,
  resultsGrid: {
    isLoading: false,
    columns: [],
    data: [],
    count: 0,
    queryRun: 'start'
  }
}

export const resetQueryAction = {
  type: 'dataQuery/RESET_QUERY'
}

export const queryingDatabaseAction = {
  type: 'dataQuery/QUERYING_DATABASE'
}

export const queriedDatabaseAction = {
  type: 'dataQuery/QUERIED_DATABASE'
}

export const downloadingQueryAction = {
  type: 'dataQuery/DOWNLOADING_QUERY'
}

export const downloadedQueryAction = {
  type: 'dataQuery/DOWNLOADED_QUERY'
}

export const queryDatabase = (
  {
    id: projectId,
    isPrecalculation,
    orderByConditions,
    paging,
    querySelectFields,
    queryWhereConditions,
    tableName
  }

) => async (dispatch, getState) => {
  const {
    project: { country }
  } = getState()
  dispatch(queryingDatabaseAction)
  const url = await apiHelper.getWebsocketUrl(country)
  if (ws.dataQuery) {
    ws.dataQuery.close()
  }
  ws.dataQuery = await new WebSocket(url)
  const body = {
    projectId,
    tableName,
    querySelectFields,
    queryWhereConditions,
    paging,
    isPrecalculation,
    orderByConditions
  }

  ws.dataQuery.onopen = () => {
    ws.dataQuery.send(`{"action":"onquery","body":${JSON.stringify(body)}}`)
    ws.dataQuery.onmessage = e => {
      ws.dataQuery.close()
      const queryResults = JSON.parse(e.data)
      if (queryResults.results) {
        return dispatch({
          ...queriedDatabaseAction,
          payload: {
            resultsGrid: {
              isLoading: false,
              columns: queryResults.results.length > 0 ? Object.keys(queryResults.results[0]) : [],
              data: queryResults.results,
              count: queryResults.count
            },
            message: queryResults.results.length === 0 ? 'No Results Found' : undefined,
            queryRun: 'done'
          }
        })
      } else {
        return dispatch({
          ...queriedDatabaseAction,
          payload: {
            resultsGrid: {
              isLoading: false,
              columns: [],
              data: [],
              count: 0
            },
            message: queryResults.error,
            queryRun: 'done'
          }
        })
      }
    }
  }
}

export const downloadQuery = (
  execute,
  orderBy,
  orderByConditions,
  querySelectFields,
  queryWhereConditions
) => async (dispatch, getState) => {
  const {
    project: { country },
    query: {
      downloadOnly
    }
  } = getState()

  const { tableName, id } = execute

  dispatch({
    ...downloadingQueryAction,
    payload: {
      isDownloading: true
    }
  })

  const url = await apiHelper.getWebsocketUrl(country)

  if (ws.dataQuery) {
    ws.dataQuery.close()
  }

  ws.dataQuery = new WebSocket(url)

  ws.dataQuery.onopen = () => {
    ws.dataQuery.send(JSON.stringify({
      action: 'onquery',
      body: {
        projectId: id,
        tableName,
        downloadOnly,
        hasMultipleOR: true,
        isPrecalculation: false,
        isRedshift: true,
        orderBy: orderBy ?? DEFAULT_ORDER_BY,
        orderByConditions: orderByConditions ?? DEFAULT_ORDER_BY_CONDITIONS,
        querySelectFields: querySelectFields ?? DEFAULT_QUERY_SELECTED_FIELDS,
        queryWhereConditions: queryWhereConditions ?? []
      }
    }))
  }

  ws.dataQuery.onmessage = event => {
    const downloadChunks = JSON.parse(event.data)

    if (downloadChunks) {
      downloadChunks.forEach(chunk => window.open(chunk, '_blank'))
    }

    ws.dataQuery.close()

    dispatch({
      ...downloadedQueryAction,
      payload: {
        isDownloading: false
      }
    })
  }
}

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case resetQueryAction.type:
      return {
        ...state,
        resultsGrid: { ...INITIAL_STATE.resultsGrid },
        message: undefined
      }
    case queryingDatabaseAction.type:
      return {
        resultsGrid: {
          isLoading: true,
          columns: [],
          data: [],
          count: 0,
          queryRun: 'loading'
        },
        message: undefined
      }
    case queriedDatabaseAction.type:
    case downloadingQueryAction.type:
    case downloadedQueryAction.type:
      return {
        ...state,
        ...payload
      }
    default:
      return state
  }
}
