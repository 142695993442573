import PropTypes from 'prop-types'
import React from 'react'
import { Grid, Dialog, styled } from '@mui/material'
import WhereList from './where'
import SetList from './set'
import FormulaDialog from '../../../../components/sql/FormulaDialog'
import SaveQueryDialogLegacy from '../../components/SaveQueryDialogLegacy'
import { useDispatch, useSelector } from 'react-redux'

import {
  actions as formulaDialogActions,
  saveFormulaDialog
} from '../../../../store/pages/common/formulaDialog'

import {
  toggledWorkflowFormulaAction
} from '../../../../store/pages/common/workflow'

const StyledGrid = styled(Grid)(() => ({
  textAlign: 'center'
}))

const Form = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2),
  width: '100%',
  height: 'max-content'
}))

function Update (props) {
  const dispatch = useDispatch()

  const dataPrep = useSelector((state) => state.dataPrep)
  const navigation = useSelector((state) => state.navigation)
  const project = useSelector((state) => state.project)
  const formulaDialog = useSelector((state) => state.formulaDialog)

  const tableName = navigation.file.guid
  const tableNameRedshift = `red_${project.id}_upload.${tableName}`
  const isFormulaOpen = props.state.state.isFormulaDialogOpen

  const handleFormulaSave = () => {
    dispatch(saveFormulaDialog(true, props.order))
  }

  const handleFormulaCancel = () => {
    dispatch({
      ...toggledWorkflowFormulaAction,
      payload: {
        order: props.order
      }
    })

    dispatch(formulaDialogActions.close())
  }

  return (
    <Grid container spacing={3}>
      <Dialog
        open={formulaDialog.open && isFormulaOpen}
        aria-labelledby="form-dialog-title"
        maxWidth='md'
        fullWidth={false}
      >
        <FormulaDialog
          tableName={tableNameRedshift}
          resource={project}
          columnNames={navigation.file.columns.map(col => col.Name)}
          onSave={handleFormulaSave}
          onCancel={handleFormulaCancel}
        />
      </Dialog>
      <Dialog
        open={dataPrep.saveQueryDialog.open}
        aria-labelledby="form-dialog-title"
        maxWidth='md'
        fullWidth={false}
      >
        <SaveQueryDialogLegacy />
      </Dialog>
      <Grid item xs={12}>
        <StyledGrid container>
          <Form elevation={1}>
            {!props.isDelete && <Grid item xs={12} data-testid="setContainer">
              <SetList state = {props.state} order = {props.order}/>
            </Grid>}
            <Grid item xs={12} data-testid="whereContainer">
              <WhereList state = {props.state} order = {props.order} isDelete={props.isDelete}/>
            </Grid>
          </Form>
        </StyledGrid>
      </Grid>
    </Grid>
  )
}

Update.propTypes = {
  isDelete: PropTypes.bool,
  order: PropTypes.number,
  state: PropTypes.object,
  actions: PropTypes.object
}

export default React.memo(Update)
