import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import MuiPaper from '@mui/material/Paper'
import CreatableSelect from 'react-select/creatable'
import Dialog from '@mui/material/Dialog'
import Icon from '@mui/material/Icon'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material'
import FormulaDialog from '../../../../components/sql/FormulaDialog'
import { SingleValue, SingleValueStyles } from '../../../../components/sql/SelectSingleValue'
import SelectFields from './selectFields'
import LoadingDialog from './loadingDialog'

import {
  actions as formulaDialogActions
} from '../../../../store/pages/common/formulaDialog'

import {
  toggledWorkflowFormulaAction,
  changedNewColumn
} from '../../../../store/pages/common/workflow'

const Paper = styled(MuiPaper)(({ theme }) => ({
  padding: theme.spacing(2),
  width: '100%'
}))

function NewColumn (props) {
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(false)

  const navigation = useSelector((state) => state.navigation)
  const project = useSelector((state) => state.project)
  const formulaDialog = useSelector((state) => state.formulaDialog)

  const { name, formula, message } = props.state.state
  const tableName = navigation.file.guid
  const tableNameRedshift = `red_${project.id}_upload.${tableName}`
  const isFormulaOpen = props.state.state.isFormulaDialogOpen

  const handleFormulaSave = result => {
    dispatch(changedNewColumn('formula', result.formula, props.order))
  }

  const handleFormulaCancel = () => {
    dispatch({
      ...toggledWorkflowFormulaAction,
      payload: {
        order: props.order
      }
    })

    dispatch(formulaDialogActions.close())
  }

  return (
    <Grid container>
      <LoadingDialog
        id={project.id}
        open={open}
        state={props.state}
        handleClose={setOpen}
      />

      <Dialog
        open={formulaDialog.open && isFormulaOpen}
        maxWidth='md'
        fullWidth={true}
      >
        <FormulaDialog
          forbidAggregators
          tableName={tableNameRedshift}
          columnNames={navigation.file.columns.map(col => col.Name)}
          resource={project}
          onSave={handleFormulaSave}
          onCancel={handleFormulaCancel}
        />
      </Dialog>

      <Paper elevation={1} outlined>
        <Grid container alignItems="flex-start" spacing={3}>
          <br/>

          <Grid item xs={5}>
            <Typography variant="h6" >
              <Icon color="primary">*</Icon> New Column Name
            </Typography>
            <br/>

            <CreatableSelect
              value={name}
              placeholder=""
              name="name"
              styles={SingleValueStyles}
              components={{ SingleValue }}
              onChange={(newValue) => dispatch(changedNewColumn('name', newValue, props.order))}
              isClearable={true}
            />
          </Grid>

          <br/>

          <Grid item xs={5}>
            <Typography variant="h6" >
              <Icon color="primary" >*</Icon> Field
            </Typography>

            <br/>

            <SelectFields
              formula={formula}
              order={props.order}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">
            {message}
          </Typography>
        </Grid>
      </Paper>
    </Grid>
  )
}

NewColumn.propTypes = {
  state: PropTypes.object,
  actions: PropTypes.object,
  order: PropTypes.number
}

export default React.memo(NewColumn)
