import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Fab from '@mui/material/Fab'
import AddIcon from '@mui/icons-material/Add'
import WhereMultiSelect from './whereList'
import { useDispatch } from 'react-redux'
import { styled } from '@mui/material'
import { addedNewOptionWhereAction } from '../../../store/pages/common/update'

const AddButton = styled(Fab)({
  marginLeft: 150
})

export default function Where () {
  const dispatch = useDispatch()

  const handleClick = () => {
    dispatch(addedNewOptionWhereAction)
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={7}>
        <Typography variant='h6'>Filters</Typography>
      </Grid>

      <Grid item xs={5}>
        <AddButton
          data-testid='button-add'
          size='small'
          color='primary'
          onClick={handleClick}
        >
          <AddIcon />
        </AddButton>
      </Grid>

      <Grid item xs={12}>
        <WhereMultiSelect />
      </Grid>
    </Grid>
  )
}
