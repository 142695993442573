import { changedNewColumnAction as changedNewColumnActionWorkflow, toggledWorkflowFormulaAction } from './workflow'
import { setQuerySelectAction, updatedWhereConditionAction } from './query'

const initialState = {
  open: false,
  target: undefined,
  index: undefined,
  name: '',
  formula: ''
}

export const updateOptionSetAction = {
  type: 'formulaDialog/UPDATE_SET_OPTION'
}

export const updatedOptionWhereAction = {
  type: 'formulaDialog/UPDATE_WHERE_OPTION'
}

const openAction = {
  type: 'formulaDialog/OPEN'
}

const closeAction = {
  type: 'formulaDialog/CLOSE'
}

const setFormAction = {
  type: 'formulaDialog/SET_FORM'
}

export const setFormulaValuesAction = {
  type: 'formulaDialog/SET_FORMULA_VALUES'
}

export const editedFormulaDialogAction = {
  type: 'formulaDialog/EDITED_FORMULA'
}

export const savedFormulaAction = {
  type: 'formulaDialog/SAVED_FORMULA'
}

const open = () => {
  return openAction
}

const close = () => {
  return closeAction
}

const setForm = model => {
  return {
    ...setFormAction,
    payload: model
  }
}

export const saveFormulaDialog = (isWorkflow, order) => async (dispatch, getState) => {
  const {
    formulaDialog: {
      formula,
      target,
      name,
      key,
      index,
      open
    },
    query,
    update,
    workflow
  } = getState()

  const result = {
    value: formula,
    label: `${name} (formula)`,
    type: 'formula',
    name
  }

  if (target === 'querySelectFields') {
    const formulaList = query[target]
    const targetIndex = formulaList.findIndex(field => field.name === name)

    if (targetIndex === -1) {
      formulaList.push(result)
    } else {
      formulaList[targetIndex] = result
    }
  } else if (target === 'queryWhereConditions') {
    query[target][index][key] = result
  } else if (isWorkflow) {
    dispatch({ ...toggledWorkflowFormulaAction, payload: { order } })
    workflow.steps[order].state[target][index][key] = result
  } else {
    update[target][index][key] = result
  }

  dispatch({
    ...savedFormulaAction,
    payload: {
      query,
      update,
      workflow,
      formulaDialog: {
        open: !open,
        target: undefined,
        index: undefined,
        key: undefined,
        name: '',
        formula: ''
      }
    }
  })
}

export const updateOptionSet = (key, index, value) => async (dispatch, getState) => {
  const { update: { updateSetConditions } } = getState()
  if (value !== null && value.value === 'USE FORMULA BUILDER') {
    return dispatch({
      ...updateOptionSetAction,
      payload: {
        formulaDialog: {
          name: '',
          formula: '',
          open: true,
          target: 'updateSetConditions',
          index,
          key
        }
      }
    })
  } else {
    updateSetConditions[index][key] = value || ''
    return dispatch({
      ...updateOptionSetAction,
      payload: {
        update: {
          updateSetConditions: [...updateSetConditions]
        }
      }
    })
  }
}

export const updateOptionWhere = (key, index, value, isDelete = false) => async (dispatch, getState) => {
  const { update: { deleteWhereConditions, updateWhereConditions } } = getState()
  const target = isDelete ? 'deleteWhereConditions' : 'updateWhereConditions'
  if (value !== null && value.value === 'USE FORMULA BUILDER') {
    return dispatch({
      ...updatedOptionWhereAction,
      payload: {
        formulaDialog: {
          name: '',
          formula: '',
          open: true,
          target,
          index,
          key
        }
      }
    })
  } else {
    const conditions = isDelete ? deleteWhereConditions : updateWhereConditions
    const whereConditions = [...conditions]
    whereConditions[index] = {
      ...conditions[index],
      [key]: value || ''
    }
    return dispatch({
      ...updatedOptionWhereAction,
      payload: {
        update: {
          [target]: whereConditions
        }
      }
    })
  }
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case openAction.type: {
      return {
        ...state,
        open: true,
        name: '',
        formula: ''
      }
    }
    case closeAction.type: {
      return {
        ...state,
        open: false,
        name: '',
        formula: ''
      }
    }
    case setFormAction.type: {
      return {
        ...state,
        name: payload.name,
        formula: payload.formula
      }
    }
    case setFormulaValuesAction.type: {
      const { key, value } = payload
      return {
        ...state,
        [key]: value
      }
    }
    case editedFormulaDialogAction.type: {
      const { formula, name, index, target, key } = payload
      const { open } = state
      return {
        ...state,
        open: !open,
        target,
        index,
        key,
        name,
        formula
      }
    }
    case savedFormulaAction.type:
      return {
        ...payload.formulaDialog
      }
    case setQuerySelectAction.type:
      return {
        ...state,
        name: '',
        formula: '',
        target: 'querySelectFields',
        index: 0,
        ...payload.formulaDialog
      }
    case changedNewColumnActionWorkflow.type:
    case updatedWhereConditionAction.type:
    case updatedOptionWhereAction.type:
    case updateOptionSetAction.type:
      return {
        ...state,
        ...payload.formulaDialog
      }
    default:
      return state
  }
}

export const actions = {
  open,
  close,
  setForm
}
