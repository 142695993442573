import React from 'react'
import Grid from '@mui/material/Grid'
import Dialog from '@mui/material/Dialog'
import Typography from '@mui/material/Typography'
import ReactCreatableSelect from 'react-select/creatable'
import FormulaDialog from '../../../components/sql/FormulaDialog'
import { useDispatch, useSelector } from 'react-redux'
import { setQuerySelect } from '../../../store/pages/common/query'
import { AppendColumnBuilder } from '../../../components/sql/SelectSingleValue'
import { MultiValueLabel, MultiValueRemove, MultiValueStyles } from '../../../components/sql/SelectMultiValue'
import { styled } from '@mui/material'

import {
  actions as formulaDialogActions,
  saveFormulaDialog
} from '../../../store/pages/common/formulaDialog'

const COMPONENTS = {
  MultiValueLabel,
  MultiValueRemove
}

const CreatableSelect = styled(ReactCreatableSelect)(({ theme }) => ({
  zIndex: 1000,
  minHeight: 58,
  margin: theme.spacing(1)
}))

export default function SelectFields () {
  const dispatch = useDispatch()
  const formulaDialog = useSelector(store => store.formulaDialog)
  const reference = useSelector(store => store.reference)
  const query = useSelector(store => store.query)

  const { guid, files } = reference.referenceTable
  const tableName = `red_${guid}_upload.${files[0].guid}`
  const columns = reference.referenceSourceInfo.columns || []
  const columnsPlusBuilder = AppendColumnBuilder(columns)

  const options = columnsPlusBuilder.map(x => ({
    label: `${x.Name} (${x.Type})`,
    value: x.Name
  }))

  const handleFormulaSave = () => {
    dispatch(saveFormulaDialog(false))
  }

  const handleFormulaCancel = () => {
    dispatch(formulaDialogActions.close())
  }

  return (
    <Grid container>
      <Grid item xs={6}>
        <Dialog
          open={formulaDialog.open}
          maxWidth='md'
          fullWidth={true}
        >
          <FormulaDialog
            tableName={tableName}
            resource={reference.referenceTable}
            columnNames={columns.map(col => col.Name)}
            onSave={handleFormulaSave}
            onCancel={handleFormulaCancel}
          />
        </Dialog>

        <Typography variant='h6'>Fields</Typography>
      </Grid>

      <Grid item xs={11}>
        <CreatableSelect
          data-testid='select'
          styles={MultiValueStyles}
          name='column-Select'
          isMulti
          placeholder=''
          queryIndex={0}
          queryTarget='querySelectFields'
          components={COMPONENTS}
          options={options}
          value={query.querySelectFields}
          onChange={value => dispatch(setQuerySelect(value))}
        />
      </Grid>
    </Grid>
  )
}
