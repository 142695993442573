import React from 'react'
import { AgGridReact } from 'ag-grid-react'
import PropTypes from 'prop-types'
import 'ag-grid-enterprise'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { styled } from '@mui/material'

const DUMMY_COLUMN_DEFS = [{
  field: null,
  suppressHeaderMenuButton: true
}]

export default function AgTable ({ onGridReady, gridRef, pagination }) {
  const StyledDiv = styled('div')({
    height: '400px'
  })

  const getProps = type => {
    switch (type) {
      case 'pagination':
        return {
        }
      default:
        return {
          pagination: true,
          paginationPageSize: 100
        }
    }
  }
  return (
    <div>
      <StyledDiv id="custom-ag-grid-id" className="ag-theme-alpine">
        <AgGridReact
          {...getProps(!pagination ? 'pagination' : 'nopag')}
          ref={gridRef}
          domLayout={'autoHeight'}
          suppressFieldDotNotation={true}
          rowModelType={'serverSide'}
          cacheBlockSize={20}
          animateRows={true}
          rowSelection={'single'}
          onGridReady={onGridReady}
          paginationPageSizeSelector={false}
          columnDefs={DUMMY_COLUMN_DEFS}
        />
      </StyledDiv>
    </div>
  )
}

AgTable.propTypes = {
  source: PropTypes.any,
  onGridReady: PropTypes.any,
  gridRef: PropTypes.any,
  pagination: PropTypes.any
}
