import React, { useState } from 'react'
import MappingList from './MappingList'
import Grid from '@mui/material/Grid'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Switch from '@mui/material/Switch'
import { excludeData } from '../../store/pages/mapping'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import SearchBox from '../../components/form/SearchBox'
import ProfileCard from './ProfileCardAG'
import { useDispatch, useSelector } from 'react-redux'
import { getAccess } from '../../store/pages/project/async'
import { resetQueryAction } from '../../store/pages/common/dataQuery'
import { styled } from '@mui/material'

const RootGrid = styled(Grid)({
  width: '50%'
})

const SpinnerCircularProgress = styled(CircularProgress)({
  marginTop: 200,
  marginLeft: '45%'
})

const NoFilesTypography = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(2)
}))

const FixGrid = styled(Grid)({
  overflow: 'auto',
  height: '800px'
})

const TextFieldDiv = styled('div')({
  backgroundColor: 'white'
})

const CardContainerGrid = styled(Grid)(({ theme }) => ({
  alignItems: 'start',
  padding: theme.spacing(2)
}))

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  '&.MuiTableCell-head': {
    backgroundColor: '#737779',
    color: theme.palette.common.white
  },
  '&.MuiTableCell-body': {
    fontSize: 14
  }
}))

const fileTypeMap = {
  I: 'inforce',
  T: 'termination',
  IT: 'inforce+termination'
}

const ValueMapping = () => {
  const mappings = useSelector((store) => store.mapping.mappings)
  const file = useSelector((store) => store.navigation.file)
  const id = useSelector((store) => store.project.id)
  const fileData = useSelector((store) => store.project.fileData)
  const ownerId = useSelector((store) => store.project.ownerId)
  const profileData = useSelector((store) => store.project.profileData)
  const sharedWithIds = useSelector((store) => store.project.sharedWithIds)
  const studyType = useSelector((store) => store.project.studyType)
  const rgaColumns = useSelector((store) => store.refData.rgaColumns)

  const userId = useSelector((store) => store.user.ownerId)
  const role = useSelector((store) => store.user.role)

  const [filterError, setFilterError] = React.useState(false)
  const [filterColumns, setFilterColumns] = useState(undefined)
  const [sourceFocus, setSourceFocus] = useState('')
  const [targetFocus, setTargetFocus] = useState('')
  const dispatch = useDispatch()

  if (mappings.length === 0 || (file && !file.guid)) {
    return (
      <RootGrid>
        <SpinnerCircularProgress/>
      </RootGrid>
    )
  }

  const isSkipMap = studyType === 'skipMap'
  const messageFabric = 'Mapping is automatically done for data fabric skip mapping datasets'
  if (typeof file === 'undefined' || Object.keys(file).length === 0 || ((!file.isProcessing) && (!file.uploaded))) {
    return (
      <RootGrid>
        <NoFilesTypography variant="h5" component="h2">
          {isSkipMap ? messageFabric : ' No Files Processed Successfully'}
        </NoFilesTypography>
      </RootGrid>
    )
  }

  const handleDataQueryChangeSource = (newValue, isMultiple) => {
    dispatch(resetQueryAction)
    if (isMultiple) {
      setSourceFocus('')
    } else {
      setSourceFocus(newValue)
    }
  }

  const handleDataQueryChangeTarget = (newValue) => {
    dispatch(resetQueryAction)
    setTargetFocus(newValue)
  }

  const fileProfileData = profileData.filter(a => a.guid === file.guid)
  const currentMappings = mappings.find(x => x.guid === file.guid)

  currentMappings && currentMappings.columns.filter(i => i.source)
  const access = getAccess(sharedWithIds, ownerId, studyType, role, userId, 'MAPPING')
  const newValMap = mappings.find(e => e.guid === file.guid).columns.find(e => e.target === targetFocus)
  return (
    <Grid container spacing={2}>
      <FixGrid item xs={6}>
        <TextFieldDiv>
          <Grid container alignItems="center">
            <Grid item xs={6}>
              <SearchBox onChange={e => setFilterColumns(e.target.value)} />
            </Grid>
            <Grid item xs={6} align='right'>
              <Typography color="textPrimary"> Filter Error <Switch
                size="small"
                color="primary"
                checked={filterError}
                onChange={e => {
                  return setFilterError(!filterError)
                }}
              /> </Typography>
            </Grid>
          </Grid>

        </TextFieldDiv>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell>
                <Grid container alignItems="center">Target Column</Grid>
              </StyledTableCell>
              <StyledTableCell align="left">
                Source Column
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { (file.fileType !== undefined && fileTypeMap[file.fileType])
              ? <MappingList
              handleSourceFocusChange={handleDataQueryChangeSource}
              handleTargetChange={handleDataQueryChangeTarget}
              fileType={fileTypeMap[file.fileType]}
              guid={file.guid}
              fileData={fileData}
              searchFilter={filterColumns}
              profileData={fileProfileData}
              filterByError={filterError}
            />
              : <tr></tr>}
          </TableBody>
        </Table>
      </FixGrid>
      <CardContainerGrid item xs={6}>
        {sourceFocus && fileProfileData && newValMap && <ProfileCard
          id={`opt-col-${file.guid}-${targetFocus}`}
          projectId={id}
          source={sourceFocus}
          data={fileProfileData}
          guid={file.guid}
          sourceColumnOptions={rgaColumns}
          targetValue={targetFocus}
          mappings={newValMap}
          excludeData = {(value, relevantData) => dispatch(excludeData(value, relevantData))}
          access={access}
        />
        }
      </CardContainerGrid>
    </Grid>
  )
}

export default ValueMapping
