import React from 'react'
import PropTypes from 'prop-types'
import { OutlinedInput, styled } from '@mui/material'

const Input = styled(OutlinedInput)(({
  width: '100%',
  backgroundColor: 'white'
}))

export default function GridSearchBox ({ onSearchTextChange }) {
  return (
    <Input
      size="small"
      placeholder="Type to search"
      onChange={onSearchTextChange}
    />
  )
}

GridSearchBox.propTypes = {
  onSearchTextChange: PropTypes.func
}
