import React from 'react'
import PropTypes from 'prop-types'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import AnnouncementDialog from '../app/AnnouncementDialog'
import ReleaseNoteDialog from '../app/ReleaseNoteDialog'
import { tableRowBackGroundColor } from '../../components/table/table.utils'
import { Grid, TextField, Button, styled } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'

const MediumTableCell = styled(TableCell)({
  width: '200px'
})
const LargeTableCell = styled(TableCell)({
  width: '400px'
})

const StyledTableRow = styled(TableRow)({
  background: 'white',
  display: 'inherit',
  maxHeight: 50
})

const StyledTableBody = styled(TableBody)({
  display: 'block',
  overflowY: 'hidden'
})

const getByType = (data, setOpen, open) => {
  const {
    type,
    notes,
    name,
    version,
    description,
    startDate,
    endDate
  } = data

  switch (type) {
    case 'release':
      return (<div>
        <Grid container >
          <Grid item xs={6}>
            <TextField
              data-testid={'text-version'}
              fullWidth
              label="Version"
              style={{ margin: 8 }}
              value={version}
              InputProps={{
                readOnly: true
              }}/>
          </Grid>
          <Grid item xs={6}>
            <Button
              data-testid={'button-open'}
              onClick={() => { setOpen(true) }}>
              <VisibilityIcon/>
            </Button>
          </Grid>
          <Grid item xs={6}>
            <TextField
              data-testid={'text-name'}
              fullWidth
              label="Name"
              style={{ margin: 8 }}
              value={name}
              InputProps={{
                readOnly: true
              }}/>
          </Grid>
          <Grid item xs={12}>
            <TextField
              data-testid={'text-description'}
              fullWidth
              id="release-description"
              label="Description"
              style={{ margin: 8 }}
              value={description}
              InputProps={{
                readOnly: true
              }}/>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="release-startdate"
              fullWidth
              label="Start release note"
              style={{ margin: 8 }}
              value={startDate}
              InputProps={{
                readOnly: true
              }}/>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="release-enddate"
              fullWidth
              label="End release note"
              style={{ margin: 8 }}
              value={endDate}
              InputProps={{
                readOnly: true
              }}/>
          </Grid>
        </Grid>
        <Typography variant="h6" >Features</Typography>
        <br/>
        <Divider />
        <Table
          title={'Features'}
          aria-label='sticky table'
          size='small'
        >
            <TableHead>
              <StyledTableRow>
                <MediumTableCell>Name</MediumTableCell>
                <LargeTableCell>Description</LargeTableCell>
              </StyledTableRow>
            </TableHead>

            <StyledTableBody>
            {
              notes && notes.map((row, idx, key) => {
                return (
                  <StyledTableRow key={idx} style={tableRowBackGroundColor(idx)}>
                    <MediumTableCell>{row.name}</MediumTableCell>
                    <MediumTableCell>{row.description}</MediumTableCell>
                  </StyledTableRow>
                )
              })

            }
          </StyledTableBody>
        </Table>

        <ReleaseNoteDialog
          data-testid={'release-note'}
          open={open}
          title={name}
          data={data}
          onClose={() => setOpen(false)}
        />
      </div>)

    case 'announcement':
      return (<div>
        <Grid container >
          <Grid item xs={6}>
            <TextField
              data-testid={'text-announcement-name'}
              fullWidth
              label="Name"
              style={{ margin: 8 }}
              value={name}
            />
          </Grid>
          <Grid item xs={6}>
            <Button
              data-testid={'button-announcement-open'}
              onClick={() => { setOpen(true) }}>
              <VisibilityIcon/>
            </Button>
          </Grid>
          <Grid item xs={12}>
            <TextField
              data-testid={'text-announcement-description'}
              multiline
              fullWidth
              label="Description"
              style={{ margin: 8 }}
              value={description}
              InputProps={{
                readOnly: true
              }}/>
          </Grid>
          <Grid item xs={12}>
            <TextField
              data-testid={'text-announcement-startDate'}
              fullWidth
              label="Start announcement"
              style={{ margin: 8 }}
              value={startDate}
              InputProps={{
                readOnly: true
              }}/>
          </Grid>
          <Grid item xs={12}>
            <TextField
              data-testid={'text-announcement-endDate'}
              fullWidth
              label="End announcement"
              style={{ margin: 8 }}
              value={endDate}
              InputProps={{
                readOnly: true
              }}/>
          </Grid>
          <Grid item xs={12}>
            <TextField multiline
              fullWidth
              data-testid={'text-announcement-title'}
              label="Announcement Title"
              style={{ margin: 8 }}
              value={data?.title}
              InputProps={{
                readOnly: true
              }}/>
          </Grid>
          <Grid item xs={12}>
            <TextField multiline
              data-testid={'text-announcement-message'}
              fullWidth
              label="Announcement Message"
              style={{ margin: 8 }}
              value={data?.message}
              InputProps={{
                readOnly: true
              }}/>
          </Grid>
        </Grid>

        <AnnouncementDialog
          data-testid={'announcementDialog'}
          open={open}
          title={data?.title}
          reason = {data?.message}
          onClose={() => setOpen(false)}
        />
      </div>)
    default:
      return (<div data-testid={'type-none'}></div>)
  }
}

const SelectedObject = ({ data }) => {
  const [open, setOpen] = React.useState(false)

  return (
    getByType(
      data,
      setOpen,
      open
    )
  )
}

SelectedObject.propTypes = {
  data: PropTypes.object
}

export default SelectedObject
