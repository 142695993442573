import React from 'react'
import Select from 'react-select'
import { useDispatch } from 'react-redux'
import { changedNewColumn } from '../../../../store/pages/common/workflow'
import PropTypes from 'prop-types'

import {
  SingleValue,
  SingleValueStyles
} from '../../../../components/sql/SelectSingleValue'
export default function SelectFields ({ formula, order }) {
  const dispatch = useDispatch()
  const columns = [{ value: 'USE FORMULA BUILDER', label: 'USE FORMULA BUILDER' }]

  const COMPONENTS = {
    SingleValue
  }

  return (
    <Select
      data-testid='select-formulaBuilder'
      name='newColumnFormula'
      isSearchable={false}
      isClearable={true}
      order={order}
      value={formula}
      options={columns}
      onChange={(newValue) => dispatch(changedNewColumn('formulaDialog', newValue, order))}
      styles={SingleValueStyles}
      components={COMPONENTS}
    />
  )
}

SelectFields.propTypes = {
  formula: PropTypes.any,
  changed: PropTypes.func,
  order: PropTypes.number
}
