import PropTypes from 'prop-types'
import React from 'react'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import { useDispatch, useSelector } from 'react-redux'
import { loadData } from '../../../store/pages/project/async'
import { setRefreshDataAction } from '../../../store/pages/dataPrep'

const LoadingDialog = props => {
  const dispatch = useDispatch()
  const dataPrep = useSelector(store => store.dataPrep)

  const showActions =
    dataPrep.newColumn.refreshData ||
    !dataPrep.newColumn.isLoading

  const handleClose = () => {
    if (dataPrep.newColumn.refreshData) {
      dispatch(loadData(props.id, true))

      dispatch({
        ...setRefreshDataAction,
        payload: {
          refreshData: false
        }
      })
    }

    props.onClose()
  }

  return (
    <Dialog
      data-testid={props['data-testid']}
      open={props.open}
    >
      {dataPrep.newColumn.isLoading &&
        <DialogContent
          data-testid='content'
        >
          <Typography variant='h6'>
            <CircularProgress />

            Adding new column and profiling.
          </Typography>
        </DialogContent>
      }

      {showActions && (
        <DialogActions
          data-testid='actions'
        >
          <Typography
            data-testid='label-message'
            variant='h6'
          >{dataPrep.newColumn.message}</Typography>

          <Button
            data-testid='button-close'
            color='primary'
            onClick={handleClose}
          >Close</Button>
        </DialogActions>
      )}
    </Dialog>
  )
}

LoadingDialog.propTypes = {
  'data-testid': PropTypes.string,
  id: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func
}

/* istanbul ignore next */
LoadingDialog.defaultProps = {
  'data-testid': '',
  id: '',
  open: false,
  onClose: () => {}
}

export default LoadingDialog
