import PropTypes from 'prop-types'
import React from 'react'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import ParametersTable from '../../../../components/views/JobRun/ParametersTable'
import SelectionTable from '../../../../components/views/JobRun/SelectionTable'
import Accordion from './Accordion'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import StopIcon from '@mui/icons-material/Stop'
import { styled } from '@mui/material'

const Header = styled('div')({
  display: 'grid',
  width: '100%',
  borderBottomWidth: '2px',
  borderBottomColor: '#DEDEDE',
  alignItems: 'center',
  gridGap: '12px',
  gridTemplateColumns: 'auto 1fr auto'
})

const Content = styled('div')(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  padding: theme.spacing(2),
  gap: theme.spacing(2),
  flexDirection: 'row',
  justifyContent: 'flex-start',
  backgroundColor: '#ECEFF1',
  fontSize: '14px'
}))

export default function CurrentParametersCard (props) {
  const handleAbortButtonClick = () => {
    props.onAbortStudy()
  }

  const handleRunButtonClick = () => {
    props.onRunStudy()
  }

  const renderSummarySlot = () => <Header>
    <Typography>
      <strong>Current Parameters</strong>
    </Typography>

    <div></div>

    <Tooltip title={props.studyIsRunning
      ? 'Abort Study'
      : props.tooltip}
    >
      <span>
        {props.studyIsRunning
          ? (
          <Button
            data-testid='button-abort'
            variant="outlined"
            color="primary"
            disabled={props.disableActionButton}
            startIcon={<StopIcon />}
            onClick={handleAbortButtonClick}
          >ABORT STUDY</Button>
            )
          : (
          <Button
            data-testid='button-run'
            color="primary"
            variant="outlined"
            disabled={props.disableActionButton}
            startIcon={<PlayArrowIcon />}
            onClick={handleRunButtonClick}
          >RUN STUDY</Button>
            )
        }
      </span>
    </Tooltip>
  </Header>

  const renderDetailsSlot = () => <Content>
    <ParametersTable
      data-testid='parameters'
      parameters={props.parameters}
    />

    <SelectionTable
      data-testid='aggregators'
      title='Aggregators'
      items={props.aggregators}
    />

    <SelectionTable
      data-testid='bases'
      title='Expected Bases'
      items={props.bases}
    />
  </Content>

  return <Accordion
    data-testid='accordion'
    summarySlot={renderSummarySlot()}
    detailsSlot={renderDetailsSlot()}
    expanded
  />
}

CurrentParametersCard.propTypes = {
  studyIsRunning: PropTypes.bool,
  disableActionButton: PropTypes.bool,
  tooltip: PropTypes.string,
  bases: PropTypes.arrayOf(PropTypes.string),
  aggregators: PropTypes.arrayOf(PropTypes.string),
  parameters: PropTypes.shape({
    applyIncidenceRateCap: PropTypes.bool,
    addMonthlyAggregation: PropTypes.bool,
    applySubstandardFlatExtra: PropTypes.bool,
    applyExpectedLapseRates: PropTypes.bool,
    treatyRestrictions: PropTypes.string,
    policyRestrictions: PropTypes.string,
    observationDate: PropTypes.string,
    periodStartDate: PropTypes.string,
    periodEndDate: PropTypes.string
  }),
  onRunStudy: PropTypes.func,
  onAbortStudy: PropTypes.func
}

/* istanbul ignore next */
CurrentParametersCard.defaultProps = {
  studyIsRunning: false,
  disableActionButton: false,
  tooltip: 'Run Study',
  bases: [],
  aggregators: [],
  parameters: {
    applyIncidenceRateCap: false,
    addMonthlyAggregation: false,
    applySubstandardFlatExtra: false,
    applyExpectedLapseRates: false,
    treatyRestrictions: '',
    policyRestrictions: '',
    observationDate: '',
    periodStartDate: '',
    periodEndDate: ''
  },
  onRunStudy: () => {},
  onAbortStudy: () => {}
}
