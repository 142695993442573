import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setCommonFile } from '../../../store/pages/navigation'

import {
  clearedEditorAction,
  setIsPrecalculationAction
} from '../../../store/pages/common/query'

export default () => {
  const dispatch = useDispatch()

  const {
    currentTab,
    studyType
  } = useSelector(({
    navigation: {
      currentTab
    },
    project: {
      studyType
    }
  }) => ({
    currentTab,
    studyType
  }))

  React.useEffect(() => {
    dispatch(clearedEditorAction)

    dispatch({
      ...setIsPrecalculationAction,
      payload: { isPrecalculation: true }
    })

    if (studyType && currentTab === undefined) {
      dispatch(setCommonFile('precalCheck'))
    }
  }, [
    dispatch,
    currentTab,
    studyType
  ])
}
