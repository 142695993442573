import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import MuiButton from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Navigation from '../components/navigation'
import BaseTable from './Table'
import LoadingDialog from './LoadingDialog'
import SaveQueryDialogLegacy from '../components/SaveQueryDialogLegacy'
import Selection from './Selection'
import SaveIcon from '@mui/icons-material/Save'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { styled } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'

import {
  executeAggregations,
  toggleSaveQueryDialogAction
} from '../../../store/pages/dataPrep'

const Form = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2),
  width: '100%',
  height: 'max-content',
  backgroundColor: '#DEDEDE'
}))

const Table = styled(BaseTable)(({ theme }) => ({
  overflow: 'visible',
  padding: theme.spacing(2),
  minHeight: 400
}))

const ButtonContainer = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(2),
  width: '100%'
}))

const Button = styled(MuiButton)(({ theme }) => ({
  margin: theme.spacing(1)
}))

export default function Aggregate () {
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(false)
  const navigation = useSelector(store => store.navigation)
  const project = useSelector(store => store.project)
  const dataPrep = useSelector(store => store.dataPrep)

  const saveLabel =
    dataPrep.saveQueryDialog.guid !== undefined &&
    dataPrep.saveQueryDialog.queryType === 'aggregate'
      ? 'UPDATE'
      : 'SAVE'

  const handleExecuteClicked = React.useCallback(() => {
    dispatch(executeAggregations(project.id, navigation.file.guid))
    setOpen(true)
  }, [
    project.id,
    navigation.file.guid,
    dispatch
  ])

  const handleSave = React.useCallback(() => {
    dispatch(toggleSaveQueryDialogAction)
  }, [dispatch])

  return (
    <Grid container>
      <Grid item xs={12}>
        <Navigation/>
      </Grid>

      <LoadingDialog
        data-testid='dialog-loading'
        id={project.id}
        open={open}
        onClose={setOpen}
      />

      <Dialog
        data-testid='dialog-save-query'
        open={dataPrep.saveQueryDialog.open}
        maxWidth='md'
      >
        <SaveQueryDialogLegacy />
      </Dialog>

      <Form
        container
        item
        xs={12}
        spacing={2}
      >
        <Grid item xs={5}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Fields</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Table />
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={6}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Aggregation</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Selection />
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={12}>
          {!dataPrep.aggregators.isLoading
            ? <ButtonContainer>
              <Button
                data-testid='button-execute'
                color="primary"
                variant="outlined"
                startIcon={<PlayArrowIcon />}
                onClick={handleExecuteClicked}
              >Execute</Button>

              <Button
                data-testid='button-save'
                color="secondary"
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={handleSave}
              >{saveLabel}</Button>
            </ButtonContainer>
            : <div></div>
          }
        </Grid>
      </Form>
    </Grid>
  )
}
