import React from 'react'
import PropTypes from 'prop-types'
import MuiGrid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import MuiDialogContent from '@mui/material/DialogContent'
import MuiDialogTitle from '@mui/material/DialogTitle'
import MuiCollapse from '@mui/material/Collapse'
import MuiTypography from '@mui/material/Typography'
import TablePagination from '@mui/material/TablePagination'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import { styled } from '@mui/material'
import { useDispatch } from 'react-redux'
import { changedReleaseExpansionAction } from '../../store/pages/adminTool'

const PAGE_SIZE = 5

const DialogTitle = styled(MuiDialogTitle)(({
  color: '#c62728'
}))

const Grid = styled(MuiGrid)(({
  borderBottom: '1px solid grey'
}))

const DialogContent = styled(MuiDialogContent)(({
  border: '2px solid grey',
  padding: 0,
  overflowX: 'hidden'
}))

const Collapse = styled(MuiCollapse)(({
  marginLeft: '5%',
  marginBottom: 0,
  marginTop: 0
}))

const Typography = styled(MuiTypography)(({
  marginLeft: '5%'
}))

const ReleaseHistory = props => {
  const dispatch = useDispatch()
  const [pageIndex, setPageIndex] = React.useState(0)

  const startIndex = pageIndex * PAGE_SIZE
  const endIndex = startIndex + PAGE_SIZE

  const filteredItems = React.useMemo(() =>
    props.data
      .slice(startIndex, endIndex)
      .sort((a, b) => new Date(b.startDate) - new Date(a.startDate)),
  [props.data, startIndex, endIndex])

  React.useEffect(() => {
    if (!props.open) {
      setPageIndex(0)
    }
  }, [props.open])

  const handleClose = () => {
    props.onClose()
  }

  const handleExpand = (expand, row) => {
    dispatch({
      ...changedReleaseExpansionAction,
      payload: {
        guid: row.guid,
        expand
      }
    })
  }

  const handlePageChange = (_event, value) => {
    setPageIndex(value)
  }

  return (
    <Dialog
      data-testid={props['data-testid']}
      fullWidth
      open={props.open}
      maxWidth='md'
      onClose={handleClose}
    >
      <DialogTitle>
        <Grid container spacing={0}>
          <Grid item xs={6}>Release History</Grid>

          <Grid item xs={6}>
            <TablePagination
              data-testid='pagination'
              component='div'
              page={pageIndex}
              count={props.data.length}
              rowsPerPage={PAGE_SIZE}
              rowsPerPageOptions={[3]}
              onPageChange={handlePageChange}
            />
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent dividers>
        <Grid container spacing={1}>
          <Grid item xs={12} ></Grid>

          {filteredItems.map((row, rowIndex) => (
            <Grid
              data-testid={`row-${rowIndex}`}
              key={rowIndex}
              xs={12}
              item
            >
              <div>
                {row.expand
                  ? <IconButton
                  data-testid={`button-collapse-${rowIndex}`}
                  onClick={() => handleExpand(false, row)}
                  size="large"><ExpandLessIcon /></IconButton>
                  : <IconButton
                  data-testid={`button-expand-${rowIndex}`}
                  onClick={() => handleExpand(true, row)}
                  size="large"><ExpandMoreIcon /></IconButton>
                }

                <Typography
                  data-testid={`label-row-version-and-date-${rowIndex}`}
                  variant='h6'
                >
                  Version: {row.version} (Release Date: {row.startDate})
                </Typography>
              </div>

              <Collapse
                data-testid={`collapse-${rowIndex}`}
                unmountOnExit
                timeout='auto'
                in={row.expand}
              >
                <Typography
                  data-testid={`label-row-name-${rowIndex}`}
                  variant='h6'
                >
                  Name: {row.name}
                </Typography>

                <Typography
                  data-testid={`label-row-description-${rowIndex}`}
                  variant='h6'
                >
                  Description: {row.description}
                </Typography>

                {row.notes && row.notes.map((note, noteIndex) => (
                  <Grid item xs={12} key={noteIndex}>
                    <Typography
                      data-testid={`label-note-name-${rowIndex}-${noteIndex}`}
                    >
                      Feature: {note.name}
                    </Typography>

                    <Typography
                      data-testid={`label-note-description-${rowIndex}-${noteIndex}`}
                      gutterBottom
                    >
                      Description: {note.description}
                    </Typography>

                    <br/>
                  </Grid>
                ))}
              </Collapse>
            </Grid>
          ))}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button
          color='secondary'
          variant='contained'
          onClick={handleClose}
        >OK</Button>
      </DialogActions>
    </Dialog>
  )
}

ReleaseHistory.propTypes = {
  'data-testid': PropTypes.string,
  open: PropTypes.bool,
  data: PropTypes.array,
  onClose: PropTypes.func
}

ReleaseHistory.defaultProps = {
  'data-testid': '',
  open: false,
  data: [],
  onClose: () => {}
}

export default React.memo(ReleaseHistory)
