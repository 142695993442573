import 'ag-grid-enterprise'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import React from 'react'
import Grid from '@mui/material/Grid'
import { AgGridReact } from 'ag-grid-react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import ActionsRender from './ActionsRender'
import ConfirmDialog from '../../components/dialogs/ConfirmDialog'
import delay from '../../store/pages/common/delay'
import products from '../../store/objects/data/products.json'
import superUsers from '../../store/objects/data/superUsers.json'
import { styled } from '@mui/material'
import { resetNavAction } from '../../store/pages/navigation'
import { resetMappingsAction } from '../../store/pages/mapping'
import { resetProjectDataAction } from '../../store/pages/project/actions'
import { loadStudies, deleteProject } from '../../store/pages/home'

const COLUMN_DEF_DEFAULT = {
  flex: 1,
  minWidth: 90,
  resizable: true
}

const Search = styled('input')({
  backgroundColor: '#f8f8f8',
  borderColor: '#dedede',
  width: '100%',
  padding: '10px 20px',
  outline: 0,
  border: '2px #dedede solid',
  fontSize: '100%'
})

const OuterGridContainer = styled('div')({
  position: 'fixed',
  overflowY: 'none',
  overflowX: 'none',
  width: '-webkit-fill-available',
  height: '-webkit-fill-available'
})

const InnerGridContainer = styled('div')({
  height: 'inherit'
})

export default function ProjectGrid () {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const gridRef = React.useRef()
  const [shouldDeleteProject, setShouldDeleteProject] = React.useState(false)
  const [projectId, setProjectId] = React.useState(0)
  const [keygrid, setKeygrid] = React.useState(null)

  const user = useSelector(store => store.user)
  const home = useSelector(store => store.home)

  React.useEffect(() => {
    if (home.allExperienceStudies.length === 0 || home.isRefreshNeeded) {
      dispatch(loadStudies)
    }
  }, [
    home.isRefreshNeeded,
    home.allExperienceStudies,
    dispatch
  ])

  const onDelete = (event, id) => {
    event.preventDefault()
    event.stopPropagation()
    setProjectId(id)
    setShouldDeleteProject(true)
  }

  const handleProjectDelete = async () => {
    setShouldDeleteProject(false)
    setProjectId(0)
    dispatch(deleteProject(projectId))
    await delay(3000)
    setKeygrid('deleteset')
  }

  const ownerNameGetter = (params) => {
    return params.data.ownerName ?? params.data.ownerId
  }

  const productGetter = (params) => {
    const product = products.find(item => item?.value?.toLowerCase() === params?.data?.product?.toLowerCase())
    return product ? product.label : params?.data?.product
  }

  const dateSorter = (date1, date2) => {
    const firstDate = date1 && new Date(date1).getTime()
    const secondDate = date2 && new Date(date2).getTime()

    if (firstDate === null && secondDate === null) {
      return 0
    }
    if (firstDate === null) {
      return -1
    }
    if (secondDate === null) {
      return 1
    }
    return firstDate - secondDate
  }

  const handleFilterChanged = React.useCallback((event) => {
    gridRef.current.api.setGridOption('quickFilterText', event.target.value)
  }, [])

  const handleCellClicked = React.useCallback(event => {
    const nameCell = event.colDef.field

    if (nameCell === 'actions') {
      gridRef.current.api.refreshCells()
    } else {
      const row = gridRef.current.api.getSelectedRows()[0]

      const superUserRedi =
        user.role === 'admin' &&
        row.studyType === 'redi'

      if (
        superUserRedi ||
          superUsers.indexOf(user.ownerId.toLowerCase()) >= 0 ||
          row.ownerId.toLowerCase() === user.ownerId.toLowerCase() ||
          row.ownerId.toLowerCase() === user.ownerName.toLowerCase() ||
          (
            row.sharedWithIds &&
            JSON.parse(row.sharedWithIds).find(item =>
              item.id.toLowerCase() === user.ownerId.toLowerCase()
            )
          )
      ) {
        dispatch(resetNavAction)
        dispatch(resetMappingsAction)
        dispatch(resetProjectDataAction)
        navigate(`/upload/${row.id}`)
      }
    }
  }, [user.role, user.ownerId, user.ownerName, dispatch, navigate])

  const columnDefs = [
    {
      field: 'actions',
      suppressSizeToFit: false,
      width: 100,
      headerName: 'Actions',
      suppressHeaderMenuButton: true,
      cellRenderer: ActionsRender,
      cellRendererParams: {
        ownerId: user.ownerId,
        onDelete
      }
    },
    {
      field: 'id',
      headerName: 'Project Id',
      width: 150,
      suppressSizeToFit: false,
      sortable: true,
      suppressHeaderMenuButton: true
    },
    {
      headerName: 'User',
      valueGetter: ownerNameGetter,
      sortable: true,
      width: 250,
      suppressHeaderMenuButton: true
    },
    {
      field: 'projectName',
      headerName: 'Project Name',
      sortable: true,
      width: 250,
      suppressHeaderMenuButton: true
    },
    {
      field: 'country',
      headerName: 'Client Country',
      sortable: true,
      suppressHeaderMenuButton: true
    },
    {
      field: 'clientName',
      headerName: 'Client',
      sortable: true,
      suppressHeaderMenuButton: true
    },
    {
      valueGetter: productGetter,
      headerName: 'Product',
      sortable: true,
      suppressHeaderMenuButton: true
    },
    {
      field: 'projectDate',
      headerName: 'Project Date',
      sortable: true,
      suppressHeaderMenuButton: true,
      comparator: dateSorter,
      valueFormatter: params => params.data.projectDate
        ? new Date(params.data.projectDate).toLocaleDateString()
        : ''
    }
  ]

  return (
    <Grid>
      <ConfirmDialog
        open={shouldDeleteProject}
        title="Confirm Project Deletion?"
        text={`Do you want to delete the project: ${projectId}?`}
        onCancel={() => setShouldDeleteProject(false)}
        onConfirm={handleProjectDelete}
      />

      <Search
        id='filter-text-box'
        type="search"
        label="Search"
        placeholder='Type to search....'
        onChange={handleFilterChanged}
      />

      <OuterGridContainer>
        <InnerGridContainer className="ag-theme-alpine">
          <AgGridReact
            key={keygrid || ''}
            ref={gridRef}
            animateRows
            pagination
            columnDefs={columnDefs}
            defaultColDef={COLUMN_DEF_DEFAULT}
            rowModelType='clientSide'
            rowData={home.allExperienceStudies}
            paginationPageSize={20}
            paginationPageSizeSelector={false}
            rowSelection='single'
            cacheBlockSize={2000}
            onCellClicked={handleCellClicked}
          />
        </InnerGridContainer>
      </OuterGridContainer>
    </Grid>
  )
}
