import PropTypes from 'prop-types'
import React from 'react'
import { styled } from '@mui/material'

const Root = styled('table')({
  border: '1px solid #80808047',
  width: '100%',
  height: '100%'
})

const Header = styled('tr')({
  adding: '0.75rem 1.25rem',
  marginBottom: '0',
  backgroundColor: '#D1D0D0',
  borderBottom: '1px solid rgba(0, 0, 0, 0.125)',
  fontWeight: 'bold'
})

const HeaderCell = styled('th')({
  display: 'flex',
  flexFlow: 'column',
  padding: '0.5em',
  borderBottom: '1px solid #80808047'
})

const Row = styled('tr')({
  height: 0,
  backgroundColor: 'white'
})

const Cell = styled('td')({
  display: 'flex',
  flexFlow: 'column',
  padding: '0.5em',
  borderBottom: '1px solid #80808047'
})

export default function Table (props) {
  return (
    <Root>
      <thead>
        <Header>
          <HeaderCell data-testid='title'>
            {props.title}
          </HeaderCell>
        </Header>
      </thead>

      <tbody>
        {props.items.map((item, index) => (
          <Row key={index}>
            <Cell data-testid={`row-${index}`}>
              {item}
            </Cell>
          </Row>
        ))}
      </tbody>
    </Root>
  )
}

Table.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.node)
}

Table.defaultProps = {
  title: '',
  items: []
}
