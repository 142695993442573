const headers = {
  includeHeaders: [
    'count', 'unique_identifier',
    'count(*)',
    'inventory_date',
    'days_since_last_annv',
    'lapse_and_conv_count',
    'policy_month',
    'lapse_count',
    'policy_year',
    'dot',
    'issue_date',
    'as_cnt',
    'JOINTID',
    'rsk_rnwl_dt,ern_prem_end_dt',
    'CLAIM_AMOUNT',
    'prim_lf_nbr',
    'ins_lf_nbr',
    'trty_clnt_grp_nm',
    'prod_typ_nm',
    'pln_id_nm',
    'prem_crncy_cd',
    'ern_prem_end_dt',
    'gndr_dsc',
    'ins_brth_dt',
    'prem_ern_rsk_amt',
    'CLNT_CO_CD',
    'prim_lf_nbr',
    'ins_lf_nbr',
    'trty_clnt_grp_nm',
    'prod_typ_nm',
    'pln_id_nm',
    'prem_crncy_cd',
    'ern_prem_end_dt',
    'gndr_dsc',
    'ins_brth_dt',
    'prem_ern_rsk_amt',
    'grp_nbr'
  ],
  issueAgeHeader: [
    'given_age',
    'calculated_age',
    'calculated age - given age',
    'record_count'
  ],
  headers: [
    'ISSUE_AGE_I',
    'ISSUE_AGE_T',
    'DAYS_SINCE_LAST_ANNV_T',
    'LAPSE_AND_CONV_COUNT_T',
    'dot',
    'issue_date',
    'as_cnt',
    'count'
  ],
  skipMapHeaders: [{
    checkName: 'AAR',
    headers: ['source', 'policyid', 'benefit#', 'treaty#', 'jointid', 'cover_Basis', 'inventory_date', 'inforce_aar']
  },
  {
    checkName: 'TERM_ISSUE_DATE_T',
    headers: ['source', 'policyid', 'benefit#', 'treaty#', 'jointid', 'cover_Basis', 'dot', 'issue_date']
  },
  {
    checkName: 'CONSISTENCY',
    headers: ['source', 'policyid', 'benefit#', 'treaty#', 'jointid', 'cover_Basis', 'dot', 'issue_date']
  },
  {
    checkName: 'REASONABILITY_I',
    headers: ['inventory_date', 'count']
  },
  {
    checkName: 'DOBLEISSUE',
    headers: ['source', 'policyid', 'benefit#', 'treaty#', 'jointid', 'cover_Basis', 'issue_date', 'dot']
  },
  {
    checkName: 'DOBLEDOR_I',
    headers: ['source', 'policyid', 'benefit#', 'treaty#', 'jointid', 'cover_Basis', 'date_on_risk', 'issue_date']
  },
  {
    checkName: 'ISSUELEOBS',
    headers: ['inventory_date', 'count']
  }
  ]
}
export default headers
