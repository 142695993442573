import apiHelper from './apiHelper'
import axios from 'axios'
import { API } from 'aws-amplify'

const {
  REACT_APP_RGAENV: rgaEnv
} = process.env

const getProject = async (projectId) => {
  const header = await apiHelper.getHeader()
  return API.get(apiHelper.apiName, `/project/${projectId}`, header).catch(apiHelper.handleError)
}

const postProject = async (body) => {
  const bodyAndHeader = await apiHelper.getBodyAndHeader(body)
  return API.post(apiHelper.apiName, '/project', bodyAndHeader).catch(apiHelper.handleError)
}

const putProject = async project => {
  const opts = await apiHelper.getBodyAndHeader({
    ...project,
    s3upload: undefined,
    profileData: undefined,
    fileData: undefined
  })

  const { region } = apiHelper.getCountry(project.country)
  return API
    .put(apiHelper.getRegionalAPIName(region), `/project/${project.id}`, opts)
    .catch(apiHelper.handleError)
}

const deleteProject = async (id) => {
  const header = await apiHelper.getHeader()
  return API.del(apiHelper.apiName, `/project/${id}`, header).catch(apiHelper.handleError)
}

const getProjectList = async (order, orderBy, page, rowsPerPage, searchText) => {
  const header = await apiHelper.getHeader()
  const pageQuery = page >= 0 && rowsPerPage > 0 ? `pageNo=${page + 1}&pageSize=${rowsPerPage}` : ''
  const sortQuery = order && orderBy ? `sortColumn=${orderBy}&sortOrder=${order}` : ''
  // const searchQuery = searchText.length > 2 ? `&searchText=${searchText}` : ''
  return API.get(apiHelper.apiName, `/project?${pageQuery}&${sortQuery}`, header).catch(apiHelper.handleError)
}

const getNamesLdap = async (text = '') => {
  const isLowerEnv = ['poc', 'sdlc'].includes(rgaEnv)
  const url = `https://dapper.red${isLowerEnv ? '-np' : ''}.rgare.net/v1/ldap/query`
  const response = await axios.post(url, {
    filter: `(givenName=${text}*)`,
    attributes: ['sn', 'givenName', 'sAMAccountName', 'name', 'mail']
  })
  return response.data ? response.data.filter(x => x.mail) : []
}

export default {
  getProject,
  deleteProject,
  postProject,
  putProject,
  getProjectList,
  getNamesLdap
}
