import React, { useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import Tooltip from '@mui/material/Tooltip'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import IconButton from '@mui/material/IconButton'
import InfoIcon from '@mui/icons-material/Info'
import CancelIcon from '@mui/icons-material/Cancel'
import VpnKeyIcon from '@mui/icons-material/VpnKey'
import DeleteIcon from '@mui/icons-material/Delete'
import ErrorIcon from '@mui/icons-material/Error'
import WarningIcon from '@mui/icons-material/Warning'
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import { styled, Select as MaterialSelect } from '@mui/material'
import { useDispatch } from 'react-redux'
import { removeOptional, setMappings } from '../../store/pages/mapping'
import { resetQueryAction } from '../../store/pages/common/dataQuery'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

const TARGET_DATA_TYPES_FOR_MULTI = [
  'varchar'
]

const StyledTableCell = styled(TableCell)(({
  minWidth: '180px'
}))

const StatusIcon = ({ targetDataType, isMultiple }) => {
  const validateDate = targetDataType === 'date'
  const validateMultiple = isMultiple

  if (validateDate) {
    return (
      <Tooltip title='Date must match the source type'>
        <ErrorIcon />
      </Tooltip>
    )
  }

  if (validateMultiple) {
    return (
      <Tooltip title='Decimal types not allowed for combined columns'>
        <WarningIcon />
      </Tooltip>
    )
  }

  return (
    <Tooltip title='Data type mismatch'>
      <WarningIcon />
    </Tooltip>
  )
}

StatusIcon.propTypes = {
  targetDataType: PropTypes.string,
  isMultiple: PropTypes.bool
}

const StyledFormControl = styled(FormControl)(({
  margin: 1,
  width: 300
}))

const MultiSelectStyledBox = styled(Box)(({
  display: 'flex',
  flexWrap: 'wrap',
  gap: 5
}))

const MultiSelectStyledChip = styled(Chip)(({
  borderRadius: 3,
  backgroundColor: '#b7d2e8',
  borderColor: 'black'
}))

function RawMultiSelectChips ({ values, handleSourceChange, fileColumn }) {
  const handleDelete = (e, value) => {
    e.preventDefault()
    const valuesWithoutItem = values.filter((item) => item !== value)
    const event = {
      target: {
        value: valuesWithoutItem
      }
    }
    handleSourceChange(event, fileColumn)
  }

  return (
    <MultiSelectStyledBox>
      {values.map((value) => (
        <MultiSelectStyledChip
          onDelete={(e) => handleDelete(e, value)}
          key={value}
          label={value}
          variant='outlined'
          deleteIcon={
            <CancelIcon
              onMouseDown={(event) => event.stopPropagation()}
            />
          }
        />
      ))}
    </MultiSelectStyledBox>
  )
}

RawMultiSelectChips.propTypes = {
  values: PropTypes.array.isRequired,
  handleSourceChange: PropTypes.func.isRequired,
  fileColumn: PropTypes.any
}

const MultiSelectChips = React.memo(RawMultiSelectChips)

const MappingListRow = ({
  access,
  guid,
  fileColumn,
  fileType,
  index,
  setSourceFocus,
  sourceColumnOptions
}) => {
  const dispatch = useDispatch()
  const [allowMultipleColumns, setAllowMultipleColumns] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const sourceColumnValue = useMemo(() => {
    const selectedOptions = fileColumn?.source ? fileColumn?.source?.split('||') : []
    return selectedOptions.reduce((acc, selected) => {
      const optionFound = sourceColumnOptions.find((option) => selected.includes(option.label))
      if (optionFound) {
        return acc.concat(selected)
      } else {
        return acc
      }
    }, [])
  }, [
    fileColumn.source,
    sourceColumnOptions
  ])

  useEffect(() => {
    if (fileColumn && fileColumn.targetDataType) {
      const isAllowed = TARGET_DATA_TYPES_FOR_MULTI.includes(fileColumn.targetDataType)
      setAllowMultipleColumns(isAllowed)
    }
  }, [fileColumn])

  const handleSourceChange = React.useCallback((event, column) => {
    const {
      target: { value }
    } = event

    const eventValues = typeof value === 'string' ? value.split(',') : value

    const filteredEventValues = allowMultipleColumns
      ? eventValues
      : [eventValues[eventValues.length - 1]]

    const updatedSourceColumnValues = filteredEventValues.map((value) => {
      return sourceColumnOptions.find(option => option.label === value)
    })

    dispatch(
      setMappings(
        fileType,
        guid,
        updatedSourceColumnValues,
        column.targetColumnName
      )
    ).then(() => {
      const lastValueSelected = eventValues.length > sourceColumnValue.length
        ? updatedSourceColumnValues[updatedSourceColumnValues.length - 1]
        : ''
      setSourceFocus(lastValueSelected)
      setOpen(false)
      dispatch(resetQueryAction)
    })
  }, [
    fileType,
    guid,
    sourceColumnOptions,
    setSourceFocus,
    sourceColumnValue.length,
    allowMultipleColumns,
    dispatch
  ])

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleRemove = (fileType, guid, index) => {
    dispatch(removeOptional(fileType, guid, index))
  }

  const areOptionSelected = useMemo(() => !!sourceColumnValue.length, [sourceColumnValue])

  const isUnmappedSelected = useMemo(() => sourceColumnValue.some((value) => value === 'Unmapped'), [sourceColumnValue])

  const getIsMenuItemDisabled = useCallback((option) => {
    if (areOptionSelected) {
      return isUnmappedSelected ? option.label !== 'Unmapped' : option.label === 'Unmapped'
    }
  }, [areOptionSelected, isUnmappedSelected])

  const optionElements = useMemo(() => sourceColumnOptions.map((option) => (
    <MenuItem
      key={option.value}
      value={option.label}
      disabled={getIsMenuItemDisabled(option)}
    >
      {option.label}
    </MenuItem>
  )), [sourceColumnOptions, getIsMenuItemDisabled])

  const dataTypeMismatch = fileColumn.dataTypeMismatch

  return (
    <TableRow key={index}>
      <TableCell>
        {!fileColumn.required && access && (
          <IconButton onClick={() => handleRemove(fileType, guid, index)}>
            <DeleteIcon />
          </IconButton>
        )}

        {dataTypeMismatch &&
          <StatusIcon
            targetDataType={fileColumn.targetDataType}
            isMultiple={fileColumn.isMultiple}
          />}
      </TableCell>

      <TableCell>
        {fileColumn.isPrimaryKey && access && <VpnKeyIcon />}
      </TableCell>

      <TableCell id={`source-label-${fileColumn.target}`}>
        {fileColumn.toolTip && (
          <Tooltip title={fileColumn.toolTip}>
            <InfoIcon />
          </Tooltip>
        )}

        {`${fileColumn.target} (${fileColumn.targetDataType})`}
      </TableCell>

      <StyledTableCell>
        <StyledFormControl>
          <MaterialSelect
            labelId={`source-label-${fileColumn.target}`}
            id={`col-${guid}-${index}`}
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
            multiple
            value={sourceColumnValue}
            onChange={(event) => handleSourceChange(event, fileColumn)}
            renderValue={(values) =>
              <MultiSelectChips
                values={values}
                handleSourceChange={handleSourceChange}
                fileColumn={fileColumn}
              />}
            MenuProps={MenuProps}
          >
            {optionElements}
          </MaterialSelect>
        </StyledFormControl>
      </StyledTableCell>
    </TableRow>
  )
}

MappingListRow.propTypes = {
  access: PropTypes.bool,
  guid: PropTypes.string,
  fileColumn: PropTypes.any,
  fileType: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  setSourceFocus: PropTypes.func.isRequired,
  sourceColumnOptions: PropTypes.array.isRequired
}

export default React.memo(MappingListRow)
