import reviewApi from '../objects/reviewApi'

const initialState = {
  valueMappingComment: {},
  columnMappingComment: {},
  piiComment: {},
  duplicates: {},
  lapseSkewness: {},
  lapseSkewnessDistribution: {},
  policyTracker: {},
  terminatingClaims: {},
  noTerminatingClaims: {},
  lapseExclusion: {},
  exposureRiskHistory: {},
  loadComments: false,
  commentsId: null
}

const mapComments = (commentData) => commentData.reduce((w, a) => {
  w[a.key] = a
  return w
}, {})

export const retrievedCommentsAction = {
  type: 'review/RETRIEVED_COMMENTS'
}

export const getComments = jobId => async (dispatch, getState) => {
  const { project: { id } } = getState()
  const information = await reviewApi.getProjectInformation(jobId, { id, jobId })
  const valuesMap = mapComments(information)
  return dispatch({
    ...retrievedCommentsAction,
    payload: {
      ...valuesMap,
      loadComments: true,
      commentsId: id
    }
  })
}

export const updatedCommentsAction = {
  type: 'review/UPDATED_COMMENTS'
}

export const updateComments = objectData => async (dispatch, getState) => {
  const { calculation: { latestRun }, project: { id } } = getState()
  objectData.id = id
  objectData.jobId = latestRun.JobId
  await reviewApi.updateProjectInformation(id, objectData)
  return dispatch({
    ...updatedCommentsAction,
    payload: {
      [objectData.key]: objectData
    }
  })
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case retrievedCommentsAction.type:
    case updatedCommentsAction.type:
      return {
        ...state,
        ...payload
      }
    default:
      return state
  }
}
