import React from 'react'
import Grid from '@mui/material/Grid'

export default function SpacingGrid () {
  return (
    <Grid container spacing={2}>
      MAP
    </Grid>
  )
}
