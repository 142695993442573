import React from 'react'
import Grid from '@mui/material/Grid'
import ReactCreatableSelect from 'react-select/creatable'
import Typography from '@mui/material/Typography'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material'
import { MultiValueLabel, MultiValueRemove, MultiValueStyles } from '../../components/sql/SelectMultiValue'
import { AppendColumnBuilder, AppendAllStar } from '../../components/sql/SelectSingleValue'
import { setQuerySelect } from '../../store/pages/common/query'

const CreatableSelect = styled(ReactCreatableSelect)(({ theme }) => ({
  zIndex: props => 900 + props.index,
  minHeight: 58,
  margin: theme.spacing(1)
}))

export default function SelectFields () {
  const dispatch = useDispatch()
  const { formatFile, querySelectFields } = useSelector(({
    navigation: { formatFile },
    query: { querySelectFields }
  }) => ({
    formatFile, querySelectFields
  }))
  const columnsMap = formatFile ? formatFile.columns : []
  let columns = AppendColumnBuilder(columnsMap)
  columns = AppendAllStar(querySelectFields, columns)

  return (
    <Grid container>
      <Grid item xs={6}>
        <Typography variant="h6">
          Fields
        </Typography>
      </Grid>
      <Grid item xs={11}>
        <CreatableSelect
          styles={MultiValueStyles}
          placeholder=""
          isMulti
          name={'column-Select'}
          value={querySelectFields}
          queryIndex={0}
          queryTarget={'querySelectFields'}
          options={columns ? columns.map(x => x && ({ value: x.Name, label: `${x.Name}` })) : []}
          onChange={(newValue) => dispatch(setQuerySelect(newValue))}
          components={{ MultiValueLabel, MultiValueRemove }}
        />
      </Grid>
    </Grid>
  )
}
