import React from 'react'
import MuiFab from '@mui/material/Fab'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import AddIcon from '@mui/icons-material/Add'
import SetMultiSelect from './setList'
import { styled } from '@mui/material'
import { useDispatch } from 'react-redux'
import { addedNewOptionSetAction } from '../../../store/pages/common/update'

const Fab = styled(MuiFab)({
  marginLeft: 150
})

export default function Set () {
  const dispatch = useDispatch()

  return (
    <Grid container>
      <Grid item xs={4}>
        <Typography variant="h6" align='left'>Set</Typography>
      </Grid>

      <Grid item xs={8}>
        <Fab
          size="small"
          color="primary"
          onClick={() => dispatch(addedNewOptionSetAction)}
        >
          <AddIcon/>
        </Fab>
      </Grid>

      <Grid item xs={12}>
        <SetMultiSelect />
      </Grid>
    </Grid>
  )
}
