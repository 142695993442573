export default [
  {
    required: true,
    nonCritical: false,
    order: 1,
    checkName: 'DUPLICATES_I',
    label: 'Inforce | Duplicates',
    info: 'An inforce duplicate is when there are multiple records per unique key combination and Observation Date.',
    goal: 'The goal is to have 1 record per combination of Unique Key and Observation Date. Otherwise the experience study wont run correctly.',
    fixText: 'Fix the data by either changing the Mappings and re-loading study input OR by aggregating the data per unique key',
    errorHandler: 'Go to Wizard to fix.',
    headers: []
  },
  {
    required: false,
    nonCritical: false,
    order: 2,
    checkName: 'AAR_I',
    label: 'Inforce | Current Amount At Risk <= 0',
    info: 'Inforce AAR <= 0 is a list of all  records for policies that have 0 aar.',
    goal: 'The goal is to have a non-zero value for inforce aar. Otherwise the policies with 0 AAR wont run through the engine.',
    fixText: 'Exclude the records with AAR <= 0 on the Value Mapping page or fix them on the data prep page and re-load study input. You can also continue by clicking acknowledge but know that these records will be dropped from the study ',
    errorHandler: 'Go back to mapping to fix.',
    headers: [
      'unique_identifier',
      'inventory_date',
      'inforce_aar'
    ]
  },
  {
    required: false,
    nonCritical: false,
    order: 3,
    checkName: 'ISSUE_AGE_I',
    label: 'Inforce | Issue Age Calculation',
    info: 'Age basis errors are when the calculated issue age doesnt match the age given based on the age basis',
    goal: 'The goal is to have the issue age calculations match the age definitions',
    fixText: 'Select a different age basis value on the Mapping page and re-load study input',
    errorHandler: 'Go back to mapping to fix.',
    headers: []
  },
  {
    required: true,
    nonCritical: false,
    order: 4,
    checkName: 'DUPLICATES_T',
    label: 'Termination | Duplicates',
    info: 'A termination duplicate is when there are multiple terminating records per unique key.',
    goal: 'The goal is to have 1 terminating record per Unique Key. Otherwise the experience study wont run correctly.',
    fixText: 'Fix the duplicates by aggregating the data below or by changing the mappings and re-loading study input. Specifically, check the mappings for Policy Status.',
    errorHandler: 'Go to Wizard to fix.',
    headers: []
  },
  {
    required: false,
    nonCritical: false,
    order: 5,
    checkName: 'AAR_T',
    label: 'Termination | Current Amount At Risk <= 0',
    info: 'Inforce AAR <= 0 is a list of all  records for policies that have 0 aar.',
    goal: 'The goal is to have a non-zero value for inforce aar. Otherwise the policies with 0 AAR wont run through the engine.',
    fixText: 'Exclude the records will AAR <= 0 on the Value Mapping page or fix them on the data prep page and re-load study input. You can also continue by clicking acknowledge but know that these records will be dropped from the study ',
    errorHandler: 'Go back to mapping to fix.',
    headers: [
      'unique_identifier',
      'inventory_date',
      'inforce_aar'
    ]
  },
  {
    required: false,
    nonCritical: false,
    order: 6,
    checkName: 'ISSUE_AGE_T',
    label: 'Termination | Issue Age Calculation',
    info: 'Age basis errors are when the calculated issue age doesnt match the age given based on the age basis',
    goal: 'The goal is to have the issue age calculations match the age definitions',
    fixText: 'Select a different age basis value on the Mapping page and re-load study input',
    errorHandler: 'Go back to mapping to fix.',
    headers: []
  },
  {
    required: false,
    nonCritical: false,
    order: 7,
    checkName: 'ESSENTIAL_FIELDS_I',
    label: 'Termination | Claims with Essential Data Missing',
    info: 'Claims with missing data are claims that REDi cant find inforce record of to populate the data via unique key.',
    goal: 'The goal is for clams data to have all the info they need to run through the engine successfully.',
    fixText: 'To fix this issue there are a few things to check:\r\n\r\n1. Double check that the unique identifier is the same between termination and inforce files. That way REDi can automatically assign values to the fields below to the termination data. Re-run Load Study Input if any changes are made.\r\n2. If the unique identifier is the same then you can map the fields below directly for the termination file(s) if you have them in the source termination data.  Re-run Load Study Input if you map the columns below.\r\n\r\n- Age basis\r\n- Inforce AAR\r\n- Issue Date\r\n- Issue Age\r\n- Gender\r\n- Uwclass\r\n- Substandard Rating\r\n\r\nIf you don\'t have the columns in your termination file(s) you can continue by acknowledging this check. However, the claims listed in this check won\'t be in the study results.',
    errorHandler: 'Go back to mapping to fix.',
    headers: []
  },
  {
    required: false,
    nonCritical: false,
    order: 8,
    checkName: 'TERM_ISSUE_DATE_T',
    label: 'Termination | Termination Date > Issue Date',
    info: 'This check shows unique identifiers that have a termination date before their issue date.',
    goal: 'The goal is to have termination dates after the issue date.',
    fixText: 'Please review your mappings to ensure that the date of termination is mapped correctly.\r\n\r\nIf so, you can acknowledge and continue. If not, please correct the mapping and re-submit the Load Study Input process.',
    errorHandler: 'Go back to mapping to fix.',
    headers: [
      'unique_identifier',
      'dot',
      'issue_date',
      'benefit#'
    ]
  },
  {
    required: true,
    nonCritical: false,
    order: 9,
    checkName: 'LAPSE_SKEWNESS_T',
    label: 'Termination | Lapse Skewness – Distribution of Day of Lapse Since Anniversary',
    info: 'This check shows the distribution of day of lapse since policy anniversary',
    goal: 'The goal is to review the distribution to ensure that there isn’t a lapse date adjustment that needs to be made',
    fixText: 'If you need to add a uniform number of days to the lapse dates then you can do that below. If the distribution looks good then you can continue (add 0 days). If you need to make more granular adjustments, then please use the data prep page to make changes and re-submit Load Study Input when ready.',
    errorHandler: '',
    headers: []
  },
  {
    required: false,
    nonCritical: false,
    order: 10,
    checkName: 'MONTHLY_SKEWNESS_T',
    label: 'Termination | Lapse Skewness – Monthly Skewness',
    info: 'This check shows the annual and monthly skewness for lapses',
    goal: 'The goal is to review the monthly skewness to make sure lapse dates are correct.',
    fixText: 'If the skewness looks wrong then please review the lapse dates on the data prep page. If any changes are made to the data, then re-submit Load Study Input. Otherwise, you can acknowledge this check and continue.',
    errorHandler: '',
    headers: []
  },
  {
    required: false,
    nonCritical: false,
    order: 11,
    checkName: 'CLAIMS_DATA_MISSING_I',
    label: 'Inforce | Claims Data Missing',
    info: 'To fix this issue there are a few things to checkL \n \n 1. Double check that the unique identifier is the same between termination and inforce fiels. That way REDi can automatomatically assign values to the fields below to the termination data. Re-run Load Study Input if any changes are made. \n 2. If the unique identifier is the same then you can map the fields below directly for the termination file(s) if you have them in the source termination data. RE-run Load Study Input if you map the columns below. \n \n -Age basis \n -inforce AAR \n -Issue Date \n -IssueAge \n -Gender \n -Uwclass \n -Substandard Ration',
    goal: 'The Goal is for claims data to have teh info the need to run through the engine succesfully',
    fixText: 'Claims with missinf data are claims that Redicant find inforce record of to populate the data via unique key',
    errorHandler: '',
    headers: []
  },
  {
    required: false,
    nonCritical: true,
    order: 13,
    checkName: 'CONSISTENCY_val policy',
    label: 'Policy Consistency Checks',
    info: 'This check examines the history of each field for each policy to look for inconsistencies over time.',
    goal: 'Examine the history of each field for each policy to look for inconsistencies over time.',
    fixText: '',
    errorHandler: '',
    headers: []
  },
  {
    required: false,
    nonCritical: true,
    order: 14,
    checkName: 'ISSUELEOBS_val policy',
    label: 'Issue Date | Observation Date',
    info: 'This check looks to see if the Issue date is before or equal to the Observation Date for all policies',
    goal: 'Check that Issue Date is before or equal to the Observation Date for all policies.',
    fixText: '',
    errorHandler: '',
    headers: [
      'totalrows',
      '?column?'
    ]
  },
  {
    required: false,
    nonCritical: true,
    order: 14,
    checkName: 'ISSUELEOBS_I',
    label: 'Issue Date | Observation Date',
    info: 'This check looks to see if the Issue date is before or equal to the Observation Date for all policies',
    goal: 'Check that Issue Date is before or equal to the Observation Date for all policies.',
    fixText: '',
    errorHandler: '',
    headers: []
  },
  {
    required: false,
    nonCritical: true,
    order: 15,
    checkName: 'REASONABILITY_val policy',
    label: 'Reasonability Check | Trend By Observation Date',
    info: 'This check provide data to see if the risk amount and count have a reasonable trend over time',
    goal: 'Check that the risk amount and count have a reasonable trend over time.',
    fixText: '',
    errorHandler: '',
    headers: [
      'inventory_date',
      'count',
      'sum'
    ]
  },
  {
    required: false,
    nonCritical: true,
    order: 15,
    checkName: 'REASONABILITY_I',
    label: 'Reasonability Check | Trend By Observation Date',
    info: 'This check provide data to see if the risk amount and count have a reasonable trend over time',
    goal: 'Check that the risk amount and count have a reasonable trend over time.',
    fixText: '',
    errorHandler: '',
    headers: [
      'inventory_date',
      'count',
      'sum'
    ]
  },
  {
    required: false,
    nonCritical: true,
    order: 16,
    checkName: 'DOBLEISSUE_val policy',
    label: 'Reasonability Check | Issue Date > Date Of Birth',
    info: 'This check shows unique identifiers where the Date of Birth is before or equal to the Issue Date for all policies.',
    goal: 'Check that Date of Birth is before or equal to the Issue Date for all policies.',
    fixText: '',
    errorHandler: '',
    headers: [
      'unique_identifier',
      'policyid',
      'treaty#',
      'benefit#',
      'jointid',
      'source',
      'cover_basis',
      'dob',
      'issue_date'
    ]
  },
  {
    required: false,
    nonCritical: true,
    order: 16,
    checkName: 'DOBLEISSUE_I',
    label: 'Reasonability Check | Issue Date > Date Of Birth',
    info: 'This check shows unique identifiers where the Date of Birth is before or equal to the Issue Date for all policies.',
    goal: 'Check that Date of Birth is before or equal to the Issue Date for all policies.',
    fixText: '',
    errorHandler: '',
    headers: [
      'inventory_date',
      'count'
    ]
  },
  {
    required: false,
    nonCritical: true,
    order: 17,
    checkName: 'DOBLEDOR_val policy',
    label: 'Reasonability Check | Date On Risk >= Issue Date',
    info: 'This check looks to see if the Issue Date is before or equal to the Date On Risk (Reinsurance) for all policies.',
    goal: 'Check that Issue Date is before or equal to the Date On Risk (Reinsurance) for all policies.',
    fixText: '',
    errorHandler: '',
    headers: []
  },
  {
    required: false,
    nonCritical: true,
    order: 17,
    checkName: 'DOBLEDOR_I',
    label: 'Reasonability Check | Date On Risk >= Issue Date',
    info: 'This check looks to see if the Issue Date is before or equal to the Date On Risk (Reinsurance) for all policies.',
    goal: 'Check that Issue Date is before or equal to the Date On Risk (Reinsurance) for all policies.',
    fixText: '',
    errorHandler: '',
    headers: []
  },
  {
    required: false,
    nonCritical: false,
    order: 18,
    checkName: 'CLAIMS_TREATMENT_DATE_I',
    label: 'Claims | Treatment Date on or after Risk Renewal Date',
    info: 'The claims need to be matched to the correct time period, so the claims treatment start date cannot be before the risk renewal date.',
    goal: 'The goal is to have all the treatment start dates on or after the last renewal dates.',
    fixText: 'Fix these on the data prep page and re-load study input. You can also continue by clicking acknowledge but know that these claims records will not be matched to the correct exposure record',
    errorHandler: '',
    headers: [
      'grp_nbr',
      'prim_lf_nbr',
      'ins_lf_nbr',
      'trty_clnt_grp_nm',
      'prod_typ_dsc',
      'pln_dsc',
      'rsk_rnwl_dt',
      'trtmt_strt_dt',
      'admsn_dt',
      'clm_nfy_dt',
      'clm_pd_dt',
      'clms_grs_amt',
      'clms_stl_amt'
    ]
  },
  {
    required: false,
    nonCritical: false,
    order: 19,
    checkName: 'CLAIMS_PAID_DATE_I',
    label: 'Claims | Paid date on or after Treatment Date',
    info: 'The claims cannot be paid before they are incurred.',
    goal: 'The goal is to have all the paid dates on or after the treatment start dates/Admission.',
    fixText: 'Fix these on the data prep page and re-load study input. You can also continue by clicking acknowledge but know that these records will affect the IBNR triangle and the results',
    errorHandler: '',
    headers: [
      'trtmt_strt_dt',
      'rsk_rnwl_dt',
      'pln_dsc',
      'trty_clnt_grp_nm',
      'grp_nbr',
      'prim_lf_nbr',
      'ins_lf_nbr',
      'prod_typ_dsc',
      'admsn_dt',
      'clm_nfy_dt',
      'clm_pd_dt',
      'clms_grs_amt',
      'clms_stl_amt'
    ]
  },
  {
    required: false,
    nonCritical: false,
    order: 20,
    checkName: 'elseDATE_I',
    label: 'label user story for elseDATE_I',
    info: '',
    goal: 'The goal is to have all policy renewal dates before the premium end date, otherwise these will be filtered out of the process',
    fixText: '',
    errorHandler: '',
    headers: []
  },
  {
    required: false,
    nonCritical: false,
    order: 21,
    checkName: 'BILLED_CLAIMS_I',
    label: 'Claims | Billed Claims less than Settled Claims',
    info: 'Billed claims cannot be less that the settled claims, this means that the insurer paid more than they should have.',
    goal: 'The goal is for the amount of the billed claims to be greater or equal to the amount of the settled claims.',
    fixText: 'Fix these on the data prep page and re-load study input. You can also continue by clicking acknowledge.',
    errorHandler: '',
    headers: [
      'grp_nbr',
      'prim_lf_nbr',
      'ins_lf_nbr',
      'trty_clnt_grp_nm',
      'prod_typ_dsc',
      'pln_dsc',
      'rsk_rnwl_dt',
      'trtmt_strt_dt',
      'admsn_dt',
      'clm_nfy_dt',
      'clm_pd_dt',
      'clms_grs_amt',
      'clms_stl_amt'
    ]
  },
  {
    required: false,
    nonCritical: false,
    order: 23,
    checkName: 'BLANK_ICD_I',
    label: 'Claims | Check blank ICD codes',
    info: '',
    goal: 'The goal is to have ICD codes so that we can view the results by ICD chapters in the dashboards.',
    fixText: '',
    errorHandler: '',
    headers: [
      'grp_nbr',
      'prim_lf_nbr',
      'ins_lf_nbr',
      'trty_clnt_grp_nm',
      'prod_typ_dsc',
      'pln_dsc',
      'rsk_rnwl_dt',
      'trtmt_strt_dt',
      'admsn_dt',
      'clm_nfy_dt',
      'clm_pd_dt',
      'clms_grs_amt',
      'clms_stl_amt'
    ]
  },
  {
    required: false,
    nonCritical: false,
    order: 12,
    checkName: 'EARNED_PREMIUM_I',
    label: 'Inforce | Renewal Date on or before Earned Premium End Date',
    info: 'The policy renewal dates need to be before the earned premium end dates',
    goal: 'The goal is to have all the policy renewal dates before the exposure/earned premium end dates',
    fixText: 'Fix these on the data prep page and re-load study input. You can also continue by clicking acknowledge but know that these records will be dropped from the process',
    errorHandler: '',
    headers: [
      'grp_nbr',
      'prim_lf_nbr',
      'ins_lf_nbr',
      'trty_clnt_grp_nm',
      'prod_typ_nm',
      'pln_id_nm',
      'rsk_rnwl_dt',
      'ern_prem_end_dt'
    ]
  }
]
