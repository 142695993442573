import { API } from 'aws-amplify'
import apiHelper from './apiHelper'

let __region = process.env.REACT_APP_REGION

const setAPIRegion = region => {
  __region = region
}

const getAPIRegion = () => {
  return __region
}

const getPreCalCheck = async projectId => {
  const header = await apiHelper.getHeader()

  return API
    .get(
      apiHelper.getRegionalAPIName(__region),
      `/source/precalcheck/precalcheck?projectId=${projectId}`,
      header
    )
    .catch(apiHelper.handleError)
}

const runRediCCFT = async body => {
  const bodyAndHeader = await apiHelper.getBodyAndHeader(body)

  return API
    .post(
      apiHelper.getRegionalAPIName(__region),
      '/engineformatting',
      bodyAndHeader
    )
    .catch(apiHelper.handleError)
}

const runFixPrecalChecks = async body => {
  const bodyAndHeader = await apiHelper.getBodyAndHeader(body)

  return API
    .post(
      apiHelper.getRegionalAPIName(__region),
      '/cal/precalfix',
      bodyAndHeader
    )
    .catch(apiHelper.handleError)
}

export default {
  setAPIRegion,
  getAPIRegion,
  getPreCalCheck,
  runRediCCFT,
  runFixPrecalChecks
}
