import stepFunctionsApi from '../../objects/stepFunctionsApi'
import delay from './delay'

export default async (executionArn, exitCondition, exitAwaitable, delayInMs = 5000) => {
  while (true) {
    const arn = await stepFunctionsApi.stepGetStatus(executionArn)
    if (exitCondition(arn)) {
      return exitAwaitable(arn)
    }
    await delay(delayInMs)
  }
}
