import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Fab from '@mui/material/Fab'
import AddIcon from '@mui/icons-material/Add'
import { styled } from '@mui/material'
import WhereMultiSelect from './whereList'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { addedNewWhereConditionAction } from '../../../../store/pages/common/workflow'

const AddButton = styled(Fab)(() => ({
  marginLeft: 150
}))

function WhereList (props) {
  const dispatch = useDispatch()
  return (
    <Grid container>
      <Grid item xs={4}>
        <Typography variant="h6" >
          Filters
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <AddButton
          data-testid='button-add-1'
          size="small"
          color="primary"
          aria-label="add"
          onClick={() => dispatch({ ...addedNewWhereConditionAction, payload: { order: props.order, isDelete: props.isDelete } })}
        >
          <AddIcon />
        </AddButton>
      </Grid>
      <Grid item xs={12}>
        <WhereMultiSelect state = {props.state} actions = {props.actions} order = {props.order} isDelete={props.isDelete}/>
      </Grid>
    </Grid>
  )
}

WhereList.propTypes = {
  state: PropTypes.object,
  actions: PropTypes.object,
  order: PropTypes.number,
  isDelete: PropTypes.bool
}

export default React.memo(WhereList)
