const initialState = {
  open: false,
  name: ''
}

export const toggledSynonymDialogAction = {
  type: 'syn/TOGGLED_SYNONYM'
}

export const setSynonymValuesAction = {
  type: 'syn/SET_SYNONYM_VALUES'
}

export const editingSynonymDialogAction = {
  type: 'syn/EDIT_SYNONYM'
}

export const savedSynonymDialogAction = {
  type: 'syn/SAVED_SYNONYM'
}

export const saveSynonymDialog = (isWorkflow, data) => async (dispatch, getState) => {
  const {
    dataPrep: {
      mergeData,
      referenceColumns
    },
    synonymDialog: {
      key, value, name
    }
  } = getState()

  const splitValue = value.split(' as ')[0]
  const mergeDataUpdated = isWorkflow ? data : mergeData
  const type = referenceColumns.find(referenceColumn => referenceColumn.Name === splitValue).Type
  const valueToUpdate = mergeDataUpdated[key].find(referenceColumn => referenceColumn.value === value)

  valueToUpdate.value = `${splitValue} as ${name}`
  valueToUpdate.label = `${splitValue} as ${name} (${type})`

  return dispatch({
    ...savedSynonymDialogAction,
    payload: {
      dataPrep: {
        mergeDataUpdated
      }
    }
  })
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case toggledSynonymDialogAction.type: {
      const { open } = state
      return {
        ...state,
        open: !open,
        name: ''
      }
    }
    case setSynonymValuesAction.type: {
      const { key, value } = payload
      return {
        ...state,
        [key]: value
      }
    }
    case editingSynonymDialogAction.type: {
      const { open } = state
      const { value, label, key } = payload
      return {
        ...state,
        open: !open,
        key,
        label,
        value,
        name: value.split(' as ').length === 2 ? value.split(' as ')[1] : ''
      }
    }
    case savedSynonymDialogAction.type: {
      const { open } = state
      return {
        ...state,
        open: !open,
        target: undefined,
        index: undefined,
        name: ''
      }
    }
    default:
      return state
  }
}
