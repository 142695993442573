import PropTypes from 'prop-types'
import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import FileNavigation from '../../components/FileNavigation'
import CalculationNavigation from '../../components/CalculationNavigation'
import PreCalculationNavigation from '../../components/PreCalculationNavigation'
import { styled } from '@mui/material'

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(4),
  fontSize: '1rem',
  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  fontWeight: 0,
  lineHeight: 1,
  float: 'right'
}))

const StyledDiv = styled('div')(({ theme }) => ({
  flexGrow: 1,
  marginBottom: theme.spacing(2)
}))

export const TopMenu = props => {
  const label = [props.projectName, props.role]
    .filter(item => item)
    .join(' - ')

  return (
    <StyledDiv>
      <Grid container>
        <Grid item xs={10}>
          <FileNavigation />
          <CalculationNavigation />
          <PreCalculationNavigation />
        </Grid>

        <Grid item xs={2}>
          <Grid container>
            <Grid item xs={12}>
              <StyledTypography
                data-testid='label'
              >{label}</StyledTypography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </StyledDiv>
  )
}

TopMenu.propTypes = {
  role: PropTypes.string,
  projectName: PropTypes.string
}

TopMenu.defaultProps = {
  role: '',
  projectName: ''
}

export default TopMenu
