import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import PropTypes from 'prop-types'
import { Typography } from '@mui/material'

class ErrorBoundary extends React.Component {
  constructor (props) {
    super(props)
    this.state = { hasError: false, errorMessage: '' }
  }

  // eslint-disable-next-line
  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch (error) {
    console.error(error.message)

    this.setState({
      errorMessage: error.message
    })
  }

  render () {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      // TODO: We should render our UI with a custom error page.
      return (
        <div style={{ width: '90%', marginTop: '5%', marginLeft: '3%' }}>
          <Card variant="outlined" style={{ border: 'none' }}>
            <CardContent>
              <Typography variant="h4" component="h2" gutterBottom>
                {this.state.errorMessage}
              </Typography>
            </CardContent>
          </Card>
        </div>
      )
    }

    return this.props.children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.array.isRequired
}

export default ErrorBoundary
