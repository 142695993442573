import React, { useState, useCallback, useMemo } from 'react'
import { styled } from '@mui/material'
import MuiTypography from '@mui/material/Typography'
import { Accordion, AccordionSummary, AccordionDetails } from '../../../themes/AccordionTheme'
import { getColumnDefs, getRowData } from './FormatGridData'
import Comments from './Comments'
import AcknowledgeButton from './AcknowledgeButton'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-enterprise'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'

const AccordionWrapper = styled(Accordion)(({
  marginBottom: '10px',
  border: '1px solid #55645896 !important'
}))

const Typography = styled(MuiTypography)(({
  fontWeight: 500,
  fontSize: '1.25rem'
}))

const ClaimsTableWrapper = styled(AccordionDetails)(({
  display: 'block'
}))

function BasicGrid ({
  title,
  objectData,
  keyValue,
  access,
  gridHeight = 'calc(100vh - 400px)',
  pagination = false,
  paginationAutoPageSize = false,
  paginationPageSize = 100,
  projectId
}) {
  const gridStyle = useMemo(() => (
    {
      height: gridHeight,
      width: '100%'
    }
  ), [gridHeight])

  objectData.key = keyValue
  objectData.title = title
  objectData.projectId = projectId

  const { data = [] } = objectData

  const [rowData, setRowData] = useState([])
  const [columnDefs, setColumnDefs] = useState([])

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 150
    }
  }, [])

  const onGridReady = useCallback((params) => {
    setRowData(getRowData(keyValue, data))
    setColumnDefs(getColumnDefs(keyValue))

    params.api.sizeColumnsToFit()
  }, [data, keyValue])

  return (<AccordionWrapper>
    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
      <Grid container>
        <Grid item xs={10}>
          <Typography>{title}</Typography>
        </Grid>
        <Grid item xs={2}>
          <AcknowledgeButton
            objectData={objectData}
            access={access}
          />
        </Grid>
      </Grid>
    </AccordionSummary>
    <ClaimsTableWrapper>
      { data && data.length > 0
        ? (
        <div style={gridStyle} className="ag-theme-alpine">
          <AgGridReact
            data-testid='agGrid'
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            pagination={pagination}
            paginationPageSize={paginationPageSize}
            paginationAutoPageSize={paginationAutoPageSize}
            onGridReady={onGridReady}
          ></AgGridReact>
        </div>
          )
        : <div data-testid='noData'> {`No ${title} Data`}</div> }
      <Comments
        objectData={objectData}
        access={access}
      />
    </ClaimsTableWrapper>
  </AccordionWrapper>)
}
BasicGrid.propTypes = {
  title: PropTypes.any,
  objectData: PropTypes.any,
  keyValue: PropTypes.any,
  access: PropTypes.any,
  projectId: PropTypes.any,
  gridHeight: PropTypes.string,
  paginationPageSize: PropTypes.number,
  pagination: PropTypes.bool,
  paginationAutoPageSize: PropTypes.bool
}

export default BasicGrid
