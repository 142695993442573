/* A React Hook wrapper around the @zensen/FormService class */

import React from 'react'
import FormService from '@zensen/form-service'

export default function (model, selectors, onChange = () => {}) {
  function buildInitFn (key) {
    return function () {
      const service = new FormService(model, selectors, () => {})

      return service[key]
    }
  }

  const [dirty, setDirty] = React.useState(false)
  const [state, setState] = React.useState(buildInitFn('state'))
  const [errors, setErrors] = React.useState(buildInitFn('errors'))
  const [pristine, setPristine] = React.useState(buildInitFn('__pristine'))

  /* istanbul ignore next */
  const handleStateChange = (dirty, state, errors, pristine) => {
    onChange(dirty, state, errors, pristine)
    setDirty(dirty)
    setState(state)
    setErrors(errors)
    setPristine(pristine)
  }

  const [service] = React.useState(() =>
    new FormService(model, selectors, handleStateChange))

  const result = React.useMemo(() => ({
    reset: () => service.reset(),
    refresh: model => service.refresh(model),
    apply: (path, value) => service.apply(path, value),
    addItem: (path, index = -1) => service.addItem(path, index),
    removeItem: (path, index = -1) => service.removeItem(path, index),
    moveItem: (path, fromIndex, toIndex) => service.moveItem(path, fromIndex, toIndex),
    swapItems: (path, index1, index2) => service.swapItems(path, index1, index2),
    convert: (data, op, rootPath = []) => service.convert(data, op, rootPath),
    build: () => service.build(),
    validate: () => service.validate(),
    validateKey: (keyPath, force = false) => service.validateKey(keyPath, force),
    unsetPristine: (keyPath) => service.unsetPristine(keyPath),
    getSelectorPath: (keyPath, ignoreCheck = false) => service.getSelectorPath(keyPath, ignoreCheck),
    getSelector: (keyPath, ignoreCheck = false) => service.getSelector(keyPath, ignoreCheck),
    getValidators: (keyPath) => service.getValidators(keyPath)
  }), [service])

  /*
    Hoisted state - we mutate rather than re-instantiate because:
    1. Cheaper than object re-instantiation
    2. The output object doesn't cause needless render cycles when used with `useEffect()`
   */

  result.hasErrors = service.hasErrors
  result.isPristine = service.isPristine
  result.isDirty = dirty
  result.state = state
  result.errors = errors
  result.pristine = pristine

  return result
}
