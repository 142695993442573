import React from 'react'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import MuiTextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import FILE_TYPES from '../../store/objects/data/fileTypes.json'
import { styled } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'

import {
  setNameValueAction,
  toggledSaveMappingsDialogAction,
  saveMappings
} from '../../store/pages/mapping'

const StyledGrid = styled(Grid)(({ theme }) => ({
  margin: theme.spacing(1),
  paddingRight: theme.spacing(1)
}))

const TextField = styled(MuiTextField)(({
  width: 400
}))

export default function SaveMappingsDialog () {
  const dispatch = useDispatch()
  const [isSaving, setIsSaving] = React.useState(false)

  const {
    saveMappingsDialog,
    file
  } = useSelector(({
    mapping: {
      saveMappingsDialog
    },
    navigation: {
      file
    }
  }) => ({
    saveMappingsDialog,
    file
  }))

  const fileType = FILE_TYPES.default.find(({ value }) => value === file.fileType).label

  const handleSaveClick = () => {
    setIsSaving(true)
    dispatch(saveMappings)
  }

  return (
    <div>
      <DialogTitle id="form-dialog-title">Save Mappings</DialogTitle>
      <DialogContent>
        <Grid container>
          <StyledGrid item xs={12}>
            <Typography>
                Type: {fileType}
            </Typography>
          </StyledGrid>
          <StyledGrid item xs={12}>
            <TextField
              label="Name"
              margin="normal"
              variant='standard'
              onChange={(e) => dispatch({ ...setNameValueAction, payload: e.target.value })}
              value={saveMappingsDialog.name}
            />
          </StyledGrid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => dispatch(toggledSaveMappingsDialogAction)}
          color="primary"
        >
              Cancel
        </Button>
        <Button
          onClick={handleSaveClick}
          color="primary"
          disabled={isSaving}
          >
              {isSaving ? 'Saving.....' : 'Save'}
        </Button>
      </DialogActions>
    </div>
  )
}
