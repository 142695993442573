import React from 'react'
import PropTypes from 'prop-types'
import CheckIcon from '@mui/icons-material/Check'
import Button from '@mui/material/Button'
import { useDispatch } from 'react-redux'
import { updateComments } from '../../../store/pages/review'

function AcknowledgeButton (props) {
  const dispatch = useDispatch()

  const isAcknowledged = props.objectData?.flagAcknowledge
  const canComment = props.access && !isAcknowledged

  const handleAcknowledgeButtonClick = event => {
    event.stopPropagation()

    props.objectData.flagAcknowledge = true

    dispatch(updateComments(props.objectData))
  }

  return (
    <Button
      data-testid='button-acknowledge'
      disabled={!canComment}
      onClick={handleAcknowledgeButtonClick}
      size='small'
      variant="outlined"
      color="primary">
      <CheckIcon />
      {`${isAcknowledged ? 'ACKNOWLEDGED' : 'ACKNOWLEDGE'}` }
    </Button>
  )
}

AcknowledgeButton.propTypes = {
  objectData: PropTypes.any,
  access: PropTypes.any
}

export default AcknowledgeButton
