import React from 'react'
import BaseSelect from 'react-select'
import BaseCreatableSelect from 'react-select/creatable'
import Fab from '@mui/material/Fab'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import { styled } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { OPERATIONS_OPTIONS } from '../../../../stringConstants'

import {
  SingleValue,
  SingleValueStyles
} from '../../../../components/sql/SelectSingleValue'

import {
  addedWhereItemAction,
  changedWhereItemAction,
  removedWhereItemAction
} from '../../../../store/pages/common/join'

const Root = styled(Grid)(({ theme }) => ({
  margin: theme.spacing(1)
}))

const Select = styled(BaseSelect)(({ theme }) => ({
  minHeight: 58,
  margin: theme.spacing(1)
}))

const CreatableSelect = styled(BaseCreatableSelect)(({ theme }) => ({
  minHeight: 58,
  margin: theme.spacing(1)
}))

const WhereList = () => {
  const dispatch = useDispatch()
  const navigation = useSelector(store => store.navigation)
  const dataPrep = useSelector(store => store.dataPrep)
  const join = useSelector(store => store.join)

  const sourceFileColumns = navigation.file.columns || []

  return (
    <Root container>
      <Grid item xs={7}>
        <Typography variant="h6">Filters</Typography>
      </Grid>

      <Grid item xs={5}>
        <Fab
          size="small"
          color="primary"
          aria-label="add"
          onClick={() => dispatch(addedWhereItemAction)}
        >
          <AddIcon/>
        </Fab>
      </Grid>

      {join.whereList.map((whereItem, index) => (
        <Grid key={index} container>
          <Grid item xs={3}>
            <Select
              placeholder=''
              styles={SingleValueStyles}
              value={Object.keys(whereItem.sourceColumn).length === 0 ? undefined : whereItem.sourceColumn}
              options={sourceFileColumns.map(x => ({ value: x.Name, label: `${x.Name} (${x.Type})` }))}
              components={{ SingleValue }}
              onChange={(newValue) => dispatch({ ...changedWhereItemAction, payload: { name: 'sourceColumn', value: newValue, index } })}
            />
          </Grid>

          <Grid item xs={1}>
            <Select
              placeholder=''
              value={Object.keys(whereItem.sourceOperation).length === 0 ? undefined : whereItem.sourceOperation}
              styles={SingleValueStyles}
              options={OPERATIONS_OPTIONS}
              components={{ SingleValue }}
              onChange={(newValue) => dispatch({ ...changedWhereItemAction, payload: { name: 'sourceOperation', value: newValue, index } })}
            />
          </Grid>

          <Grid item xs={3}>
            <CreatableSelect
              placeholder=''
              value={Object.keys(whereItem.referenceColumn).length === 0 ? undefined : whereItem.referenceColumn}
              styles={SingleValueStyles}
              options={dataPrep.referenceColumns.map(x => ({ value: x.Name, label: `${x.Name} (${x.Type})` }))}
              components={{ SingleValue }}
              onChange={(newValue) => dispatch({ ...changedWhereItemAction, payload: { name: 'referenceColumn', value: newValue, index } })}
            />
          </Grid>

          <Grid item xs={4}>
            <IconButton
              size="large"
              onClick={() => dispatch({ ...removedWhereItemAction, payload: index })}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Grid>
        </Grid>
      ))}
    </Root>
  );
}

export default WhereList
