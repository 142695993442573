import React from 'react'
import PropTypes from 'prop-types'
import BarCanvas from '../../components/NivoChart/BarCanvas'
import DateChart from '../../components/NivoChart/DateNivo'
import LineChart from '../../components/NivoChart/LineChart'

const getGraph = (dataRelevant) => {
  const { sourceDataType } = dataRelevant
  switch (sourceDataType) {
    case 'character varying':
    case 'varchar': return (<BarCanvas data={dataRelevant} />)
    case 'double precision':
    case 'decimal' :return (<BarCanvas data={dataRelevant} />)
    case 'datex' :return (<DateChart data ={dataRelevant}/>)
    case 'date' :return (<LineChart data ={dataRelevant}/>)
    case 'double':
    case 'numeric':
    case 'int':
    case 'integer':
    case 'bigint': return (<BarCanvas data={dataRelevant}/>)
    default: return (<div>default</div>)
  }
}

const NivoChart = ({ data }) => {
  const { distinctCnt = null } = data
  return (
    distinctCnt && distinctCnt < 100 ? getGraph(data) : 'Graph is not avaliable for more than 100 values.'
  )
}
NivoChart.propTypes = {
  data: PropTypes.any
}

export default NivoChart
