import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import MuiTableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Checkbox from '@mui/material/Checkbox'
import Grid from '@mui/material/Grid'
import { styled } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import SearchBox from '../../../../components/form/SearchBox'
import { getComparator, stableSort } from '../../../../commonFunc'
import { selectAggregatorsAction } from '../../../../store/pages/common/workflow'

const TableCell = styled(MuiTableCell)(({
  padding: '5px 5px 5px 5px',
  maxHeight: '20px',
  maxWidth: 100
}))

function EnhancedTableHead (props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headCellData } = props
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all' }}
          />
        </TableCell>
        {headCellData.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={'none'}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ padding: '2px 2px', maxHeight: '20px', maxWidth: headCell.weight }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  headCellData: PropTypes.any
}

function EnhancedTable (props) {
  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState('label')
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)
  const dispatch = useDispatch()

  const navigation = useSelector((state) => state.navigation)

  const columns = navigation.file.columns.reduce((s, a) => {
    s.push({ label: a.Name, type: a.Type })
    return s
  }, [])

  const { selectedAggregators } = props.state.state
  const selectedAggregatorsCheck = selectedAggregators.filter(a => columns.find(x => x.label === a))
  const headCells3 = [
    { id: 'Field', label: 'Field', weight: '100px' },
    { id: 'Type', label: 'Type', weight: '100px' }]

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }
  const [filterQueries, setFilterQueries] = useState(undefined)
  const rowsFilter = filterQueries === undefined
    ? columns
    : columns.filter(x => x.label.toLowerCase().match(filterQueries.toLowerCase()))

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = columns.map((n) => n.label)
      dispatch({ ...selectAggregatorsAction, payload: { selected: newSelecteds, order: props.order } })
      return
    }
    dispatch({ ...selectAggregatorsAction, payload: { selected: [], order: props.order } })
  }

  const handleClick = (event, name) => {
    const selectedIndex = selectedAggregatorsCheck.indexOf(name)
    let newSelected = []
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedAggregatorsCheck, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedAggregatorsCheck.slice(1))
    } else if (selectedIndex === selectedAggregatorsCheck.length - 1) {
      newSelected = newSelected.concat(selectedAggregatorsCheck.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedAggregatorsCheck.slice(0, selectedIndex),
        selectedAggregatorsCheck.slice(selectedIndex + 1)
      )
    }
    dispatch({ ...selectAggregatorsAction, payload: { selected: newSelected, order: props.order } })
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const isSelected = (name) => selectedAggregatorsCheck.indexOf(name) !== -1

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, columns.length - page * rowsPerPage)

  return (
    <div>
      <Grid item xs={6}>
        <SearchBox onChange={e => setFilterQueries(e.target.value)} />
      </Grid>
      <TableContainer>
        <Table size='small'>
          <EnhancedTableHead
            numSelected={selectedAggregatorsCheck.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={columns.length}
            headCellData ={headCells3}
          />
          <TableBody>
            {
              stableSort(rowsFilter, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.label)
                  const labelId = `enhanced-table-checkbox-${index}`
                  return (
                    <TableRow
                      key={index}
                      hover
                      onClick={(event) => handleClick(event, row.label)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      selected={isItemSelected}
                      style ={ isItemSelected ? { background: '#D3D3D3' } : {} }
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          color="primary"
                        />
                      </TableCell>
                      <TableCell align="left" component="th" id={labelId} scope="row" padding="none">
                        {row.label}
                      </TableCell>
                      <TableCell align="left" component="th" id={labelId} scope="row" padding="none">
                        {row.type}
                      </TableCell>
                    </TableRow>
                  )
                })}
            {emptyRows > 0 && (
              <TableRow style={{ maxHeight: 20 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 20]}
        component="div"
        count={columns.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  )
}

EnhancedTable.propTypes = {
  state: PropTypes.object,
  actions: PropTypes.object,
  order: PropTypes.number
}

export {
  EnhancedTableHead,
  EnhancedTable
}
