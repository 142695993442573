import React from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { loadReferenceTable, loadReferenceTableInitialStateAction } from '../../../store/pages/reference'

export default () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const prevPath = React.useRef('')

  React.useEffect(() => {
    const [
      // eslint-disable-next-line no-unused-vars
      _unused,
      path,
      referenceTableId,
      view
    ] = location.pathname.split('/')

    const numericId = !isNaN(referenceTableId)

    if (path === 'reference') {
      if (numericId) {
        dispatch(loadReferenceTable(referenceTableId, view))
      } else {
        dispatch(loadReferenceTableInitialStateAction)
      }
    } else {
      if (prevPath.current === 'reference') {
        dispatch(loadReferenceTableInitialStateAction)
      }
    }

    prevPath.current = path
  }, [
    location.pathname,
    dispatch
  ])
}
