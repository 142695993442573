import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  getPrecalCheckData,
  startNonCriticalChecks
} from '../../../store/pages/preCalculation'

export default () => {
  const dispatch = useDispatch()

  const {
    loadNonCrit,
    nonCriticalCompleted,
    nonCriticalCheckData,
    preCalculationCheckData = []
  } = useSelector(store => store.preCalc)
  const {
    id,
    studyType,
    formatFiles
  } = useSelector(store => store.project)

  useEffect(() => {
    const errorsIncludeFix = preCalculationCheckData
      .findIndex(data => !data.fix && data.results?.length > 0) === -1
    const runNonCritical =
      errorsIncludeFix &&
      loadNonCrit &&
      !nonCriticalCompleted &&
      studyType === 'redi' &&
      formatFiles.length > 5

    if (!preCalculationCheckData) {
      dispatch(getPrecalCheckData(id, nonCriticalCheckData))
    }

    if (runNonCritical && errorsIncludeFix) {
      dispatch(startNonCriticalChecks)
    }
  })
}
