import React from 'react'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'

const replaceFormat = (value) => value ? value.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : ''
const getProfileRows = (values, access) => {
  return (
    <TableRow id='rowSummary'>
      <TableCell>
        {replaceFormat(values.distinctCnt)}
      </TableCell>
      <TableCell>
        {replaceFormat(values.total)}
      </TableCell>
      <TableCell>
        {values.targetDataType === 'date' ? values.min : replaceFormat(values.min)}
      </TableCell>
      <TableCell>
        {values.targetDataType === 'date' ? values.max : replaceFormat(values.max)}
      </TableCell>
    </TableRow>

  )
}

export { getProfileRows }
