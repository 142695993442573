import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { saveFinished, setRedirectAction } from '../../../store/pages/reference'

const useFileProfilingComplete = async () => {
  const dispatch = useDispatch()
  const { isSaving, isFileUploadComplete, isReadyToRedirect, referenceTable } = useSelector(({ reference: { isSaving, isFileUploadComplete, isReadyToRedirect, referenceTable } }) => ({
    isSaving, isFileUploadComplete, isReadyToRedirect, referenceTable
  }))
  const navigate = useNavigate()
  const location = useLocation()

  React.useEffect(() => {
    const areFilesProcessed = async () => {
      if (isSaving && isFileUploadComplete) {
        dispatch(saveFinished)
      }
      if (isReadyToRedirect && referenceTable.files.filter(file => file.profileStatus === 'SUCCEEDED').length > 0) {
        navigate(`/reference/${referenceTable.id}/query`)
        dispatch({
          ...setRedirectAction,
          payload: false
        })
      }
    }
    areFilesProcessed()
  }, [dispatch, navigate, isSaving, isFileUploadComplete, isReadyToRedirect, referenceTable.id, referenceTable.files, location.pathname])
}

export default useFileProfilingComplete
