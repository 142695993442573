import React from 'react'
import MuiGrid from '@mui/material/Grid'
import ReactSelect from 'react-select'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import Typography from '@mui/material/Typography'
import Fab from '@mui/material/Fab'
import AddIcon from '@mui/icons-material/Add'
import PropTypes from 'prop-types'
import { styled } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'

import { SingleValue, SingleValueStyles } from '../../../../../components/sql/SelectSingleValue'
import { addedJoinItemAction, changedJoinItemAction, removedJoinItemAction } from '../../../../../store/pages/common/workflow'
import { OPERATIONS_OPTIONS } from '../../../../../stringConstants'

const Grid = styled(MuiGrid)(({ theme }) => ({
  margin: theme.spacing(1)
}))

const Select = styled(ReactSelect)(({ theme }) => ({
  minHeight: 58,
  margin: theme.spacing(1)
}))

function JoinList (props) {
  const dispatch = useDispatch()

  const navigation = useSelector((state) => state.navigation)
  const dataPrep = useSelector((state) => state.dataPrep)

  const sourceFileColumns = navigation.file.columns || []
  const joinList = props.state.state.joinList

  return (
    <Grid container>
      <Grid item xs={7}>
        <Typography variant="h6">
          Linking Keys
        </Typography>
      </Grid>
      <Grid item xs={5}>
        <Fab
          size="small"
          color="primary"
          aria-label="add"
          onClick={() => dispatch({ ...addedJoinItemAction, payload: { order: props.order } })}
        >
          <AddIcon/>
        </Fab>
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={3}>
        <Typography variant="h6">
          Source Columns
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography variant="h6">
          Reference Columns
        </Typography>
      </Grid>
      {
        joinList.map((joinItem, index) =>
          <Grid key={index} container>

            <Grid item xs={3} >
              <Select
                placeholder=""
                value={Object.keys(joinItem.sourceColumn).length === 0 ? '' : joinItem.sourceColumn}
                options={sourceFileColumns.map(x => ({ value: x.Name, label: `${x.Name} (${x.Type})` }))}
                components={{ SingleValue }}
                styles={SingleValueStyles}
                onChange={(newValue) => dispatch({ ...changedJoinItemAction, payload: { name: 'sourceColumn', value: newValue, index, order: props.order } })}

              />
            </Grid>
            <Grid item xs={1}>
              <Select
                placeholder=""
                value={Object.keys(joinItem.sourceOperation).length === 0 ? '' : joinItem.sourceOperation}
                styles={SingleValueStyles}
                options={OPERATIONS_OPTIONS}
                components={{ SingleValue }}
                onChange={(newValue) => dispatch({ ...changedJoinItemAction, payload: { name: 'sourceOperation', value: newValue, index, order: props.order } })}
              />
            </Grid>
            <Grid item xs={3}>
              <Select
                placeholder=""
                value={Object.keys(joinItem.referenceColumn).length === 0 ? '' : joinItem.referenceColumn}
                styles={SingleValueStyles}
                options={dataPrep.referenceColumns.map(x => ({ value: x.Name, label: `${x.Name} (${x.Type})` }))}
                components={{ SingleValue }}
                onChange={(newValue) => dispatch({ ...changedJoinItemAction, payload: { name: 'referenceColumn', value: newValue, index, order: props.order } })}
              />
            </Grid>
            <Grid item xs={4}>
              <IconButton
                aria-label="delete"
                onClick={() => dispatch({ ...removedJoinItemAction, payload: { index, order: props.order } })}
                size="large">
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        )
      }
    </Grid>
  );
}

JoinList.propTypes = {
  state: PropTypes.object,
  actions: PropTypes.object,
  order: PropTypes.number
}

export default React.memo(JoinList)
