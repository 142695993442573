import referenceApi from '../../../objects/referenceApi'
import apiHelper from '../../../objects/apiHelper'
import { buildSQL } from '../../../../containers/valueMapping/dataQuery.utils'
import { setDownloadOnlyAction } from '../query'

const defaultOrderBy = { value: 'asc', label: 'Asc' }
const defaultFields = [{ value: '*', label: '* (all)' }]

const PRECALC_DEFAULT_PAGE_SIZE = 50

export class DataPrepServer {
  constructor ({
    hasMultipleOR = false,
    country,
    orderBy = defaultOrderBy,
    orderByConditions = defaultFields,
    querySelectFields = [{ value: '*', label: '* (all)' }],
    queryWhereConditions = []
  }, gridRef, execute, dispatch, onFinish = () => {}) {
    this.getRows = async (params) => {
      const { tableName, id } = execute
      const url = await apiHelper.getWebsocketUrl(country)
      const ws = new WebSocket(url)
      const page = gridRef.current.api.paginationGetCurrentPage()
      const body = {
        hasMultipleOR,
        isPrecalculation: false,
        orderByConditions,
        orderBy,
        paging: { pageNo: page, pageSize: 25 },
        projectId: id,
        querySelectFields,
        queryWhereConditions,
        tableName
      }

      ws.onopen = () => {
        ws.send(`{"action":"onquery", "body":${JSON.stringify(body)}}`)
        ws.onmessage = e => {
          const colDefs = []
          const dataFormat = JSON.parse(e.data)

          try {
            const keys = Object.keys(dataFormat.results[0])

            keys.forEach(key => colDefs.push({
              field: key,
              suppressHeaderMenuButton: true
            }))
            dispatch({ ...setDownloadOnlyAction, payload: true })

            params.api.setGridOption('columnDefs', colDefs)
            params.success({
              rowData: dataFormat.results,
              rowCount: dataFormat.count || dataFormat.results.length
            })
            ws.close()

            onFinish(null, {
              count: dataFormat.count,
              rows: dataFormat.results,
              colDefs
            })
          } catch (err) {
            console.error(err)
            params.fail()
            onFinish(err)
          }
        }
      }
    }
  }
}

export class ReferenceServer {
  constructor () {
    this.getRows = async (params) => {
      try {
        const { data, count } = await referenceApi.searchReferenceTable()
        params.success({
          rowData: data,
          rowCount: count
        })
      } catch (error) {
        console.error(error.stack)
        params.fail()
      }
    }
  }
}

export class PreCalcServer {
  constructor ({
    country,
    orderBy = defaultOrderBy,
    orderByConditions = [],
    querySelectFields = [{ value: '*', label: '* (all)' }],
    queryWhereConditions = [],
    pageSize = PRECALC_DEFAULT_PAGE_SIZE
  }, gridRef, tableName, id, dispatch) {
    this.getRows = async (params) => {
      const page = gridRef.current.api.paginationGetCurrentPage()
      const url = await apiHelper.getWebsocketUrl(country)
      const ws = new WebSocket(url)
      const body = {
        isPrecalculation: true,
        orderByConditions,
        orderBy,
        paging: { pageNo: page, pageSize },
        projectId: id,
        querySelectFields,
        queryWhereConditions,
        tableName
      }

      ws.onopen = () => {
        ws.send(`{"action":"onquery", "body":${JSON.stringify(body)}}`)
        ws.onmessage = e => {
          const colDefs = []
          const dataFormat = JSON.parse(e.data)
          try {
            const keys = Object.keys(dataFormat.results[0])
            keys.forEach(key => colDefs.push({
              field: key,
              suppressHeaderMenuButton: true
            }))
            if (dataFormat) {
              dispatch({ ...setDownloadOnlyAction, payload: true })
            }
            const countForGrid = dataFormat.count ?? dataFormat.results.length
            params.success({
              rowData: dataFormat.results,
              rowCount: countForGrid
            })
            params.api.setGridOption('columnDefs', colDefs)

            ws.close()
          } catch (err) {
            console.error(err)
            params.fail()
          }
        }
      }
    }
  }
}

export class MappingPreviewGrid {
  constructor ({
    file, profileData
  }, source) {
    this.getRows = async (params) => {
      const relevantData = profileData.find(datum => datum.guid === file.guid && datum.fieldName === source)
      const colDefs = []
      try {
        const keys = Object.keys(relevantData.values[0])
        const checkValueExists = !keys.includes('value')
        if (checkValueExists) {
          keys.push('value')
        }
        keys.forEach(key => !['field', 'name'].includes(key) && colDefs.push({
          field: key,
          suppressHeaderMenuButton: true
        }))
        params.api.setGridOption('columnDefs', colDefs)

        params.success({
          rowData: relevantData.values,
          rowCount: relevantData.values.length
        })
      } catch (err) {
        console.error(err)
        params.fail()
      }
    }
  }
}

export class MappingPreviewGridAllData {
  constructor ({ country },
    gridRef,
    execute
  ) {
    this.getRows = async (params) => {
      const page = gridRef.current.api.paginationGetCurrentPage()
      const url = await apiHelper.getWebsocketUrl(country)
      const ws = new WebSocket(url)

      const query = buildSQL(
        execute.source,
        execute.sourceDataType,
        execute.projectId,
        execute.guid,
        execute.totalCount,
        page
      )

      const body = {
        isDataProfile: true,
        isPrecalculation: false,
        projectId: query.id,
        tableName: query.tableName,
        querySelectFields: query.querySelectFields,
        queryWhereConditions: query.queryWhereConditions,
        paging: { pageNo: page, pageSize: 100 },
        orderByConditions: query.orderByConditions,
        orderBy: query.orderBy || { value: 'asc', label: 'Asc' },
        totalCount: execute.totalCount
      }
      ws.onopen = (x, req) => {
        ws.send(`{"action":"onquery", "body":${JSON.stringify(body)}}`)
        ws.onmessage = e => {
          const colDefs = []
          const dataFormat = JSON.parse(e.data)
          try {
            const keys = Object.keys(dataFormat.results[0])

            keys.forEach(key => key.includes('DATE')
              ? colDefs.push({
                field: execute.source,
                suppressHeaderMenuButton: true
              })
              : colDefs.push({
                field: key,
                suppressHeaderMenuButton: true
              }))

            const dateField = keys.find(key => key.includes('DATE'))

            const datamap = dataFormat.results.reduce((accum, curr) => {
              if (dateField) accum.push({ ...curr, [`${execute.source}`]: curr[`${dateField}`] })
              else {
                accum.push(curr)
              }
              return accum
            }, [])

            params.api.setGridOption('columnDefs', colDefs)

            params.success({
              rowData: datamap,
              rowCount: execute.totalCount
            })

            ws.close()
          } catch (err) {
            console.error(err)
            params.fail()
          }
        }
      }
    }
  }
}

export class ValueMappingGridData {
  constructor ({
    country, resultsGrid
  }, gridRef, execute, relevantData, excludeObj) {
    this.getRows = async (params) => {
      const page = gridRef.current.api.paginationGetCurrentPage()
      const { valMapp } = relevantData
      const url = await apiHelper.getWebsocketUrl(country)
      const ws = new WebSocket(url)
      const query = buildSQL(execute.source, execute.sourceDataType, execute.projectId, execute.guid, execute.totalCount, page)
      const body = {
        isDataProfile: true,
        isPrecalculation: false,
        projectId: query.id,
        tableName: query.tableName,
        querySelectFields: query.querySelectFields,
        queryWhereConditions: query.queryWhereConditions,
        paging: { pageNo: page, pageSize: 100 },
        orderByConditions: query.orderByConditions,
        orderBy: query.orderBy || { value: 'asc', label: 'Asc' },
        totalCount: execute.totalCount
      }
      ws.onopen = (x, req) => {
        ws.send(`{"action":"onquery", "body":${JSON.stringify(body)}}`)
        ws.onmessage = e => {
          const colDefs = []
          const dataFormat = JSON.parse(e.data)
          try {
            const keys = Object.keys(dataFormat.results[0])
            keys.forEach(key => colDefs.push({
              field: key,
              suppressHeaderMenuButton: true
            }))
            colDefs.push(excludeObj)
            const labelMapp = resultsGrid.columns[0]
            dataFormat.results.forEach(a => {
              const map = valMapp.find(x => x.originalValue === a[labelMapp] && x.exclude === true)
              if (map) {
                a.exclude = map.exclude
              } else {
                a.exclude = false
              }
            })
            params.api.setGridOption('columnDefs', colDefs)
            params.success({
              rowData: dataFormat.results,
              rowCount: body.totalCount
            })
            ws.close()
          } catch (err) {
            console.error(err)
            params.fail()
          }
        }
      }
    }
  }
}
