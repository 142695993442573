import PropTypes from 'prop-types'
import React from 'react'
import Grid from '@mui/material/Grid'
import MuiPaper from '@mui/material/Paper'
import BaseButton from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import SaveIcon from '@mui/icons-material/Save'
import FormulaDialog from '../../../components/sql/FormulaDialog'
import SaveQueryDialogLegacy from '../components/SaveQueryDialogLegacy'
import Navigation from '../components/navigation'
import WhereList from './where'
import SetList from './set'
import { styled } from '@mui/material'
import { update as setUpdate } from '../../../store/pages/common/update'
import { useDispatch, useSelector } from 'react-redux'
import { toggleSaveQueryDialogAction } from '../../../store/pages/dataPrep'

import {
  actions as formulaDialogActions,
  saveFormulaDialog
} from '../../../store/pages/common/formulaDialog'

const Form = styled(Grid)(({ theme }) => ({
  textAlign: 'center',
  color: theme.palette.text.secondary
}))

const Paper = styled(MuiPaper)(({ theme }) => ({
  padding: theme.spacing(2),
  width: '100%',
  backgroundColor: '#DEDEDE'
}))

const Button = styled(BaseButton)(({ theme }) => ({
  margin: theme.spacing(1)
}))

const ResultsText = styled(Typography)({
  float: 'left',
  marginTop: 10
})

const ButtonBar = styled('div')(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(2),
  gridTemplateColumns: 'auto auto 1fr'
}))

export default function Update (props) {
  const dispatch = useDispatch()
  const navigation = useSelector(store => store.navigation)
  const project = useSelector(store => store.project)
  const update = useSelector(store => store.update)
  const dataPrep = useSelector(store => store.dataPrep)
  const formulaDialog = useSelector(store => store.formulaDialog)

  const { columns } = navigation.file
  const tableName = navigation.file.guid
  const tableNameRedshift = `red_${project.id}_upload.${tableName}`
  const showSpinner = props.isDelete ? update.isDeleting : update.isSaving
  const targetQueryType = props.isDelete ? 'delete' : 'update'

  const hasId =
    dataPrep.saveQueryDialog.guid !== undefined &&
    dataPrep.saveQueryDialog.queryType === targetQueryType

  const saveLabel = hasId ? 'Update' : 'Save'

  const handleFormulaSave = () => {
    dispatch(saveFormulaDialog(false))
  }

  const handleFormulaCancel = () => {
    dispatch(formulaDialogActions.close())
  }

  const handleExecuteClick = () => {
    dispatch(setUpdate(tableName, project.id, true, props.isDelete))
  }

  const handleSaveClick = () => {
    dispatch(toggleSaveQueryDialogAction)
  }

  return (
    <Grid container spacing={3}>
      <Dialog
        open={formulaDialog.open}
        maxWidth='md'
      >
        <FormulaDialog
          tableName={tableNameRedshift}
          resource={project}
          columnNames={columns.map(col => col.Name)}
          onSave={handleFormulaSave}
          onCancel={handleFormulaCancel}
        />
      </Dialog>

      <Dialog
        open={dataPrep.saveQueryDialog.open}
        maxWidth='md'
      >
        <SaveQueryDialogLegacy />
      </Dialog>

      <Grid item xs={12}>
        <Form>
          <Grid item xs={12}>
            <Navigation/>
          </Grid>

          <Paper>
            {!props.isDelete && (
              <Grid item xs={12}>
                <SetList
                  data-testid='set-list'
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <WhereList
                data-testid='where-list'
                isDelete={props.isDelete}
              />
            </Grid>

            <Grid item xs={12}>
              {showSpinner
                ? <CircularProgress
                  data-testid='progress'
                />
                : <ButtonBar>
                    <Button
                      data-testid='button-execute'
                      color="primary"
                      variant="outlined"
                      startIcon={<PlayArrowIcon />}
                      onClick={handleExecuteClick}
                    >Execute</Button>

                    <Button
                      data-testid='button-save'
                      color="secondary"
                      variant="contained"
                      startIcon={<SaveIcon />}
                      onClick={handleSaveClick}
                    >{saveLabel}</Button>

                  </ButtonBar>
              }

              <ResultsText data-testid='message'>
                {update.message || ''}
              </ResultsText>
            </Grid>
          </Paper>
        </Form>
      </Grid>
    </Grid>
  )
}

Update.propTypes = {
  isDelete: PropTypes.bool,
  children: PropTypes.node
}
