import React from 'react'
import { useDispatch } from 'react-redux'
import { resetQueryAction } from '../../../store/pages/common/dataQuery'

import {
  clearedEditorAction,
  setIsReferenceAction
} from '../../../store/pages/common/query'

export default () => {
  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch({
      ...setIsReferenceAction,
      payload: { isReference: true }
    })

    return () => {
      dispatch(resetQueryAction)

      dispatch({
        ...setIsReferenceAction,
        payload: { isReference: false }
      })

      dispatch(clearedEditorAction)
    }
  }, [dispatch])
}
