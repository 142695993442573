import referenceApi from '../objects/referenceApi'
import stepFunctionsApi from '../objects/stepFunctionsApi'
import mappingApi from '../objects/mappingApi'
import preCalApi from '../objects/preCalApi'
import calculationApi from '../objects/calculationApi'
import postCalApi from '../objects/postCalApi'
import apiHelper from '../objects/apiHelper'
import { profiledDataAction } from './dataPrep'

import {
  startedProjectDataLoadAction,
  unauthorizedProjectDataLoadAction,
  resetProjectDataAction,
  loadedProjectDataAction,
  creatingProjectAction,
  updatingProjectAction,
  updatedProjectAction,
  saveFinishedAction
} from './project/actions'

const initialState = {
  canRunStudy: false,
  drawerOpen: true,
  hasRunStudy: false,
  isSaving: false,
  isLoaded: false,
  isReadyToRedirect: false,
  redirectToHome: false
}

export const changeRegion = country => {
  const { region } = apiHelper.getCountry(country)
  calculationApi.setAPIRegion(region)
  mappingApi.setAPIRegion(region)
  preCalApi.setAPIRegion(region)
  postCalApi.setAPIRegion(region)
  referenceApi.setAPIRegion(region)
  // sourcesApi.setAPIRegion(region) address these
  stepFunctionsApi.setAPIRegion(region)
}

export const setDrawerAction = {
  type: 'global/SET_DRAWER'
}

export const loadedAction = {
  type: 'global/LOADED'
}

export const updatedUploadAction = {
  type: 'global/UPDATED_UPLOAD'
}

export const setCalculationRedirectAction = {
  type: 'global/SET_CALCULATION_REDIRECT'
}

export const cancelRedirectAction = {
  type: 'global/CANCEL_REDIRECT'
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case loadedProjectDataAction.type:
      return {
        ...state,
        isReadyToRedirect: false,
        redirectToHome: false,
        isLoaded: true
      }
    case setDrawerAction.type:
      return {
        ...state,
        drawerOpen: payload
      }
    case startedProjectDataLoadAction.type:
      return Object.assign({
        ...state,
        redirectToHome: false,
        isLoaded: !payload
      },
      payload ? { isReadyToRedirect: false } : null
      )
    case unauthorizedProjectDataLoadAction.type:
      return {
        ...state,
        isLoaded: true,
        redirectToHome: true
      }
    case resetProjectDataAction.type:
      return {
        ...state,
        isLoaded: false,
        redirectToHome: false
      }
    case loadedAction.type:
      return {
        ...state,
        isLoaded: true
      }
    case updatedUploadAction.type:
      return {
        ...state,
        ...payload
      }
    case profiledDataAction.type:
      return {
        ...state,
        ...payload.global
      }
    case creatingProjectAction.type:
    case updatingProjectAction.type:
      return {
        ...state,
        isSaving: true
      }
    case updatedProjectAction.type:
    case setCalculationRedirectAction.type:
      return {
        ...state,
        isSaving: false,
        redirectCalculation: payload
      }
    case cancelRedirectAction.type:
      return {
        ...state,
        isReadyToRedirect: false
      }
    case saveFinishedAction.type:
      return {
        ...state,
        ...payload,
        isSaving: false
      }
    default:
      return state
  }
}
