import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { useDispatch } from 'react-redux'
import { setSelectedJobIdAction } from '../../../store/pages/postCalculation'

const CalculationSelectStyles = {
  control: (base) => ({
    ...base,
    width: '600px',
    height: '40px',
    marginBottom: '20px'
  })
}

export default function SelectRunCard ({
  latestRunJobId,
  selectedJobId,
  dropOptions
}) {
  const dispatch = useDispatch()

  const placeHolder = selectedJobId !== 0 ? selectedJobId : latestRunJobId

  return (<Select
    styles={CalculationSelectStyles}
    placeholder={placeHolder}
    options={dropOptions}
    value={selectedJobId}
    onChange={(newValue) =>
      dispatch({
        ...setSelectedJobIdAction,
        payload: {
          selectedJobId: newValue.value
        }
      })
    }
  />

  )
}
SelectRunCard.propTypes = {
  latestRunJobId: PropTypes.any,
  selectedJobId: PropTypes.any,
  dropOptions: PropTypes.any
}
