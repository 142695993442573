import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import JobRun from '../../components/views/JobRun'
import { styled } from '@mui/material'

const Content = styled(Box)({
  border: '1px solid #28A745'
})

const Header = styled(Box)(({ theme }) => ({
  display: 'grid',
  padding: theme.spacing(2),
  width: '100%',
  backgroundColor: '#D1D0D0',
  borderBottom: '1px solid rgba(0, 0, 0, 0.125)',
  alignItems: 'center',
  gridGap: '12px',
  gridTemplateColumns: '180px 360px 1fr'
}))

const StatusLabel = styled(Typography)({
  fontSize: 16,
  color: '#28A745'
})

const SinceCreatedDateText = styled(Typography)({
  fontWeight: 'bold',
  fontSize: '0.8rem'
})

function diffDate (date) {
  const today = dayjs()
  const dateofvisit = dayjs(date)
  const finalDate = today.diff(dateofvisit, 'days')

  if (finalDate === 0) {
    return `${today.diff(dateofvisit, 'hours')} hours ago`
  } else if (finalDate <= 28) {
    return `${today.diff(dateofvisit, 'days')} days ago`
  }

  return `${today.diff(dateofvisit, 'months')} months ago`
}

export default function LatestRunSection (props) {
  const status = React.useMemo(() => {
    if (!props.job.finished) {
      return 'RUNNING JOB'
    } else if (props.job.errorMessage === 'Aborted') {
      return 'ABORTED'
    } else if (!props.completedChecks) {
      return 'RUNNING POST-CALCULATION CHECKS'
    } else {
      return 'FINISHED'
    }
  }, [
    props.completedChecks,
    props.job.finished,
    props.job.errorMessage
  ])

  const name = props.job.finished
    ? `Run Name: ${props.job.id}`
    : 'Latest Run Information Not Available'

  const sinceCreatedLabel = props.job.finished
    ? diffDate(props.job.createdDate)
    : ''

  return (
    <Box>
      <h1>Latest Run</h1>

      <Content>

        <Header>
          <StatusLabel
            data-testid='label-status'
          >{status}</StatusLabel>

          <Typography
            data-testid='label-name'
          >{name}</Typography>

          <SinceCreatedDateText
            data-testid='label-created'
          >{sinceCreatedLabel}</SinceCreatedDateText>
        </Header>

        <JobRun job={props.job} />
      </Content>
    </Box>
  )
}

LatestRunSection.propTypes = {
  completedChecks: PropTypes.bool,
  job: PropTypes.shape({
    id: PropTypes.string,
    finished: PropTypes.bool,
    errorMessage: PropTypes.string,
    createdDate: PropTypes.string
  })
}
