import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import Tooltip from '@mui/material/Tooltip'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import ErrorIcon from '@mui/icons-material/Error'
import WarningIcon from '@mui/icons-material/Warning'
import { styled } from '@mui/material'
import COLUMNS_RGA from '../../store/objects/data/rga_columns.json'
import { useDispatch, useSelector } from 'react-redux'
import { setOptionalMappings, removeOptional } from '../../store/pages/mapping'
import { SingleValue, SingleValueStyles } from '../../components/sql/SelectSingleValue'
import MappingListRow from './MappingListRow'

const EMPTY_ARRAY = Object.freeze([])

const STUDY_TYPE = {
  redi: 'REDI',
  skipMap: 'REDI'
}

const FILE_TYPE = {
  I: 'inforce',
  T: 'termination',
  IT: 'inforce+termination'
}

const OPTION_UNMAPPED = {
  value: 'Unmapped',
  label: 'Unmapped'
}

const EMPTY_ROW_ELEMENT = (
  <TableRow>
    <TableCell>
    </TableCell>
    <TableCell>
    </TableCell>
    <TableCell>
    </TableCell>
  </TableRow>
)

const mapDataType = rgaColumnType => {
  const type = rgaColumnType.toUpperCase()
  if (type.toUpperCase().includes('NUMBER')) {
    return 'decimal'
  }

  if (type.toUpperCase().includes('DATE')) {
    return 'date'
  }

  return 'varchar'
}

const StatusIcon = ({ column }) => {
  const validateDate = column.targetDataType === 'date'
    ? column.targetDataType !== column.sourceDataType
    : false

  return !validateDate
    ? (
      <Tooltip title='Data type mismatch'>
        <WarningIcon/>
      </Tooltip>
      )
    : (
      <Tooltip title='Date must match the source type'>
        <ErrorIcon/>
      </Tooltip>
      )
}

StatusIcon.propTypes = {
  column: PropTypes.shape({
    sourceDataType: PropTypes.string,
    targetDataType: PropTypes.string
  })
}

const StyledTableCell = styled(TableCell)(({
  minWidth: '180px'
}))

function CoreRows ({
  access,
  guid,
  fileType,
  fileData,
  mappingColumns,
  setSourceFocus
}) {
  const file = fileData?.find(file => file.guid === guid)

  if (file === undefined) {
    return ''
  }

  const baseSourceColumnOptions = file.columns.map((column, index) => ({
    value: index.toString(),
    label: column.Name,
    sourceDataType: column.Type
  }))

  const sourceColumnOptions = [
    ...baseSourceColumnOptions,
    OPTION_UNMAPPED
  ]

  return mappingColumns.map((fileColumn, index) => {
    return <MappingListRow
      key={index}
      access={access}
      guid={guid}
      fileColumn={fileColumn}
      fileType={fileType}
      index={index}
      setSourceFocus={setSourceFocus}
      sourceColumnOptions={sourceColumnOptions}
    />
  })
}

function OptionalRows ({
  access,
  guid,
  fileType,
  columnCount,
  options,
  fileMappingsLength
}) {
  const dispatch = useDispatch()

  return [...Array(columnCount).keys()].map((key, index) => {
    const handleRemove = () => {
      dispatch(removeOptional(fileType, guid, fileMappingsLength + index))
    }

    const handleSelectChange = value => {
      dispatch(setOptionalMappings(fileType, guid, value))
    }

    return (
      <TableRow key={key}>
        <TableCell>
          {access &&
            <IconButton
              size="large"
              onClick={handleRemove}
            >
              <DeleteIcon />
            </IconButton>
          }
        </TableCell>

        <TableCell></TableCell>

        <TableCell>
          <Select
            name={`opt-col-${guid}-${index}`}
            value=''
            placeholder=""
            options={options}
            menuPlacement='auto'
            styles={SingleValueStyles}
            components={{ SingleValue }}
            onChange={handleSelectChange}
          />
        </TableCell>

        <StyledTableCell>
          Select an optional column.
        </StyledTableCell>
      </TableRow>
    )
  })
}

const MappingList = ({
  access,
  fileData,
  fileMapping,
  setSourceFocus
}) => {
  const project = useSelector(store => store.project)
  const navigation = useSelector(store => store.navigation)

  const guid = navigation.file.guid
  const fileType = FILE_TYPE[navigation.file?.fileType]
  const studyTypeKey = STUDY_TYPE[project.studyType].toUpperCase()

  if (!fileType) {
    return EMPTY_ROW_ELEMENT
  }

  const optionalColumns = fileType === 'inforce+termination'
    ? COLUMNS_RGA
    : COLUMNS_RGA.filter(
      column => column.required_for_filetype.length === 0 || (
        column.output_type.toUpperCase().includes(studyTypeKey) && (
          column.optionalFileType && column.optionalFileType
            .toUpperCase()
            .includes(fileType.toUpperCase())
        )
      )
    )

  const previouslyMappedOptionalColumns = fileMapping.columns.length
    ? fileMapping.columns.map(column => column.targetColumnName)
    : EMPTY_ARRAY

  const optColumns = optionalColumns.filter(col =>
    !previouslyMappedOptionalColumns.includes(col.name)
  )

  const optionalOptions = optColumns.map((column, index) => ({
    key: column.name,
    value: index,
    isPrimaryKey: column.isPrimaryKey,
    targetDataType: mapDataType(column.type),
    label: column.label,
    toolTip: column.description
  }))

  return (
    <>
      <CoreRows
        access={access}
        guid={guid}
        fileType={fileType}
        fileData={fileData}
        mappingColumns={fileMapping.columns}
        setSourceFocus={setSourceFocus}
      />

      {fileMapping.optionalColumnCount
        ? (
        <OptionalRows
          access={access}
          guid={guid}
          fileType={fileType}
          columnCount={fileMapping.optionalColumnCount}
          options={optionalOptions}
          fileMappingsLength={fileMapping.columns.length}
        />
          )
        : ''}
    </>
  )
}

MappingList.propTypes = {
  access: PropTypes.bool,
  fileData: PropTypes.array.isRequired,
  setSourceFocus: PropTypes.func.isRequired,
  fileMapping: PropTypes.shape({
    columns: PropTypes.array.isRequired,
    optionalColumnCount: PropTypes.number
  })
}

export default React.memo(MappingList)
