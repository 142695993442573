import React from 'react'
import BaseSelect from 'react-select'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import SynonymDialog from '../../../components/sql/SynonymDialog'
import SaveQueryDialogLegacy from '../components/SaveQueryDialogLegacy'
import Navigation from '../components/navigation'
import JoinList from './components/joinList'
import WhereList from './components/whereList'
import ResultsGrid from './components/resultsGrid'
import SaveIcon from '@mui/icons-material/Save'
import PreviewIcon from '@mui/icons-material/Visibility'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import sourcesApi from '../../../store/objects/sourcesAPI'

import { styled } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import { useDispatch, useSelector } from 'react-redux'
import { executeMergeData } from '../../../store/pages/common/join'
import { OPTIONS_JOIN } from '../../../stringConstants'

import {
  SingleValue,
  SingleValueStyles,
  AppendAllStar
} from '../../../components/sql/SelectSingleValue'

import {
  MultiValueLabel,
  MultiValueRemove,
  MultiValueStyles
} from '../../../components/sql/SelectMultiValue'

import {
  toggleSaveQueryDialogAction,
  updateMergeReference
} from '../../../store/pages/dataPrep'

const COMPONENTS_SINGLE = {
  SingleValue
}

const COMPONENTS_MULTI = {
  MultiValueLabel,
  MultiValueRemove
}

const Root = styled(Grid)({
  width: '100%',
  minHeight: 700
})

const Form = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  width: '100%',
  backgroundColor: '#DEDEDE'
}))

const ButtonBar = styled('div')(({ theme }) => ({
  display: 'grid',
  gridGap: theme.spacing(2),
  gridTemplateColumns: 'auto auto auto 1fr auto'
}))

const GridContainer = styled('div')({
  overflowY: 'scroll',
  overflowX: 'auto',
  height: 400
})

const Select = styled(BaseSelect)(({ theme }) => ({
  minHeight: 58,
  margin: theme.spacing(1)
}))

export default function Reference () {
  const dispatch = useDispatch()
  const gridRef = React.useRef()
  const [gridState, setGridState] = React.useState(null)
  const drawerOpen = useSelector(store => store.global.drawerOpen)
  const navigation = useSelector(store => store.navigation)
  const project = useSelector(store => store.project)
  const dataPrep = useSelector(store => store.dataPrep)
  const join = useSelector(store => store.join)
  const synonymDialog = useSelector(store => store.synonymDialog)

  const columns = AppendAllStar(dataPrep.mergeData.fileColumns, navigation.file.columns)

  const hasId =
    dataPrep.saveQueryDialog.guid !== undefined &&
    dataPrep.saveQueryDialog.queryType === 'merge'

  const selectedReferenceTable = Object.keys(dataPrep.mergeData.referenceTable).length
    ? dataPrep.mergeData.referenceTable
    : ''

  const gridContainerStyles = React.useMemo(() => ({
    position: 'relative',
    overflowY: 'none',
    overflowX: 'none',
    width: drawerOpen ? '-webkit-fill-available' : 'calc(99vw)',
    height: '-webkit-fill-available'
  }), [drawerOpen])

  const refTableOptions = dataPrep.referenceTables.data.map(table => ({
    label: table.tableName,
    value: table.guid
  }))

  const supplementaryFileOptions = navigation.files
    .filter(file => file.fileType === 'S')
    .filter(file => file.guid !== navigation.file.guid)
    .map(file => ({
      label: file.fileName,
      value: file.guid
    }))

  const refDataOptions = [
    ...refTableOptions,
    ...supplementaryFileOptions
  ]

  const refTableColumnOptions = dataPrep.referenceColumns.map(column => ({
    label: `${column.Name} (${column.Type})`,
    value: column.Name
  }))

  const fileColumnOptions = columns.map(column => ({
    value: column.Name,
    label: `${column.Name} (${column.Type})`
  }))

  const previewLoadingJsx = gridState?.loading
    ? <CircularProgress size='1rem' />
    : ''

  const handleSelectChange = (value, event) => {
    const result = event.name === 'fileColumns'
      ? value || []
      : value

    if (event.name === 'referenceTable') {
      dispatch(updateMergeReference('referenceColumns', []))
    }

    dispatch(updateMergeReference(event.name, result))
  }

  const handlePreview = async () => {
    setGridState(prev => ({
      loading: true,
      rows: null,
      totalCount: 0
    }))

    const res = await sourcesApi.previewMerge({
      ...dataPrep.mergeData,
      preview: true,
      joinList: join.joinList,
      whereList: join.whereList,
      fileInfo: {
        id: project.id,
        guid: navigation.file.guid
      }
    })

    const rows = res.results
    const totalCount = res.totalCount

    const columns = rows.length
      ? Object.keys(rows[0]).map(field => ({ field }))
      : []

    setGridState(prev => ({
      ...prev,
      loading: false,
      columns,
      rows,
      totalCount
    }))
  }

  const handleExecute = () => {
    dispatch(executeMergeData)
  }

  const handleSave = () => {
    dispatch(toggleSaveQueryDialogAction)
  }

  return (
    <Grid container>
      <Dialog
        open={synonymDialog.open}
        maxWidth='md'
      ><SynonymDialog /></Dialog>

      <Dialog
        open={dataPrep.saveQueryDialog.open}
        maxWidth='md'
      ><SaveQueryDialogLegacy /></Dialog>

      <Root container>
        <Grid item xs={12}>
          <Navigation />
        </Grid>

        <Form outlined elevation={1}>
          <Grid container>
            <Grid item xs={4}>
              <Typography variant='h6'>Reference Data</Typography>

              <Select
                data-testid='select-referenceTable'
                styles={SingleValueStyles}
                name='referenceTable'
                components={COMPONENTS_SINGLE}
                options={refDataOptions}
                value={selectedReferenceTable}
                onChange={handleSelectChange}
              />

              <Typography variant='h6'>
                Reference Columns

                {dataPrep.mergeData.isLoading ? <CircularProgress /> : ''}
              </Typography>

              <Select
                data-testid='select-referenceColumns'
                styles={MultiValueStyles}
                isMulti
                allowEdit
                name='referenceColumns'
                queryKey='referenceColumns'
                components={COMPONENTS_MULTI}
                options={refTableColumnOptions}
                value={dataPrep.mergeData.referenceColumns}
                onChange={handleSelectChange}
              />
            </Grid>

            <Grid item xs={4}>
              <Typography variant='h6'>File Columns</Typography>

              <Select
                data-testid='select-fileColumns'
                styles={MultiValueStyles}
                isMulti
                name='fileColumns'
                components={COMPONENTS_MULTI}
                options={fileColumnOptions}
                value={dataPrep.mergeData.fileColumns}
                onChange={handleSelectChange}
              />
            </Grid>

            <Grid item xs={4}></Grid>

            <Grid item xs={4}>
              <Typography variant='h6'>Join Type</Typography>

              <Select
                data-testid='select-joinType'
                styles={SingleValueStyles}
                name='joinType'
                components={COMPONENTS_SINGLE}
                options={OPTIONS_JOIN}
                value={dataPrep.mergeData.joinType}
                onChange={handleSelectChange}
              />
            </Grid>

            <Grid item xs={12}>
              <JoinList />
            </Grid>

            <Grid item xs={12}>
              <WhereList />
            </Grid>

            <Grid item xs={8}>
              {join.resultsGrid.isLoading
                ? <CircularProgress />
                : <ButtonBar>
                  <Button
                    data-testid='button-preview'
                    color='primary'
                    variant='outlined'
                    disabled={gridState?.loading}
                    startIcon={<PreviewIcon />}
                    endIcon={previewLoadingJsx}
                    onClick={handlePreview}
                  >Preview</Button>

                  <Button
                    data-testid='button-execute'
                    color='primary'
                    variant='outlined'
                    startIcon={<PlayArrowIcon />}
                    disabled={gridState?.loading}
                    onClick={handleExecute}
                  >Execute</Button>

                  <Button
                    data-testid='button-save'
                    color='secondary'
                    variant='contained'
                    startIcon={<SaveIcon />}
                    disabled={gridState?.loading}
                    onClick={handleSave}
                  >{hasId ? 'UPDATE' : 'SAVE'}</Button>

                  <div></div>

                  {gridState && !gridState.loading &&
                    <Typography
                      data-testid='label-count'
                      variant='h6'
                    >
                      Number of records in result set: {gridState.totalCount}
                    </Typography>
                  }
                </ButtonBar>
              }

              <Typography>{join.message || ''}</Typography>
            </Grid>

            <Grid item xs={12}>
              <ResultsGrid />
            </Grid>
          </Grid>
        </Form>
      </Root>

      <Grid item xs={12}>
        <div style={gridContainerStyles}>
        {gridState &&
          <GridContainer className="ag-theme-alpine">
               <AgGridReact
                data-testid="agGridReact"
                ref={gridRef}
                pagination
                loading={false}
                paginationPageSize={25}
                overlayNoRowsTemplate='Loading...'
                columnDefs={gridState.columns}
                rowData={gridState.rows}
              ></AgGridReact>
          </GridContainer>
        }
        </div>
      </Grid>
    </Grid>
  )
}
