import { ResponsiveFunnel } from '@nivo/funnel'
import React from 'react'
import PropTypes from 'prop-types'

const getFunnelData = (data) => {
  const dataMap = data.map(x => ({ id: x.year.toString(), label: x.year.toString() + '-' + x.month.toString(), value: Number(x.cnt) }))
  return dataMap
}

const FunnelChart = ({ data }) => {
  const dataFunnelFormat = getFunnelData(data)
  return (<ResponsiveFunnel
    data={dataFunnelFormat}
    margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
    valueFormat=">-.4s"
    colors={{ scheme: 'spectral' }}
    borderWidth={20}
    labelColor={{
      from: 'color',
      modifiers: [
        [
          'darker',
          3
        ]
      ]
    }}
    beforeSeparatorLength={100}
    beforeSeparatorOffset={20}
    afterSeparatorLength={100}
    afterSeparatorOffset={20}
    currentPartSizeExtension={10}
    currentBorderWidth={40}
    motionConfig="wobbly"
  />
  )
}

FunnelChart.propTypes = {
  data: PropTypes.any,
  title: PropTypes.any
}

export default FunnelChart
