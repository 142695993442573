import sourcesApi from '../../objects/sourcesAPI'
import apiHelper from '../../objects/apiHelper'
import { openQueryAction, setSubNavigationAction } from '../dataPrep'

const ws = { query: null }
const initialState = {
  downloadOnly: false,
  isDownloading: false,
  isLoading: false,
  isPrecalculation: false,
  isReference: false,
  isSaving: false,
  message: '',
  orderByConditions: [],
  orderBy: { value: 'asc', label: 'Asc' },
  pagesOnPage: 25,
  querySelectFields: [{ value: '*', label: '* (all)' }],
  queryWhereConditions: [],
  resultsGrid: {
    isLoading: false,
    columns: [],
    data: [],
    count: 0
  },
  showOrderByCondition: false
}

export const setQuerySelectAction = {
  type: 'query/SET_QUERY_SELECT'
}

export const clearedEditorAction = {
  type: 'query/CLEARED_EDITOR'
}

export const removedSortAction = {
  type: 'query/REMOVED_SORT'
}

export const setDownloadOnlyAction = {
  type: 'query/SET_DOWNLOAD_ONLY'
}

export const addedSortAction = {
  type: 'query/ADDED_SORT'
}

export const setOrderByAction = {
  type: 'query/SET_ORDER_BY'
}

export const setOrderByConditionsAction = {
  type: 'query/SET_ORDER_BY_CONDITIONS'
}

export const setPagesOnPageAction = {
  type: 'query/SET_PAGES_ON_PAGE'
}

export const setIsReferenceAction = {
  type: 'query/SET_IS_REDIRECT'
}

export const setIsPrecalculationAction = {
  type: 'query/SET_IS_PRECALC'
}

export const addedWhereConditionAction = {
  type: 'query/ADDED_WHERE_CONDITION'
}

export const updatedWhereConditionAction = {
  type: 'query/UPDATED_WHERE_CONDITION'
}

export const removedWhereConditionAction = {
  type: 'query/REMOVED_WHERE_CONDITION'
}

export const updatedGridResults = {
  type: 'query/UPDATED_GRID_RESULTS'
}

export const queryingDatabaseAction = {
  type: 'query/QUERYING_DATABASE'
}

export const receivedQueryResultsAction = {
  type: 'query/RECEIVED_QUERY_RESULTS'
}

export const gettingQueryDatabaseAction = {
  type: 'query/GETTING_QUERY_DATABASE'
}

export const updatedGridResultsAction = {
  type: 'query/UPDATED_GRID_RESULTS'
}

export const enableOrQueryAction = {
  type: 'query/ENABLE_OR_QUERY'
}

const buildLabelType = (data, labels) => {
  return labels.reduce((accum, curr) => {
    const label = data.columns.find(i => i.Name === curr)
    label ? accum.push(`${label.Name}<${label.Type}>`) : accum.push(curr)
    return accum
  }, [])
}

export const setQuerySelect = selectedOption => (dispatch, getState) => {
  const {
    query: { downloadOnly }
  } = getState()

  if (downloadOnly) {
    dispatch({ ...setDownloadOnlyAction, payload: false })
  }
  if (selectedOption) {
    const isbuildingQuery = selectedOption.find(x => x.value === 'USE FORMULA BUILDER')
    const querySelectFields = selectedOption.filter(x => x.value !== 'USE FORMULA BUILDER')
    dispatch({
      ...setQuerySelectAction,
      payload: {
        query: {
          querySelectFields
        },
        formulaDialog: {
          open: isbuildingQuery !== undefined
        }
      }
    })
  } else {
    dispatch({
      ...setQuerySelectAction,
      payload: {
        query: {
          querySelectFields: []
        },
        formulaDialog: {
          open: false
        }
      }
    })
  }
}

export const queryDataBase = (page, pageSize, tableName, id) => async (dispatch, getState) => {
  const {
    project: { country: projCountry, fileData, formatFiles },
    query: { isPrecalculation, isReference, querySelectFields, queryWhereConditions },
    reference: { country: refCountry, referenceSourceInfo }
  } = getState()
  /*
   * One of the 2 places for changing regions in the UI.
   *   1. When loading project data, which happens for any screen.
   *   2. When utilizing a reference table, where the metadata for the table contains information on which region is applicable.
   *      - note: this only happens to apply to the 'sourcesApi', since it should be the only interface point to the regional reference data
   */
  const { region } = apiHelper.getCountry(projCountry || refCountry)
  sourcesApi.setAPIRegion(region)

  dispatch({
    ...queryingDatabaseAction,
    payload: { resultsGrid: { ...initialState.resultsGrid } }
  })

  try {
    const { results, count } = await sourcesApi.querySourceData(id, tableName, querySelectFields,
      queryWhereConditions, { pageNo: page, pageSize }, isPrecalculation)

    const columnsFile = fileData ? fileData.find(i => i.guid === tableName && !isPrecalculation) : []
    const columnsFormat = formatFiles.find(i => i.tableName === tableName && isPrecalculation)
    const columns = isReference ? referenceSourceInfo : columnsFile

    dispatch({
      ...receivedQueryResultsAction,
      payload: {
        resultsGrid: results.length > 0
          ? {
              isLoading: false,
              columns: buildLabelType(isPrecalculation ? columnsFormat : columns, Object.keys(results[0])),
              data: results,
              count
            }
          : { ...initialState.resultsGrid },
        message: results.length > 0 ? undefined : 'No Results Found'
      }
    })
  } catch (ex) {
    dispatch({
      ...receivedQueryResultsAction,
      payload: {
        resultsGrid: { ...initialState.resultsGrid },
        message: ex.data ? ex.data.error : 'Network Error - Try Again'
      }
    })
  }
}

export const getQueryDataBase = (pageNo, pageSize, tableName, id, country = null) => async (dispatch, getState) => {
  const {
    project: { country: projectCountry },
    query: { querySelectFields, queryWhereConditions, isPrecalculation }
  } = getState()

  const url = await apiHelper.getWebsocketUrl(country || projectCountry)
  ws.query = await new WebSocket(url)
  dispatch({
    ...gettingQueryDatabaseAction,
    payload: {
      resultsGrid: { ...initialState.resultsGrid, isLoading: true },
      message: undefined
    }
  })

  const paging = { pageNo, pageSize }

  const body = { projectId: id, tableName, querySelectFields, queryWhereConditions, paging, isPrecalculation, orderByConditions: [], orderBy: {} }
  querySelectFields.forEach(x => {
    if (!x.value.includes('"') && !x.value.includes('*') && x.value.includes('#')) {
      x.value = '`' + x.value + '`'
    }
  })
  ws.query.onopen = (x, req) => {
    ws.query.send(`{"action":"onquery", "body":${JSON.stringify(body)}}`)
    ws.query.onmessage = async e => {
      try {
        await dispatch(updateGridResults(JSON.parse(e.data), tableName))
      } finally {
        ws.query.close()
      }
    }
  }
}

export const updateGridResults = ({ results, count, error }, tableName) => (dispatch, getState) => {
  const {
    project: { fileData, formatFiles },
    query: { isPrecalculation, isReference, referenceSourceInfo }
  } = getState()

  if (results) {
    const columnsFile = fileData ? fileData.find(i => i.guid === tableName && !isPrecalculation) : []
    const columnsFormat = formatFiles.find(i => i.tableName === tableName && isPrecalculation)
    const columns = isReference ? referenceSourceInfo : columnsFile

    dispatch({
      ...updatedGridResultsAction,
      payload: {
        resultsGrid: results.length > 0
          ? {
              isLoading: false,
              columns: buildLabelType(isPrecalculation ? columnsFormat : columns, Object.keys(results[0])),
              data: results,
              count: count || results.length
            }
          : { ...initialState.resultsGrid },
        message: results.length > 0 ? undefined : 'No Results Found'
      }
    })
  } else {
    dispatch({
      ...updatedGridResultsAction,
      payload: {
        resultsGrid: { ...initialState.resultsGrid },
        message: error
      }
    })
  }
}

export const updateOptionQueryWhereList = (key, index, value) => (dispatch, getState) => {
  const { query: { downloadOnly, queryWhereConditions } } = getState()
  if (downloadOnly) {
    dispatch({ ...setDownloadOnlyAction, payload: false })
  }
  if (value !== null && value.value === 'USE FORMULA BUILDER') {
    dispatch({
      ...updatedWhereConditionAction,
      payload: {
        formulaDialog: {
          index,
          key,
          name: '',
          formula: '',
          open: true,
          target: 'queryWhereConditions'
        }
      }
    })
  } else {
    queryWhereConditions[index][key] = value || ''
    dispatch({ ...updatedWhereConditionAction, payload: { query: { queryWhereConditions } } })
  }
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case removedWhereConditionAction.type: {
      const { queryWhereConditions } = state
      queryWhereConditions.splice(payload, 1)
      return {
        ...state,
        queryWhereConditions,
        downloadOnly: false
      }
    }
    case enableOrQueryAction.type: {
      const { index, enable } = payload
      const { queryWhereConditions } = state

      const condition = queryWhereConditions[index]
      condition.enableORQuery = enable

      if (!enable) {
        condition.orColumnName = ''
        condition.orOperation = '='
        condition.orWhereValue = ''
      }
      return {
        ...state,
        queryWhereConditions: [
          ...queryWhereConditions
        ]
      }
    }
    case addedWhereConditionAction.type: {
      const { queryWhereConditions } = state
      return {
        ...state,
        queryWhereConditions: queryWhereConditions.concat({
          enableORQuery: false,
          columnName: '',
          operation: '',
          whereValue: ''
        })
      }
    }
    case setQuerySelectAction.type:
    case updatedWhereConditionAction.type:
    case openQueryAction.type:
      return {
        ...state,
        ...payload.query
      }
    case queryingDatabaseAction.type:
      return {
        ...state,
        resultsGrid: payload,
        message: undefined
      }
    case clearedEditorAction.type: {
      const { isReference } = state
      return {
        ...initialState,
        isReference
      }
    }
    case setPagesOnPageAction.type:
    case setOrderByConditionsAction.type:
    case setOrderByAction.type:
    case addedSortAction.type:
    case receivedQueryResultsAction.type:
    case updatedGridResultsAction.type:
    case setIsPrecalculationAction.type:
    case setIsReferenceAction.type:
    case gettingQueryDatabaseAction.type:
      return {
        ...state,
        ...payload
      }
    case removedSortAction.type: {
      const { downloadOnly } = state
      return Object.assign({
        ...state,
        showOrderByCondition: false,
        orderByConditions: []
      },
      downloadOnly ? { downloadOnly: false } : null)
    }
    case setDownloadOnlyAction.type:
      return {
        ...state,
        downloadOnly: payload
      }
    case setSubNavigationAction.type:
      return {
        ...state,
        message: ''
      }
    default:
      return state
  }
}
