import React from 'react'
import { styled } from '@mui/material'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Typography from '@mui/material/Typography'
import MuiStepConnector from '@mui/material/StepConnector'
import MuiAvatar from '@mui/material/Avatar'
import ErrorIcon from '@mui/icons-material/Error'
import CircularProgress from '@mui/material/CircularProgress'
import MuiCheckCircleIcon from '@mui/icons-material/CheckCircle'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import loadingJobStep from '../store/objects/data/loadingJobStep'

const StepConnector = styled(MuiStepConnector)(({ theme }) => ({
  padding: theme.spacing(2)
}))

const Label = styled('div')(({
  marginLeft: '5%'
}))

const Avatar = styled(MuiAvatar)(({
  width: 20,
  height: 20,
  backgroundColor: 'grey',
  fontSize: 10
}))

const CheckCircleIcon = styled(MuiCheckCircleIcon)(({
  color: 'green',
  zIndex: 1,
  fontSize: 25
}))

const ActiveContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'active'
})(({ active }) => ({
  color: active ? '#784af4' : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center'
}))

const steps = loadingJobStep.steps

const errorMap = [
  { code: 3, step: 1 },
  { code: 4, step: 1 },
  { code: 5, step: 1 },
  { code: 7, step: 3 },
  { code: 13, step: 3 },
  { code: 8, step: 4 },
  { code: 10, step: 5 },
  { code: 11, step: 5 }
]

function getSteps (studyType) {
  return steps[studyType]
}

const QontoStepIcon = (props) => {
  const { active, completed, icon, error } = props
  return (
    <ActiveContainer active={active}>
      {([6, 3].indexOf(icon) < 0) && active && !error && <CircularProgress size={20} />}
      {((([6, 3].indexOf(icon) >= 0) && active) || completed) && !error && <CheckCircleIcon /> }
      {error && <ErrorIcon color='primary' /> }
      {!active && !completed && !error && <Avatar>{icon}</Avatar>}

    </ActiveContainer>
  )
}

export default function LoadingStepper () {
  const { code, errors, message, studyType } = useSelector(({
    project: { studyType },
    valueMapping: { code, message, errors }
  }) => ({
    code, errors, message, studyType
  }))
  const steps = getSteps(studyType)
  const currentStep = steps.find(x => x.code === code)
  const getCurrentStep = currentStep ? currentStep.step : errorMap.find((w) => w.code === code).step
  return (
    <div>
      <Stepper
        connector={
          <StepConnector/>}
        activeStep={getCurrentStep} orientation="vertical">
        {
          steps.map((object, index) => {
            const labelProps = {}
            if (object.step === getCurrentStep && errors && errors.length > 0) {
              labelProps.optional = (
                <Label id={index}>
                  <Typography variant="caption" color="error">
                    {message}
                    <br/>
                    {errors}
                  </Typography>
                </Label>
              )
              labelProps.error = true
            }
            return (
              <Step key={object.step}>
                <StepLabel StepIconComponent={QontoStepIcon} {...labelProps}>
                  <Typography >
                    {object.message}
                  </Typography>
                </StepLabel>
              </Step>

            )
          })}
      </Stepper>
    </div>
  )
}

QontoStepIcon.propTypes = {
  active: PropTypes.any, completed: PropTypes.any, icon: PropTypes.any, error: PropTypes.any
}
