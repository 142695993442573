import React from 'react'
import RediStudy from './redi/Study'
import { useSelector } from 'react-redux'
import { getAccess } from '../../../store/pages/project/async'

export default function Calculation () {
  const user = useSelector(store => store.user)
  const project = useSelector(store => store.project)

  const access = getAccess(
    project.sharedWithIds,
    project.ownerId,
    project.studyType,
    user.role,
    user.ownerId,
    'CALCULATION'
  )

  switch (project.studyType) {
    case 'redi':
    case 'skipMap':
      return <RediStudy
        data-testid='redi'
        access={access}
      />

    default:
      throw new Error(`Invalid studyType: ${project.studyType}`)
  }
}
