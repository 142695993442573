import React from 'react'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { styled } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'

import { saveSynonymDialog, setSynonymValuesAction, toggledSynonymDialogAction } from '../../store/pages/common/synonymDialog'

const OuterContainer = styled('div')(({
  width: 400
}))

const StyledGrid = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(1)
}))

// eslint-disable-next-line react/prop-types
export default function SynonymDialog ({ isWorkflow, mergeData }) {
  const dispatch = useDispatch()
  const { name } = useSelector(({ synonymDialog: { name } }) => ({
    name
  }))
  return (
    <OuterContainer>
      <DialogTitle id="form-dialog-title">Synonym Builder</DialogTitle>
      <DialogContent>
        <Grid container>
          <StyledGrid item xs={12}>
            <TextField
              label="Name"
              margin="normal"
              value={name}
              onChange={e => dispatch({
                ...setSynonymValuesAction,
                payload: {
                  key: 'name',
                  value: e.target.value
                }
              })}
            />
          </StyledGrid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => dispatch(toggledSynonymDialogAction)}
          color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => dispatch(saveSynonymDialog(isWorkflow, mergeData))}
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </OuterContainer>
  )
}
