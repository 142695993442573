import moment from 'moment'
import { typeValidators, isTypeNumeric } from '../../mapping'

export const validationByType = (type, value, validOptions, required, isNullCol) => {
  if (!value && isNullCol) {
    return false
  }

  if (type === 'date' || value) {
    switch (type.toLowerCase()) {
      case 'int':
      case 'bigint':
      case 'numeric':
      case 'double precision':
      case 'decimal':
        return value ? isNaN(value) : true
      case 'date':
        if (!required) {
          return false
        } else {
          return !(value && (moment(value, 'YYYY-MM-DD', true).isValid() ||
            moment(value, 'MM/DD/YYYY', true).isValid() ||
            (moment(value.toString().substring(0, 10), 'YYYY-MM-DD', true).isValid())))
        }
      case 'options':
        return (validOptions.find(option => option.value === value)) === undefined
      case 'varchar':
        return false
      case 'strinvalidation':
        return !validOptions.split(',').includes(value)
      default:
        return false
    }
  } else {
    return true
  }
}

export const showProfileSummary = (fileData) => {
  if (fileData.values.length === 0) {
    return true
  }
  return fileData.distinctCnt > 99
}

export const validateRowLevel = (fileData, rgaColumnsMapValidValues, isNullCol) => {
  const {
    values = [],
    targetDataType,
    valMapp = [],
    inputSourceType = ''
  } = fileData

  const validOptionString = targetDataType === 'varchar' && rgaColumnsMapValidValues !== ''
    ? 'STRINVALIDATION'
    : targetDataType

  return values.reduce((valueSet, accum, idx) => {
    const valueMP = valMapp && valMapp.find(item => item.originalValue === accum.value)
      ? valMapp.find(item => item.originalValue === accum.value).valueMapping
      : accum.value

    const performValidation = showProfileSummary(fileData)

    const currentValueMap = valMapp.find(item => item.originalValue === accum.value)
      ? valMapp.find(item => item.originalValue === accum.value)
      : {}

    if (currentValueMap.exclude === undefined &&
      ((!performValidation &&
        validationByType(validOptionString, valueMP, rgaColumnsMapValidValues, fileData.requiredValidation, isNullCol)) ||
        (performValidation &&
          validateTargetType(fileData, validOptionString, inputSourceType))
      )) {
      valueSet.push(
        {
          valid: 'invalid',
          value: valueMP,
          idx
        }
      )
      currentValueMap.error = true
    } else {
      currentValueMap.error = false
    }
    return valueSet
  }, []).length
}

export const getValidationPerFile = async (mapping, file, rgaColumnsMap, files) => {
  mapping.forEach(item => {
    if (item.columns) {
      validationStep(item.columns, file, item.guid, rgaColumnsMap, item.fileType)
    }
  })
  mapping.forEach(y => {
    if (y.columns) {
      y.totalErrosValue = [...y.columns].reduce((r, accum) => accum.erros ? r + accum.erros : r, 0)
      y.totalErrosType = [...y.columns].reduce((r, accum) => accum.dataTypeMismatch ? ++r : r, 0)
    }
  })
  files.forEach(x => {
    x.erros = mapping.find(y => x.guid === y.guid)
      ? mapping.find(y => x.guid === y.guid).totalErrosValue
      : 0
  })
}

export const getValidationForAllFiles = (files, mappings, fileColumns, rgaColumns, guid) => {
  mappings.filter(y => y.guid === guid).forEach(x => {
    if (x.columns) {
      validationStep(x.columns, fileColumns, x.guid, rgaColumns, x.fileType)
    }
  })
  mappings.forEach(y => {
    if (y.columns) {
      y.totalErrosValue = [...y.columns].reduce((r, accum) => accum.erros ? r + accum.erros : r, 0)
      y.totalErrosType = [...y.columns].reduce((r, accum) => accum.dataTypeMismatch ? ++r : r, 0)
    }
  })
  files.filter(y => y.guid === guid)
    .forEach(x => {
      x.erros = mappings.find(y => x.guid === y.guid)
        ? mappings.find(y => x.guid === y.guid).totalErrosValue
        : 0
    })
}

const validateTargetType = (fileData, rgaColumnsMapValidValues, inputSourceType) => {
  const targetDataType = fileData.targetDataType.toLowerCase()
  const sourceDataType = fileData.sourceDataType

  switch (targetDataType) {
    case 'int':
    case 'integer':
    case 'bigint':
    case 'numeric':
    case 'double':
    case 'double precision':
    case 'character varying':
    case 'decimal':
      return !isTypeNumeric(sourceDataType)
    case 'varchar':
      return (rgaColumnsMapValidValues === 'STRINVALIDATION' && inputSourceType !== 'countries')
    case 'date':
      return !(sourceDataType === 'date')
    default:
      return false
  }
}

const validatingCol = (rgaColumnsMap, column, fileData, fileType, singleSourceDataType, isNullCol) => {
  const { targetDataType, target, valMapp } = column
  const rgaColumnsMapX = rgaColumnsMap.find(column => column.label === target)
  const rgaColumnsMapValidValues = rgaColumnsMapX ? rgaColumnsMapX.valid_values : []

  fileData.valMapp = valMapp
  fileData.targetDataType = targetDataType
  fileData.sourceDataType = singleSourceDataType
  fileData.inputSourceType = rgaColumnsMapX ? rgaColumnsMapX.input_source_type : ''
  fileData.requiredValidation = ((rgaColumnsMapX.required_for_filetype === 'Termination' && fileType === 'T') || (rgaColumnsMapX.required_for_filetype === 'Inforce' && fileType === 'I'))

  return validateRowLevel(fileData, rgaColumnsMapValidValues, isNullCol)
}

const validationStep = (columns, file, guid, rgaColumnsMap, fileType) => {
  columns.forEach(col => {
    let colErrors = 0
    let colQuery = false

    const isNullCol = rgaColumnsMap.find(column => column.name === col.targetColumnName)?.isNull

    if (!col.source) {
      colErrors = 1
    } else {
      const sourceDataTypes = col.sourceDataType ? col.sourceDataType.split('||') : []
      const sources = col.source.split('||') ?? []
      const findFiles = file.filter(item => item.guid === guid && sources.includes(item.fieldName))
      const isNotMultiple = col.isMultiple ? !col.isMultiple : true

      if (col.source === 'Unmapped') {
        colErrors = !col.valMapp || col.valMapp?.length === 0 ? 1 : 0
        findFiles.forEach((findFile) => {
          if (sourceDataTypes.length > 0 && findFile && isNotMultiple) {
            colQuery = showProfileSummary(findFile)
          }
        })
      } else {
        findFiles.forEach((findFile, idx) => {
          if (sourceDataTypes.length > 0 && findFile && isNotMultiple) {
            const singleSourceDataType = sourceDataTypes[idx]
            colErrors += validatingCol(rgaColumnsMap, col, findFile, fileType, singleSourceDataType, isNullCol)
            colQuery = showProfileSummary(findFile)
          }
        })
      }
    }

    // TODO: refactor to not modify column directly
    col.erros = colErrors
    col.query = colQuery
    col.dataTypeMismatch = col.isMultiple
      ? false
      : typeValidators[col.targetDataType](col)
  })
}
