import React from 'react'
import { ResponsiveBar } from '@nivo/bar'
import PropTypes from 'prop-types'

const BarCanvas = ({ data }) => {
  const keys = []
  const dataBarChar = []
  const { sourceDataType } = data

  const symbolOrder = (sourceDataType === 'decimal' || sourceDataType === 'bigint' || sourceDataType === 'double' || sourceDataType === 'int' || sourceDataType === 'integer') ? data.values.sort((a, b) => Number(a.value) - Number(b.value)) : data.values.sort((a, b) => Number(b.count) - Number(a.count))
  symbolOrder
    .map(x => {
      keys.push(x.value || '')
      dataBarChar.push({ label: x.value || '', count: Number(x.count), countColor: 'hsl(333, 70%, 50%)' })
      return keys
    })

  return (
    <ResponsiveBar
      data={dataBarChar}
      keys={['count']}
      indexBy="label"
      margin={{ top: 50, right: 60, bottom: 50, left: 60 }}
      padding={0.05}
      innerPadding={0}
      minValue="auto"
      maxValue="auto"
      groupMode="grouped"
      reverse={false}
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      colors={{ scheme: 'nivo' }}
      colorBy="indexValue"
      borderWidth={0}
      borderRadius={0}
      axisBottom={false}
      borderColor={{
        from: 'color',
        modifiers: [
          [
            'darker',
            1.6
          ]
        ]
      }}
      axisTop={null}
      axisRight={null}
      axisLeft={null}
      enableGridX={true}
      enableGridY={false}
      enableLabel={true}
      isInteractive={true}
      legends={[]}
      labelSkipWidth={11111}
    />
  )
}
BarCanvas.propTypes = {
  data: PropTypes.any
}
export default BarCanvas
